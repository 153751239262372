import {
  ACCEPT_STATE_BACKEND_ACCEPT_STATE_KEY,
  ACCEPT_STATE_CALCULATOR_ACCEPT_STATE,
  ACCEPT_STATE_DESCRIPTION,
  PROCESS_STATE_GELIEFERT,
  PROCESS_STATE_OPTIONS,
} from './constants';
import { type AcceptState } from './types';

export const AcceptStateCalculatorObject = {
  ACCEPT_STATE: ACCEPT_STATE_CALCULATOR_ACCEPT_STATE,
  BACKEND_ACCEPT_STATE_KEY: ACCEPT_STATE_BACKEND_ACCEPT_STATE_KEY,

  /**
   * Calculates the overall accept state based on parties' individual states.
   * @param acceptStateSupplier - State of the supplier.
   * @param acceptStateCarrier - State of the carrier.
   * @param acceptStateRecipient - State of the recipient.
   * @param acceptStateOnBehalfSupplier - State on behalf of the supplier.
   * @param acceptStateOnBehalfCarrier - State on behalf of the carrier.
   * @param acceptStateOnBehalfRecipient - State on behalf of the recipient.
   * @returns The overall accept state.
   */
  calculateOverallAcceptStateFromParties({
    acceptStateCarrier,
    acceptStateOnBehalfCarrier,
    acceptStateOnBehalfRecipient,
    acceptStateOnBehalfSupplier,
    acceptStateRecipient,
    acceptStateSupplier,
  }: {
    acceptStateCarrier: AcceptState;
    acceptStateOnBehalfCarrier: AcceptState;
    acceptStateOnBehalfRecipient: AcceptState;
    acceptStateOnBehalfSupplier: AcceptState;
    acceptStateRecipient: AcceptState;
    acceptStateSupplier: AcceptState;
  }) {
    if (
      [
        acceptStateCarrier,
        acceptStateOnBehalfCarrier,
        acceptStateOnBehalfRecipient,
        acceptStateOnBehalfSupplier,
        acceptStateRecipient,
        acceptStateSupplier,
      ].includes(this.ACCEPT_STATE.DECLINED)
    ) {
      return this.ACCEPT_STATE.DECLINED;
    }

    if (
      [acceptStateRecipient, acceptStateOnBehalfRecipient].includes(
        this.ACCEPT_STATE.APPROVED,
      )
    ) {
      return this.ACCEPT_STATE.APPROVED;
    }

    return this.ACCEPT_STATE.OPEN;
  },

  /**
   * Calculates the overall accept state from a list of article states.
   * @param articleAcceptStates - Array of article accept states.
   * @returns The overall accept state.
   */
  calculateOverallAcceptStateFromArticles(articleAcceptStates: AcceptState[]) {
    if (articleAcceptStates.length === 0) {
      return this.ACCEPT_STATE.OPEN;
    }

    if (articleAcceptStates.includes(this.ACCEPT_STATE.DECLINED)) {
      return this.ACCEPT_STATE.DECLINED;
    }

    if (
      articleAcceptStates.every((state) => state === this.ACCEPT_STATE.APPROVED)
    ) {
      return this.ACCEPT_STATE.APPROVED;
    }

    return this.ACCEPT_STATE.OPEN;
  },

  /**
   * Determines accept state options based on process states.
   * @param processStates - Array of process states.
   * @returns An array of available accept state options.
   */
  getAcceptStateOptionsBasedOnProcessStates(processStates: string[]) {
    if (processStates.length === 0) {
      return [
        this.ACCEPT_STATE.APPROVED,
        this.ACCEPT_STATE.DECLINED,
        this.ACCEPT_STATE.OPEN,
      ];
    }

    const acceptStateOptions: AcceptState[] = [];

    if (processStates.includes(PROCESS_STATE_GELIEFERT)) {
      acceptStateOptions.push(this.ACCEPT_STATE.APPROVED);
    }

    if (PROCESS_STATE_OPTIONS.some((state) => processStates.includes(state))) {
      acceptStateOptions.push(this.ACCEPT_STATE.DECLINED);
    }

    acceptStateOptions.push(this.ACCEPT_STATE.OPEN);

    return acceptStateOptions;
  },

  /**
   * Maps an accept state to its corresponding backend key.
   * @param acceptState - The accept state to map.
   * @returns The corresponding backend key.
   */
  getBackendAcceptStateKey(acceptState: AcceptState) {
    switch (acceptState) {
      case this.ACCEPT_STATE.APPROVED: {
        return this.BACKEND_ACCEPT_STATE_KEY.APPROVED;
      }

      case this.ACCEPT_STATE.DECLINED: {
        return this.BACKEND_ACCEPT_STATE_KEY.DECLINED;
      }

      case this.ACCEPT_STATE.OPEN: {
        return this.BACKEND_ACCEPT_STATE_KEY.OPEN;
      }

      default: {
        return '';
      }
    }
  },

  /**
   * Provides a description for a given accept state.
   * @param acceptState - The accept state to describe.
   * @returns A description for the accept state.
   */
  getAcceptStateDesc(acceptState: AcceptState) {
    switch (acceptState) {
      case this.ACCEPT_STATE.APPROVED: {
        return ACCEPT_STATE_DESCRIPTION.APPROVED;
      }

      case this.ACCEPT_STATE.DECLINED: {
        return ACCEPT_STATE_DESCRIPTION.DECLINED;
      }

      case this.ACCEPT_STATE.OPEN: {
        return ACCEPT_STATE_DESCRIPTION.OPEN;
      }

      default: {
        return '';
      }
    }
  },

  /**
   * Retrieves all available accept states.
   * @returns An array of all accept states.
   */
  getAcceptStates() {
    return [
      this.ACCEPT_STATE.APPROVED,
      this.ACCEPT_STATE.DECLINED,
      this.ACCEPT_STATE.OPEN,
    ];
  },
};

import { ComponentTestIds } from '~/constants/test-ids';

type P = {
  readonly isError?: boolean;
};

export const NoRowsOverlay = ({ isError }: P) => (
  <div
    className="flex size-full items-center justify-center"
    data-testid={ComponentTestIds.TABLE.EMPTY_TABLE}
  >
    {isError ? 'Daten konnten nicht geladen werden.' : 'Keine Einträge'}
  </div>
);

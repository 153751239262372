import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { memo, useRef } from 'react';

import { useGetFilterConfig } from '~/data/deliveryNote';

import { AcceptStateCalculatorObject } from '~/models/acceptState';
import { DeliveryNoteObject } from '~/models/deliveries/DeliveryNote';

import { isCustomFieldName } from '~/utils/customField';
import { type FilterContext } from '~/utils/filters';
import { toCamelCase } from '~/utils/string';

import { SearchInput } from '~/ui/molecules/SearchInput';
import {
  MultiSelectCostCenters,
  MultiSelectCustomFieldValues,
  MultiSelectFilterValues,
  MultiSelectOrganizationalUnits,
  MultiSelectSites,
} from '~/ui/molecules/SelectServerDriven';

type Filter = {
  allOptions: string[];
  disabled: boolean;
  filteredOptions: string[];
  id: string;
  name: string;
  type?: 'bool' | 'enumeration';
};

type FilterInputProps = {
  readonly filterContext: FilterContext;
  readonly filterName: string;
  readonly isDisabled: boolean;
  readonly onChangeValue: (event: unknown, value: string[] | boolean) => void;
  readonly selectableFilters: Filter[];
  readonly selectedValues: string[] | boolean;
  readonly testId?: string;
};

export const FilterInput = memo(
  ({
    filterContext,
    filterName,
    isDisabled,
    onChangeValue,
    selectableFilters,
    selectedValues,
    testId,
  }: FilterInputProps) => {
    const filterConfig = useGetFilterConfig({ filterContext });

    const inputRef = useRef<HTMLInputElement>(null);

    const normalizedFilterName = toCamelCase(filterName) ?? '';
    const isCustomField = isCustomFieldName(filterName);
    const isBooleanCustomField =
      isCustomField &&
      Boolean(
        selectableFilters.find(({ id }) => id === filterName)?.type === 'bool',
      );

    if (
      ['acceptState', 'category', 'processState', 'settledStatus'].includes(
        normalizedFilterName,
      ) ||
      isBooleanCustomField
    ) {
      let options: string[] = [];

      switch (normalizedFilterName) {
        case 'acceptState': {
          options = AcceptStateCalculatorObject.getAcceptStates();
          break;
        }

        case 'processState': {
          options = DeliveryNoteObject.getProcessStateOptions();
          break;
        }

        case 'category': {
          options = ['Eingehend', 'Ausgehend', 'Intern'];
          break;
        }

        case 'settledStatus': {
          options = ['Ja', 'Nein'];
          break;
        }
      }

      const isExclusiveSelection =
        isBooleanCustomField || normalizedFilterName === 'settledStatus';

      const selectedToggleButtonValues =
        isExclusiveSelection && Array.isArray(selectedValues)
          ? selectedValues[0]
          : selectedValues;

      return (
        <ToggleButtonGroup
          className="bg-white"
          data-testid={`${testId}_filter_operator_value_select`}
          exclusive={isExclusiveSelection}
          size="small"
          value={selectedToggleButtonValues}
          onChange={(event, value) => {
            // Always return an array for consistency, even for exclusive fields
            const returnValue = Array.isArray(value) ? value : [value];

            // ToggleButtonGroup returns null when deselecting a value in exclusive mode.
            // This can cause problems with the filter logic, so we filter out null values.
            onChangeValue(
              event,
              returnValue.filter((value) => value !== null),
            );
          }}
        >
          {options.map((option) => (
            <ToggleButton key={option} className="min-w-16 px-4" value={option}>
              {option}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      );
    }

    if (normalizedFilterName === 'permittedToSites') {
      return (
        <MultiSelectSites
          key={filterName}
          className="min-w-96"
          data-testid={`${testId}_filter_operator_value_select`}
          inputRef={inputRef}
          placeholder="Lieferorte auswählen"
          value={Array.isArray(selectedValues) ? selectedValues : []}
          hasEmptyOption
          onChange={(values) => {
            onChangeValue(
              {},
              values.map(({ id }) => id),
            );
          }}
        />
      );
    }

    if (normalizedFilterName === 'permittedCostCenters') {
      return (
        <MultiSelectCostCenters
          key={filterName}
          className="min-w-96"
          data-testid={`${testId}_filter_operator_value_select`}
          inputRef={inputRef}
          placeholder="Kostenstellen auswählen"
          value={Array.isArray(selectedValues) ? selectedValues : []}
          hasEmptyOption
          onChange={(values) => {
            onChangeValue(
              {},
              values.map(({ id }) => id),
            );
          }}
        />
      );
    }

    if (normalizedFilterName === 'ouId') {
      return (
        <MultiSelectOrganizationalUnits
          key={filterName}
          className="min-w-96"
          inputRef={inputRef}
          placeholder="Organisations-Gruppen auswählen"
          value={Array.isArray(selectedValues) ? selectedValues : []}
          onChange={(values) => {
            onChangeValue(
              {},
              values.map(({ id }) => id),
            );
          }}
        />
      );
    }

    if (isCustomField) {
      const hasPredefinedOptions = Boolean(
        selectableFilters.find(({ id }) => id === filterName)?.type ===
          'enumeration',
      );

      if (hasPredefinedOptions) {
        return (
          <MultiSelectCustomFieldValues
            key={filterName}
            className="min-w-96"
            customFieldId={filterName}
            placeholder="Werte auswählen"
            value={Array.isArray(selectedValues) ? selectedValues : []}
            onChange={(values) => {
              onChangeValue(
                {},
                values.map(({ id }) => id),
              );
            }}
          />
        );
      }

      return (
        <SearchInput
          ref={inputRef}
          className="w-96"
          debounceTime={800}
          placeholder="Werte eingeben, getrennt durch Strichpunkte"
          startAdornment={null}
          testId={`${testId}_filter_operator_value_select`}
          value={Array.isArray(selectedValues) ? selectedValues.join('; ') : ''}
          onChange={(value) => {
            onChangeValue(
              {},
              value.split(';').map((v) => v.trim()),
            );
          }}
        />
      );
    }

    if (normalizedFilterName === 'customerNumber') {
      return (
        <SearchInput
          ref={inputRef}
          className="w-96"
          debounceTime={800}
          placeholder="Kundennummer eingeben"
          startAdornment={null}
          testId={`${testId}_filter_operator_value_select`}
          value={Array.isArray(selectedValues) ? selectedValues.join('; ') : ''}
          onChange={(value) => {
            onChangeValue(
              {},
              value.split(';').map((v) => v.trim()),
            );
          }}
        />
      );
    }

    return (
      <MultiSelectFilterValues
        key={filterName}
        className="min-w-96"
        data-testid={`${testId}_filter_operator_value_select`}
        filterConfig={filterConfig}
        filterName={filterName}
        inputRef={inputRef}
        isDisabled={isDisabled}
        placeholder="Wert auswählen"
        value={Array.isArray(selectedValues) ? selectedValues : []}
        hasEmptyOption
        onChange={(values) => {
          onChangeValue({}, values);
        }}
      />
    );
  },
);

FilterInput.displayName = 'FilterInput';

import { type CustomFieldOption } from './types';

/**
 * Represents a single custom field option with its associated properties.
 */
export const CustomFieldOptionObject = {
  /**
   * Creates a custom field option with the provided data.
   * @param customFieldOption - An object containing the custom field option data.
   */
  create(customFieldOption: Partial<CustomFieldOption> = {}) {
    return {
      customerValue: customFieldOption?.customerValue,
      description: customFieldOption?.description,
      displayName: customFieldOption.displayName,
      id: customFieldOption.id,
      name: customFieldOption?.name,
    };
  },
};

import { apiUrl } from '~/constants/environment';
import { LOADING_STATE } from '~/constants/LoadingState';

import { fetchCustomFieldsBulk } from '~/data/customField';
import { USERS_WITH_CUSTOM_FIELDS } from '~/data/user';

import {
  replaceCustomFields,
  setCustomFieldsLoading,
} from '~/redux/customFieldsSlice';
import store from '~/redux/store';

import { CustomFieldObject } from '~/models/customData';

import axios from '~/utils/api-client';
import { Log } from '~/utils/logging';
import { promiseHandler } from '~/utils/promiseHandler';

import AuthService from './auth.service';

const API_URL = apiUrl + '/custom_field';

class CustomFieldService {
  constructor() {
    this.customFieldsLoading = LOADING_STATE.NOT_LOADED;
    this.customFields = [];
  }

  async getAllCustomFields() {
    return axios.get(API_URL + '/all').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.items.map((item) => CustomFieldObject.create(item));
    });
  }

  loadCustomFields = async () => {
    // to not load data again when they are already loading or have already been loaded
    if (
      store.getState().customFields?.customFieldsLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshCustomFields();
  };
  refreshCustomFields = async () => {
    store.dispatch(setCustomFieldsLoading(LOADING_STATE.LOADING));

    const [customFields, error] = await promiseHandler(
      this.getAllCustomFields(),
    );

    if (error) {
      store.dispatch(setCustomFieldsLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load custom fields.', error);
      Log.productAnalyticsEvent(
        'Failed to load custom fields',
        Log.FEATURE.CUSTOM_FIELD,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(
      replaceCustomFields([
        ...customFields,
        ...this.getHardcodedCustomFields(),
      ]),
    );
  };

  // Currently the mappings of the custom data keys is hardcoded based on specific users.
  // Hence, the mappings must be loaded into the redux state to be applied in the delivery list and dln detail view.
  getHardcodedCustomFields() {
    return (
      USERS_WITH_CUSTOM_FIELDS.find(
        (user) => AuthService.getUserIdFromAccessToken() === user.userId,
      )?.customFields ?? []
    );
  }
}

const customFieldServiceInstance = new CustomFieldService();

export default customFieldServiceInstance;

import { type FeatureFlagKey } from './types';

export const featureFlagMap: Record<FeatureFlagKey, string> = {
  accessPermittedSites:
    'Nutzer kann berechtigte Lieferorte und Kostenstellen einsehen',
  concreteDiary: 'Nutzer hat Zugriff auf Betontagebuch',
  concreteEditingEnabled: 'Nutzer kann Betondaten bearbeiten',
  lineItemDetailsEditingDisabled: 'Nutzer kann Artikeldetails nicht bearbeiten',
  ocrEnabled: 'Nutzer kann Lieferscheine mittels OCR einlesen',
  recipientSetArrived: 'Empfänger kann das "Angekommen" Event setzen',
  showOrderAtPreview:
    'Nutzer kann Bestellreferenz in Vorschau anzeigen (mobile App)',
  userCannotSetAccepted: 'Nutzer kann das "Angenommen" Event nicht setzen',
  userCannotSetDeclined: 'Nutzer kann das "Abgelehnt" Event nicht setzen',
  userCannotSetDelivered: 'Nutzer kann das "Geliefert" Event nicht setzen',
  userMetrics: 'Nutzer kann Benutzerstatistiken einsehen',
};

import { type DataExchange } from './types';

export const DataExchangeObject = {
  /**
   * Creates a data exchange with the provided data.
   * @param DataExchange - An object containing the data exchange data.
   */
  create(dataExchange: Partial<DataExchange> = {}) {
    if (!dataExchange?.id) {
      return null;
    }

    return {
      id: dataExchange?.id ?? '',
      activeCustomerIds: dataExchange?.activeCustomerIds ?? '',
      contactAttempts: dataExchange?.contactAttempts ?? [],
      contactedPerson: dataExchange?.contactedPerson ?? null,
      contactingLink: dataExchange?.contactingLink ?? null,
      exchangeDeliveryNotes:
        dataExchange?.exchangeDeliveryNotes ??
        dataExchange?.exchangeLfs ??
        false,
      exchangeInvoices: dataExchange?.exchangeInvoices ?? false,
      exchangeOrders: dataExchange?.exchangeOrders ?? false,
      furtherInformation: dataExchange?.furtherInformation ?? '',
      isExchangeViaPaper: dataExchange?.isExchangeViaPaper ?? false,
      knownIssues: dataExchange?.knownIssues ?? '',
      lastContactAttempt:
        dataExchange?.lastContactAttempt ??
        dataExchange?.contactAttempts?.[0] ??
        null,
      lastContactAttemptTimestamp:
        dataExchange?.lastContactAttemptTimestamp ??
        dataExchange?.contactAttempts?.[0]?.timestamp ??
        null,
      receiverId: dataExchange?.receiverId ?? '',
      responsiblePerson: dataExchange?.responsiblePerson ?? null,
      senderId: dataExchange?.senderId ?? '',
    };
  },
};

import { useAuth } from 'oidc-react';

import { redirectUrl } from '~/constants/environment';

import { Log } from '~/utils/logging';

import { Spinner } from '~/ui/atoms/Spinner';
import { Tooltip } from '~/ui/atoms/Tooltip';
import { LogoWithText } from '~/ui/atoms/VestigasLogo';

import { BRAND_COLOR } from '../constants';

type P = {
  readonly isFetching: boolean;
};

export const PageHeader = ({ isFetching }: P) => {
  const auth = useAuth();

  const logout = () => {
    Log.productAnalyticsEvent('Logout', Log.FEATURE.AUTHENTICATION);
    auth.signOutRedirect({
      post_logout_redirect_uri: redirectUrl,
    });
  };

  return (
    <div
      className="flex-none text-white"
      style={{ background: BRAND_COLOR, padding: '1vw' }}
    >
      <div className="align-center flex flex-1 justify-between">
        <Tooltip title="Ausloggen">
          <button
            className="cursor-pointer border-0 bg-transparent text-white"
            type="button"
            onClick={logout}
          >
            <LogoWithText style={{ height: '4vw' }} />
          </button>
        </Tooltip>
        <h1
          className="m-0 text-center font-normal"
          style={{
            flex: '4',
            fontSize: '3vw',
          }}
        >
          Abholer-Übersicht
        </h1>
        <div className="flex-1">{isFetching && <Spinner />}</div>
      </div>
    </div>
  );
};

export const DataExchangeCurrentIssuesIcon = ({
  className,
  style,
}: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 8.98002C22.4279 7.39843 20.558 6.14402 18.4983 5.28925C16.4386 4.43448 14.23 3.9963 12 4.00002C7.31 4.00002 3.07 5.90002 0 8.98002L12 21V12H20.99L24 8.98002ZM19.59 14L17.5 16.09L17.2 15.79L15.41 14L14 15.41L15.79 17.2L16.09 17.5L14 19.59L15.41 21L17.5 18.92L19.59 21L21 19.59L18.92 17.5L21 15.41L19.59 14Z"
      fill="currentColor"
    />
  </svg>
);

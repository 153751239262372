import { useQueryClient } from '@tanstack/react-query';
import ms from 'ms';
import { useDispatch } from 'react-redux';

import { FEATURE_FLAG } from '~/constants/featureFlags';
import { LOADING_STATE } from '~/constants/LoadingState';

import {
  fetchUserData,
  queryKeysUser,
  useGetUserUtils,
  useQueryUserData,
} from '~/data/user';

import { saveCompanyAccount } from '~/redux/companyAccountSlice';
import {
  setDashboard_filterGroups,
  setDelivery_filterGroups,
  setInvoice_filterGroups,
} from '~/redux/filtersSlice';
import {
  saveDeliveryTabs,
  saveUserCompany,
  saveUserFeatureFlags,
  saveUserPermissions,
  saveUserType,
  setUserinfoLoading,
  setUserPermissionsLoading,
  updateUserInfos,
} from '~/redux/userinfoSlice';

import ThirdPartyService from '~/services/thirdParty.service';
import UserService from '~/services/user.service';

import { Log } from '~/utils/logging';
import { promiseHandler } from '~/utils/promiseHandler';

import { useSaveLoadedUserData } from './useSaveLoadedUserData';

/**
 * Custom hook for loading user data.
 *
 * This hook provides functionality to load user data either from the window.redux_state
 * (if available and successfully loaded) or by fetching from the backend using UserService.
 * It also initializes third-party services and loads delivery notes and templates.
 *
 * @returns {Object} An object containing the loadUserData function.
 */
export const useLoadUserData = () => {
  const dispatch = useDispatch();

  const { saveLoadedUserData } = useSaveLoadedUserData();

  const queryClient = useQueryClient();

  /**
   * Loads user data and associated information.
   *
   * This function checks if data is available in window.redux_state first.
   * If so, it updates the Redux store with this data. Otherwise, it fetches
   * the data from the backend using UserService.
   *
   * @returns {Promise<void>}
   */
  async function loadUserData(UserUtils) {
    const { FAILED, LOADING, SUCCEEDED } = LOADING_STATE;

    if (globalThis.redux_state) {
      /**
       * Opening an item in a new browser tab using BrowserUtils.openMenuItemInNewTab or BrowserUtils.openNewTab
       * passes the whole Redux state to the new tab as window.redux_state.
       * If that is the case, we don't need to load the data again from the backend,
       * but push the data from the window object into Redux state.
       */
      const {
        companyAccount: { companyAccount },
        filters: {
          dashboard_filterGroups,
          delivery_filterGroups,
          invoice_filterGroups,
        },
        userinfo: {
          deliveryTabs,
          userinfo,
          userinfoLoading,
          userPermissions,
          userPermissionsLoading,
        },
      } = globalThis.redux_state;
      const { company, isVestigasSupport, userFeatureFlags, userType } =
        userinfo;

      if (
        userinfoLoading === SUCCEEDED &&
        userPermissionsLoading === SUCCEEDED
      ) {
        dispatch(saveCompanyAccount(companyAccount));
        dispatch(saveDeliveryTabs(deliveryTabs));
        dispatch(saveUserCompany(company));
        dispatch(saveUserFeatureFlags(userFeatureFlags));
        dispatch(saveUserPermissions(userPermissions));
        dispatch(saveUserType(userType));
        dispatch(setDashboard_filterGroups(dashboard_filterGroups));
        dispatch(setDelivery_filterGroups(delivery_filterGroups));
        dispatch(setInvoice_filterGroups(invoice_filterGroups));
        dispatch(updateUserInfos(userinfo));

        ThirdPartyService.initSentryCompany(company.name);
        ThirdPartyService.initProductAnalyticsTools(
          companyAccount.featureFlags[FEATURE_FLAG.PRODUCT_ANALYTICS_TOOLS],
          company.name,
          UserUtils,
        );

        Log.info('Userinfo state passed via window.redux_state.');

        return;
      }
    }

    dispatch(setUserPermissionsLoading(LOADING));
    dispatch(setUserinfoLoading(LOADING));

    try {
      const response = await queryClient.fetchQuery({
        queryKey: queryKeysUser.getData(),
        queryFn: () => fetchUserData(),
        staleTime: ms('60s'),
      });

      dispatch(setUserPermissionsLoading(SUCCEEDED));
      dispatch(setUserinfoLoading(SUCCEEDED));

      saveLoadedUserData(response);
    } catch {
      dispatch(setUserPermissionsLoading(FAILED));
      dispatch(setUserinfoLoading(FAILED));
    }
  }

  return {
    loadUserData,
  };
};

import { useCallback, useEffect, useState } from 'react';

import { type UncefactUnitType } from '~/constants/units';

import { dateUtils, parseDate } from '~/utils/dateUtils';
import { precision } from '~/utils/number';
import { clone } from '~/utils/object';
import { getAbbreviatedUnit } from '~/utils/unit';
import UnitUtils from '~/utils/unitUtils';
import { usePrevious } from '~/utils/usePrevious';

import { SkeletonGridCell } from '~/ui/molecules/Datagrid/components';

import { SORTABLE_COLUMNS } from './constants';

export const useReportColumns = ({
  isLoading,
  reportData,
  selectableColumns,
  selectedColumns,
}: {
  isLoading: boolean;
  reportData: { data: any[] };
  selectableColumns: Array<{ name: string; field: string }>;
  selectedColumns: string[];
}) => {
  const previousSelectedColumns = usePrevious(selectedColumns);
  const [skeletonColumns, setSkeletonColumns] = useState(new Set());

  // Track which columns should show skeletons
  useEffect(() => {
    if (selectedColumns) {
      const newSkeletonColumns = new Set(skeletonColumns);

      for (const column of selectedColumns) {
        // Only show skeleton for newly added columns
        if (!previousSelectedColumns?.includes(column)) {
          newSkeletonColumns.add(column);
        }
      }

      setSkeletonColumns(newSkeletonColumns);
    }
  }, [selectedColumns, previousSelectedColumns]);

  // Clear skeleton state when data is loaded
  useEffect(() => {
    if (!isLoading && reportData?.data) {
      const updatedSkeletonColumns = new Set(skeletonColumns);
      for (const column of selectedColumns) {
        // Only remove skeleton if we have data for this column
        const hasData = reportData.data.some(
          (row) => row[column] !== undefined,
        );
        if (hasData) {
          updatedSkeletonColumns.delete(column);
        }
      }

      setSkeletonColumns(updatedSkeletonColumns);
    }
  }, [isLoading, reportData?.data]);

  const getSelectedColumns = useCallback(() => {
    const columns = clone(
      selectedColumns
        .map((column) =>
          selectableColumns.find(({ field }) => field === column),
        )
        .filter(Boolean)
        .filter(({ field }) => field),
    );

    for (const column of columns) {
      if (!column) {
        continue;
      }

      // Disable reordering for all columns since we are controlling the order externally via DashboardReportChips.
      column.disableReorder = true;
      column.sortable = SORTABLE_COLUMNS.includes(column.field);

      if (column.type === 'date') {
        // Re-add formatting because functions get lost f.i. when getting the columns from localStorage.
        // renderCell is required here instead of valueFormatter to override the skeleton cell rendering.
        column.valueGetter = (value: string) => parseDate(value);
        column.renderCell = ({ value }: { value: string }) =>
          dateUtils.getFormattedDate_safe(
            parseDate(value),
            dateUtils.DATE_FORMAT.DD_MM_YYYY,
          );
      }

      if (skeletonColumns.has(column.field)) {
        // Show skeleton cell if column was added and its data is not loaded yet
        column.renderCell = () => (
          <SkeletonGridCell className="w-full" height={14} />
        );
      }
    }

    return columns;
  }, [selectableColumns, selectedColumns, skeletonColumns]);

  const getColumns = useCallback(() => {
    let maxPrecision = 0;

    if (reportData) {
      for (const row of reportData.data) {
        const p = precision(row.amount);
        if (p > maxPrecision && p <= 2) {
          maxPrecision = p;
        }
      }
    }

    const columns = getSelectedColumns();

    columns.push(
      {
        field: 'amount',
        headerName: 'Menge',
        minWidth: 100,
        valueFormatter: (value: number) =>
          UnitUtils.formatDeWithPrecision_safe(
            value,
            maxPrecision,
            maxPrecision,
          ),
        resizable: false,
        type: 'number',
      },
      {
        field: 'unitType',
        headerName: 'Einheit',
        minWidth: 60,
        valueFormatter(value: UncefactUnitType) {
          return getAbbreviatedUnit(value);
        },
        resizable: false,
        sortable: false,
      },
    );

    return columns;
  }, [reportData?.data, getSelectedColumns]);

  return {
    getColumns,
  };
};

import store from '~/redux/store';

import { camelcaseKeysFromApi } from '~/services/kyClient';

import { sortByKey, sumBy, unique, uniqueBy } from '~/utils/array';
import { parseDate } from '~/utils/dateUtils';

import InvoiceParser from '~/parser/InvoiceParser';

import { DeliveryNoteObject } from '../deliveries/DeliveryNote';
import { CompanyObject } from '../masterdata/Company';

import { InvoiceCheckCategoryObject } from './invoiceCheckCategoryUtils';
import { InvoiceCheckResultObject } from './invoiceCheckResultUtils';
import { InvoicePositionObject } from './invoicePositionUtils';

export default class Invoice {
  constructor(assetMain, direction) {
    this.version = assetMain?.meta?.v ?? Invoice.VERSION.V1;
    this.direction = direction;

    if (this.version === Invoice.VERSION.V1) {
      this.init_v1(assetMain);
    } else if (this.version === Invoice.VERSION.V2) {
      this.init_v2(assetMain);
    }

    this.checkCategory = {
      amountApprovedCheck: InvoiceCheckCategoryObject.create(
        InvoiceCheckCategoryObject.CATEGORIES.AMOUNT_APPROVED_CHECK,
        this.checkResults,
      ),
      amountCheck: InvoiceCheckCategoryObject.create(
        InvoiceCheckCategoryObject.CATEGORIES.AMOUNT_CHECK,
        this.checkResults,
      ),
      articleExistsCheck: InvoiceCheckCategoryObject.create(
        InvoiceCheckCategoryObject.CATEGORIES.ARTICLE_EXISTS_CHECK,
        this.checkResults,
      ),
      dlnCheck: InvoiceCheckCategoryObject.create(
        InvoiceCheckCategoryObject.CATEGORIES.DLN_CHECK,
        this.checkResults,
      ),
      formalCheck: InvoiceCheckCategoryObject.create(
        InvoiceCheckCategoryObject.CATEGORIES.FORMAL_CHECK,
        this.checkResults,
      ),
      priceCheck: InvoiceCheckCategoryObject.create(
        InvoiceCheckCategoryObject.CATEGORIES.PRICE_CHECK,
        this.checkResults,
      ),
      signatureCheck: InvoiceCheckCategoryObject.create(
        InvoiceCheckCategoryObject.CATEGORIES.SIGNATURE_CHECK,
        this.checkResults,
      ),
    };

    this.status = this.getStatus();
    this.subStatus = this.getSubStatus();

    this.initNoCheckingPossibleCheckResults();

    this.chartCategories = this.getChartCategories();

    this.toSite = '';
    this.containsFilteredDeliveryNote = false;
    // To track whether all referenced delivery notes have been found in the store so that this.toSite and delayed signed dlns could have been initialized.
    this.referencedDeliveryNotesLoaded = false;
  }

  init_v1(assetMain) {
    assetMain = camelcaseKeysFromApi(assetMain);
    InvoiceParser.parseAssetMain_v1(assetMain);

    this.id = assetMain?.id;
    this.number = assetMain?.body?.header?.id;
    this.date = parseDate(assetMain?.body?.header?.date);
    this.parsedDate = parseDate(assetMain?.meta?.datetimeParsed);
    this.totalPriceGross = Number.parseFloat(
      assetMain?.body?.invoice?.totalAmount?.grossTotal,
    );
    this.totalPriceNet = Number.parseFloat(
      assetMain?.body?.invoice?.totalAmount?.netTotal,
    );
    this.totalTax = Number.parseFloat(
      assetMain?.body?.invoice?.totalAmount?.taxTotal,
    );
    this.logisticFees = Number.parseFloat(
      assetMain?.body?.invoice?.totalAmount?.logisticFees ?? 0,
    );
    this.generalAllowance = Number.parseFloat(
      assetMain?.body?.invoice?.totalAmount?.generalAllowance ?? 0,
    );
    this.currency = assetMain?.body?.invoice?.totalAmount?.currency;
    this.seller = CompanyObject.create(
      assetMain?.body?.invoice?.seller?.legalOrganization,
    );
    this.buyer = CompanyObject.create(
      assetMain?.body?.invoice?.buyer?.legalOrganization,
    );
    this.referencedDeliveryNotes = assetMain?.meta?.dnAssetIdList ?? [];
    this.checkResults =
      assetMain?.meta?.checkResults?.map((checkResult) =>
        InvoiceCheckResultObject.create(checkResult, Invoice.VERSION.V1),
      ) ?? [];

    this.positions = assetMain?.body?.invoice?.items?.map((item) =>
      InvoicePositionObject.create(item, this.checkResults, Invoice.VERSION.V1),
    );
  }

  init_v2(assetMain) {
    assetMain = camelcaseKeysFromApi(assetMain);
    InvoiceParser.parseAssetMain_v2(assetMain);

    this.id = assetMain?.assetId;
    this.number = assetMain?.invoiceNr;
    this.date = assetMain?.date;
    this.parsedDate = assetMain?.meta?.datetimeParsed;
    this.originalFilename = assetMain?.meta?.originalFilename;
    this.totalPriceGross = Number.parseFloat(
      assetMain?.totalAmount?.grossTotal,
    );
    this.totalPriceNet = Number.parseFloat(assetMain?.totalAmount?.netTotal);
    this.totalTax = Number.parseFloat(assetMain?.totalAmount?.taxTotal);
    this.logisticFees = Number.parseFloat(
      assetMain?.totalAmount?.logisticFees ?? 0,
    );
    this.generalAllowance =
      Number.parseFloat(assetMain?.totalAmount?.generalAllowance ?? 0) * -1;
    this.currency = assetMain?.totalAmount?.currency;
    this.seller = CompanyObject.create(assetMain?.seller);
    this.buyer = CompanyObject.create(assetMain?.buyer);
    this.referencedDeliveryNotes = assetMain?.meta?.dnAssetIdList ?? [];
    this.checkResults =
      assetMain?.meta?.checkResults?.map((checkResult) =>
        InvoiceCheckResultObject.create(checkResult, Invoice.VERSION.V2),
      ) ?? [];

    this.positions = assetMain?.items?.map((item) =>
      InvoicePositionObject.create(item, this.checkResults, Invoice.VERSION.V2),
    );

    this.watermark = assetMain?.meta?.watermark;
  }

  getStatus() {
    if (
      this.watermark === Invoice.WATERMARK.GREY_CREDIT_NOTE ||
      this.watermark === Invoice.WATERMARK.GREY_DLN_REFERENCE_MISSING
    ) {
      return InvoiceCheckResultObject.STATUS.NO_CHECKING_POSSIBLE;
    }

    if (
      Object.keys(this.checkCategory).some(
        (key) =>
          this.checkCategory[key].status ===
          InvoiceCheckResultObject.STATUS.ERROR,
      )
    ) {
      return InvoiceCheckResultObject.STATUS.ERROR;
    }

    if (
      Object.keys(this.checkCategory).some(
        (key) =>
          this.checkCategory[key].status ===
          InvoiceCheckResultObject.STATUS.WARNING,
      )
    ) {
      return InvoiceCheckResultObject.STATUS.WARNING;
    }

    if (
      Object.keys(this.checkCategory).some(
        (key) =>
          this.checkCategory[key].status ===
          InvoiceCheckResultObject.STATUS.DELAYED_SUCCESS,
      )
    ) {
      return InvoiceCheckResultObject.STATUS.DELAYED_SUCCESS;
    }

    if (
      Object.keys(this.checkCategory).some(
        (key) =>
          this.checkCategory[key].status ===
          InvoiceCheckResultObject.STATUS.SUCCESS,
      )
    ) {
      return InvoiceCheckResultObject.STATUS.SUCCESS;
    }

    return InvoiceCheckResultObject.STATUS.MANUAL;
  }

  getSubStatus() {
    if (this.status !== InvoiceCheckResultObject.STATUS.NO_CHECKING_POSSIBLE) {
      return null;
    }

    if (this.watermark === Invoice.WATERMARK.GREY_CREDIT_NOTE) {
      return Invoice.SUB_STATUS.CREDIT_NOTE.KEY;
    }

    if (this.watermark === Invoice.WATERMARK.GREY_DLN_REFERENCE_MISSING) {
      return Invoice.SUB_STATUS.DLN_MISSING.KEY;
    }

    return null;
  }

  // If the status of the invoice is "no checking possible", also the categories of the invoice check should be displayed in grey.
  initNoCheckingPossibleCheckResults() {
    if (this.status !== InvoiceCheckResultObject.STATUS.NO_CHECKING_POSSIBLE) {
      return;
    }

    this.checkCategory.dlnCheck.status =
      InvoiceCheckResultObject.STATUS.NO_CHECKING_POSSIBLE;
    this.checkCategory.signatureCheck.status =
      InvoiceCheckResultObject.STATUS.NO_CHECKING_POSSIBLE;
    this.checkCategory.articleExistsCheck.status =
      InvoiceCheckResultObject.STATUS.NO_CHECKING_POSSIBLE;
    this.checkCategory.amountCheck.status =
      InvoiceCheckResultObject.STATUS.NO_CHECKING_POSSIBLE;
    this.checkCategory.amountApprovedCheck.status =
      InvoiceCheckResultObject.STATUS.NO_CHECKING_POSSIBLE;
  }

  getChartCategories() {
    const chartCategories = [];

    if (!this.positions) {
      return chartCategories;
    }

    for (const x of Object.keys(InvoiceCheckCategoryObject.CHART_CATEGORIES)) {
      const positions = this.positions.filter(
        (position) =>
          position.chartCategory ===
          InvoiceCheckCategoryObject.CHART_CATEGORIES[x].NAME,
      );

      const deliveryNotes = positions.map(({ dlnId, dlnNumber }) => ({
        id: dlnId,
        number: dlnNumber,
      }));

      chartCategories.push({
        amount: sumBy(positions, ({ amount }) => amount),
        deliveryNotes: uniqueBy(
          sortByKey(deliveryNotes, 'number'),
          ({ number }) => number,
        ),
        name: InvoiceCheckCategoryObject.CHART_CATEGORIES[x].NAME,
      });
    }

    return chartCategories;
  }

  getDescriptiveSubStatus() {
    return Invoice.getDescriptiveSubStatus(this.subStatus);
  }

  initWithReferencedDeliveryNotes(
    updateToSiteAndStatus,
    updateContainsFilteredDeliveryNote,
    deliveryNotes = [],
  ) {
    // No point to init the referenced delivery notes if there are none.
    if (!this.referencedDeliveryNotes?.length) {
      return;
    }

    if (!updateToSiteAndStatus && !updateContainsFilteredDeliveryNote) {
      return;
    }

    this.referencedDeliveryNotesLoaded = deliveryNotes.length > 0;

    if (updateToSiteAndStatus) {
      this.toSite = Invoice.getToSite(deliveryNotes);
      this.updateStatus(deliveryNotes);
    }

    if (updateContainsFilteredDeliveryNote) {
      this.updateContainsFilteredDeliveryNote();
    }
  }

  updateContainsFilteredDeliveryNote() {
    const filteredDeliveryNotes =
      store
        .getState()
        ?.deliveryNotes?.filteredDeliveryNotes?.filter(({ id }) =>
          this.referencedDeliveryNotes?.includes(id),
        ) ?? [];

    this.containsFilteredDeliveryNote = filteredDeliveryNotes.length > 0;
  }

  updateStatus(deliveryNotes) {
    if (!deliveryNotes?.length) {
      return;
    }

    // If we have already updated the status based on the delivery notes, don't do it again
    // because this leads to an incorrect determination of the delayedSuccessChecks.
    if (
      this.checkCategory.signatureCheck.status ===
      InvoiceCheckResultObject.STATUS.DELAYED_SUCCESS
    ) {
      return;
    }

    // The delayedSuccessChecks should be determined based on deliveryNote.id.
    // Unfortunately, this is currently not possible because due to a bug in the backend, ids of the referenced delivery notes are not provided in the check results. -> https://innovent-consulting.atlassian.net/browse/VGSD-2609
    // As a workaround, filter the delivery notes by deliveryNote.number which should also be unique.
    // Though, change this when backend has fixed the bug.
    const signedDeliveryNoteNumbers = new Set(
      deliveryNotes
        .filter(
          (deliveryNote) =>
            deliveryNote.processState ===
            DeliveryNoteObject.PROCESS_STATE.DELIVERED.STRING,
        )
        .map(({ number }) => number),
    );

    this.checkCategory.signatureCheck.delayedSuccessChecks =
      this.checkCategory.signatureCheck.errorChecks.filter((errorCheck) =>
        errorCheck.deliveryNotes.every((deliveryNote) =>
          signedDeliveryNoteNumbers.has(deliveryNote.number),
        ),
      );
    this.checkCategory.signatureCheck.errorChecks =
      this.checkCategory.signatureCheck.errorChecks.filter((errorCheck) =>
        errorCheck.deliveryNotes.some(
          (deliveryNote) => !signedDeliveryNoteNumbers.has(deliveryNote.number),
        ),
      );

    // If delivery notes have been signed in the meantime, the invoice status must be updated.
    if (
      this.checkCategory.signatureCheck.delayedSuccessChecks.length > 0 &&
      this.checkCategory.signatureCheck.errorChecks.length === 0
    ) {
      this.checkCategory.signatureCheck.status =
        InvoiceCheckResultObject.STATUS.DELAYED_SUCCESS;
    }

    this.status = this.getStatus();
  }

  updateToSite(deliveryNotes) {
    this.toSite = Invoice.getToSite(deliveryNotes);
  }

  static getToSite(deliveryNotes) {
    if (!deliveryNotes?.length) {
      return '';
    }

    let toSitesRecipient = [];
    let permittedToSites = [];

    for (const deliveryNote of deliveryNotes) {
      if (deliveryNote?.toSiteRecipient?.name) {
        toSitesRecipient.push(deliveryNote.toSiteRecipient.name);
      }

      for (const permittedToSite of deliveryNote?.permittedToSites ?? []) {
        if (permittedToSite?.name) {
          permittedToSites.push(permittedToSite.name);
        }
      }
    }

    toSitesRecipient = unique(toSitesRecipient);
    permittedToSites = unique(permittedToSites);

    // Give higher priority to toSitesRecipient as it has been actively set by the suer when signing the dln or via the site identifier.
    // Thus, if toSitesRecipient is given, ignore permittedToSites.
    if (toSitesRecipient.length === 1) {
      return toSitesRecipient[0];
    }

    if (toSitesRecipient.length > 1) {
      return 'Verschiedene Lieferorte';
    }

    // If toSitesRecipient isn't set, get the toSite from the permittedToSites.
    if (permittedToSites.length === 1) {
      return permittedToSites[0];
    }

    if (permittedToSites.length > 1) {
      return 'Verschiedene Lieferorte';
    }

    // If nothing is given, return an empty String.
    return '';
  }

  static getDescriptiveSubStatus(subStatus) {
    if (!subStatus) {
      return null;
    }

    return Object.values(Invoice.SUB_STATUS).find(
      (value) => value.KEY === subStatus,
    ).DESCRIPTION;
  }

  static getById(id) {
    const invoice = store
      .getState()
      ?.invoices.incomingInvoices.find((invoice) => invoice.id === id);

    if (invoice) {
      return invoice;
    }

    return store
      .getState()
      ?.invoices.outgoingInvoices.find((invoice) => invoice.id === id);
  }

  static getNumberById(id) {
    const number = store
      .getState()
      ?.invoices.incomingInvoices.find((invoice) => invoice.id === id)?.number;

    if (number) {
      return number;
    }

    return store
      .getState()
      ?.invoices.outgoingInvoices.find((invoice) => invoice.id === id)?.number;
  }

  static VERSION = {
    V1: '1.0',
    V2: '2.0',
  };
  static WATERMARK = {
    CORRECT: 'correct',
    ERRONEOUS: 'erroneous',
    GREY_CREDIT_NOTE: 'grey-credit_note',
    GREY_DLN_REFERENCE_MISSING: 'grey-dln_reference_missing',
  };
  static SUB_STATUS = {
    CREDIT_NOTE: {
      DESCRIPTION: 'Gutschriften werden derzeit noch nicht unterstützt.',
      KEY: 'credit-note',
    },
    DLN_MISSING: {
      DESCRIPTION: 'Keine referenzierten Lieferungen gefunden.',
      KEY: 'dln-missing',
    },
  };
  static DIRECTION = {
    INCOMING: 'incoming',
    NA: 'na',
    OUTGOING: 'outgoing', // When user navigates on invoice via PDF link, we don't know if invoice is incoming or outgoing.
  };
  static PROPERTY = {
    BUYER: {
      KEY: 'buyer',
      STRING: 'Empfänger',
    },
    CURRENCY: {
      KEY: 'currency',
      STRING: 'Währung',
    },
    DATE: {
      KEY: 'date',
      STRING: 'Rechnungsdatum',
      TYPE: 'date',
    },
    NUMBER: {
      KEY: 'number',
      STRING: 'Rechnungs-Nr.',
    },
    PARSED_DATE: {
      KEY: 'parsedDate',
      STRING: 'Prüfdatum',
      TYPE: 'date',
    },
    SELLER: {
      KEY: 'seller',
      STRING: 'Rechnungssteller',
    },
    STATUS: {
      KEY: 'status',
      STRING: 'Status',
    },
    TO_SITE: {
      KEY: 'toSite',
      STRING: 'Lieferort',
    },
    TOTAL_PRICE_GROSS: {
      KEY: 'totalPriceGross',
      STRING: 'Betrag',
      TYPE: 'number',
    },
  };
  static FILTER_CATEGORY = {
    AMOUNT_APPROVED_CHECK_ERROR: 'Reklamationen vorhanden',
    AMOUNT_CHECK_CHECK_ERROR: 'Mengenabgleich fehlerhaft',
    ARTICLE_EXISTS_CHECK_ERROR: 'Artikel fehlen',
    CORRECT: 'Rechnungsprüfung erfolgreich',
    DELAYED_SIGNED: 'Nachträglich signiert',
    DLN_CHECK_ERROR: 'Lieferscheinprüfung fehlerhaft',
    FORMAL_CHECK_ERROR: 'Formale Prüfung fehlerhaft',
    NO_CHECKING_POSSIBLE: 'Keine Prüfung möglich',
    SIGNATURE_CHECK_ERROR: 'Signaturen fehlen',
  };

  /** ################# TO MOCK TEST DATA ################# **/

  mockInvoiceChecks(check_results) {
    check_results?.push(
      // FORMAL CHECK
      // formal check basic data
      this.getCheckResult('SenderNameExists', '', '', '', '', ''),
      this.getCheckResult('SenderAddressExists', '', '', ''),
      this.getCheckResult('ReceiverNameExists', '', '', ''),
      this.getCheckResult('ReceiverAddressExists', '', '', ''),
      this.getCheckResult('TaxIDExists', '', '', ''),
      this.getCheckResult('InvoiceDateExists', '', '', ''),
      this.getCheckResult('InvoiceIDExists', '', '', ''),
      this.getCheckResult('DeliveryDateExists', '', '', '', '', ''),
      this.getCheckResult('TotalPriceExists', '', '', '', '', ''),
      // formal check items
      this.getCheckResult('ItemIdentificationExists', '1', '', ''),
      this.getCheckResult('ItemCountExists', '1', '', 'Büroartikel'),
      this.getCheckResult('ItemUnitTypeExists', '1', '', 'Büroartikel'),
      this.getCheckResult('ItemUnitTypeExists', '1', '', 'Holz'),
      this.getCheckResult('ItemIdentificationExists', '2', '', ''),
      this.getCheckResult('ItemCountExists', '3', '', 'Büroartikel'),
      this.getCheckResult('ItemTaxRateExists', '3', '', 'Büroartikel'),
      this.getCheckResult('ItemTaxRateExists', '4567', '', 'Büroartikel'),
      this.getCheckResult('ItemTaxRateExists', '4567', '', 'Büroartikel'),
      this.getCheckResult(
        'MaterialNetPriceCalculation',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        'Kies',
      ),
      this.getCheckResult(
        'MaterialNetPriceCalculation',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        'Schutt',
      ),
      this.getCheckResult(
        'MaterialGrossPriceCalculation',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        'Kies',
      ),
      this.getCheckResult(
        'MaterialTaxRateCheck',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        'Kies',
      ),
      this.getCheckResult(
        'MaterialTaxAmountCalculation',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        'Kies',
      ),
      this.getCheckResult(
        'MaterialNetPriceCalculation',
        '999',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        'Kies',
        '100',
        '900',
      ),
      this.getCheckResult(
        'MaterialNetPriceCalculation',
        '999',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        'Schutt',
        '10000',
        '90',
      ),
      this.getCheckResult('TaxAmountExists', '', '', '', '', ''),
      this.getCheckResult('TaxRateExists', '', '', '', '', ''),
      this.getCheckResult('TaxRateCheck', '', '', '', '13', ''),
      this.getCheckResult('TotalTaxAmountCalculation', '', '', '', '100', '99'),
      this.getCheckResult(
        'TotalGrossPriceCalculation',
        '',
        '',
        '',
        '200',
        '201',
      ),
      this.getCheckResult('TotalNetPriceCalculation', '', '', '', '', ''),
      // formal check VESTIGAS data
      this.getCheckResult('MatchingRecipientData', '', '', '', '', ''),
      this.getCheckResult('MatchingSenderData', '', '', '', '', ''),
      this.getCheckResult('UniqueInvoiceID', '', '', '', '', ''),
      // QUANTITY CHECK
      // validity delivery notes
      this.getCheckResult('DeliveryNoteExists', '1234', '', ''),
      this.getCheckResult('DeliveryNoteExists', '2345', '', ''),
      this.getCheckResult('DeliveryNoteExists', '3456', '', ''),
      this.getCheckResult('DeliveryNoteExists', '4567', '', ''),
      this.getCheckResult(
        'DeliveryNoteAuthorized',
        '1234',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteAuthorized',
        '2345',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteAuthorized',
        '3456',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteAuthorized',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteChangesAuthorized',
        '1234',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteChangesAuthorized',
        '2345',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteChangesAuthorized',
        '3456',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteChangesAuthorized',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteNotAlreadySettled',
        '1234',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteNotAlreadySettled',
        '2345',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteNotAlreadySettled',
        '3456',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteNotAlreadySettled',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      // actual quantity check
      this.getCheckResult(
        'AcceptedPartialDeliveryQuantityMatches',
        '999',
        'd982c956-49e8-4f6c-bbd9-49671a222c77',
        'Schutt',
      ),
      this.getCheckResult(
        'AcceptedPartialDeliveryQuantityMatches',
        '999',
        'd982c956-49e8-4f6c-bbd9-49671a222c77',
        'Kies',
      ),
      this.getCheckResult(
        'AcceptedPartialDeliveryQuantityMatches',
        '999',
        'd982c956-49e8-4f6c-bbd9-49671a222c77',
        'Rohre',
      ),
      this.getCheckResult(
        'DeliveryNoteMaterialMatches',
        '3571645998893266',
        '',
        'Holzwinkelstücke',
        'Holzwinkelstücke',
        'Holzwinkelstücke Rechnung',
      ),
      this.getCheckResult(
        'DeliveryNoteMaterialMatches',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteMaterialMatches',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteQuantityMatches',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        'Schutt',
        12.41,
        8,
      ),
      this.getCheckResult(
        'DeliveryNoteUnitTypeMatches',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        'Schutt',
        'TNE',
        'KGM',
      ),
      this.getCheckResult(
        'DeliveryNoteUnitTypeMatches',
        '4567',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        'Kies',
        1.8711,
        124.11,
      ),
      // this.getCheckResult('DeliveryNoteQuantityMatches', '4567', '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca', 'Schutt'),
      // this.getCheckResult('DeliveryNoteUnitTypeMatches', '4567', '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca', 'Kies', '123', '1009'),
      // this.getCheckResult('DeliveryNoteUnitTypeMatches', '4567', '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca', 'Schutt', '1', '2'),
      this.getCheckResult(
        'DeliveryNoteMaterialMatches',
        '999',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteMaterialMatches',
        '999',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteMaterialMatches',
        '999',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteSellerMatches',
        '1',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
        'Test',
        'Schicker',
      ),
      this.getCheckResult(
        'DeliveryNoteSellerMatches',
        '2',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
        'test neu',
        'Lieferant Name',
      ),
      this.getCheckResult(
        'DeliveryNoteSellerMatches',
        '999',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteTransportMatches',
        '2',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteTransportMatches',
        '999',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
      ),
      this.getCheckResult(
        'DeliveryNoteCostCenterMatches',
        '2',
        '96dc9144-4fc9-40bb-ad1d-abac25d1f8ca',
        '',
        '1234',
        '1111',
      ),
      this.getCheckResult(
        'DeliveredQuantityCalculation',
        '',
        '',
        'Schutt',
        '500',
        '12345',
      ),
      this.getCheckResult(
        'DeliveredQuantityCalculation',
        '',
        '',
        'Kies',
        '40',
        '111',
      ),
      // this.getCheckResult('DeliveredQuantityCalculation', '', '', 'Kies'),
      // this.getCheckResult('DeliveredQuantityCalculation', '', '', 'Rohre'),
    );

    return check_results;
  }

  getCheckResult(name, dlnNumber, dlnId, entity, actual, expected) {
    return {
      conflicting_entity: entity,
      conflicting_values: { 1: actual, 2: expected },
      delivery_note_asset_id: dlnId,
      delivery_note_id: dlnNumber,
      name,
      status: InvoiceCheckResultObject.STATUS.ERROR,
    };
  }
}

import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

import { ComponentTestIds } from '~/constants/test-ids';

export const BasicModal = ({
  children,
  className,
  closeModal,
  fullWidth,
  opacity,
  open,
  title,
  testId,
}) => (
  <Dialog
    PaperProps={{
      style: {
        overflow: 'visible',
      },
    }}
    aria-describedby="modal-modal-description"
    aria-labelledby="modal-modal-title"
    className={className}
    data-testid={ComponentTestIds.FORM_MODAL.TITLE}
    fullWidth={fullWidth}
    maxWidth="lg"
    open={Boolean(open)}
    scroll="paper"
    slotProps={{
      backdrop: {
        style: {
          backgroundColor: 'black',
          opacity: opacity ?? 0.3,
        },
      },
    }}
    onClose={closeModal}
  >
    <div
      className="relative z-20 flex items-center justify-between gap-4"
      data-testid={testId}
    >
      <div className="flex-1 flex-wrap">
        <DialogTitle className="text-xl font-bold">{title}</DialogTitle>
      </div>
      <IconButton
        className="mr-2 flex-none"
        data-testid={ComponentTestIds.FORM_MODAL.CLOSE_BUTTON}
        size="large"
        onClick={closeModal}
      >
        <CloseIcon />
      </IconButton>
    </div>
    <DialogContent className="pt-0">{children}</DialogContent>
  </Dialog>
);

import ToastService from '~/services/toast.service';

import { Log } from '~/utils/logging';
import UnitUtils from '~/utils/unitUtils';

import { DEFAULT_COORDINATES, INVALID_COORDINATES_MESSAGE } from './constants';
import { type Coordinates } from './types';

export const CoordinatesObject = {
  /**
   * Creates coordinates from the given data.
   * If no data is provided, returns the default coordinates.
   * @param {Coordinates} [coordinates] - The input coordinates object.
   * @returns {Coordinates} - The created coordinates object.
   */
  create(coordinates?: Coordinates) {
    if (!coordinates) {
      return DEFAULT_COORDINATES;
    }

    return {
      latitude: coordinates.latitude,
      longitude: coordinates.latitude,
    };
  },

  /**
   * Converts coordinates after validating their correctness.
   * If coordinates are invalid, displays a warning and logs the event.
   * @param {number} [latitude] - The latitude value.
   * @param {number} [longitude] - The longitude value.
   * @returns {Coordinates} - The converted coordinates or the default coordinates if invalid.
   */
  getConverted(latitude?: number, longitude?: number): Coordinates {
    if (!latitude && !longitude) {
      return DEFAULT_COORDINATES;
    }

    if (
      !UnitUtils.isValidCoordinate(latitude) ||
      !UnitUtils.isValidCoordinate(longitude)
    ) {
      ToastService.warning(INVALID_COORDINATES_MESSAGE);
      Log.productAnalyticsEvent(
        'Invalid coordinates',
        Log.FEATURE.SITE,
        Log.TYPE.FAILED_VALIDATION,
      );

      return DEFAULT_COORDINATES;
    }

    return {
      latitude: UnitUtils.getConvertedCoordinate(latitude),
      longitude: UnitUtils.getConvertedCoordinate(longitude),
    };
  },

  /**
   * Compares two coordinate objects and returns a list of differences.
   * Compares latitude and longitude and adds their names to the differences array if they do not match.
   * @param {Coordinates} coordinatesA - The first coordinates object to compare.
   * @param {Coordinates} coordinatesB - The second coordinates object to compare.
   * @returns {string[]} - A list of differences (either 'Breitengrad' or 'Längengrad').
   */
  getDifferences(
    coordinatesA: Coordinates,
    coordinatesB: Coordinates,
  ): string[] {
    const differentValues: string[] = [];

    if (coordinatesA?.latitude !== coordinatesB?.latitude) {
      differentValues.push('Breitengrad');
    }

    if (coordinatesA?.longitude !== coordinatesB?.longitude) {
      differentValues.push('Längengrad');
    }

    return differentValues;
  },
};

import { useQueryClient } from '@tanstack/react-query';
import ms from 'ms';
import { useEffect, useRef } from 'react';

import {
  type DeliveryNoteSearchParams,
  queryKeysDeliveryNote,
  useQueryDeliveryNotes,
  useQueryPollDeliveryNoteUpdates,
} from '~/data/deliveryNote';

import ToastService from '~/services/toast.service';

import { DeliveryNoteActionObject } from '~/models/deliveries';

const dateToday = new Date().toISOString().split('T')[0];
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
const dateTomorrow = tomorrow.toISOString().split('T')[0];

// Provide the minimum required filter params to fetch the least amount of data.
const minimumRequiredDlnSearchParams: DeliveryNoteSearchParams = {
  filterConfig: {
    filterAggr: 'and',
    filterGroups: [
      {
        filterAggr: 'and',
        filterClauses: [
          {
            comp: 'ge',
            name: 'dln_date',
            value: dateToday,
          },
          {
            comp: 'le',
            name: 'dln_date',
            value: dateTomorrow,
          },
        ],
      },
    ],
  },
  limit: 1,
};

/**
 * This component is used to poll for delivery note updates and notify the user about incoming changes.
 */
export const DeliveryNoteUpdatesHandler = () => {
  const currentChangesSequenceNumber = useRef<number | undefined>(undefined);
  const isDeliveryNoteListRefreshed = useRef(true);

  const queryClient = useQueryClient();

  /**
   * Fetch the delivery notes list once to get a sequence number so we can poll for changes.
   * The changes polling will update itself with new sequence numbers from its own response.
   */
  const { data: deliveryNotesData } = useQueryDeliveryNotes(
    minimumRequiredDlnSearchParams,
    {
      enabled: !currentChangesSequenceNumber.current,
    },
  );

  const { data } = useQueryPollDeliveryNoteUpdates(
    currentChangesSequenceNumber.current ?? deliveryNotesData?.sequenceNumber,
  );

  const isNewerSequence =
    data?.lastSequence &&
    data.lastSequence > (currentChangesSequenceNumber.current ?? 0);

  if (isNewerSequence) {
    currentChangesSequenceNumber.current = data.lastSequence;
  }

  // Process changes
  queueMicrotask(() => {
    const hasChanges = data?.dlnChanges?.length > 0;

    if (!hasChanges) {
      return;
    }

    isDeliveryNoteListRefreshed.current = false;

    for (const change of data.dlnChanges) {
      const key = Object.keys(DeliveryNoteActionObject.ACTION).find(
        (action) =>
          DeliveryNoteActionObject.ACTION[
            action as keyof typeof DeliveryNoteActionObject.ACTION
          ].STRING === change.message,
      ) as keyof typeof DeliveryNoteActionObject.ACTION;

      if (
        'SHOW_TOAST' in DeliveryNoteActionObject.ACTION[key] &&
        DeliveryNoteActionObject.ACTION[key].SHOW_TOAST
      ) {
        ToastService.dlnInfo(
          [change.message],
          change.dlns,
          change.message === DeliveryNoteActionObject.ACTION.CREATED.STRING,
        );
      }
    }
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isDeliveryNoteListRefreshed.current) {
        return;
      }

      isDeliveryNoteListRefreshed.current = true;

      // Refetch delivery note list and search count
      void queryClient.invalidateQueries({
        queryKey: [queryKeysDeliveryNote.base(), 'list'],
      });
      void queryClient.invalidateQueries({
        queryKey: [queryKeysDeliveryNote.base(), 'searchCount'],
      });
    }, ms('2s'));

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    data?.dlnChanges?.length,
    currentChangesSequenceNumber.current,
    queryClient,
  ]);

  // We don't need to render anything, we just need to poll for changes.
  return null;
};

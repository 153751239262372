import { deDE as coreDeDE } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { deDE as dataGridDeDE } from '@mui/x-data-grid/locales';
import { deDE as datePickerDeDE } from '@mui/x-date-pickers/locales';

export const theme = createTheme(
  {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // Disable MUI ripple effect for all buttons
        },
        styleOverrides: {
          root: {
            textTransform: 'none', // Remove uppercase transformation
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          '@font-face': {
            fontDisplay: 'swap',
            fontFamily: 'Inter',
          },
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave',
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
          slotProps: {
            inputLabel: {
              shrink: true,
            },
          },
        },
        styleOverrides: {
          root: {
            '& .MuiInputBase-input::placeholder': {
              fontStyle: 'italic',
              paddingRight: '0.25rem',
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          grouped: {
            '&.Mui-selected': {
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.13)',
              },
              backgroundColor: 'rgba(25, 118, 210, 0.13)',
              fontWeight: '600',
            },
            '&:not(:last-of-type)': {
              borderColor: '#c4c4c4',
            },
            borderColor: '#c4c4c4',
            textTransform: 'none',
          },
        },
      },
    },
    cssVariables: true,
    typography: {
      fontFamily: [
        'Inter',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
  },
  coreDeDE, // core translations
  dataGridDeDE, // x-data-grid translations
  datePickerDeDE, // x-date-pickers translations
);

import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';

import { queryKeysUser } from './queryKeys';
import { type UserListItem } from './useQueryUsers';

type BulkUserType = UserListItem & {
  permittedSignatureTypes: string[];
  isVestigasSupport: boolean;
};

type BulkUsersResponse = {
  items: BulkUserType[];
};

/**
 * Fetches multiple users in bulk from the API.
 * @param userIds - Array of user IDs to fetch.
 * @param includeCompanyInformation - Whether to include the company name in the response.
 * @returns Array of user data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/User/operation/query_users_in_bulk_user_query_bulk_post
 */
export const fetchUsersBulk = async (
  userIds: UUID[],
  includeCompanyInformation = false,
) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.USER.GET_BULK(), {
        json: {
          ids: userIds,
          include_company_information: includeCompanyInformation,
        },
      })
      .json<BulkUsersResponse>();

    return response?.items ?? [];
  } catch (error) {
    Log.error('Error fetching users in bulk', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting multiple users in bulk.
 * @param userIds - Array of user IDs to fetch.
 * @param options - Additional options for the useQuery hook.
 * @returns The query result containing array of users.
 */
export const useQueryUsersBulk = <TSelected = BulkUserType>(
  userIds: UUID[],
  options?: Omit<
    UseQueryOptions<BulkUserType[], Error, TSelected>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<BulkUserType[], Error, TSelected>({
    enabled: userIds.length > 0,
    queryFn: async () => fetchUsersBulk(userIds),
    queryKey: queryKeysUser.getBulk(userIds),
    ...options,
  });

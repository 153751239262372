import { type DeliveryNoteListItem } from '~/data/deliveryNote/types';
import {
  type User,
  type UserCompany,
  type UserCompanyAccount,
} from '~/data/user';

import companyService from '~/services/company.service';
import FeatureService from '~/services/feature.service';

import { Log } from '~/utils/logging';
import { clone } from '~/utils/object';
import { promiseHandler } from '~/utils/promiseHandler';
import { toCamelCase } from '~/utils/string';

import { type DeliveryNoteType } from '~/components/deliveries/deliveryNote/types';

import {
  type AcceptArticleCreatedValues,
  AcceptArticleObject,
  AcceptStateCalculatorObject,
} from '../acceptState';
import { type ArticleCreatedValues } from '../articles';
import { CompanyObject } from '../masterdata/Company';
import { SignatureRolesObject } from '../masterdata/SignatureRoles';
import { TradeContactObject } from '../masterdata/TradeContact';
import { UserObject } from '../masterdata/User';

import {
  DELIVERY_NOTE_ACTION_DEFAULT_ACTION,
  DELIVERY_NOTE_ACTION_PROPERTY,
} from './constants';

export const DeliveryNoteActionObject = {
  acceptArticles: [] as AcceptArticleCreatedValues[],
  acceptState: '',
  action: '',
  ACTION: DELIVERY_NOTE_ACTION_DEFAULT_ACTION,
  approvedArticles: [] as ArticleCreatedValues[],
  attachments: [] as Array<Record<string, any>>,
  changes: [] as Array<Record<string, any>>,
  company: {},
  datetime: '',
  declinedArticles: [] as ArticleCreatedValues[],
  deliveryNoteId: '',
  icon: '',
  id: '',
  PROPERTY: DELIVERY_NOTE_ACTION_PROPERTY,
  signatureRole: {},
  signerCompany: {},
  signerTradeContact: {},
  user: {},

  create(assetChain: DeliveryNoteListItem, deliveryNote?: DeliveryNoteType) {
    const instance = clone(this);

    instance.id = assetChain.id;
    instance.deliveryNoteId = assetChain.assetId;
    instance.datetime = assetChain.createdOn;
    instance.user = UserObject.create({
      id: assetChain.createdBy?.split('_')?.pop(),
    });
    instance.signatureRole = toCamelCase(
      assetChain.signatureRole ===
        SignatureRolesObject.SIGNATURE_ROLE.RECIPIENT.LEGACY_KEY
        ? SignatureRolesObject.SIGNATURE_ROLE.RECIPIENT.KEY
        : assetChain.signatureRole,
    );
    instance.company = instance.getCompany(assetChain, deliveryNote);
    instance.acceptArticles =
      assetChain.meta?.acceptEvent?.acceptedItems?.map((acceptedItem) =>
        AcceptArticleObject.create(acceptedItem, assetChain.id),
      ) ?? [];
    instance.acceptState =
      AcceptStateCalculatorObject.calculateOverallAcceptStateFromArticles(
        instance.acceptArticles.map(({ acceptState }) => acceptState),
      );
    instance.signerTradeContact = TradeContactObject.create(
      assetChain.meta?.acceptEvent?.signer?.tradeContact,
    );
    instance.signerCompany = CompanyObject.create(
      assetChain.meta?.acceptEvent?.signer?.legalOrganization,
    );
    instance.declinedArticles = instance.getDeclinedArticles(deliveryNote);
    instance.approvedArticles = instance.getApprovedArticles(deliveryNote);

    instance.determineAction(assetChain);

    return instance;
  },

  getFilteredArticles(deliveryNote?: DeliveryNoteType, targetState: string) {
    if (!deliveryNote) {
      return [];
    }

    return this.acceptArticles
      .filter(({ acceptState }) => acceptState === targetState)
      .reduce((filteredArticles, acceptArticle) => {
        const article = deliveryNote.articles?.find(
          (article) =>
            article.documentLogisticsPackagePosition ===
              acceptArticle.documentLogisticsPackagePosition &&
            article.documentLineItemPosition ===
              acceptArticle.documentLineItemPosition,
        );

        if (!article) {
          return filteredArticles;
        }

        acceptArticle.setUnit(article.amount.unit);

        return [...filteredArticles, { ...article, acceptArticle }];
      }, []);
  },

  getDeclinedArticles(deliveryNote?: DeliveryNoteType) {
    return this.getFilteredArticles(
      deliveryNote,
      AcceptStateCalculatorObject.ACCEPT_STATE.DECLINED,
    );
  },

  getApprovedArticles(deliveryNote?: DeliveryNoteType) {
    return this.getFilteredArticles(
      deliveryNote,
      AcceptStateCalculatorObject.ACCEPT_STATE.APPROVED,
    );
  },

  /**
   * Determines the action name and icon displayed in the changes list based on the asset chain metadata.
   * @param {DeliveryNoteListItem} assetChain - The asset chain containing the metadata to determine the action.
   */
  determineAction(assetChain: DeliveryNoteListItem) {
    // Handle 'accepted' actions
    if (assetChain.meta?.action?.accepted) {
      this.handleAcceptedAction();
      return;
    }

    // Handle 'arrived' actions
    if (assetChain.meta?.action?.arrived || assetChain.meta?.arrived) {
      this.action = this.ACTION.ARRIVED.STRING;
      this.icon = this.ACTION.ARRIVED.ICON;
      return;
    }

    // Handle 'cancelled' actions
    if (assetChain.meta?.action?.cancelled) {
      this.action = this.ACTION.CANCELLED.STRING;
      this.icon = this.ACTION.CANCELLED.ICON;
      return;
    }

    // Handle 'archived'/'unarchived' actions
    if (
      assetChain.meta?.action?.archived !== null &&
      assetChain.meta?.action?.archived !== undefined
    ) {
      if (assetChain.meta?.action?.archived) {
        this.action = this.ACTION.ARCHIVED.STRING;
        this.icon = this.ACTION.ARCHIVED.ICON;
      } else {
        this.action = this.ACTION.UNARCHIVED.STRING;
        this.icon = this.ACTION.UNARCHIVED.ICON;
      }

      return;
    }

    // Handle 'shared' actions
    if (
      assetChain.meta?.share &&
      Object.keys(assetChain.meta?.share).length > 0
    ) {
      this.action = this.ACTION.SHARED.STRING;
      this.icon = this.ACTION.SHARED.ICON;
      return;
    }

    // Handle 'created'/'edited' actions
    if (assetChain.body?.context) {
      if (assetChain.idPrevItem) {
        this.action = this.ACTION.EDITED.STRING;
        this.icon = this.ACTION.EDITED.ICON;
      } else {
        this.action = this.ACTION.CREATED.STRING;
        this.icon = this.ACTION.CREATED.ICON;
      }

      return;
    }

    // Handle 'assigned' actions
    if (assetChain.meta?.action?.assign) {
      this.action = this.ACTION.ASSIGNED.STRING;
      this.icon = this.ACTION.ASSIGNED.ICON;
      return;
    }

    // Default fallback
    this.action = this.ACTION.EDITED.STRING;
    this.icon = this.ACTION.EDITED.ICON;
  },

  /**
   * Helper method to handle accepted actions based on accept state and signature role
   */
  handleAcceptedAction() {
    if (
      this.acceptState === AcceptStateCalculatorObject.ACCEPT_STATE.DECLINED
    ) {
      this.handleDeclinedAction();
    } else {
      this.handleConfirmedAction();
    }
  },

  /**
   * Sets action and icon for 'declined' actions based on signature role
   */
  handleDeclinedAction() {
    // diff to mobile
    switch (this.signatureRole) {
      case SignatureRolesObject.SIGNATURE_ROLE.SUPPLIER.KEY: {
        this.action = this.ACTION.DECLINED_SUPPLIER.STRING;
        this.icon = this.ACTION.DECLINED_SUPPLIER.ICON;
        break;
      }

      case SignatureRolesObject.SIGNATURE_ROLE.CARRIER.KEY: {
        this.action = this.ACTION.DECLINED_CARRIER.STRING;
        this.icon = this.ACTION.DECLINED_CARRIER.ICON;
        break;
      }

      case SignatureRolesObject.SIGNATURE_ROLE.RECIPIENT.KEY: {
        this.action = this.ACTION.DECLINED_RECIPIENT.STRING;
        this.icon = this.ACTION.DECLINED_RECIPIENT.ICON;
        break;
      }

      case SignatureRolesObject.SIGNATURE_ROLE.ON_BEHALF_SUPPLIER.KEY: {
        this.action = this.ACTION.DECLINED_ON_BEHALF_SUPPLIER.STRING;
        this.icon = this.ACTION.DECLINED_ON_BEHALF_SUPPLIER.ICON;
        break;
      }

      case SignatureRolesObject.SIGNATURE_ROLE.ON_BEHALF_CARRIER.KEY: {
        this.action = this.ACTION.DECLINED_ON_BEHALF_CARRIER.STRING;
        this.icon = this.ACTION.DECLINED_ON_BEHALF_CARRIER.ICON;
        break;
      }

      case SignatureRolesObject.SIGNATURE_ROLE.ON_BEHALF_RECIPIENT.KEY: {
        this.action = this.ACTION.DECLINED_ON_BEHALF_RECIPIENT.STRING;
        this.icon = this.ACTION.DECLINED_ON_BEHALF_RECIPIENT.ICON;
        break;
      }

      default:
      // No default
    }
  },

  /**
   * Sets action and icon for 'confirmed' actions based on signature role
   */
  handleConfirmedAction() {
    switch (this.signatureRole) {
      case SignatureRolesObject.SIGNATURE_ROLE.SUPPLIER.KEY: {
        // diff to mobile
        this.action = this.ACTION.CONFIRMED_SUPPLIER.STRING;
        this.icon = this.ACTION.CONFIRMED_SUPPLIER.ICON;
        break;
      }

      case SignatureRolesObject.SIGNATURE_ROLE.CARRIER.KEY: {
        this.action = this.ACTION.CONFIRMED_CARRIER.STRING;
        this.icon = this.ACTION.CONFIRMED_CARRIER.ICON;
        break;
      }

      case SignatureRolesObject.SIGNATURE_ROLE.RECIPIENT.KEY: {
        this.action = this.ACTION.CONFIRMED_RECIPIENT.STRING;
        this.icon = this.ACTION.CONFIRMED_RECIPIENT.ICON;
        break;
      }

      case SignatureRolesObject.SIGNATURE_ROLE.ON_BEHALF_SUPPLIER.KEY: {
        this.action = this.ACTION.CONFIRMED_ON_BEHALF_SUPPLIER.STRING;
        this.icon = this.ACTION.CONFIRMED_ON_BEHALF_SUPPLIER.ICON;
        break;
      }

      case SignatureRolesObject.SIGNATURE_ROLE.ON_BEHALF_CARRIER.KEY: {
        this.action = this.ACTION.CONFIRMED_ON_BEHALF_CARRIER.STRING;
        this.icon = this.ACTION.CONFIRMED_ON_BEHALF_CARRIER.ICON;
        break;
      }

      case SignatureRolesObject.SIGNATURE_ROLE.ON_BEHALF_RECIPIENT.KEY: {
        this.action = this.ACTION.CONFIRMED_ON_BEHALF_RECIPIENT.STRING;
        this.icon = this.ACTION.CONFIRMED_ON_BEHALF_RECIPIENT.ICON;
        break;
      }

      default:
      // No default
    }
  },

  getCompany(
    assetChain: DeliveryNoteListItem,
    deliveryNote?: DeliveryNoteType,
  ) {
    // if the asset creator has created the chain, take the issuer as fallback
    if (UserObject.isAssetCreator(this.user?.id)) {
      return CompanyObject.create({ name: deliveryNote?.issuer?.name });
    }

    return CompanyObject.create();
  },

  /**
   * Updates the changes array with the latest change values from the delivery note.
   * @param {DeliveryNoteType} deliveryNote - The delivery note containing the changes history to be processed.
   */
  setChanges(deliveryNote: DeliveryNoteType) {
    deliveryNote.setChanges?.();

    for (const change of deliveryNote.changes) {
      const latestValue = change.history.at(-1);
      const priorValue =
        change.history.length > 1 ? change.history.at(-2) : change.initial;

      if (latestValue.datetime !== this.datetime) {
        continue;
      }

      this.changes.push({
        formatter: change.formatter,
        name: change.name,
        newValue: latestValue.value,
        oldValue: priorValue.value,
      });
    }
  },

  /**
   * Initializes the company using a provided function to fetch the user's company.
   * @param {Function} fetchUserCompanyFunction - A function that returns a promise resolving to the user's company.
   */
  async initCompanyWithReactQueryFn(
    fetchUserCompanyFunction: () => Promise<UserCompany>,
  ) {
    if (UserObject.isAssetCreator(this.user?.id)) {
      // As the user of a dln creation is the System User Asset Creator, there is no company, which leads to a 404 error.
      // Therefore, don't call getUserCompany in this case.
      return;
    }

    const company = await fetchUserCompanyFunction();
    const newCompany = CompanyObject.create(company);

    this.company = newCompany;
  },

  /**
   * Initializes the user with their associated company and company account using the provided functions.
   * @param {Function} fetchUserFunction - A function that returns a promise resolving to the user.
   * @param {Function} fetchUserCompanyFunction - A function that returns a promise resolving to the user's company.
   * @param {Function} fetchUserCompanyAccountFunction - A function that returns a promise resolving to the user's company account.
   */
  async initUserWithReactQueryFn(
    fetchUserFunction: () => Promise<User>,
    fetchUserCompanyFunction: () => Promise<UserCompany>,
    fetchUserCompanyAccountFunction: () => Promise<UserCompanyAccount>,
  ) {
    // no use in loading the usernames if they aren't displayed anyway
    if (!FeatureService.showUserInDlnHistory()) {
      return;
    }

    // for the asset creator we will not find any specific information in the backend
    if (UserObject.isAssetCreator(this.user?.id)) {
      return;
    }

    // this case shouldn't happen because this.initCompany() is already called directly before in DeliveryNoteHistory
    if (!this.company?.id) {
      await promiseHandler(
        this.initCompanyWithReactQueryFn(fetchUserCompanyFunction),
      );
    }

    const companyAccount = await fetchUserCompanyAccountFunction();

    // Due to data privacy we decided that if the company account of the logged-in user and the creator of the delivery note action are different, no user data should be loaded and displayed.
    if (companyAccount.id !== this.company?.companyAccount) {
      return;
    }

    let user;
    try {
      user = await fetchUserFunction();
    } catch {
      // Display an error message in the changes table if the user lacks permission to view the user
      user = {
        firstName: '- Berechtigung fehlt -',
      };
    }

    const newUser = UserObject.create(user);

    if (newUser) {
      this.user = newUser;
      return;
    }

    // If UserService.getUserById doesn't return the user, load them from CompanyService.getEmployeesOfCompany as fallback.
    // This is a dirty workaround because users can't see anymore who signed dlns if they are not permitted to see users as UserService.getUserById is now checking permissions.
    const [usersFromEmployees, error4] = await promiseHandler(
      companyService.getEmployeesOfCompany(this.company?.id, true),
    );
    if (error4) {
      throw error4;
    }

    const userFromEmployees = usersFromEmployees.find(
      (item) => item.id === this.user?.id,
    );

    if (!userFromEmployees) {
      Log.error(
        'Failed to find user in list of company employees. userId: ' +
          this.user?.id,
      );
      return;
    }

    this.user = userFromEmployees;
  },

  /**
   * Checks if the given action is an "on behalf" action.
   * @param {string} [action] - The action string to check.
   * @returns {boolean} - Returns true if the action is an "on behalf" action, otherwise false.
   */
  isOnBehalfAction(action?: string) {
    return [
      this.ACTION.CONFIRMED_ON_BEHALF_SUPPLIER.STRING,
      this.ACTION.CONFIRMED_ON_BEHALF_CARRIER.STRING,
      this.ACTION.CONFIRMED_ON_BEHALF_RECIPIENT.STRING,
      this.ACTION.DECLINED_ON_BEHALF_SUPPLIER.STRING,
      this.ACTION.DECLINED_ON_BEHALF_CARRIER.STRING,
      this.ACTION.DECLINED_ON_BEHALF_RECIPIENT.STRING,
    ].includes(action);
  },
};

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from 'oidc-react';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router';

import { ROUTE } from '~/constants/Route';

import store from '~/redux/store';

import ThirdPartyService from '~/services/thirdParty.service';

import { UpdatePrompt } from '~/modules/pwa';

import { isLocalhost } from '~/utils/environment';
// Initialize i18next
/* eslint-disable-next-line import/no-unassigned-import */
import '~/utils/i18n';
import { HotkeysProvider } from '~/utils/useHotkeys';

import { IdsRedirectPage } from '~/components/ids/IdsRedirectPage';
import { router } from '~/components/menu/Routes';
import { theme } from '~/ui/styles';
import '~/ui/styles/index.css';
import '~/ui/styles/legacy.css';

import { useGetUserUtils } from './data/user';
import AuthService from './services/auth.service';

ThirdPartyService.initMuiPro();

export const App = () => {
  const { UserUtils } = useGetUserUtils();

  useEffect(() => {
    if (!isLocalhost) {
      ThirdPartyService.initSentry(UserUtils);
    }
  }, [UserUtils]);

  if (globalThis.location.pathname === ROUTE.IDS_REDIRECT.ROUTE) {
    return <IdsRedirectPage />;
  }

  return (
    <ReduxProvider store={store}>
      <HotkeysProvider>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <CssBaseline />
            <AuthProvider {...(AuthService ? AuthService.oidcConfig : {})}>
              <RouterProvider router={router} />
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </HelmetProvider>
        </ThemeProvider>
        <UpdatePrompt />
      </HotkeysProvider>
    </ReduxProvider>
  );
};

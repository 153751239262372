import { type GridColDef, type GridSortModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';

import { type DeliveryNoteAnalyticsData } from '~/data/deliveryNote';

import LocalStorageService from '~/services/localStorage.service';

import { precision } from '~/utils/number';
import { getAbbreviatedUnit } from '~/utils/unit';
import UnitUtils from '~/utils/unitUtils';

import { BasicTable } from '~/components/BasicTable';

type P = {
  readonly data: DeliveryNoteAnalyticsData[];
  readonly isLoading: boolean;
  readonly selectedUnit: string;
};

export const DashboardDetailOverview = ({
  data,
  isLoading,
  selectedUnit,
}: P) => {
  const [rows, setRows] = useState([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'value',
      sort: 'desc',
    },
  ]);

  const updateStates = useCallback(
    (data: DeliveryNoteAnalyticsData[]) => {
      if (!data) {
        return;
      }

      const newRows =
        data.map(({ article, values }, index) => ({
          article,
          id: index,
          unit: getAbbreviatedUnit(selectedUnit),
          unitKey: selectedUnit,
          value: values.reduce((a: number, b: number) => a + b, 0),
        })) ?? [];

      setRows(newRows);
    },
    [selectedUnit],
  );

  const onSortModelChange = useCallback((event: GridSortModel) => {
    setSortModel(event);
  }, []);

  const getColumns = (): GridColDef[] => {
    let maxPrecision = 0;

    for (const row of rows) {
      const p = precision(row.value);

      if (p > maxPrecision && p <= 2) {
        maxPrecision = p;
      }
    }

    return [
      {
        field: 'article',
        flex: 6,
        headerName: 'Artikel',
        resizable: true,
        sortable: true,
      },
      {
        field: 'value',
        flex: 2,
        headerName: 'Menge',
        renderCell: ({ value }) =>
          UnitUtils.formatDeWithPrecision_safe(
            value,
            maxPrecision,
            maxPrecision,
          ),
        resizable: true,
        sortable: true,
        type: 'number',
      },
      {
        field: 'unit',
        flex: 1,
        headerName: 'Einheit',
        resizable: true,
        sortable: true,
      },
    ];
  };

  useEffect(() => {
    updateStates(data);
  }, [data]);

  return (
    <div className="h-[475px]">
      <BasicTable
        className="rounded-md border"
        columns={getColumns()}
        isLoading={isLoading}
        localStorageKey={LocalStorageService.DASHBOARD}
        rows={rows}
        sortModel={sortModel}
        hideHeader
        onSortModelChange={onSortModelChange}
      />
    </div>
  );
};

DashboardDetailOverview.displayName = 'DashboardDetailOverview';

import { ValueGroupObject } from '~/models/deliveries/valueGroup';

import { UniversalCommunicationObject } from '../UniversalCommunication';

import { DEFAULT_TRADE_CONTACT } from './constants';
import { type TradeContact } from './types';

export const TradeContactObject = {
  /**
   * Creates a trade contact.
   * If no data is provided, returns the default trade contact.
   * @param {TradeContact} [tradeContact] - The input trade contact object.
   * @returns {TradeContact} - The created trade contact object.
   */
  create(tradeContact?: TradeContact) {
    if (!tradeContact) {
      return DEFAULT_TRADE_CONTACT;
    }

    return {
      personName: tradeContact?.personName ?? DEFAULT_TRADE_CONTACT.personName,
      jobTitle: tradeContact?.jobTitle ?? DEFAULT_TRADE_CONTACT.jobTitle,
      departmentName:
        tradeContact?.departmentName ?? DEFAULT_TRADE_CONTACT.departmentName,
      universalCommunication: UniversalCommunicationObject.create(
        tradeContact?.universalCommunication ??
          DEFAULT_TRADE_CONTACT.universalCommunication,
      ),
    };
  },

  /**
   * Checks if the trade contact has any contact information (either person name or valid universal communication).
   * @param {TradeContact} tradeContact - The trade contact object to check.
   * @returns {boolean} - True if the trade contact has valid contact information.
   */
  hasContactInformation(tradeContact: TradeContact): boolean {
    return (
      ValueGroupObject.getCurrentValue(tradeContact?.personName) ||
      UniversalCommunicationObject.hasCommunicationInformation(
        tradeContact?.universalCommunication,
      )
    );
  },

  /**
   * Concatenates the contact information of a trade contact into a single string.
   * @param {TradeContact} tradeContact - The trade contact object to get information from.
   * @returns {string} - Concatenated person name and communication information, separated by ' | '.
   */
  getConcatenatedContactInformation(tradeContact: TradeContact) {
    return [
      ValueGroupObject.getCurrentValue(tradeContact.personName),
      UniversalCommunicationObject.getConcatenatedCommunicationInformation(
        tradeContact.universalCommunication,
      ),
    ]
      .filter(Boolean)
      .join(' | ');
  },
};

import { InvoiceCheckResultObject } from '~/models/invoices';

import { unique } from '~/utils/array';
import { getAbbreviatedUnits } from '~/utils/unit';
import UnitUtils from '~/utils/unitUtils';

/**
 * Compares two arrays and returns matches and unmatched items based on a condition
 * @param {Array} array1 - The first array to compare
 * @param {Array} array2 - The second array to compare
 * @param {Function} condition - A callback function that takes two elements (one from each array)
 *                              and returns true if they match
 * @returns {Object} An object containing:
 *                   - matches: Array of pairs [element1, element2] that satisfy the condition
 *                   - unmatched1: Array of elements from array1 that didn't match any element in array2
 *                   - unmatched2: Array of elements from array2 that didn't match any element in array1
 * @example
 * const arr1 = [1, 2, 3];
 * const arr2 = [2, 3, 4];
 * const result = matchArrays(arr1, arr2, (a, b) => a === b);
 * // result = {
 * //   matches: [[2, 2], [3, 3]],
 * //   unmatched1: [1],
 * //   unmatched2: [4]
 * // }
 */
const matchArrays = (array1, array2, condition) => {
  const matches = [];
  const unmatched1 = [];
  const unmatched2 = [];

  // Iterate over the first array
  for (const element of array1) {
    let foundMatch = false;

    // Iterate over the second array
    for (const element2 of array2) {
      if (condition(element, element2)) {
        // Condition is satisfied, add the match and mark it as found
        matches.push([element, element2]);
        foundMatch = true;
        break;
      }
    }

    // If no match was found, add the item to the unmatched array
    if (!foundMatch) {
      unmatched1.push(element);
    }
  }

  // Find unmatched items from the second array
  for (const element of array2) {
    let foundMatch = false;

    // Iterate over the matched items to avoid duplicates
    for (const match of matches) {
      if (element === match[1]) {
        foundMatch = true;
        break;
      }
    }

    // If no match was found, add the item to the unmatched array
    if (!foundMatch) {
      unmatched2.push(element);
    }
  }

  // Return an object containing matches and unmatched items
  return {
    matches,
    unmatched1,
    unmatched2,
  };
};

export default class InvoiceParser {
  static parseAssetMain_v1(assetMain) {
    if (!assetMain?.meta?.check_results) {
      return;
    }

    assetMain.meta.check_results = InvoiceParser.filterChecks_v1(
      assetMain.meta.check_results,
    );
    assetMain.meta.check_results =
      InvoiceParser.splitDeliveryNoteExistsErrors_v1(
        assetMain.meta.check_results,
      );
    assetMain.meta.check_results =
      InvoiceParser.addMissingDeliveryNoteAuthorizedNaCheckResults_v1(
        assetMain.meta.check_results,
      );
    assetMain.meta.check_results =
      InvoiceParser.mergeDeliveryNoteQuantityAndUnitTypeMatchesChecks_v1(
        assetMain.meta.check_results,
      );
  }

  static parseAssetMain_v2(assetMain) {
    if (!assetMain?.meta?.check_results) {
      return;
    }

    assetMain.meta.check_results =
      InvoiceParser.mergeDeliveryNoteQuantityAndUnitTypeMatchesChecks_v2(
        assetMain.meta.check_results,
      );
  }

  // This filters the redundant check AcceptedPartialDeliveryQuantityMatches if DeliveryNoteAuthorized has the same status for the same dln.
  // -> This logic should be moved to the backend.
  static filterChecks_v1(checkResults) {
    return checkResults.filter((checkResult) => {
      if (checkResult.name === 'AcceptedPartialDeliveryQuantityMatches') {
        const checkDlnAuthorized = checkResults.find(
          ({ delivery_note_asset_id, name, status }) => {
            return (
              name === 'DeliveryNoteAuthorized' &&
              delivery_note_asset_id === checkResult.delivery_note_asset_id &&
              status === checkResult.status
            );
          },
        );

        if (checkDlnAuthorized) {
          return false;
        }
      }

      return true;
    });
  }

  // Currently DeliveryNoteExists errors can contain multiple delivery notes. However, it makes more sense to have one error for each delivery note.
  // -> This logic should be moved to the backend.
  static splitDeliveryNoteExistsErrors_v1(checkResults) {
    const deliveryNoteExistsErrors = checkResults.filter(
      ({ name, status }) =>
        name === 'DeliveryNoteExists' &&
        status === InvoiceCheckResultObject.STATUS.ERROR,
    );

    const deliveryNotes =
      deliveryNoteExistsErrors
        .map((deliveryNoteExistsError) =>
          deliveryNoteExistsError.delivery_note_id?.split(', '),
        )
        ?.flat(1) ?? [];

    checkResults = checkResults.filter(
      ({ name, status }) =>
        !(
          name === 'DeliveryNoteExists' &&
          status === InvoiceCheckResultObject.STATUS.ERROR
        ),
    );

    for (const deliveryNote of unique(deliveryNotes)) {
      checkResults.push({
        delivery_note_id: deliveryNote,
        name: 'DeliveryNoteExists',
        status: InvoiceCheckResultObject.STATUS.ERROR,
      });
    }

    return checkResults;
  }

  // na check results aren't thrown in DeliveryNoteAuthorized checks for dlns where DeliveryNoteExists has thrown an error
  // This is solved after the invoice check refactoring in the backend but missing for old invoices.
  static addMissingDeliveryNoteAuthorizedNaCheckResults_v1(checkResults) {
    const deliveryNoteExistsErrors = checkResults.filter(
      ({ name, status }) =>
        name === 'DeliveryNoteExists' &&
        status === InvoiceCheckResultObject.STATUS.ERROR,
    );

    for (const deliveryNoteExistsError of deliveryNoteExistsErrors) {
      checkResults.push({
        delivery_note_id: deliveryNoteExistsError.delivery_note_id,
        name: 'DeliveryNoteAuthorized',
        status: InvoiceCheckResultObject.STATUS.NA,
      });
    }

    return checkResults;
  }

  // It is easier for the frontend to treat unit and value together if the amount is different between invoice and delivery note. Hence, the two errors are merged.
  // This logic is supposed to be moved to the backend in the course of the backend invoice check refactoring.
  static mergeDeliveryNoteQuantityAndUnitTypeMatchesChecks_v1(checkResults) {
    const deliveryNoteQuantityMatchesCheckResults = checkResults.filter(
      ({ name }) => name === 'DeliveryNoteQuantityMatches',
    );
    const deliveryNoteUnitTypeMatchesCheckResults = checkResults.filter(
      ({ name }) => name === 'DeliveryNoteUnitTypeMatches',
    );

    // Get all pairs of matching deliveryNoteQuantityMatchesCheckResults and deliveryNoteUnitTypeMatchesCheckResults
    // as well as all check results that have no match
    const arrayMatch = matchArrays(
      deliveryNoteQuantityMatchesCheckResults,
      deliveryNoteUnitTypeMatchesCheckResults,
      (
        deliveryNoteQuantityMatchesCheckResult,
        deliveryNoteUnitTypeMatchesCheckResult,
      ) =>
        deliveryNoteQuantityMatchesCheckResult.conflicting_entity ===
          deliveryNoteUnitTypeMatchesCheckResult.conflicting_entity &&
        deliveryNoteQuantityMatchesCheckResult.delivery_note_id ===
          deliveryNoteUnitTypeMatchesCheckResult.delivery_note_id,
    );

    const newDeliveryNoteQuantityMatchesCheckResults = [];

    // All pairs of deliveryNoteQuantityMatchesCheckResults and deliveryNoteUnitTypeMatchesCheckResults should be formatted together
    for (const checkResultPair of arrayMatch.matches) {
      const deliveryNoteQuantityMatchesCheckResult = checkResultPair[0];
      const deliveryNoteUnitTypeMatchesCheckResult = checkResultPair[1];

      newDeliveryNoteQuantityMatchesCheckResults.push({
        ...deliveryNoteQuantityMatchesCheckResult,
        conflicting_values: {
          1: UnitUtils.formatStringUnitPair_safe(
            deliveryNoteQuantityMatchesCheckResult.conflicting_values?.[1],
            deliveryNoteUnitTypeMatchesCheckResult.conflicting_values?.[1],
            getAbbreviatedUnits,
          ),
          2: UnitUtils.formatStringUnitPair_safe(
            deliveryNoteQuantityMatchesCheckResult.conflicting_values?.[2],
            deliveryNoteUnitTypeMatchesCheckResult.conflicting_values?.[2],
            getAbbreviatedUnits,
          ),
        },
      });
    }

    // All deliveryNoteQuantityMatchesCheckResults that have no deliveryNoteUnitTypeMatchesCheckResult should only format to DE number
    for (const deliveryNoteQuantityMatchesCheckResult of arrayMatch.unmatched1) {
      newDeliveryNoteQuantityMatchesCheckResults.push({
        ...deliveryNoteQuantityMatchesCheckResult,
        conflicting_values: {
          1: UnitUtils.formatStringDe_safe(
            deliveryNoteQuantityMatchesCheckResult.conflicting_values?.[1],
          ),
          2: UnitUtils.formatStringDe_safe(
            deliveryNoteQuantityMatchesCheckResult.conflicting_values?.[2],
          ),
        },
      });
    }

    // All deliveryNoteUnitTypeMatchesCheckResults that have no deliveryNoteQuantityMatchesCheckResult should only format the unit
    for (const deliveryNoteUnitTypeMatchesCheckResult of arrayMatch.unmatched2) {
      newDeliveryNoteQuantityMatchesCheckResults.push({
        ...deliveryNoteUnitTypeMatchesCheckResult,
        conflicting_values: {
          1: getAbbreviatedUnits(
            deliveryNoteUnitTypeMatchesCheckResult.conflicting_values?.[1],
          ),
          2: getAbbreviatedUnits(
            deliveryNoteUnitTypeMatchesCheckResult.conflicting_values?.[2],
          ),
        },
      });
    }

    // Filter out the old check results and replace them with the merged ones
    checkResults = checkResults.filter(
      ({ name }) =>
        name !== 'DeliveryNoteQuantityMatches' &&
        name !== 'DeliveryNoteUnitTypeMatches',
    );
    checkResults.push(...newDeliveryNoteQuantityMatchesCheckResults);

    return checkResults;
  }

  static mergeDeliveryNoteQuantityAndUnitTypeMatchesChecks_v2(checkResults) {
    const deliveryNoteQuantityMatchesCheckResults = checkResults.filter(
      ({ name }) => name === 'DeliveryNoteQuantityMatches',
    );
    const deliveryNoteUnitTypeMatchesCheckResults = checkResults.filter(
      ({ name }) => name === 'DeliveryNoteUnitTypeMatches',
    );

    // Get all pairs of matching deliveryNoteQuantityMatchesCheckResults and deliveryNoteUnitTypeMatchesCheckResults
    // as well as all check results that have no match
    const arrayMatch = matchArrays(
      deliveryNoteQuantityMatchesCheckResults,
      deliveryNoteUnitTypeMatchesCheckResults,
      (
        deliveryNoteQuantityMatchesCheckResult,
        deliveryNoteUnitTypeMatchesCheckResult,
      ) =>
        deliveryNoteQuantityMatchesCheckResult.item_name ===
          deliveryNoteUnitTypeMatchesCheckResult.item_name &&
        JSON.stringify(
          deliveryNoteQuantityMatchesCheckResult.delivery_note_asset_ids,
        ) ===
          JSON.stringify(
            deliveryNoteUnitTypeMatchesCheckResult.delivery_note_asset_ids,
          ),
    );

    const newDeliveryNoteQuantityMatchesCheckResults = [];

    // All pairs of deliveryNoteQuantityMatchesCheckResults and deliveryNoteUnitTypeMatchesCheckResults should be formatted together
    for (const checkResultPair of arrayMatch.matches) {
      const deliveryNoteQuantityMatchesCheckResult = checkResultPair[0];
      const deliveryNoteUnitTypeMatchesCheckResult = checkResultPair[1];

      // If one of the check results is an error, the merged check must be an error.
      // If no error and at least one successful, the merged check must be successful.
      let displayStatus = InvoiceCheckResultObject.STATUS.NA;
      if (
        deliveryNoteQuantityMatchesCheckResult.display_status ===
          InvoiceCheckResultObject.STATUS.SUCCESS ||
        deliveryNoteUnitTypeMatchesCheckResult.display_status ===
          InvoiceCheckResultObject.STATUS.SUCCESS
      ) {
        displayStatus = InvoiceCheckResultObject.STATUS.SUCCESS;
      }

      if (
        deliveryNoteQuantityMatchesCheckResult.display_status ===
          InvoiceCheckResultObject.STATUS.ERROR ||
        deliveryNoteUnitTypeMatchesCheckResult.display_status ===
          InvoiceCheckResultObject.STATUS.ERROR
      ) {
        displayStatus = InvoiceCheckResultObject.STATUS.ERROR;
      }

      let expectedValue = null;
      let invoiceValue = null;

      if (
        deliveryNoteQuantityMatchesCheckResult.expected_value &&
        deliveryNoteUnitTypeMatchesCheckResult.expected_value
      ) {
        // If both quantity and unit are given, format the together in the merged expected value.
        expectedValue = UnitUtils.formatStringUnitPair_safe(
          deliveryNoteQuantityMatchesCheckResult.expected_value,
          deliveryNoteUnitTypeMatchesCheckResult.expected_value,
          getAbbreviatedUnits,
        );
      } else if (deliveryNoteQuantityMatchesCheckResult.expected_value) {
        // If only quantity is given, don't try to merge it with unit.
        expectedValue = UnitUtils.formatStringDe_safe(
          deliveryNoteQuantityMatchesCheckResult.expected_value,
        );
      } else if (deliveryNoteUnitTypeMatchesCheckResult.expected_value) {
        // If only unit is given, don't try to merge it with quantity.
        expectedValue = getAbbreviatedUnits(
          deliveryNoteUnitTypeMatchesCheckResult.expected_value,
        );
      }

      // Analogous approach for invoice_value as previous for expected_value
      if (
        deliveryNoteQuantityMatchesCheckResult.invoice_value &&
        deliveryNoteUnitTypeMatchesCheckResult.invoice_value
      ) {
        invoiceValue = UnitUtils.formatStringUnitPair_safe(
          deliveryNoteQuantityMatchesCheckResult.invoice_value,
          deliveryNoteUnitTypeMatchesCheckResult.invoice_value,
          getAbbreviatedUnits,
        );
      } else if (deliveryNoteQuantityMatchesCheckResult.invoice_value) {
        invoiceValue = UnitUtils.formatStringDe_safe(
          deliveryNoteQuantityMatchesCheckResult.invoice_value,
        );
      } else if (deliveryNoteUnitTypeMatchesCheckResult.invoice_value) {
        invoiceValue = getAbbreviatedUnits(
          deliveryNoteUnitTypeMatchesCheckResult.invoice_value,
        );
      }

      newDeliveryNoteQuantityMatchesCheckResults.push({
        ...deliveryNoteQuantityMatchesCheckResult,
        display_status: displayStatus,
        expected_value: expectedValue,
        invoice_value: invoiceValue,
      });
    }

    // All deliveryNoteQuantityMatchesCheckResults that have no deliveryNoteUnitTypeMatchesCheckResult should only format to DE number
    for (const deliveryNoteQuantityMatchesCheckResult of arrayMatch.unmatched1) {
      newDeliveryNoteQuantityMatchesCheckResults.push({
        ...deliveryNoteQuantityMatchesCheckResult,
        expected_value: UnitUtils.formatStringDe_safe(
          deliveryNoteQuantityMatchesCheckResult.expected_value,
        ),
        invoice_value: UnitUtils.formatStringDe_safe(
          deliveryNoteQuantityMatchesCheckResult.invoice_value,
        ),
      });
    }

    // All deliveryNoteUnitTypeMatchesCheckResults that have no deliveryNoteQuantityMatchesCheckResult should only format the unit
    for (const deliveryNoteUnitTypeMatchesCheckResult of arrayMatch.unmatched2) {
      newDeliveryNoteQuantityMatchesCheckResults.push({
        ...deliveryNoteUnitTypeMatchesCheckResult,
        expected_value: getAbbreviatedUnits(
          deliveryNoteUnitTypeMatchesCheckResult.expected_value,
        ),
        invoice_value: getAbbreviatedUnits(
          deliveryNoteUnitTypeMatchesCheckResult.invoice_value,
        ),
      });
    }

    // Filter out the old check results and replace them with the merged ones
    checkResults = checkResults.filter(
      ({ name }) =>
        name !== 'DeliveryNoteQuantityMatches' &&
        name !== 'DeliveryNoteUnitTypeMatches',
    );
    checkResults.push(...newDeliveryNoteQuantityMatchesCheckResults);

    return checkResults;
  }
}

import { createSelector } from '@reduxjs/toolkit';

export const selectFilters = (state) => state.filters;

export const selectDeliveryFilters = createSelector(
  [selectFilters],
  (filters) => ({
    dateRange: filters.delivery_dateRange,
    query: filters.delivery_query,
    selectedAcceptState: filters.delivery_selectedAcceptState,
    selectedArticle: filters.delivery_selectedArticle,
    selectedArticleNumber: filters.delivery_selectedArticleNumber,
    selectedCategory: filters.delivery_selectedCategory,
    selectedCostCenter: filters.delivery_selectedCostCenter,
    selectedCostCenters: filters.selectedCostCenters,
    selectedCustomFields: filters.delivery_selectedCustomFields,
    selectedCustomerNumber: filters.delivery_selectedCustomerNumber,
    selectedFromSite: filters.delivery_selectedFromSite,
    selectedNumber: filters.delivery_selectedNumber,
    selectedOuId: filters.delivery_selectedOuId,
    selectedPermittedCostCenters: filters.delivery_selectedPermittedCostCenters,
    selectedPermittedToSites: filters.delivery_selectedPermittedToSites,
    selectedPredefinedDateRange: filters.delivery_predefinedDateRange,
    selectedProcessState: filters.delivery_selectedProcessState,
    selectedRecipient: filters.delivery_selectedRecipient,
    selectedSettledStatus: filters.delivery_selectedSettledStatus,
    selectedSites: filters.selectedSites,
    selectedSupplier: filters.delivery_selectedSupplier,
    selectedToSiteRecipient: filters.delivery_selectedToSiteRecipient,
    selectedToSiteSupplier: filters.delivery_selectedToSiteSupplier,
    selectField: filters.delivery_selectField,
  }),
);

import { type DateRange } from '~/types/common';

import { dayjs } from '~/utils/datetime';

import { type AggregationInterval } from '../types';

/**
 * Generates an array of dates within a given date range, for which data should be aggregated and graphed.
 * The dates are spaced based on the specified aggregation interval.
 * @param dateRange - A tuple containing start and end dates as strings [startDate, endDate]
 * @param aggregationInterval - The interval at which to aggregate data ('day', 'week', 'month', 'quarter', etc.).
 *                             Defaults to 'day'.
 * @returns An array of Dayjs objects representing distinct dates within the range,
 *          spaced according to the specified aggregation interval.
 */
export const getAggregationDatesFromDateRange = (
  dateRange: DateRange,
  aggregationInterval: AggregationInterval = 'day',
) => {
  const dates = [];

  let current = dayjs(dateRange[0]).startOf(
    aggregationInterval === 'hour' ? 'day' : aggregationInterval,
  ); // Start at beginning of interval; display full day for hourly data
  const to = dayjs(dateRange[1]).endOf('day');

  while (current.isBefore(to)) {
    dates.push(current);
    current = current.add(1, aggregationInterval);
  }

  return dates;
};

import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { fetchDeliveryNotes } from '~/data/deliveryNote';
import { type DeliveryNoteSearchParams } from '~/data/deliveryNote/types';

import { useInitDlns } from '~/models/deliveries/DeliveryNote/utils/hooks';

import { mapDeliveryRow } from '~/components/deliveries/utils';

/**
 * Simplified duplicate of our useQueryDeliveryNotes query to allow passing in options like polling every 10 seconds.
 */
export const useQueryDeliveryNotesPickupScreen = (
  searchParams: DeliveryNoteSearchParams,
  options?: Omit<UseQueryOptions<number>, 'queryKey' | 'queryFn'>,
) => {
  const initDlns = useInitDlns();

  return useQuery({
    async queryFn() {
      const response = await fetchDeliveryNotes(searchParams);

      const initializedAssets = response.assets?.length
        ? await initDlns(response.assets)
        : [];

      const rows = initializedAssets.map((deliveryNote) =>
        mapDeliveryRow(deliveryNote),
      );

      const result = {
        ...response,
        rows,
      };

      return result;
    },
    queryKey: ['customQueryDeliveryNotesPickupScreen', searchParams],
    ...options,
  });
};

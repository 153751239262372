import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { Log } from '~/utils/logging';

import { type DeliveryNoteCreatedValues } from '../deliveries/DeliveryNote';

import { INVOICE_CHECK_RESULT_STATUS } from './constants';
import { type InvoiceCheckResult } from './types';

export const InvoiceCheckResultObject = {
  STATUS: INVOICE_CHECK_RESULT_STATUS,

  /**
   * Initializes the invoice check result based on the provided version.
   * @param {CheckResult} checkResult - The raw check result data.
   * @param {string} version - The version of the check result format.
   * @returns {object} - The formatted invoice check result.
   */
  create(checkResult: InvoiceCheckResult, version: string) {
    return version === '1.0'
      ? this.initV1(checkResult)
      : this.initV2(checkResult);
  },

  /**
   * Initializes the invoice check result for version 1.0.
   * @param {CheckResult} checkResult - The raw check result data.
   * @returns {object} - The formatted invoice check result.
   */
  initV1(checkResult: InvoiceCheckResult) {
    return {
      name: checkResult?.name ?? null,
      status: checkResult?.status ?? null,
      articleName: checkResult?.conflictingEntity ?? null,
      expectedValue: checkResult.conflictingValues?.['2'] ?? null,
      invoiceValue: checkResult.conflictingValues?.['1'] ?? null,
      deliveryNotes: checkResult?.deliveryNoteId
        ? [
            {
              id: checkResult?.deliveryNoteAssetId ?? null,
              number: checkResult?.deliveryNoteId ?? null,
            },
          ]
        : [],
    };
  },

  /**
   * Initializes the invoice check result for version 2.0.
   * @param {CheckResult} checkResult - The raw check result data.
   * @returns {object} - The formatted invoice check result.
   */
  initV2(checkResult: InvoiceCheckResult) {
    return {
      name: checkResult?.name ?? null,
      status: checkResult?.displayStatus ?? null,
      articleName: checkResult?.itemName ?? null,
      expectedValue: checkResult?.expectedValue ?? null,
      invoiceValue: checkResult?.invoiceValue ?? null,
      deliveryNotes:
        checkResult?.deliveryNoteNrs?.map((deliveryNoteNr, index) => ({
          id: checkResult?.deliveryNoteAssetIds?.[index] ?? null,
          number: deliveryNoteNr,
        })) ?? [],
    };
  },

  /**
   * Extracts delivery note numbers from the given invoice check result.
   * @param {object} checkResult - The formatted invoice check result.
   * @returns {string[]} - The list of delivery note numbers.
   */
  getDeliveryNoteNumbers(checkResult: {
    deliveryNotes: DeliveryNoteCreatedValues[];
  }) {
    return checkResult.deliveryNotes?.map(({ number }) => number ?? '');
  },

  /**
   * Flattens delivery note numbers from multiple check results.
   * @param {object[]} checkResults - The list of invoice check results.
   * @returns {string[]} - The flattened list of delivery note numbers.
   */
  getAllDeliveryNoteNumbers(
    checkResults: Array<{ deliveryNotes: DeliveryNoteCreatedValues[] }>,
  ) {
    if (!Array.isArray(checkResults)) {
      return [];
    }

    return checkResults.flatMap(
      (result) => InvoiceCheckResultObject.getDeliveryNoteNumbers(result) ?? [],
    );
  },

  /**
   * Gets the corresponding CSS class for a given invoice status.
   * @param {string} status - The status of the invoice.
   * @returns {string | null} - The corresponding CSS class or null if invalid.
   */
  getStatusColorClass(status: string) {
    switch (status) {
      case this.STATUS.SUCCESS: {
        return 'text-success500';
      }

      case this.STATUS.WARNING: {
        return 'text-warningBase';
      }

      case this.STATUS.ERROR: {
        return 'text-red-500';
      }

      case this.STATUS.NA:
      case this.STATUS.MANUAL:
      case this.STATUS.NO_CHECKING_POSSIBLE: {
        return 'text-gray-500';
      }

      case this.STATUS.DELAYED_SUCCESS: {
        return 'text-success100';
      }

      default: {
        Log.error(
          null,
          new EnumValueNotFoundException(`Invalid invoice status: ${status}`),
        );
        return null;
      }
    }
  },
};

export const LocationIcon = ({ className, style }: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 16 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10C8.55 10 9.021 9.804 9.413 9.412C9.805 9.02 10.0007 8.54933 10 8C10 7.45 9.804 6.979 9.412 6.587C9.02 6.195 8.54933 5.99933 8 6C7.45 6 6.979 6.196 6.587 6.588C6.195 6.98 5.99933 7.45067 6 8C6 8.55 6.196 9.021 6.588 9.413C6.98 9.805 7.45067 10.0007 8 10ZM8 17.35C10.0333 15.4833 11.5417 13.7873 12.525 12.262C13.5083 10.7367 14 9.38267 14 8.2C14 6.38333 13.4207 4.896 12.262 3.738C11.1033 2.58 9.68267 2.00067 8 2C6.31667 2 4.89567 2.57933 3.737 3.738C2.57833 4.89667 1.99933 6.384 2 8.2C2 9.38333 2.49167 10.7377 3.475 12.263C4.45833 13.7883 5.96667 15.484 8 17.35ZM8 20C5.31667 17.7167 3.31267 15.596 1.988 13.638C0.663333 11.68 0.000666667 9.86733 0 8.2C0 5.7 0.804333 3.70833 2.413 2.225C4.02167 0.741667 5.884 0 8 0C10.1167 0 11.9793 0.741667 13.588 2.225C15.1967 3.70833 16.0007 5.7 16 8.2C16 9.86667 15.3373 11.6793 14.012 13.638C12.6867 15.5967 10.6827 17.7173 8 20Z"
      fill="currentColor"
    />
  </svg>
);

import { Drawer, Hidden, List } from '@mui/material';
import clsx from 'clsx';
import { memo, type ReactElement } from 'react';

import { ClientPortalTooltip } from '~/components/salesPackages/clientPortal/ClientPortalTooltip';
import { ModuleInvoiceRestrictionTooltip } from '~/components/salesPackages/moduleInvoiceRestriction/ModuleInvoiceRestrictionTooltip';
import { PackageBasicRestrictionTooltip } from '~/components/salesPackages/packageBasicRestriction/PackageBasicRestrictionTooltip';
import { Tooltip } from '~/ui/atoms/Tooltip';

import { ExpandButton, MainNavLogo, NavItem } from './components';
import { type MainNavItemType } from './types';
import { useMainNav } from './useMainNav';

type P = {
  readonly drawerWidth: number;
  readonly isNavCollapsed: boolean;
  readonly items: MainNavItemType[];
  readonly setIsNavCollapsed: (isNavCollapsed: boolean) => void;
};

export const MainNav = memo(
  ({ drawerWidth, isNavCollapsed, items, setIsNavCollapsed }: P) => {
    const {
      allowedMainNavItems,
      handleChildItemsToggle,
      handleContextMenu,
      handleMainNavToggle,
      handleNavItemClick,
      isChildItemOpen,
    } = useMainNav({
      isNavCollapsed,
      items,
      setIsNavCollapsed,
    });

    const withTooltip = (
      component: ReactElement,
      title: string,
      placement: 'top' | 'bottom' | 'left' | 'right' = 'top',
    ) =>
      title ? (
        <Tooltip placement={placement} title={title}>
          {component}
        </Tooltip>
      ) : (
        component
      );

    const withSalesTooltip = (
      component: ReactElement,
      disabledByClientPortal: boolean,
      disabledByModuleInvoiceRestriction: boolean,
      disabledByPackageBasicRestriction: boolean,
    ) => {
      if (disabledByPackageBasicRestriction) {
        return (
          <PackageBasicRestrictionTooltip className="w-full">
            {component}
          </PackageBasicRestrictionTooltip>
        );
      }

      if (disabledByModuleInvoiceRestriction) {
        return (
          <ModuleInvoiceRestrictionTooltip className="w-full">
            {component}
          </ModuleInvoiceRestrictionTooltip>
        );
      }

      if (disabledByClientPortal) {
        return (
          <ClientPortalTooltip className="w-full">
            {component}
          </ClientPortalTooltip>
        );
      }

      return component;
    };

    const drawerTransitionClasses =
      'will-change-transform! transition-all! duration-200! ease-out!';

    return (
      <nav
        aria-label="main navigation"
        className={clsx('z-10 flex-shrink-0', drawerTransitionClasses)}
        style={{ width: drawerWidth }}
      >
        <Hidden implementation="css" smDown>
          <Drawer
            PaperProps={{
              style: { width: drawerWidth },
            }}
            classes={{
              paper: clsx(
                'h-screen !bg-[#0c1e44] !text-white',
                drawerTransitionClasses,
              ),
            }}
            variant="permanent"
            open
          >
            <div className="relative flex h-full w-full flex-col">
              <MainNavLogo />
              <List className="flex-1 overflow-y-auto" disablePadding>
                {allowedMainNavItems.map((item: MainNavItemType) => (
                  <NavItem
                    key={item.name}
                    handleChildItemsToggle={() => {
                      handleChildItemsToggle(item.name);
                    }}
                    handleContextMenu={handleContextMenu}
                    handleItemClick={handleNavItemClick}
                    isChildItemOpen={isChildItemOpen}
                    isNavCollapsed={isNavCollapsed}
                    item={item}
                    withSalesTooltip={withSalesTooltip}
                    withTooltip={withTooltip}
                  />
                ))}
              </List>
              <ExpandButton
                isCollapsed={isNavCollapsed}
                toggleState={handleMainNavToggle}
                withTooltip={withTooltip}
              />
            </div>
          </Drawer>
        </Hidden>
      </nav>
    );
  },
);

MainNav.displayName = 'MainNav';

export const DataExchangeInterestedIcon = ({
  className,
  style,
}: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3.70349C6.201 3.70349 1.5 7.22849 1.5 11.5785C1.5 13.0635 2.055 14.4532 3.012 15.639L1.5 18.954L5.70975 17.874C7.46475 18.861 9.6375 19.4535 12 19.4535C17.799 19.4535 22.5 15.9285 22.5 11.5785C22.5 7.22849 17.799 3.70349 12 3.70349ZM12.7365 16.0312H11.259V14.6137H12.7365V16.0312ZM12.7365 13.1017V13.5697H11.259V12.9922C11.259 11.2522 13.2405 10.9762 13.2405 9.74024C13.2405 9.17624 12.7365 8.74274 12.0758 8.74274C11.3932 8.74274 10.7917 9.24749 10.7917 9.24749L9.95175 8.20349C9.95175 8.20349 10.7797 7.33874 12.2092 7.33874C13.5667 7.33874 14.8267 8.17874 14.8267 9.59624C14.8267 11.5777 12.7365 11.8057 12.7365 13.1017Z"
      fill="currentColor"
    />
  </svg>
);

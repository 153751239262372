import { type UUID } from '~/types/common';

import { type DataExchangeEligiblePeopleQueryParams } from './useQueryDataExchangeEligiblePeople';
import { type DataExchangeExcelDataType } from './useQueryDataExchangeExcelData';

export const queryKeysDataExchange = {
  base: () => 'dataExchange' as const,
  get: (dataExchangeId: UUID) =>
    [queryKeysDataExchange.base(), 'detail', dataExchangeId] as const,
  getCompany: (companyId: UUID) =>
    [queryKeysDataExchange.base(), 'company', companyId] as const,
  getAllEligiblePeople: (
    dataExchangeId: UUID,
    queryParams: Partial<DataExchangeEligiblePeopleQueryParams>,
  ) =>
    [
      queryKeysDataExchange.base(),
      'eligiblePeople',
      dataExchangeId,
      queryParams,
    ] as const,
  getAllSuppliers: (queryParams) =>
    [queryKeysDataExchange.base(), 'list', queryParams].filter(
      Boolean,
    ) as const,
  getAllLegacy: () => [queryKeysDataExchange.base(), 'list', 'LEGACY'] as const,
  getCompanies: (initDataExchangeCompanies = true) =>
    [
      queryKeysDataExchange.base(),
      'companies',
      { initDataExchangeCompanies },
    ] as const,
  getDataAsExcel: (queryParams: Partial<DataExchangeExcelDataType>) =>
    [queryKeysDataExchange.base(), 'excelData', queryParams] as const,
} as const;

import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import ms from 'ms';

import { type UUID } from '~/types/common';

import { queryKeysUser } from './queryKeys';
import { fetchUserData, type UserData } from './useQueryUserData';

// Previously stored keys have been corrupted by converting their UUID keys to snake_case.
// This is a workaround to still find the correct user actions.
const normalizeAssetId = (assetId: UUID) =>
  assetId.replaceAll(/[^a-z\d]/gi, '').toLowerCase();

// Previously stored keys have been corrupted by converting their UUID keys to snake_case.
// This is a workaround to reformat such key to UUID format.
export const fixUUIDKeys = (object: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      key
        .replaceAll(/[^a-z\d]/gi, '')
        .toLowerCase()
        .replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, '$1-$2-$3-$4-$5'),
      value,
    ]),
  );
};

/**
 * Custom hook to fetch user actions for given asset IDs using React Query.
 * @param {UUID[]} assetIds - Array of asset IDs to fetch user actions for.
 * @param {boolean} [getAll=false] - Flag to fetch all user actions without passing a list of asset IDs.
 * @param {Object} [options] - Additional options for the useQuery hook.
 * @returns {UseQueryResult<UserData | undefined>} The full user actions and the selected user actions based on the given asset IDs.
 */
export const useQueryUserActions = (
  assetIds: UUID[] = [],
  getAll = false,
  options?: Parameters<typeof useQuery>[0],
): UseQueryResult<UserData | undefined> =>
  useQuery({
    queryFn: async () => fetchUserData(false),
    queryKey: queryKeysUser.getActions(),
    enabled: Boolean(assetIds?.length) || getAll,
    select(data: UserData) {
      const normalizedAssetIds = new Set(
        assetIds.map((id) => normalizeAssetId(id)),
      );

      const allUserActions = data?.settings?.webappUserActions ?? {};

      const selectedUserActions = Object.fromEntries(
        Object.entries(allUserActions).filter(([key]) =>
          normalizedAssetIds.has(normalizeAssetId(key)),
        ),
      );

      return {
        all: allUserActions,
        selected: selectedUserActions,
      };
    },
    staleTime: ms('1m'),
    ...options,
  });

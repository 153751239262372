import { validate as uuidvalidate } from 'uuid';

import { CustomFieldObject } from '~/models/customData';

import { isPlainObject } from '~/utils/object';

export type ChangesListPath = {
  NAME: string;
  PATH: string[];
};

// Mapping option ids to the display name during dln bulk load is necessary so that search function in delivery list is working properly -> TODO: this code is likely dead
export const initCustomData = async (
  customData: Record<string, any>,
  changesListIncludedPaths: ChangesListPath[] = [],
  customFieldsBulk: Array<Record<string, any>>,
) => {
  const processedCustomData = { ...customData };
  const updatedPaths = [...changesListIncludedPaths];

  for (const [customDataKey, customDataValue] of Object.entries(
    processedCustomData,
  )) {
    const customFieldId = CustomFieldObject.getIdFromKey(customDataKey);

    if (!customFieldId) {
      continue;
    }

    const customField = customFieldsBulk.find(({ id }) => id === customFieldId);

    if (!customField) {
      continue;
    }

    // Remove custom fields from the delivery note if the user isn't allowed to access them.
    // (e.g. when the custom fields are from another company).
    if (!customField) {
      delete processedCustomData[customDataKey];
      continue;
    }

    // Parsing the value of the custom field to a human-readable formatted value, should actually be made in the UI layer and not when initializing the dln.
    // However, this requires changes at multiple places in the code. The quick fix is to directly parse it here.
    const formattedCustomDataValue = CustomFieldObject.getFormatter(
      customField.type,
    )(customDataValue);

    // CHANGES_LIST_INCLUDED_PATHS must be extended by the custom fields
    // level can throw "Cannot read properties of null (reading 'level')" error if customField has been deleted in the meantime.
    if (customField.level === CustomFieldObject.LEVEL.DELIVERY_NOTE.KEY) {
      updatedPaths.push({
        NAME: customField.displayName,
        PATH: ['customData', customDataKey],
      });
    } else if (customField.level === CustomFieldObject.LEVEL.ARTICLE.KEY) {
      updatedPaths.push({
        NAME: `Artikel - ${customField.displayName}`,
        PATH: ['customData', customDataKey],
      });
    }

    // Remove the key if the value is an object because this leads to problems in the UI.
    if (isPlainObject(formattedCustomDataValue)) {
      delete processedCustomData[customDataKey];
      continue;
    }

    // Remove the key if it shouldn't be displayed.
    if (
      [
        CustomFieldObject.VISIBILITY.NEVER.KEY,
        CustomFieldObject.VISIBILITY.WHEN_SET.KEY,
      ].includes(customField.visibility) &&
      CustomFieldObject.valueIsEmpty(formattedCustomDataValue)
    ) {
      delete processedCustomData[customDataKey];
      continue;
    }

    const customFieldOptionId = CustomFieldObject.getIdFromKey(
      formattedCustomDataValue,
    );

    if (
      customField.type !== CustomFieldObject.TYPE.ENUMERATION.KEY ||
      !uuidvalidate(customFieldOptionId) ||
      !customField.hasOptions
    ) {
      delete processedCustomData[customDataKey];
      processedCustomData[customDataKey] = formattedCustomDataValue;
      continue;
    }

    const customFieldOption = customField.options.find(
      ({ id }) => id === customFieldOptionId,
    );

    processedCustomData[customDataKey] = customFieldOption?.displayName ?? null;
  }

  Object.assign(customData, processedCustomData);
  return updatedPaths;
};

import { useSelector } from 'react-redux';

import { selectDashboardFilters } from '~/modules/dashboard';

import { type FilterContext } from '~/utils/filters';
import { toCamelCase } from '~/utils/string';

import { selectDeliveryFilters } from '~/components/deliveries/deliveryOverview/DeliveryList/utils/reduxSelectorFunctions';

import { getSearchBody } from './getSearchBody';

type P = {
  filterContext: FilterContext;
};

/**
 * Hook for getting a filter configuration for querying delivery notes, e.g. for getting filter value suggestions.
 *
 * Takes a filter context object containing the current page and returns a filter config
 * that can be used to filter delivery notes.
 * The hook handles mapping between Redux filter names and backend filter names.
 *
 * @param {Object} params - The parameters object
 * @param {Object} params.filterContext - Context object containing the current page
 * @param {('dashboard'|'delivery')} params.filterContext.page - The current page
 * @returns {Object} Filter configuration object for delivery notes
 */
export const useGetFilterConfig = ({ filterContext }: P) => {
  const deliveryFilters = useSelector(selectDeliveryFilters);
  const dashboardFilters = useSelector(selectDashboardFilters);

  const filters =
    filterContext?.page === 'dashboard' ? dashboardFilters : deliveryFilters;

  // The filter names in Redux are wildly inconsistent with the filter names in the backend and need to be processed before querying the backend.
  const mappedFilters = Object.fromEntries(
    Object.entries(filters).map(([key, value]) => {
      let cleanedKey = toCamelCase(key.replace(/^selected/, ''));

      switch (cleanedKey) {
        case 'processState': {
          cleanedKey = 'processStates';
          break;
        }

        case 'recipient': {
          cleanedKey = 'recipients';
          break;
        }

        case 'supplier': {
          cleanedKey = 'suppliers';
          break;
        }

        case 'costCenter': {
          cleanedKey = 'selectedCostCenter';
          break;
        }

        case 'costCenters': {
          cleanedKey = 'selectedCostCenters';
          break;
        }

        case 'sites': {
          cleanedKey = 'selectedSites';
          break;
        }

        default: {
          break;
        }
      }

      return [cleanedKey, value];
    }),
  );

  const searchBody = getSearchBody({
    filterGroups: mappedFilters,
  });

  return searchBody?.filterConfig ?? {};
};

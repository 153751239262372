import { memo, type PropsWithChildren } from 'react';

import { DataExchangeCompanyObject } from '~/models/dataExchanges';

import { Tooltip } from '~/ui/atoms/Tooltip';

type P = PropsWithChildren & { readonly status: string };

export const DataExchangeStatusTooltip = memo(({ children, status }: P) => {
  const tooltipTitle = (
    <div>
      <div className="font-bold">
        {DataExchangeCompanyObject.getStatusLabel(status)}
      </div>
      <div>{DataExchangeCompanyObject.getStatusDescription(status)}</div>
    </div>
  );

  return (
    <Tooltip enterDelay={1000} enterNextDelay={1000} title={tooltipTitle}>
      {children}
    </Tooltip>
  );
});

DataExchangeStatusTooltip.displayName = 'DataExchangeStatusTooltip';

import { SIGNATURE_ROLE } from './constants';
import { type SignatureRoleKeys, type SignatureRoles } from './types';

export const SignatureRolesObject = {
  /**
   * Creates a SignatureRoles object with optional overrides from the given argument.
   * @param {Partial<SignatureRoles>} [signatureRoles={}] - The signature roles to override.
   * @returns {SignatureRoles} - The constructed SignatureRoles object.
   */
  create(signatureRoles: Partial<SignatureRoles> = {}): SignatureRoles {
    return {
      supplier: signatureRoles.supplier ?? false,
      carrier: signatureRoles.carrier ?? false,
      recipient:
        signatureRoles.recipient ??
        SignatureRolesObject.getRecipient(signatureRoles),
      onBehalfSupplier: signatureRoles.onBehalfSupplier ?? false,
      onBehalfCarrier: signatureRoles.onBehalfCarrier ?? false,
      onBehalfRecipient: signatureRoles.onBehalfRecipient ?? false,
      defaultSignatureRole: signatureRoles.defaultSignatureRole ?? '',
    };
  },

  /**
   * Determines if the 'recipient' role is assigned, checking the 'recipient' field or a legacy default role.
   * @param {Partial<SignatureRoles>} signatureRoles - The signature roles to check.
   * @returns {boolean} - Returns true if the 'recipient' role is assigned.
   */
  getRecipient(signatureRoles: Partial<SignatureRoles>) {
    return (
      signatureRoles.recipient ||
      signatureRoles.defaultSignatureRole ===
        SignatureRolesObject.SIGNATURE_ROLE.RECIPIENT.LEGACY_KEY ||
      false
    );
  },

  /**
   * Checks if any of the signature roles (supplier, carrier, recipient, etc.) are assigned.
   * @param {SignatureRoles} signatureRoles - The signature roles to check.
   * @returns {boolean} - Returns true if any role is assigned.
   */
  signatureRoleIsAssigned(signatureRoles: SignatureRoles) {
    return (
      signatureRoles.supplier ||
      signatureRoles.carrier ||
      signatureRoles.recipient ||
      signatureRoles.onBehalfSupplier ||
      signatureRoles.onBehalfCarrier ||
      signatureRoles.onBehalfRecipient
    );
  },

  /**
   * Returns the key for the user type based on the assigned signature role (recipient, carrier, supplier).
   * @param {SignatureRoles} signatureRoles - The signature roles to check.
   * @returns {string | null} - The key of the assigned user type or null if none is assigned.
   */
  getUserType(signatureRoles: SignatureRoles) {
    if (signatureRoles.recipient) {
      return SignatureRolesObject.SIGNATURE_ROLE.RECIPIENT.KEY;
    }

    if (signatureRoles.carrier) {
      return SignatureRolesObject.SIGNATURE_ROLE.CARRIER.KEY;
    }

    if (signatureRoles.supplier) {
      return SignatureRolesObject.SIGNATURE_ROLE.SUPPLIER.KEY;
    }

    return null;
  },

  /**
   * Checks if no signature role is selected (supplier, carrier, recipient are all false).
   * @param {SignatureRoles} signatureRoles - The signature roles to check.
   * @returns {boolean} - Returns true if no role is selected.
   */
  noSignatureRoleIsSelected(signatureRoles: SignatureRoles) {
    return (
      !signatureRoles.supplier &&
      !signatureRoles.carrier &&
      !signatureRoles.recipient
    );
  },

  /**
   * Checks if more than one signature role is selected.
   * @param {SignatureRoles} signatureRoles - The signature roles to check.
   * @returns {boolean} - Returns true if more than one role is selected.
   */
  multipleSignatureRolesAreSelected(signatureRoles: SignatureRoles): boolean {
    return (
      Number(signatureRoles.supplier) +
        Number(signatureRoles.carrier) +
        Number(signatureRoles.recipient) >
      1
    );
  },

  /**
   * Returns an array of string keys for the assigned signature roles.
   * @param {SignatureRoles} signatureRoles - The signature roles to check.
   * @returns {string[]} - An array of keys for the assigned roles.
   */
  getSignatureRoles(signatureRoles: SignatureRoles) {
    const roles: string[] = [];

    if (signatureRoles.supplier) {
      roles.push(SignatureRolesObject.SIGNATURE_ROLE.SUPPLIER.KEY);
    }

    if (signatureRoles.carrier) {
      roles.push(SignatureRolesObject.SIGNATURE_ROLE.CARRIER.KEY);
    }

    if (signatureRoles.recipient) {
      roles.push(SignatureRolesObject.SIGNATURE_ROLE.RECIPIENT.KEY);
    }

    if (signatureRoles.onBehalfSupplier) {
      roles.push(SignatureRolesObject.SIGNATURE_ROLE.ON_BEHALF_SUPPLIER.KEY);
    }

    if (signatureRoles.onBehalfCarrier) {
      roles.push(SignatureRolesObject.SIGNATURE_ROLE.ON_BEHALF_CARRIER.KEY);
    }

    if (signatureRoles.onBehalfRecipient) {
      roles.push(SignatureRolesObject.SIGNATURE_ROLE.ON_BEHALF_RECIPIENT.KEY);
    }

    return roles;
  },

  /**
   * Returns an array of translated labels (names) for the assigned signature roles.
   * @param {SignatureRoles} signatureRoles - The signature roles to check.
   * @returns {string[]} - An array of labels for the assigned roles.
   */
  getSignatureRoleLabels(signatureRoles: SignatureRoles) {
    const roles: string[] = [];

    if (signatureRoles.supplier) {
      roles.push(SignatureRolesObject.SIGNATURE_ROLE.SUPPLIER.STRING);
    }

    if (signatureRoles.carrier) {
      roles.push(SignatureRolesObject.SIGNATURE_ROLE.CARRIER.STRING);
    }

    if (signatureRoles.recipient) {
      roles.push(SignatureRolesObject.SIGNATURE_ROLE.RECIPIENT.STRING);
    }

    if (signatureRoles.onBehalfSupplier) {
      roles.push(SignatureRolesObject.SIGNATURE_ROLE.ON_BEHALF_SUPPLIER.STRING);
    }

    if (signatureRoles.onBehalfCarrier) {
      roles.push(SignatureRolesObject.SIGNATURE_ROLE.ON_BEHALF_CARRIER.STRING);
    }

    if (signatureRoles.onBehalfRecipient) {
      roles.push(
        SignatureRolesObject.SIGNATURE_ROLE.ON_BEHALF_RECIPIENT.STRING,
      );
    }

    return roles;
  },

  /**
   * Compares two SignatureRoles objects and returns the differences as an array of strings.
   * @param {SignatureRoles} a - The first SignatureRoles object.
   * @param {SignatureRoles} b - The second SignatureRoles object.
   * @returns {string[]} - An array of strings representing the differences.
   */
  getDifferences(a: SignatureRoles, b: SignatureRoles) {
    const differences: string[] = [];
    for (const key of Object.keys(SignatureRolesObject.SIGNATURE_ROLE)) {
      if (a[key as SignatureRoleKeys] !== b[key as SignatureRoleKeys]) {
        differences.push(
          SignatureRolesObject.SIGNATURE_ROLE[
            key as keyof typeof SignatureRolesObject.SIGNATURE_ROLE
          ].STRING,
        );
      }
    }

    return differences;
  },

  // The SIGNATURE_ROLE constant, which defines the keys for various roles.
  SIGNATURE_ROLE,
};

import {
  useMutation,
  type UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { z } from 'zod';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';
import ToastService from '~/services/toast.service';

import { Log } from '~/utils/logging';
import { withoutObjectKeysWhereValueIs } from '~/utils/object';

import { queryKeysDataExchange } from './queryKeys';
import { type DataExchange } from './useQueryDataExchange';

const zodUpsertDataExchangeSchema = z.object({
  activeCustomerIds: z.array(z.string()).optional(),
  contactedPersonId: z.string().uuid().optional(),
  exchangeInvoices: z.boolean().optional(),
  exchangeLfs: z.boolean().optional(),
  exchangeOrders: z.boolean().optional(),
  furtherInformation: z.string().optional(),
  isExchangeViaPaper: z.boolean().optional(),
  knownIssues: z.string().optional(),
  receiverId: z.string().uuid(),
  responsiblePersonId: z.string().uuid().nullish(),
  senderId: z.string().uuid(),
});

type UpsertDataExchangePayload = z.infer<typeof zodUpsertDataExchangeSchema>;

/**
 * Creates or updates a data exchange.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Data-Exchange/operation/add_data_exchange_data_exchange_put

 */
export const upsertDataExchange = async (
  dataExchange: UpsertDataExchangePayload,
) => {
  try {
    const filteredPayload = withoutObjectKeysWhereValueIs(dataExchange, [
      undefined,
    ]);

    const response = await vestigasApi
      .put(ENDPOINT.DATA_EXCHANGE.CREATE(), {
        json: filteredPayload,
        validate: {
          body: zodUpsertDataExchangeSchema,
        },
      })
      .json<DataExchange>();

    return response;
  } catch (error) {
    Log.error('Error creating data exchange', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for creating or updating a data exchange using mutations.
 *
 * @returns {Object} An object containing the mutation function and other properties.
 */
export const useMutationUpsertDataExchange = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dataExchange: UpsertDataExchangePayload) =>
      upsertDataExchange(dataExchange),
    async onError(error) {
      Log.error('Failed to create new data exchange', error);
      Log.productAnalyticsEvent(
        'Failed to create new data exchange',
        Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );

      ToastService.error([
        'Dieser Lieferant ist noch nicht freigeschalten. Bitte melde dich bei deiner VESTIGAS Ansprechperson',
      ]);
    },
    onSuccess({ id: dataExchangeId }) {
      void queryClient.invalidateQueries({
        queryKey: queryKeysDataExchange.getAllSuppliers(undefined),
      });
      // This will always refetch the data exchange, even if it was just created.
      // How can we tell if we created or updated a data exchange?
      void queryClient.invalidateQueries({
        queryKey: queryKeysDataExchange.get(dataExchangeId),
      });
    },
    ...options,
  });
};

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import clsx from 'clsx';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useQueryCompany } from '~/data/company';

import { DataExchangeCompanyObject } from '~/models/dataExchanges';

import { Log } from '~/utils/logging';

import { Reveal } from '~/ui/atoms/Reveal';

import { DataExchangeStatusIcon } from './DataExchangeStatusIcon';
import { DataExchangeStatusTooltip } from './DataExchangeStatusTooltip';
import { DataExchangeTile } from './DataExchangeTile';

export const DataExchangeCategory = ({
  category,
  onChangeRequestInformationSelection,
  requestInformationSelection,
  requestInformationSelectionList,
}) => {
  const [open, setOpen] = useState(true);

  const selectedCompany = useSelector(
    (state) => state.filters.dataExchanges_selectedCompany,
  );

  const { data: company } = useQueryCompany(selectedCompany);

  const getLabel = () => {
    const isCategoryActivated =
      category.key === DataExchangeCompanyObject.STATUS.ACTIVATED.KEY;

    const heading =
      isCategoryActivated && company
        ? `Für ${company.name} ${category.label}`
        : category.label;

    return (
      <span
        className={clsx('mx-2', {
          'font-bold': isCategoryActivated,
        })}
      >
        {heading}
      </span>
    );
  };

  const handleClick = () => {
    if (open) {
      Log.productAnalyticsEvent(
        'Collapse category',
        Log.FEATURE.SUPPLIER_OVERVIEW,
      );

      setOpen(false);
    } else {
      Log.productAnalyticsEvent(
        'Expand category',
        Log.FEATURE.SUPPLIER_OVERVIEW,
      );

      setOpen(true);
    }
  };

  if (!category?.dataExchanges?.length) {
    return null;
  }

  return (
    <div id={category.key}>
      <DataExchangeStatusTooltip status={category.key}>
        <div
          className="t-2 sticky z-10 mb-4 flex w-80 cursor-pointer items-center justify-between rounded-md border-gray-400 bg-white px-2 py-1"
          onClick={handleClick}
        >
          <div className="flex items-center">
            <DataExchangeStatusIcon status={category.key} />
            {getLabel()}
          </div>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
      </DataExchangeStatusTooltip>
      <Reveal show={open}>
        <div className="mb-4 flex flex-wrap gap-4">
          {category.dataExchanges.map((dataExchange) => (
            <div
              key={dataExchange.id}
              className="min-h-60 w-80 rounded-lg bg-white p-4"
            >
              <DataExchangeTile
                key={dataExchange.id}
                dataExchange={dataExchange}
                requestInformationSelection={requestInformationSelection}
                requestInformationSelectionList={
                  requestInformationSelectionList
                }
                onChangeRequestInformationSelection={
                  onChangeRequestInformationSelection
                }
              />
            </div>
          ))}
        </div>
      </Reveal>
    </div>
  );
};

import cloneLib from 'clone';
import { isEqualWith } from 'lodash-es';
import { isObjectType } from 'remeda';

export { isObjectType, isPlainObject } from 'remeda';

/**
 * Deep clones an object with high performance.
 * See https://medium.com/@tiagobertolo/which-is-the-best-method-for-deep-cloning-in-javascript-are-they-all-bad-101f32d620c5
 * @param object - The object to clone
 * @returns The cloned object
 */
export const clone = <T>(object: T) => cloneLib(object);

/**
 * Retrieves a deeply nested property from an object.
 *
 * @param {Record<string, unknown>} obj - The object from which to retrieve the property.
 * @param {string} nestedProperty - The path to the nested property, separated by dots.
 * @return {unknown} The value of the nested property.
 * @example
 * getNestedProperty({foo: {bar: { baz: 'hello'}}}, 'foo.bar.baz')
 * return 'hello'
 */
export const getNestedProperty = <T>(
  object: Record<string, unknown>,
  nestedProperty: string,
): T | undefined => {
  const propertyPath = nestedProperty.split('.');

  return propertyPath.reduce((current, key) => current?.[key], object) as
    | T
    | undefined;
};

/**
 * Recursively removes keys from an object and its nested objects if their value matches any of the specified values.
 *
 * @param {T} object - The object to process.
 * @param {unknown[]} valuesToRemove - An array of values that trigger key removal.
 * @returns {Partial<T>} A new object with keys removed if their values are in valuesToRemove.
 */
export const withoutObjectKeysWhereValueIs = <T extends Record<string, any>>(
  object: T,
  valuesToRemove: unknown[],
): Partial<T> => {
  const removeNestedKeys = (object_: any): any => {
    if (!isObjectType(object_) || object_ === null) {
      return object_;
    }

    if (Array.isArray(object_)) {
      return object_.map((item) => removeNestedKeys(item));
    }

    const newObject: Record<string, any> = {};
    for (const key in object_) {
      if (!Object.hasOwn(object_, key)) {
        continue;
      }

      const value = object_[key];

      if (valuesToRemove.includes(value)) {
        continue; // Skip this key
      }

      if (isObjectType(value)) {
        const newValue = removeNestedKeys(value);
        newObject[key] = newValue; // Assign the modified value
      } else {
        newObject[key] = value; // Keep the original value
      }
    }

    return newObject;
  };

  return removeNestedKeys(object);
};

/** Compares two objects/arrays for content equality, regardless of property/element order.
 *
 * @param {unknown} objectOne - First object/array to compare
 * @param {unknown} objectTwo - Second object/array to compare
 * @returns {boolean} True if contents are equal, false otherwise
 */
export const isObjectContentEqual = (
  objectOne: unknown,
  objectTwo: unknown,
): boolean =>
  isEqualWith(objectOne, objectTwo, (value1: unknown, value2: unknown) => {
    // Handle arrays - sort them for comparison
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return (
        JSON.stringify([...value1].sort()) ===
        JSON.stringify([...value2].sort())
      );
    }

    // Handle objects - sort keys and compare
    if (
      value1 &&
      value2 &&
      typeof value1 === 'object' &&
      typeof value2 === 'object'
    ) {
      const sortedObject1 = JSON.stringify(value1, Object.keys(value1).sort());
      const sortedObject2 = JSON.stringify(value2, Object.keys(value2).sort());
      return sortedObject1 === sortedObject2;
    }

    // Return undefined for other types to let lodash handle the comparison
    return undefined;
  });

export const UNITS_WEIGHT = {
  TNE: {
    label: 't',
    labelLong: 'Tonnen',
    type: 'weight',
    factor: 1000,
  },
  KGM: {
    label: 'kg',
    labelLong: 'Kilogramm',
    type: 'weight',
    factor: 1,
  },
  GRM: {
    label: 'g',
    labelLong: 'Gramm',
    type: 'weight',
    factor: 1 / 1000,
  },
} as const;
export type UnitsWeightType = typeof UNITS_WEIGHT;
export type UnitsKeyWeightType = keyof UnitsWeightType;

export const UNITS_AMOUNT = {
  BR: {
    label: 'STG',
    labelLong: 'Stangen',
    type: 'amount',
  },
  EA: {
    label: 'Stk',
    labelLong: 'Stück',
    type: 'amount',
  },
  MMT: {
    label: 'mm',
    labelLong: 'Millimeter',
    type: 'amount',
  },
  CMT: {
    label: 'cm',
    labelLong: 'Zentimeter',
    type: 'amount',
  },
  MTR: {
    label: 'm',
    labelLong: 'Meter',
    type: 'amount',
  },
  KMT: {
    label: 'km',
    labelLong: 'Kilometer',
    type: 'amount',
  },
  SA: {
    label: 'Säcke',
    labelLong: 'Säcke',
    type: 'amount',
  },
  RO: {
    label: 'Rollen',
    labelLong: 'Rollen',
    type: 'amount',
  },
  PF: {
    label: 'Pal',
    labelLong: 'Paletten',
    type: 'amount',
  },
  PA: {
    label: 'Pkt',
    labelLong: 'Pakete',
    type: 'amount',
  },
  BJ: {
    label: 'Eim',
    labelLong: 'Eimer',
    type: 'amount',
  },
  BG: {
    label: 'Beutel',
    labelLong: 'Beutel',
    type: 'amount',
  },
  PK: {
    label: 'Pack',
    labelLong: 'Packungen',
    type: 'amount',
  },
  TU: {
    label: 'Tuben',
    labelLong: 'Tuben',
    type: 'amount',
  },
  CA: {
    label: 'Dosen',
    labelLong: 'Dosen',
    type: 'amount',
  },
  CI: {
    label: 'Kanister',
    labelLong: 'Kanister',
    type: 'amount',
  },
  PR: {
    label: 'Paar',
    labelLong: 'Paar',
    type: 'amount',
  },
  CT: {
    label: 'Kartone',
    labelLong: 'Kartone',
    type: 'amount',
  },
  CQ: {
    label: 'Kartusche',
    labelLong: 'Kartusche',
    type: 'amount',
  },
  BE: {
    label: 'Bündel',
    labelLong: 'Bündel',
    type: 'amount',
  },
  LR: {
    label: 'Lage',
    labelLong: 'Lage',
    type: 'amount',
  },
  SET: {
    label: 'Satz',
    labelLong: 'Satz',
    type: 'amount',
  },
  E48: {
    label: 'Service',
    labelLong: 'Service',
    type: 'amount',
  },
  C62: {
    label: 'Einheiten',
    labelLong: 'Einheiten',
    type: 'amount',
  },
  AB: {
    label: 'Großpack',
    labelLong: 'Großpack',
    type: 'amount',
  },
  KT: {
    label: 'Kit',
    labelLong: 'Kit',
    type: 'amount',
  },
  MTK: {
    label: 'm²',
    labelLong: 'Quadratmeter',
    type: 'amount',
  },
  PSCH: {
    label: 'pauschal',
    labelLong: 'pauschal',
    type: 'amount',
  },
} as const;
export type UnitsAmountType = typeof UNITS_AMOUNT;
export type UnitsKeyAmountType = keyof UnitsAmountType;

export const UNITS_VOLUME = {
  LTR: {
    label: 'Ltr',
    labelLong: 'Liter',
    type: 'volume',
    factor: 1,
  },
  MTQ: {
    label: 'm³',
    labelLong: 'Kubikmeter',
    type: 'volume',
    factor: 1000,
  },
  DMQ: {
    label: 'dm³',
    labelLong: 'Kubikdezimeter',
    type: 'volume',
    factor: 1,
  },
  CMQ: {
    label: 'cm³',
    labelLong: 'Kubikzentimeter',
    type: 'volume',
    factor: 0.001,
  },
  MMQ: {
    label: 'mm³',
    labelLong: 'Kubikmillimeter',
    type: 'volume',
    factor: 0.000_001,
  },
} as const;
export type UnitsVolumeType = typeof UNITS_VOLUME;
export type UnitsKeyVolumeType = keyof UnitsVolumeType;

export const UNITS_TIME = {
  MIN: {
    label: 'Min',
    labelLong: 'Minuten',
    type: 'time',
    factor: 1,
  },
  HUR: {
    label: 'Std',
    labelLong: 'Stunden',
    type: 'time',
    factor: 60,
  },
  DAY: {
    label: 'Tage',
    labelLong: 'Tage',
    type: 'time',
    factor: 1440,
  },
  MON: {
    label: 'Monate',
    labelLong: 'Monate',
    type: 'time',
    factor: 43_200, // Based on thirty days
  },
} as const;
export type UnitsTimeType = typeof UNITS_TIME;
export type UnitsKeyTimeType = keyof UnitsTimeType;

export const UNITS_CURRENCY = {
  EUR: {
    label: '€',
    labelLong: 'Euro',
    type: 'currency',
  },
} as const;
export type UnitsCurrencyType = typeof UNITS_CURRENCY;
export type UnitsKeyCurrencyType = keyof UnitsCurrencyType;

export const UNITS_OTHER = {
  KMQ: {
    label: 'kg/m³',
    labelLong: 'Kilogramm pro Kubikmeter',
    type: 'weight-per-volume',
  },
} as const;
export type UnitsOtherType = typeof UNITS_OTHER;
export type UnitsKeyOtherType = keyof UnitsOtherType;

export const UNITS = {
  ...UNITS_AMOUNT,
  ...UNITS_CURRENCY,
  ...UNITS_OTHER,
  ...UNITS_TIME,
  ...UNITS_VOLUME,
  ...UNITS_WEIGHT,
};

export type UnitsType = UnitsWeightType &
  UnitsAmountType &
  UnitsVolumeType &
  UnitsTimeType &
  UnitsCurrencyType &
  UnitsOtherType;

export type UncefactUnitType =
  | UnitsKeyWeightType
  | UnitsKeyAmountType
  | UnitsKeyVolumeType
  | UnitsKeyTimeType
  | UnitsKeyCurrencyType
  | UnitsKeyOtherType;

export type UnitsTypeType = UnitsType[keyof UnitsType]['type'];

import { UNITS } from '~/constants/units';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { type UUID } from '~/types/common';

import { Log } from '~/utils/logging';
import { isValidNumber } from '~/utils/number';
import { clone } from '~/utils/object';
import { toCamelCase } from '~/utils/string';
import UnitUtils from '~/utils/unitUtils';

import {
  AcceptArticleObject,
  type AcceptItem,
  AcceptStateCalculatorObject,
} from '../acceptState';
import { BilledItemObject } from '../billingState';
import { CustomDataObject } from '../customData';
import { ShippingMarkObject } from '../deliveries/shippingMarkUtils';
import { type ShippingMark } from '../deliveries/types';
import { ValueGroupObject } from '../deliveries/valueGroup';
import { CompanyObject } from '../masterdata/Company';
import { SignatureRolesObject } from '../masterdata/SignatureRoles';

import { ConcreteObject } from './concreteUtils';
import {
  ARTICLE_CHANGES_BOOLEAN_EXCLUDED_PATHS,
  ARTICLE_DELIVERY_DIRECTION,
  ARTICLE_MATERIAL,
} from './constants';
import {
  type ArticleMaterialKeys,
  type LineItem,
  type LogisticsPackage,
  type UnitAndValue,
} from './types';

export const ArticleObject = {
  id: undefined as UUID | undefined,
  acceptArticleCarrier: {} as Record<string, any>,
  acceptArticleOnBehalfCarrier: {} as Record<string, any>,
  acceptArticleOnBehalfRecipient: {} as Record<string, any>,
  acceptArticleOnBehalfSupplier: {} as Record<string, any>,
  acceptArticleRecipient: {} as Record<string, any>,
  acceptArticleSupplier: {} as Record<string, any>,
  acceptState: '' as string,
  amount: {
    unit: '',
    value: 0,
  } as UnitAndValue,
  billedItem: {} as Record<string, any>,
  comment: '',
  concrete: null as Record<string, any> | undefined,
  constructionComponent: null as string | undefined,
  constructionPlan: null as string | undefined,
  costCenter: null as string | undefined,
  customData: {} as Record<string, any>,
  delivery: {} as Record<string, any>,
  description: '',
  documentLineItemPosition: 0,
  documentLogisticsPackagePosition: 0,
  ean: '',
  logisticsPackage: 0 as LogisticsPackage,
  manufacturer: {} as Record<string, any>,
  number: 0,
  position: 0,
  quantity: {
    unit: '',
    value: 0,
  } as UnitAndValue,
  shippingMarks: [] as ShippingMark[],
  type: '',
  weighingInformation: {} as Record<string, any>,
  weight: {
    unit: '',
    value: 0,
  } as UnitAndValue,

  MATERIAL: ARTICLE_MATERIAL,
  UNIT: UNITS,
  DELIVERY_DIRECTION: ARTICLE_DELIVERY_DIRECTION,
  CHANGES_BOOLEAN_EXCLUDED_PATHS: ARTICLE_CHANGES_BOOLEAN_EXCLUDED_PATHS,

  /**
   * Initializes the Article object with the given data.
   *
   * @param lineItem - An object containing details about the line item, such as product, delivery, settlement, and additional data.
   * @param logisticsPackage - A `LogisticsPackage` instance representing the logistics package associated with the article.
   * @param documentLogisticsPackagePosition - The position of the logistics package in the document log.
   * @param documentLineItemPosition - The position of the line item in the document log.
   * @param acceptItems - An array of `AcceptItem` objects representing items to be accepted for the article.
   * @param billedItems - An array of objects representing items that have been billed, used for calculating billing details.
   * @returns  Updated ArticleObject
   */
  create({
    acceptItems,
    billedItems,
    documentLineItemPosition,
    documentLogisticsPackagePosition,
    lineItem,
    logisticsPackage,
  }: {
    acceptItems?: AcceptItem[];
    billedItems?: Array<Record<string, any>>;
    documentLineItemPosition?: number;
    documentLogisticsPackagePosition?: number;
    lineItem?: Record<string, any>;
    logisticsPackage?: LogisticsPackage;
  }) {
    const instance = clone(this);

    instance.logisticsPackage = logisticsPackage ?? 0;
    instance.position = lineItem?.id ?? 0;

    const product = lineItem?.product || {};
    instance.number = product?.sellerAssignedId ?? 0;
    instance.ean = product?.ean ?? '';
    instance.type = product?.name ?? '';
    instance.description = product?.description ?? '';
    instance.comment = product?.comment ?? '';
    instance.constructionPlan = product?.constructionPlan ?? null;
    instance.constructionComponent = product?.constructionComponent ?? null;

    const settlement = lineItem?.settlement || {};
    const payableAccount = settlement?.payableAccount ?? {};
    instance.costCenter = payableAccount?.name ?? null;

    const delivery = lineItem?.delivery || {};
    instance.shippingMarks = (delivery?.shippingMarks ?? []).map(
      (shippingMark) => ShippingMarkObject.create(shippingMark),
    );

    const manufacturer = product?.manufacturer || {};
    instance.manufacturer = CompanyObject.create(
      manufacturer?.legal_organization ?? manufacturer?.legalOrganization,
    );

    instance.customData = CustomDataObject.create(
      lineItem?.additional_party_data ?? lineItem?.additionalPartyData,
    );

    instance.documentLogisticsPackagePosition =
      documentLogisticsPackagePosition ?? 0;
    instance.documentLineItemPosition = documentLineItemPosition ?? 0;

    instance.quantity = instance.getEmptyValue();
    instance.weight = instance.getEmptyValue();
    instance.amount = instance.getEmptyValue();

    instance.weighingInformation =
      instance.calculateWeighingInformation(lineItem);

    if (!lineItem) {
      return instance;
    }

    instance.loadNetWeight(lineItem);
    instance.loadProductUnit(lineItem);
    instance.loadConcrete(lineItem);

    instance.amount = instance.getAmountFromArticle(
      instance.quantity,
      instance.weight,
    );
    instance.billedItem = instance.getBilledItem(billedItems);

    instance.setAcceptArticles(acceptItems);

    return instance;
  },
  /**
   * Configures the acceptance details for the article based on provided accept items and predefined roles.
   *
   * @param acceptItems - An array of `AcceptItem` objects representing acceptance information for various parties.
   */
  setAcceptArticles(acceptItems?: AcceptItem[]) {
    const roles = SignatureRolesObject.SIGNATURE_ROLE;

    this.acceptArticleSupplier = AcceptArticleObject.getAcceptArticleOfParty(
      acceptItems ?? [],
      roles.SUPPLIER.KEY,
      this.documentLogisticsPackagePosition,
      this.documentLineItemPosition,
    );

    this.acceptArticleCarrier = AcceptArticleObject.getAcceptArticleOfParty(
      acceptItems ?? [],
      roles.CARRIER.KEY,
      this.documentLogisticsPackagePosition,
      this.documentLineItemPosition,
    );

    this.acceptArticleRecipient = AcceptArticleObject.getAcceptArticleOfParty(
      acceptItems ?? [],
      roles.RECIPIENT.KEY,
      this.documentLogisticsPackagePosition,
      this.documentLineItemPosition,
    );

    this.acceptArticleOnBehalfSupplier =
      AcceptArticleObject.getAcceptArticleOfParty(
        acceptItems ?? [],
        roles.ON_BEHALF_SUPPLIER.KEY,
        this.documentLogisticsPackagePosition,
        this.documentLineItemPosition,
      );

    this.acceptArticleOnBehalfCarrier =
      AcceptArticleObject.getAcceptArticleOfParty(
        acceptItems ?? [],
        roles.ON_BEHALF_CARRIER.KEY,
        this.documentLogisticsPackagePosition,
        this.documentLineItemPosition,
      );

    this.acceptArticleOnBehalfRecipient =
      AcceptArticleObject.getAcceptArticleOfParty(
        acceptItems ?? [],
        roles.ON_BEHALF_RECIPIENT.KEY,
        this.documentLogisticsPackagePosition,
        this.documentLineItemPosition,
      );

    this.acceptState =
      AcceptStateCalculatorObject.calculateOverallAcceptStateFromParties({
        acceptStateCarrier: this.acceptArticleCarrier.acceptState,
        acceptStateOnBehalfCarrier:
          this.acceptArticleOnBehalfCarrier.acceptState,
        acceptStateOnBehalfRecipient:
          this.acceptArticleOnBehalfRecipient.acceptState,
        acceptStateOnBehalfSupplier:
          this.acceptArticleOnBehalfSupplier.acceptState,
        acceptStateRecipient: this.acceptArticleRecipient.acceptState,
        acceptStateSupplier: this.acceptArticleSupplier.acceptState,
      });

    this.setUnitForAcceptArticles(this.amount.unit);
  },

  /**
   * Sets the unit for all acceptance articles associated with the article.
   *
   * @param unit - The unit to be applied to the acceptance articles.
   */
  setUnitForAcceptArticles(unit: string | undefined) {
    this.acceptArticleSupplier.setUnit(unit);
    this.acceptArticleCarrier.setUnit(unit);
    this.acceptArticleRecipient.setUnit(unit);
    this.acceptArticleOnBehalfSupplier.setUnit(unit);
    this.acceptArticleOnBehalfCarrier.setUnit(unit);
    this.acceptArticleOnBehalfRecipient.setUnit(unit);
  },

  /**
   * Loads the net weight of the article from the provided line item and sets it to the `weight` property.
   *
   * @param lineItem - The line item containing delivery details, including net weight information.
   */
  loadNetWeight(lineItem: LineItem) {
    if (!lineItem.delivery?.netWeight?.measure) {
      return;
    }

    this.weight = {
      unit: lineItem.delivery?.netWeight?.measure,
      value: UnitUtils.parseToNumber(lineItem.delivery?.netWeight?.weight) ?? 0,
    };
  },

  /**
   * Loads the product unit information from the given line item and sets the quantity.
   * @param lineItem - The line item containing delivery and product unit details.
   * @returns {void} - This function does not return any value.
   */
  loadProductUnit(lineItem: LineItem) {
    if (!lineItem.delivery?.productUnit?.quantityType) {
      return;
    }

    this.quantity = {
      unit: lineItem.delivery?.productUnit?.quantityType,
      value:
        UnitUtils.parseToNumber(lineItem.delivery?.productUnit?.quantity) ?? 0,
    };
  },

  /**
   * Loads concrete information from the given line item and updates delivery details.
   * @param lineItem - The line item containing product, delivery, and concrete details.
   * @returns {void} - This function does not return any value.
   */
  loadConcrete(lineItem: LineItem) {
    if (
      !Object.hasOwn(lineItem.product ?? {}, 'concrete') ||
      lineItem.product?.concrete === null
    ) {
      return;
    }

    this.delivery = {
      actual: {
        unit: lineItem.delivery?.actualDelivery?.measure,
        value:
          UnitUtils.parseToNumber(lineItem.delivery?.actualDelivery?.value) ??
          0,
      },
      remaining: {
        unit: lineItem.delivery?.remainingDelivery?.measure,
        value: UnitUtils.parseToNumber(
          lineItem.delivery?.remainingDelivery?.value,
        ),
      },
      requested: {
        unit: lineItem.delivery?.requestedDelivery?.measure,
        value: UnitUtils.parseToNumber(
          lineItem.delivery?.requestedDelivery?.value,
        ),
      },
    };

    this.concrete = ConcreteObject.create(
      this.number,
      lineItem.product?.concrete,
    );
  },

  /**
   * Calculates and returns the weighing information for the given line item.
   * @param lineItem - The line item containing weighing and weight details.
   * @returns {object} An object containing gross weight, tare weight, and weighing person information.
   */
  calculateWeighingInformation(lineItem?: LineItem) {
    return {
      gross: {
        scaleId: lineItem?.documentLine?.note?.content?.grossWeight?.id,
        weight: {
          unit: lineItem?.documentLine?.note?.content?.grossWeight?.measure,
          value: UnitUtils.parseToNumber(
            lineItem?.documentLine?.note?.content?.grossWeight?.weight,
          ),
        },
      },
      person: {
        name: lineItem?.documentLine?.note?.content?.weighingPerson
          ?.person_name,
      },
      tare: {
        scaleId: lineItem?.documentLine?.note?.content?.tareWeight?.id,
        weight: {
          unit: lineItem?.documentLine?.note?.content?.tareWeight?.measure,
          value: UnitUtils.parseToNumber(
            lineItem?.documentLine?.note?.content?.tareWeight?.weight,
          ),
        },
      },
    };
  },

  /**
   * Retrieves the amount (unit and value) from the given quantity or weight, based on validity.
   * @param quantity - An object containing the quantity unit and value.
   * @param weight - An object containing the weight unit and value.
   * @returns {object} An object with unit and value if valid, or an empty value if neither is valid.
   */
  getAmountFromArticle(
    quantity: Record<string, any>,
    weight: Record<string, any>,
  ) {
    if (isValidNumber(quantity.value) && quantity.unit) {
      return {
        unit: quantity.unit,
        value: quantity.value,
      };
    }

    if (isValidNumber(weight.value) && weight.unit) {
      return {
        unit: weight.unit,
        value: weight.value,
      };
    }

    return this.getEmptyValue();
  },

  /**
   * Retrieves and returns the billed item for the specified line item from the billedItems record.
   * @param billedItems - An object containing seller or trader billed items.
   * @returns {BilledItem} The billed item associated with the specified line item, or a default empty item.
   */
  getBilledItem(billedItems?: Record<string, any>) {
    const partyBilledItems: Array<Record<string, any>> =
      billedItems?.seller ?? billedItems?.trader ?? [];

    const partyBilledItem = partyBilledItems.find(
      (partyBilledItem) =>
        JSON.stringify(
          partyBilledItem.path.map((item: string) => toCamelCase(item)),
        ) ===
        JSON.stringify([
          'transaction',
          'logisticsPackage',
          this.documentLogisticsPackagePosition,
          'lineItem',
          this.documentLineItemPosition,
        ]),
    );

    return BilledItemObject.create(partyBilledItem);
  },

  /**
   * Returns an object representing an empty value with null unit and value.
   * @returns {object} An object with `unit` and `value` set to `null`.
   */
  getEmptyValue() {
    return {
      unit: null,
      value: null,
    };
  },

  /**
   * Checks if the current object has been edited, excluding specified paths.
   * @returns {boolean} True if the object has been edited, false otherwise.
   */
  hasBeenEdited() {
    return ValueGroupObject.hasBeenEdited(
      this,
      this.CHANGES_BOOLEAN_EXCLUDED_PATHS,
      null,
    );
  },

  // Return the string that should be displayed for the partial accept status in the dln article list.
  // This contains the bug that when recipient has declined, supplier and carrier are ignored.
  // However, the reason for the bug is already the UI because there is only one "Abgelehnt" column in the dln article list.
  // Hence, already there multiple parties declining isn't handled. Thus, the design of the dln article list must be adjusted to fix this bug.
  getPartialAcceptStatusString(article) {
    if (
      article.acceptArticleRecipient.acceptState ===
      AcceptStateCalculatorObject.ACCEPT_STATE.DECLINED
    ) {
      return AcceptArticleObject.partialAcceptStatusToString(
        article.acceptArticleRecipient.partialAcceptStatus,
        article.amount.unit,
      );
    }

    if (
      article.acceptArticleSupplier.acceptState ===
      AcceptStateCalculatorObject.ACCEPT_STATE.DECLINED
    ) {
      return AcceptArticleObject.partialAcceptStatusToString(
        article.acceptArticleSupplier.partialAcceptStatus,
        article.amount.unit,
      );
    }

    if (
      article.acceptArticleCarrier.acceptState ===
      AcceptStateCalculatorObject.ACCEPT_STATE.DECLINED
    ) {
      return AcceptArticleObject.partialAcceptStatusToString(
        article.acceptArticleCarrier.partialAcceptStatus,
        article.amount.unit,
      );
    }

    if (
      article.acceptArticleOnBehalfRecipient.acceptState ===
      AcceptStateCalculatorObject.ACCEPT_STATE.DECLINED
    ) {
      return AcceptArticleObject.partialAcceptStatusToString(
        article.acceptArticleOnBehalfRecipient.partialAcceptStatus,
        article.amount.unit,
      );
    }

    if (
      article.acceptArticleOnBehalfSupplier.acceptState ===
      AcceptStateCalculatorObject.ACCEPT_STATE.DECLINED
    ) {
      return AcceptArticleObject.partialAcceptStatusToString(
        article.acceptArticleOnBehalfSupplier.partialAcceptStatus,
        article.amount.unit,
      );
    }

    if (
      article.acceptArticleOnBehalfCarrier.acceptState ===
      AcceptStateCalculatorObject.ACCEPT_STATE.DECLINED
    ) {
      return AcceptArticleObject.partialAcceptStatusToString(
        article.acceptArticleOnBehalfCarrier.partialAcceptStatus,
        article.amount.unit,
      );
    }

    return null;
  },

  /**
   * Checks if the amount value and unit are valid and should be displayed.
   * @returns {boolean} True if both the amount value and unit are valid, false otherwise.
   */
  displayAmount() {
    return (
      isValidNumber(ValueGroupObject.getCurrentValue(this.amount.value)) &&
      ValueGroupObject.getCurrentValue(this.amount.unit)
    );
  },

  /**
   * Checks if the weight and amount values are valid and not equal to each other, ensuring they are different units.
   * @returns {boolean} True if both the weight and amount are valid and have different values and units, false otherwise.
   */
  displayWeight() {
    return (
      isValidNumber(ValueGroupObject.getCurrentValue(this.weight.value)) &&
      ValueGroupObject.getCurrentValue(this.weight.unit) &&
      ValueGroupObject.getCurrentValue(this.amount.value) !==
        ValueGroupObject.getCurrentValue(this.weight.value) &&
      ValueGroupObject.getCurrentValue(this.amount.unit) !==
        ValueGroupObject.getCurrentValue(this.weight.unit)
    );
  },

  /**
   * Checks if the object has a 'concrete' property with a value.
   * @returns {boolean} True if the object has the 'concrete' property and it is not null, false otherwise.
   */
  isConcrete() {
    return Object.hasOwn(this, 'concrete') && Boolean(this.concrete);
  },

  /**
   * Retrieves the material name based on the provided key.
   * @param key - The key representing a material.
   * @returns {string} The descriptive name of the material corresponding to the key, or the key itself if not found.
   */
  getMaterialName(key: string) {
    const articleConst = Object.keys(this.MATERIAL).find(
      (x) => key === this.MATERIAL[x as ArticleMaterialKeys].STANDARDISED,
    );

    if (!articleConst) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid material: ' + key),
      );
      return key;
    }

    return this.MATERIAL[articleConst as ArticleMaterialKeys].DESCRIPTIVE;
  },

  /**
   * Generates and returns the backend format for the object, including various details like agreement, delivery, product, and settlement.
   * @param costCenter - The cost center to be used in the settlement (optional).
   * @param sellerOrderReferences - The references associated with the seller's order.
   * @param buyerOrderReferences - The references associated with the buyer's order.
   * @param constructionComponents - The construction components associated with the product.
   * @param constructionPlans - The construction plans associated with the product.
   * @returns {object} The formatted object suitable for backend processing, including information about agreement, delivery, document line, product, and settlement.
   */
  getBackendFormat(
    costCenter: string | undefined,
    sellerOrderReferences: any,
    buyerOrderReferences: any,
    constructionComponents: any,
    constructionPlans: any,
  ) {
    return {
      agreement: {
        buyer_order: buyerOrderReferences,
        seller_order: sellerOrderReferences,
      },
      delivery: {
        net_weight: this.weight.value
          ? {
              measure: this.weight.unit,
              weight: this.weight.value.toString().replace(',', '.'),
            }
          : null,
        product_unit: this.quantity.value
          ? {
              quantity: this.quantity.value.toString().replace(',', '.'),
              quantity_type: this.quantity.unit,
            }
          : null,
      },
      document_line: {
        note: {
          content: {
            gross_weight: this.weighingInformation.gross.weight.value
              ? {
                  measure: this.weight.unit,
                  weight: this.weighingInformation.gross.weight.value.replace(
                    ',',
                    '.',
                  ),
                }
              : null,
            tare_weight: this.weighingInformation.tare.weight.value
              ? {
                  measure: this.weight.unit,
                  weight: this.weighingInformation.tare.weight.value.replace(
                    ',',
                    '.',
                  ),
                }
              : null,
          },
        },
      },
      id: this.position,
      product: {
        construction_component: constructionComponents,
        // Writing the constructionPlans into the construction_plan variable doesn't work properly if a delivery note has multiple articles with different construction plans.
        // In this case, every article has all the construction plans assigned to. This is similar to construction components and buyer/seller order references.
        construction_plan: constructionPlans,
        ean: this.ean,
        manufacturer: CompanyObject.getBackendFormat(this.manufacturer),
        name: this.type,
        seller_assigned_id: this.number,
      },
      settlement: costCenter
        ? {
            payable_account: {
              name: costCenter,
              type_code: 'AWE',
            },
          }
        : null,
    };
  },
};

import { hasDifference } from '~/models/utils';

import { DIFFERENCE_VALUES } from './constants';
import { type PushNotificationSettings } from './types';

export const PushNotificationSettingsObject = {
  /**
   * Creates PushNotificationSettings object with provided settings.
   * @param pushNotificationSettings - The push notification settings object.
   */
  create(pushNotificationSettings: Partial<PushNotificationSettings> = {}) {
    return {
      dlnCreated: {
        all: pushNotificationSettings?.dlnCreated?.all ?? false,
        costCenterIds: pushNotificationSettings?.dlnCreated?.accRefs ?? [],
        siteIds: pushNotificationSettings?.dlnCreated?.sites ?? [],
      },
      dlnSignReminder: {
        all: pushNotificationSettings?.dlnSignReminder?.all ?? false,
        costCenterIds: pushNotificationSettings?.dlnSignReminder?.accRefs ?? [],
        siteIds: pushNotificationSettings?.dlnSignReminder?.sites ?? [],
      },
    };
  },

  /**
   * Compare two sets of push notification settings and find differences.
   * @param pushNotificationSettingsA - The first set of notification settings.
   * @param pushNotificationSettingsB - The second set of notification settings.
   * @returns An array of strings indicating the differences.
   */
  getDifferences(
    pushNotificationSettingsA: PushNotificationSettings,
    pushNotificationSettingsB: PushNotificationSettings,
  ): string[] {
    const differentValues: string[] = [];

    if (
      pushNotificationSettingsA?.dlnCreated?.all !==
      pushNotificationSettingsB?.dlnCreated?.all
    ) {
      differentValues.push(DIFFERENCE_VALUES.DLN_CREATED_ALL);
    }

    if (
      pushNotificationSettingsA?.dlnSignReminder?.all !==
      pushNotificationSettingsB?.dlnSignReminder?.all
    ) {
      differentValues.push(DIFFERENCE_VALUES.DLN_SIGN_REMINDER_ALL);
    }

    const isDifferentDlnCreatedSiteIds = hasDifference(
      pushNotificationSettingsA?.dlnCreated?.siteIds,
      pushNotificationSettingsB?.dlnCreated?.siteIds,
    );
    if (isDifferentDlnCreatedSiteIds) {
      differentValues.push(DIFFERENCE_VALUES.DLN_CREATED_SITES);
    }

    const isDifferentDlnCreatedCostCenterIds = hasDifference(
      pushNotificationSettingsA?.dlnCreated?.costCenterIds,
      pushNotificationSettingsB?.dlnCreated?.costCenterIds,
    );
    if (isDifferentDlnCreatedCostCenterIds) {
      differentValues.push(DIFFERENCE_VALUES.DLN_CREATED_COST_CENTERS);
    }

    const isDifferentDlnSignReminderSiteIds = hasDifference(
      pushNotificationSettingsA?.dlnSignReminder?.siteIds,
      pushNotificationSettingsB?.dlnSignReminder?.siteIds,
    );
    if (isDifferentDlnSignReminderSiteIds) {
      differentValues.push(DIFFERENCE_VALUES.DLN_SIGN_REMINDER_SITES);
    }

    const isDifferentDlnSignReminderCostCenterIds = hasDifference(
      pushNotificationSettingsA?.dlnSignReminder?.costCenterIds,
      pushNotificationSettingsB?.dlnSignReminder?.costCenterIds,
    );
    if (isDifferentDlnSignReminderCostCenterIds) {
      differentValues.push(DIFFERENCE_VALUES.DLN_SIGN_REMINDER_COST_CENTERS);
    }

    return differentValues;
  },

  /**
   * Transforms the provided push notification settings into a backend-compatible format.
   * @param pushNotificationSettings - An object containing push notification settings,
   * @returns An object formatted for backend processing, mapping notification settings
   */
  getBackendFormat(pushNotificationSettings: PushNotificationSettings) {
    return {
      dln_created: {
        accRefs: pushNotificationSettings.dlnCreated.costCenterIds,
        all: pushNotificationSettings.dlnCreated.all,
        sites: pushNotificationSettings.dlnCreated.siteIds,
      },
      dln_sign_reminder: {
        accRefs: pushNotificationSettings.dlnSignReminder.costCenterIds,
        all: pushNotificationSettings.dlnSignReminder.all,
        sites: pushNotificationSettings.dlnSignReminder.siteIds,
      },
    };
  },
};

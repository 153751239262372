import { type UUID } from '~/types/common';

import { hasDifference } from '~/models/utils';

import { CompanyObject } from '../Company';
import {
  type PermissionGrantCreatedObject,
  PermissionGrantObject,
  type PermissionGrantSubjectType,
  type PermissionGrantTargetType,
} from '../PermissionGrant';
import { PushNotificationSettingsObject } from '../PushNotificationSettings';
import { SignatureRolesObject } from '../SignatureRoles';
import { UserFeatureFlagsObject } from '../UserFeatureFlags';

import {
  DIFFERENCE_VALUES,
  USER_ASSET_CREATOR_USER_ID,
  USER_CSV_ALLOWED_COLUMNS,
  USER_TYPE,
} from './constants';
import { type User } from './types';

export const UserObject = {
  /**
   * Creates a User object.
   * @param {Partial<User>} user - The user data.
   * @returns {User} - The created User object.
   */
  create(user: Partial<User> = {}) {
    return {
      company: CompanyObject.create({ id: user?.companyId }),
      companyId: user?.companyId ?? undefined,
      email: user?.email ?? '',
      featureFlags: UserFeatureFlagsObject.create(),
      firstName: user?.firstName ?? '',
      id: user?.id ?? '',
      isActive: user?.isActive ?? true,
      lastName: user?.lastName ?? '',
      notificationSettings: {
        delayDuration: user?.delayDuration ?? 0,
        sendEmail: user?.sendEmail ?? true,
      },
      organisationalGroupPaths: user?.orgUnitPaths ?? [],
      organisationalGroups: user?.orgUnits ?? [],
      parentOrganizationalUnitPaths: user?.parentOrganizationalUnitPaths ?? [],
      parentOrganizationalUnits: user?.parentOrganizationalUnits ?? [],
      password: '',
      permissionGrantsFrom:
        (user?.permissionsFrom ?? []).map((permissionGrant) =>
          PermissionGrantObject.create({
            ...permissionGrant,
            targetId: user.id,
            targetType: PermissionGrantObject.ENTITY_TYPE
              .USER as PermissionGrantTargetType,
          }),
        ) ?? [],
      permissionGrantsOn:
        (user?.permissionsOn ?? []).map((permissionGrant) =>
          PermissionGrantObject.create({
            ...permissionGrant,
            subjectId: user.id,
            subjectType: PermissionGrantObject.SUBJECT_TYPE
              .USER as PermissionGrantSubjectType,
          }),
        ) ?? [],
      position: user?.position ?? '',
      pushNotificationSettings: PushNotificationSettingsObject.create(),
      signatureRoles: SignatureRolesObject.create(),
      userGroupPaths: user?.userGroupPaths ?? [],
      userGroups: user?.userGroups ?? [],
    };
  },

  /**
   * Retrieves the full name of the user based on their first and last name.
   * @param user - A partial user object containing optional firstName and lastName properties. Defaults to an empty object.
   * @returns A string representing the user's full name, or an empty string if no name is available.
   */
  getName(user: Partial<User> = {}) {
    return [user?.firstName, user?.lastName].filter(Boolean).join(' ') ?? '';
  },

  /**
   * Finds differences between two User objects.
   * @param {User} oldUser - The first User object.
   * @param {User} newUser - The second User object.
   * @returns {string[]} - A list of different values.
   */
  getDifferences(oldUser: Record<string, any>, newUser: Record<string, any>) {
    const differentValues = [];

    if (oldUser?.id !== newUser?.id) differentValues.push(DIFFERENCE_VALUES.ID);
    if (oldUser?.firstName !== newUser?.firstName)
      differentValues.push(DIFFERENCE_VALUES.FIRST_NAME);
    if (oldUser?.lastName !== newUser?.lastName)
      differentValues.push(DIFFERENCE_VALUES.LAST_NAME);
    if (oldUser?.email !== newUser?.email)
      differentValues.push(DIFFERENCE_VALUES.EMAIL);
    if (oldUser?.position !== newUser?.position)
      differentValues.push(DIFFERENCE_VALUES.POSITION);
    if (oldUser?.password !== newUser?.password)
      differentValues.push(DIFFERENCE_VALUES.PASSWORD);
    if (oldUser?.isActive !== newUser?.isActive)
      differentValues.push(DIFFERENCE_VALUES.ACTIVE);
    if (oldUser?.companyId !== newUser?.companyId)
      differentValues.push(DIFFERENCE_VALUES.COMPANY);

    if (
      SignatureRolesObject.getDifferences(
        oldUser?.signatureRoles,
        newUser?.signatureRoles,
      ).length > 0
    ) {
      differentValues.push(
        `${DIFFERENCE_VALUES.SIGNATURE_ROLES} (${SignatureRolesObject.getDifferences(
          oldUser?.signatureRoles,
          newUser?.signatureRoles,
        ).join(', ')})`,
      );
    }

    if (
      UserFeatureFlagsObject.getDifferences(
        oldUser?.featureFlags,
        newUser?.featureFlags,
      ).length > 0
    ) {
      differentValues.push(
        `${DIFFERENCE_VALUES.FEATURE_FLAGS} (${UserFeatureFlagsObject.getDifferences(
          oldUser?.featureFlags,
          newUser?.featureFlags,
        ).join(', ')})`,
      );
    }

    if (
      PushNotificationSettingsObject.getDifferences(
        oldUser?.pushNotificationSettings,
        newUser?.pushNotificationSettings,
      ).length > 0
    ) {
      differentValues.push(
        `${DIFFERENCE_VALUES.PUSH_NOTIFICATIONS_SETTINGS} (${PushNotificationSettingsObject.getDifferences(
          oldUser?.pushNotificationSettings,
          newUser?.pushNotificationSettings,
        ).join(', ')})`,
      );
    }

    const isDifferentOrganisationalGroups = hasDifference(
      oldUser?.organisationalGroups,
      newUser?.organisationalGroups,
    );
    if (isDifferentOrganisationalGroups) {
      differentValues.push(DIFFERENCE_VALUES.ORG_GROUPS);
    }

    const isDifferentUserGroups = hasDifference(
      oldUser?.userGroups,
      newUser?.userGroups,
    );
    if (isDifferentUserGroups) {
      differentValues.push(DIFFERENCE_VALUES.USER_GROUPS);
    }

    return differentValues;
  },

  /**
   * Checks if the provided user ID matches the asset creator's user ID.
   * @param userId - The user ID to compare with the asset creator's user ID.
   * @returns A boolean indicating if the provided user ID is the same as the asset creator's user ID.
   */
  isAssetCreator(userId?: UUID) {
    return userId === UserObject.ASSET_CREATOR_USER_ID;
  },

  canReadDeliveryNotes(permissionGrantsOn: PermissionGrantCreatedObject[]) {
    return permissionGrantsOn.some(({ permissions }) =>
      PermissionGrantObject.canReadDeliveryNotes(permissions),
    );
  },

  ASSET_CREATOR_USER_ID: USER_ASSET_CREATOR_USER_ID,
  CSV_ALLOWED_COLUMNS: USER_CSV_ALLOWED_COLUMNS,
  TYPE: USER_TYPE,
};

import clsx from 'clsx';
import { type ReactNode } from 'react';

import { Spinner } from '~/ui/atoms/Spinner';

import { DLNNumber } from './DLNNumber';

type P = {
  readonly dlns: string[];
  readonly icon: ReactNode;
  readonly isLoading: boolean;
  readonly prompt: string;
  readonly status: string;
} & ComponentStyling;

export const DLNList = ({
  className,
  dlns,
  icon,
  isLoading,
  prompt,
  status,
  style = {},
}: P) => {
  const totalItems = dlns?.length;

  const shouldOverflow = totalItems > 50; // As last resort, we show a scrollbar, if there are way too many items to display.
  const numberSize = totalItems > 6 ? 'lg' : 'xl'; // If there are many items, we reduce the size of each item.

  return (
    <div
      className={clsx('h-full w-1/2 flex-1 text-center', className)}
      style={{
        padding: '1vw',
        ...style,
      }}
    >
      <p
        className="bg-grey100 mb-4 rounded-lg p-1 align-top mix-blend-multiply"
        style={{ fontSize: '2.5vw' }}
      >
        {prompt}
      </p>
      {/* <div className="">
        {icon}
        <h2
          className="m-0 inline-block align-top font-normal"
          style={{ fontSize: '3vw' }}
        >
          {status}
        </h2>
      </div> */}
      {isLoading ? (
        <Spinner title="Lade Daten..." />
      ) : (
        <div
          className="inline-flex flex-col flex-wrap overflow-auto text-center"
          style={{
            gap: '1vw',
            height: 'calc(100vh - 16vw)',
            marginTop: '1vw',
            maxWidth: 'calc(100% - 2 * 2vw)',
            overflow: shouldOverflow ? 'auto' : 'hidden',
          }}
        >
          {dlns.map((dln) => (
            <DLNNumber key={dln} size={numberSize} text={dln} />
          ))}
        </div>
      )}
    </div>
  );
};

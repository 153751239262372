import { hasDifference } from '~/models/utils';

import {
  PermissionGrantObject,
  type PermissionGrantTargetType,
} from '../PermissionGrant';

import { DIFFERENCE_VALUES } from './constants';
import { type CostCenter } from './types';

export const CostCenterObject = {
  /**
   * Creates a CostCenter object.
   * @param {Partial<CostCenter>} costCenter - The cost center data.
   * @param {boolean} additionalDataInitiated - Whether additional data is initiated.
   * @returns {CostCenter} - The created CostCenter object.
   */
  create(
    costCenter: Partial<CostCenter> = {},
    additionalDataInitiated = false,
  ) {
    const costCenterData = {
      id: costCenter?.id ?? null,
      name: costCenter?.name ?? '',
      company: costCenter?.companyId ?? '',
      companyId: costCenter?.companyId ?? '',
      active: costCenter?.isActive ?? true,
      isActive: costCenter?.isActive ?? true,
      start: costCenter?.start ?? null,
      end: costCenter?.end ?? null,
      createdOn: costCenter?.createdOn ?? '',
      modifiedOn: costCenter?.modifiedOn ?? '',
      organisationalGroups: costCenter?.orgUnits ?? [],
      parentOrganizationalUnits: costCenter?.parentOrganizationalUnits ?? [],
      organisationalGroupPaths: costCenter?.orgUnitPaths ?? [],
      parentOrganizationalUnitPaths:
        costCenter?.parentOrganizationalUnitPaths ?? [],
      permissionGrantsFrom:
        costCenter?.permissionsFrom?.map((permissionGrant) =>
          PermissionGrantObject.create({
            ...permissionGrant,
            targetId: costCenter?.id ?? undefined,
            targetType: PermissionGrantObject.ENTITY_TYPE
              .COST_CENTER as PermissionGrantTargetType,
          }),
        ) ?? [],
      additionalDataInitiated,
    };

    if (!costCenterData.additionalDataInitiated) {
      costCenterData.organisationalGroupPaths = [];
    }

    return costCenterData;
  },

  /**
   * Compares two CostCenter objects to find the different values.
   * @param {CostCenter} costCenterA - The first CostCenter object.
   * @param {CostCenter} costCenterB - The second CostCenter object.
   * @returns {string[]} - A list of different values.
   */
  getDifferences(
    costCenterA: Record<string, any>,
    costCenterB: Record<string, any>,
  ): string[] {
    const differentValues: string[] = [];

    if (costCenterA?.id !== costCenterB?.id) {
      differentValues.push(DIFFERENCE_VALUES.ID);
    }

    if (costCenterA?.name !== costCenterB?.name) {
      differentValues.push(DIFFERENCE_VALUES.NAME);
    }

    if (costCenterA?.active !== costCenterB?.active) {
      differentValues.push(DIFFERENCE_VALUES.ACTIVE);
    }

    if (costCenterA?.company !== costCenterB?.company) {
      differentValues.push(DIFFERENCE_VALUES.COMPANY);
    }

    const isDifferentOrganisationalGroups = hasDifference(
      costCenterA?.organisationalGroups,
      costCenterB?.organisationalGroups,
    );
    if (isDifferentOrganisationalGroups) {
      differentValues.push(DIFFERENCE_VALUES.ORG_GROUPS);
    }

    return differentValues;
  },
};

import {
  INVOICE_CHECK_RESULT_STATUS,
  InvoiceCheckCategoryObject,
  type InvoiceCheckResult,
} from '~/models/invoices';

import { InvoiceCheckSummaryText } from '~/modules/invoice/detail/InvoiceCheck/invoiceCheckSummary/InvoiceCheckSummaryText';

import ComponentUtils from '~/utils/componentUtils';
import { getAbbreviatedUnit } from '~/utils/unit';
import UnitUtils from '~/utils/unitUtils';

// Cache the invoice rows to avoid re-calculating them on every render.
const invoiceRowCache = new Map();

// Cache component strings to avoid repeated ReactDOMServer.renderToString calls
const componentStringCache = new Map();

/**
 * Clears the cached invoice rows.
 *
 * @function
 * @returns {void} No return value.
 */
export const clearInvoiceRowCache = () => {
  invoiceRowCache.clear();
};

const getInvoiceCheckSummaryComponentAsText = (category: any) => {
  const cacheKey = category;

  if (componentStringCache.has(cacheKey)) {
    return componentStringCache.get(cacheKey);
  }

  const componentString = ComponentUtils.getStringFromComponent(
    <InvoiceCheckSummaryText category={category} />,
  );

  componentStringCache.set(cacheKey, componentString);

  return componentString;
};

const getCombinedStatusString = (
  checkCategory: Record<string, InvoiceCheckResult>,
  status: string,
  subStatus: string,
) => {
  const summaryText = getInvoiceCheckSummaryComponentAsText(
    checkCategory.signatureCheck,
  );
  const categoriesText = Object.keys(checkCategory)
    .filter(
      (key) => checkCategory[key].status === INVOICE_CHECK_RESULT_STATUS.ERROR,
    )
    .map(
      (key) =>
        `${checkCategory[key]?.key}|${getInvoiceCheckSummaryComponentAsText(checkCategory[key])}`,
    )
    .join(';');

  return [
    status,
    subStatus,
    checkCategory.signatureCheck?.status,
    summaryText,
    categoriesText,
  ].join(';');
};

const getErrorAndWarningCategoriesString = (checkCategory) => {
  return Object.keys(checkCategory)
    .filter(
      (key) =>
        checkCategory[key].status === INVOICE_CHECK_RESULT_STATUS.ERROR ||
        checkCategory[key].status === INVOICE_CHECK_RESULT_STATUS.WARNING,
    )
    .map((key) => checkCategory[key].key)
    .join(';');
};

const getSearchString = (row, invoice) => {
  const invoiceCheckSummaryText = Object.keys(invoice.checkCategory)
    .filter(
      (key) =>
        invoice.checkCategory[key].status ===
          INVOICE_CHECK_RESULT_STATUS.ERROR ||
        invoice.checkCategory[key].status ===
          INVOICE_CHECK_RESULT_STATUS.WARNING,
    )
    .map((key) =>
      getInvoiceCheckSummaryComponentAsText(invoice.checkCategory[key]),
    )
    .join(';');

  const totalPriceGross = UnitUtils.formatDeMoneyAmount_safe(
    row.totalPriceGross,
  );

  return [
    row.id ?? '',
    row.number ?? '',
    row.seller ?? '',
    row.buyer ?? '',
    totalPriceGross ?? '',
    totalPriceGross?.replaceAll(/\D/g, '') ?? '',
    invoiceCheckSummaryText ?? '',
  ]
    .join(';')
    .toLowerCase();
};

export const mapInvoiceRow = (invoice) => {
  const cacheKey = `${invoice.id}-${invoice.parsedDate}`;

  if (invoiceRowCache.has(cacheKey)) {
    return invoiceRowCache.get(cacheKey);
  }

  let row = {
    buyer: invoice.buyer.name,
    // Add the icons of the erroneous categories so that the status column in the invoice overview can be instantiated.
    combinedStatus: getCombinedStatusString(
      invoice.checkCategory,
      invoice.status,
      invoice.subStatus,
    ),
    currency: getAbbreviatedUnit(invoice.currency),
    date: invoice.date,
    delayedSigned:
      invoice.checkCategory.signatureCheck.status ===
      INVOICE_CHECK_RESULT_STATUS.DELAYED_SUCCESS,
    // Add the erroneous categories so that the status filter can easily filter by this.
    errorAndWarningCategories: getErrorAndWarningCategoriesString(
      invoice.checkCategory,
    ),
    id: invoice.id,
    number: invoice.number,
    parsedDate: invoice.parsedDate,
    seller: invoice.seller.name,
    status: invoice.status,
    toSite: invoice.toSite,
    totalPriceGross: invoice.totalPriceGross,
    unsignedDeliveryNoteIds:
      invoice.checkCategory.signatureCheck.status ===
      INVOICE_CHECK_RESULT_STATUS.DELAYED_SUCCESS
        ? ''
        : InvoiceCheckCategoryObject.getUnsignedDeliveryNoteIds(
            invoice.checkCategory.signatureCheck.errorChecks,
          ).join(';'),
  };

  row = {
    ...row,
    searchString: getSearchString(row, invoice),
  };

  // Store the mapped row in the cache
  invoiceRowCache.set(cacheKey, row);

  return row;
};

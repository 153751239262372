type P = {
  readonly fill?: string;
} & ComponentStyling;

export const OrderInProcessIcon = ({
  className,
  fill = 'currentColor',
  style,
}: P) => (
  <svg
    className={className}
    fill={fill}
    style={style}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M32 34.8h-.1l-5.7 1.6c-.2 0-.4.2-.4.4v2.3c0 .3.2.5.5.5h.1l5.7-1.6c.2 0 .4-.2.4-.4v-2.3c0-.3-.2-.5-.5-.5zm-.4 2.4-4.8 1.4v-1.4l4.8-1.4v1.4zM26.3 35.5l3.6-1c.6-.2.3-1-.2-.9l-3.6 1c-.7.2-.4 1.1.2.9zM11.8 31.7c-.2-.3-.6-.3-.8 0l-1.2 1.9c-.1.2-.1.5.1.6.2.1.5.1.6-.1l.4-.6v3.3c0 .6.9.6.9 0v-3.3l.4.6c.1.2.4.3.6.1.2-.1.3-.4.1-.6l-1.1-1.9z" />
    <path d="m44.4 22.4-3.6-3.6 3.7-3.7c.2-.2.1-.7-.2-.8L27.7 9.6V5.3c0-.9-.8-1.7-1.7-1.7h-4.1c-.9 0-1.7.8-1.7 1.7v4.3L3.8 14.4c-.3.1-.4.5-.2.8l3.6 3.6-3.7 3.7c-.2.2-.1.7.2.8l3.7 1.1V38c0 .2.1.4.4.4l16.1 4.3h.2l16.1-4.3c.2-.1.4-.2.4-.4V24.2l3.6-1.1c.3 0 .4-.5.2-.7zM27.7 10.7 43.1 15l-3.3 3.3-11.2-3 1.1-1.5c.5-.8 0-1.8-.9-1.8h-1.1v-1.3zm-7 2.2c.3 0 .5-.2.5-.5V5.3c0-.4.4-.8.8-.8h4c.4 0 .8.4.8.8v7.1c0 .3.2.5.5.5h1.5c.2 0 .3.2.2.3L27.7 15l-.5.8-2.5 3.6c-.2.2-.4.4-.7.4-.2 0-.5-.1-.7-.4l-2.5-3.6-.5-.8-1.3-1.8v-.1c0-.1.1-.2.2-.2h1.5zM4.8 15.1l15.4-4.5V12h-1.1c-.9 0-1.4 1-.9 1.8l1.1 1.5-11.3 3-3.2-3.2zm0 7.5L8 19.3l15.1 4-3.5 3.5-14.8-4.2zm18.7 19-15.2-4v-13l11.2 3.2c.2.1.3 0 .5-.1l3.5-3.5v17.4zm.5-19L9.7 18.8l10.2-2.7 2.7 3.9c.3.5.9.7 1.4.7s1.1-.2 1.4-.7l2.7-3.9 10.2 2.7L24 22.6zm15.6 14.9-15.2 4V24.2l3.5 3.5c.1.1.3.2.5.1l11.2-3.3v13zM28.4 26.8l-3.5-3.5 15.1-4 3.2 3.2-14.8 4.3z" />
  </svg>
);

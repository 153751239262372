import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import ms from 'ms';
import { useEffect } from 'react';
import snakecaseKeys from 'snakecase-keys';

import { ENDPOINT } from '~/constants/endpoints';
import { type PaginationSortDirection } from '~/constants/pagination';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import { CustomFieldObject } from '~/models/customData';

import {
  createInfiniteQuery,
  type InfiniteQueryOptionsType,
} from '~/utils/createInfiniteQuery';
import { Log } from '~/utils/logging';
import { withoutObjectKeysWhereValueIs } from '~/utils/object';
import { toSnakeCase } from '~/utils/string';

import { queryKeysCustomField } from './queryKeys';

export type CustomFieldsQueryParams = {
  language?: string;
  orderBy?: string; // TODO: list explicitly
  sort?: PaginationSortDirection;
};

type CustomFieldLevel = 'global' | 'item';
type CustomFieldType =
  | 'bool'
  | 'datetime'
  | 'dict'
  | 'double'
  | 'enumeration'
  | 'legal_organization'
  | 'string'
  | 'value_measure';

export type CustomFieldsListItem = {
  allowedUnits: string[];
  defaultUnit: string | undefined;
  displayName: string;
  hasOptions: boolean;
  helpText: string;
  id: UUID;
  level: CustomFieldLevel;
  name: string;
  public: boolean;
  required: boolean;
  sorting: number;
  type: CustomFieldType;
  usedBy: UUID[];
  visibility: string;
};

type CustomFieldsResponse = {
  items: readonly CustomFieldsListItem[];
  count: number;
};

const defaultQueryParams: CustomFieldsQueryParams = {
  language: undefined,
};

/**
 * Generates the query options for the CustomFields query so they can be shared between the useQuery hook and the prefetching.
 */
export const getCustomFieldsQueryOptions = ({
  queryParams,
  options = {},
}: {
  queryParams: Partial<CustomFieldsQueryParams>;
  options?: Omit<UseQueryOptions<CustomFieldsResponse>, 'queryKey' | 'queryFn'>;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return {
    queryFn: async () => fetchCustomFields(qp),
    queryKey: queryKeysCustomField.getAll(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all CustomFields from the API.
 * @param queryParams - The query parameters for fetching CustomFields.
 * @returns The category data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Custom-Field/operation/get_all_custom_fields_custom_field_all_get
 */
export const fetchCustomFields = async (
  queryParams: Partial<CustomFieldsQueryParams>,
) => {
  const mergedParams = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const qp = withoutObjectKeysWhereValueIs(
    {
      ...mergedParams,
      orderBy: mergedParams.orderBy
        ? toSnakeCase(mergedParams.orderBy)
        : undefined,
      sort: mergedParams.sort ? mergedParams.sort.toUpperCase() : undefined,
    },
    [undefined],
  );

  try {
    const response = await vestigasApi
      .get(ENDPOINT.CUSTOM_FIELD.GET_ALL(), {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<CustomFieldsResponse>();

    return (
      response ?? {
        data: [],
        hasNextPage: false,
        paginatedCount: 0,
        totalCount: 0,
      }
    );
  } catch (error) {
    Log.error('Error fetching CustomFields', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all CustomFields with given query parameters.
 * Handles pagination and prefetches the next page of CustomFields for better performance.
 *
 * @param {Object} queryParams - The query parameters for fetching CustomFields.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryCustomFields = (
  queryParams: Partial<CustomFieldsQueryParams>,
  options?: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getCustomFieldsQueryOptions({
        options,
        queryParams,
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery<CustomFieldsResponse>({
    placeholderData: keepPreviousData,
    select: (data) =>
      data?.items?.map((customField) =>
        CustomFieldObject.create(customField),
      ) ?? [],
    ...getCustomFieldsQueryOptions({ options, queryParams }),
  });
};

/**
 * React Query based custom hook for getting the data for all CustomFields with infinite scrolling.
 * Uses useInfiniteQuery to handle loading more data as the user scrolls.
 *
 * @param {Object} queryParams - The query parameters for fetching CustomFields.
 * @param {Object} options - Additional options for the useInfiniteQuery hook.
 * @returns {UseInfiniteQueryResult} The result of the useInfiniteQuery hook.
 */
export const useQueryCustomFieldsInfinite = (
  queryParams?: Partial<CustomFieldsQueryParams>,
  options?: InfiniteQueryOptionsType<CustomFieldsResponse, Error>,
) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const infiniteQueryOptions = {
    queryFn: async () =>
      fetchCustomFields({
        ...qp,
      }),
    queryKey: queryKeysCustomField.getAllInfinite(queryParams),
    ...options,
  };

  return createInfiniteQuery<CustomFieldsResponse>(qp, infiniteQueryOptions);
};

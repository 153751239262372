import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import {
  type PermissionCreatedObject,
  PermissionsObject,
} from '../Permissions';

import {
  PERMISSION_GRANT_ENTITY_TYPE,
  PERMISSION_GRANT_ENTITY_TYPES,
  PERMISSION_GRANT_SUBJECT_TYPE,
  PERMISSION_GRANT_SUBJECT_TYPES,
  PERMISSION_GRANT_TYPE,
} from './constants';
import {
  type PermissionGrant,
  type PermissionGrantSubjectType,
  type PermissionGrantTargetType,
} from './types';

export const PermissionGrantObject = {
  /**
   * Checks if two permission grants are identical by comparing their subject ID, target ID, and permissions.
   */
  arePermissionGrantsMatching(
    permissionGrantA: PermissionGrant,
    permissionGrantB: PermissionGrant,
  ) {
    return (
      permissionGrantA.subjectId === permissionGrantB.subjectId &&
      permissionGrantA.targetId === permissionGrantB.targetId &&
      JSON.stringify(permissionGrantA.permissions) ===
        JSON.stringify(permissionGrantB.permissions)
    );
  },

  /**
   * Creates a formatted permission grant object with processed permissions.
   */
  create(permissionGrant: Partial<PermissionGrant> = {}) {
    const permissions = PermissionsObject.create(permissionGrant.permissions);

    return {
      id: permissionGrant?.id?.toString() ?? undefined,
      permissions,
      subjectType: permissionGrant?.subjectType ?? undefined,
      subjectId: permissionGrant?.subjectId ?? undefined,
      subjectName: permissionGrant?.subjectName ?? undefined,
      entityType: permissionGrant?.targetType ?? undefined,
      entityId: permissionGrant?.targetId ?? undefined,
      entityName: permissionGrant?.targetName ?? undefined,
    };
  },

  /**
   * Converts an entity type key to its corresponding string representation. Throws an error if invalid.
   */
  getEntityTypeString(entityType: string) {
    const entityTypeKey = Object.keys(PERMISSION_GRANT_ENTITY_TYPE).find(
      (key) =>
        PERMISSION_GRANT_ENTITY_TYPE[key as PermissionGrantSubjectType] ===
        entityType,
    );

    if (!entityTypeKey) {
      throw new EnumValueNotFoundException(
        `Invalid entity type: ${entityType}`,
      );
    }

    return PERMISSION_GRANT_ENTITY_TYPE[
      entityTypeKey as PermissionGrantTargetType
    ];
  },

  /**
   * Converts a subject type key to its corresponding string representation. Throws an error if invalid.
   */
  getSubjectTypeString(subjectType: string) {
    const subjectTypeKey = Object.keys(PERMISSION_GRANT_SUBJECT_TYPE).find(
      (key) =>
        PERMISSION_GRANT_SUBJECT_TYPE[key as PermissionGrantSubjectType] ===
        subjectType,
    );

    if (!subjectTypeKey) {
      throw new EnumValueNotFoundException(
        `Invalid subject type: ${subjectType}`,
      );
    }

    return PERMISSION_GRANT_SUBJECT_TYPE[
      subjectTypeKey as PermissionGrantSubjectType
    ];
  },

  /**
   * Checks if the given permissions allow reading delivery notes.
   */
  canReadDeliveryNotes(permission: PermissionCreatedObject) {
    return permission.canReadDeliveryNotes?.();
  },

  /**
   * Retrieves the default role name from the given permissions.
   */
  getDefaultRoleName(permission: PermissionCreatedObject) {
    return permission.getDefaultRoleName?.();
  },

  TYPE: PERMISSION_GRANT_TYPE,
  SUBJECT_TYPE: PERMISSION_GRANT_SUBJECT_TYPE,
  ENTITY_TYPE: PERMISSION_GRANT_ENTITY_TYPE,
  SUBJECT_TYPES: PERMISSION_GRANT_SUBJECT_TYPES,
  ENTITY_TYPES: PERMISSION_GRANT_ENTITY_TYPES,
};

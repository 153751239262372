export const INCOTERMS = {
  CFR: {
    DESCRIPTION: 'Kosten und Fracht (CFR)',
    KEY: 'CFR',
  },
  CIF: {
    DESCRIPTION: 'Kosten, Versicherung und Fracht (CIF)',
    KEY: 'CIF',
  },
  CIP: {
    DESCRIPTION: 'Frachtfrei versichert (CIP)',
    KEY: 'CIP',
  },
  CPT: {
    DESCRIPTION: 'Frachtfrei (CPT)',
    KEY: 'CPT',
  },
  DAP: {
    DESCRIPTION: 'Geliefert benannter Ort (DAP)',
    KEY: 'DAP',
  },
  DDP: {
    DESCRIPTION: 'Geliefert verzollt (DDP)',
    KEY: 'DDP',
  },
  DPU: {
    DESCRIPTION: 'Frei Bau (DPU)',
    KEY: 'DPU',
  },
  EXW: {
    DESCRIPTION: 'Ab Werk (EXW)',
    KEY: 'EXW',
  },
  FAS: {
    DESCRIPTION: 'Frei Längsseite Schiff (FAS)',
    KEY: 'FAS',
  },
  FCA: {
    DESCRIPTION: 'Frei Frachtführer (FCA)',
    KEY: 'FCA',
  },
  FH: {
    DESCRIPTION: 'Frei Haus (FH)',
    KEY: 'FH',
  },
  FOB: {
    DESCRIPTION: 'Frei an Bord (FOB)',
    KEY: 'FOB',
  },
};

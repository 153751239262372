import { clone } from '~/utils/object';

import { CHANGES_LIST_INCLUDED_PATHS } from '../../constants/changes-list-included-paths';
import { DeliveryNoteAttachmentHandlerObject } from '../../deliveryNoteAttachmentHandlerUtils';
import { type DeliveryNoteValues } from '../types';

export const INITIAL_DLN_VALUES: DeliveryNoteValues = {
  acceptState: {},
  acceptStateCarrier: {},
  acceptStateOnBehalfCarrier: {},
  acceptStateOnBehalfRecipient: {},
  acceptStateOnBehalfSupplier: {},
  acceptStateRecipient: {},
  acceptStateSupplier: {},
  articles: [],
  assetChain: [],
  asyncInitiated: false,
  attachmentHandler: DeliveryNoteAttachmentHandlerObject.create(),
  buyer: {},
  buyerId: '',
  buyerOrderReferences: '',
  carrier: {},
  carrierId: '',
  carrierLicensePlate: '',
  carrierVehicleNumber: '',
  changes: [],
  changesListIncludedPaths: clone(CHANGES_LIST_INCLUDED_PATHS),
  combinedState: '',
  comments: [],
  constructionComponents: '',
  constructionPlans: '',
  costCenters: [],
  createdOn: '',
  customData: {},
  deliveredArticles: [],
  deliveryDate: '',
  deliveryDescription: '',
  deliveryTerms: {
    code: '',
  },
  deliveryType: '',
  dlnDate: '',
  driver: {},
  execution: {},
  freightForwarder: {},
  fromSite: {},
  history: {},
  id: '',
  issuer: {},
  mainArticle: {},
  modifiedOn: '',
  movementMeans: '',
  number: '',
  palletAmount: 0,
  permittedCostCenters: [],
  permittedToSites: [],
  permittedUsers: [],
  processCategory: '',
  processRolesOfUserCompany: {},
  processState: '',
  processStateChangeUser: {},
  project: '',
  recalculatedMain: null,
  recipient: {},
  referencedInvoices: [],
  regieTime: 0,
  returnedArticles: [],
  seller: {},
  sellerOrderReferences: '',
  settledStatus: '',
  shippingMarks: [],
  sOus: [],
  supplier: {},
  toSiteRecipient: {},
  toSiteSupplier: {},
  totalWeightDeliveredArticles: '',
  totalWeightGross: '',
  totalWeightNet: '',
  totalWeightReturnedArticles: '',
  totalWeightTare: '',
  trader: {},
  userActions: {
    requestSignatures: [],
    shareDeliveryNote: [],
  },
  waitingTime: 0,
};

import {
  BackupTable as BackupTableIcon,
  Check as CheckIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  QuestionMark as QuestionMarkIcon,
} from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import {
  type ChangeEvent,
  type FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { type UUID } from '~/types/common';

import {
  useQueryDataExchangeCompaniesLegacy,
  useQueryDataExchangesLegacy,
} from '~/data/dataExchange';
import {
  useGetUserUtils,
  useMutationUpdateCurrentUserSettings,
  useQueryUserData,
} from '~/data/user';

import {
  setDataExchanges_query,
  setDataExchanges_selectedCompany,
} from '~/redux/filtersSlice';

import ExportService from '~/services/export.service';
import MapperService from '~/services/mapper.service';

import { DataExchangeCompanyObject } from '~/models/dataExchanges';
import { AddressObject } from '~/models/masterdata/Address/addressUtils';
import { UniversalCommunicationObject } from '~/models/masterdata/UniversalCommunication';

import { globalState } from '~/modules/applicationState';

import { sortByKey, updateByKey } from '~/utils/array';
import { Log } from '~/utils/logging';

import BasicForm from '~/components/BasicForm';
import { IconButton } from '~/components/IconButton';
import { withErrorBoundary } from '~/ui/atoms';
import { Spinner } from '~/ui/atoms/Spinner';
import { SearchInput } from '~/ui/molecules/SearchInput';
import { SelectCompany } from '~/ui/molecules/SelectServerDriven';

import { DataExchangeTiles } from './DataExchangeTiles';

type DataExchangesProps = {
  externalSupplierOverview?: boolean;
};

export const DataExchanges = withErrorBoundary(
  ({ externalSupplierOverview }: DataExchangesProps) => {
    const query = useSelector((state) => state.filters.dataExchanges_query);
    const selectedCompany = useSelector(
      (state) => state.filters.dataExchanges_selectedCompany,
    );

    const [dataExchangesState, setDataExchangesState] = useState([]);
    const [filteredDataExchanges, setFilteredDataExchanges] = useState([]);
    const [
      requestInformationSelectionList,
      setRequestInformationSelectionList,
    ] = useState<string[]>([]);
    const [requestInformationSelection, setRequestInformationSelection] =
      useState(false);

    const [supplierOverviewWarningOpen, setSupplierOverviewWarningOpen] =
      useState(false);

    const { data: currentUser } = useQueryUserData(true);
    const companyAccountInfo = currentUser?.companyAccountInfo ?? {};
    const hasAcceptedSupplierOverviewWarning =
      currentUser?.userSettings?.hasAcceptedSupplierOverviewWarning;

    const { UserUtils } = useGetUserUtils();

    const {
      data: dataExchangesLegacy,
      isLoading: isLoadingDataExchangesLegacy,
      isError: isErrorDataExchangesLegacy,
    } = useQueryDataExchangesLegacy();

    const {
      data: dataExchangeCompanies,
      isLoading: isLoadingDataExchangeCompany,
      isError: isErrorDataExchangeCompany,
    } = useQueryDataExchangeCompaniesLegacy();

    const [
      supplierOverviewWarningAccepted,
      setSupplierOverviewWarningAccepted,
    ] = useState(hasAcceptedSupplierOverviewWarning);

    const { mutate: updateSettingsMutation } =
      useMutationUpdateCurrentUserSettings();

    const isLoadingData = useMemo(() => {
      return isLoadingDataExchangesLegacy || isLoadingDataExchangeCompany;
    }, [isLoadingDataExchangesLegacy, isLoadingDataExchangeCompany]);

    const isErrorData = useMemo(() => {
      return isErrorDataExchangesLegacy || isErrorDataExchangeCompany;
    }, [isErrorDataExchangesLegacy, isErrorDataExchangeCompany]);

    const dispatch = useDispatch();

    const loadData = useCallback(() => {
      let dataExchanges = [];

      if (!dataExchangesLegacy || !dataExchangeCompanies) {
        return;
      }

      for (const dataExchange of dataExchangesLegacy) {
        dataExchanges.push({
          activeCustomerIds: dataExchange.activeCustomerIds?.join(', ') || null,
          deliveryNoteStatus: dataExchange.exchangeDeliveryNotes
            ? DataExchangeCompanyObject.STATUS.ACTIVATED.KEY
            : null,

          // Set alternative status via data exchange company later
          furtherInformation: dataExchange.furtherInformation || null,
          id: dataExchange.id,

          // Set alternative status via data exchange company later
          invoiceStatus: dataExchange.exchangeInvoices
            ? DataExchangeCompanyObject.STATUS.ACTIVATED.KEY
            : null,
          knownIssues: dataExchange.knownIssues || null,
          overallStatus: DataExchangeCompanyObject.STATUS.ACTIVATED.KEY,
          receiverId: dataExchange.receiverId || null,
          senderId: dataExchange.senderId || null,
        });
      }

      for (const dataExchangeCompany of dataExchangeCompanies) {
        // If the data exchange company is blacklisted, we don't want to display it in the supplier overview.
        if (dataExchangeCompany.blacklisted) {
          continue;
        }

        const filteredDataExchanges = dataExchanges.filter(
          ({ senderId }) => senderId === dataExchangeCompany.id,
        );

        // If there are data exchanges and the filter "Alle Firmen" is selected, the data exchanges are merged into one set.
        if (filteredDataExchanges.length > 0 && !selectedCompany) {
          const newDataExchange = { ...filteredDataExchanges[0] };

          newDataExchange.name = dataExchangeCompany.name;
          newDataExchange.address = AddressObject.stringify(
            dataExchangeCompany?.address,
          );
          newDataExchange.rank = dataExchangeCompany.rank;
          // Only add the current issues for the data exchanges that have been "activated" for the specific company of the user
          newDataExchange.currentIssues =
            dataExchangeCompany.currentIssues || null;
          // Take the highest status during merge.
          newDataExchange.deliveryNoteStatus ||=
            dataExchangeCompany.statusDeliveryNote;
          newDataExchange.invoiceStatus ||= dataExchangeCompany.statusInvoice;
          // Text fields should be appended during merge.
          newDataExchange.furtherInformation = filteredDataExchanges
            .map((d) => d.furtherInformation)
            .filter(Boolean)
            .join(', ');
          newDataExchange.activeCustomerIds = filteredDataExchanges
            .map((d) => d.activeCustomerIds)
            .filter(Boolean)
            .join(', ');
          newDataExchange.knownIssues = filteredDataExchanges
            .map((d) => d.knownIssues)
            .filter(Boolean)
            .join(', ');

          dataExchanges = updateByKey(
            dataExchanges,
            'id',
            newDataExchange.id,
            newDataExchange,
          );

          // Delete the other data exchanges as we only want to have the merged one.
          for (const [
            index,
            filteredDataExchange,
          ] of filteredDataExchanges.entries()) {
            if (index > 0) {
              dataExchanges = dataExchanges.filter(
                ({ id }) => id !== filteredDataExchange.id,
              );
            }
          }

          continue;
        }

        // If there are data exchanges and a specific company is selected as filter, each data exchange must be updated with the data exchange company information.
        // The filter on the company is applied in this.filterDataExchanges().
        if (filteredDataExchanges.length > 0 && selectedCompany) {
          for (const filteredDataExchange of filteredDataExchanges) {
            filteredDataExchange.name = dataExchangeCompany.name;
            filteredDataExchange.address = AddressObject.stringify(
              dataExchangeCompany?.address,
            );
            filteredDataExchange.rank = dataExchangeCompany.rank;
            filteredDataExchange.deliveryNoteStatus ||=
              dataExchangeCompany.statusDeliveryNote;
            filteredDataExchange.invoiceStatus ||=
              dataExchangeCompany.statusInvoice;

            updateByKey(
              dataExchanges,
              'id',
              filteredDataExchange.id,
              filteredDataExchange,
            );
          }

          continue;
        }

        // Last case: No data exchange for this data exchange company existing. In this case, create a tile based on the company information.
        dataExchanges.push({
          activeCustomerIds: null,
          address: AddressObject.stringify(dataExchangeCompany?.address),
          deliveryNoteStatus: dataExchangeCompany.statusDeliveryNote,
          furtherInformation: dataExchangeCompany.furtherInformation || null,
          id: dataExchangeCompany.id,
          invoiceStatus: dataExchangeCompany.statusInvoice,
          knownIssues: dataExchangeCompany.knownIssues || null,
          name: dataExchangeCompany.name,
          overallStatus: dataExchangeCompany.statusDeliveryNote,
          rank: dataExchangeCompany.rank,
          receiverId: null,
          senderId: dataExchangeCompany.id,
        });
      }

      dataExchanges = dataExchanges.map((dataExchange) =>
        MapperService.addSearchString(dataExchange, [
          'id',
          'senderId',
          'receiverId',
          'name',
          'address',
          'activeCustomerIds',
          'knownIssues',
          'furtherInformation',
          'deliveryNoteStatusLabel',
          'invoiceStatusLabel',
          'currentIssues',
        ]),
      );
      dataExchanges = sortByKey(dataExchanges, 'rank', true);

      setDataExchangesState(dataExchanges);
    }, [dataExchangesLegacy, dataExchangeCompanies]);

    const filterDataExchanges = useCallback(() => {
      const newFilteredDataExchanges = dataExchangesState.filter(
        (dataExchange) => {
          if (
            query !== '' &&
            !dataExchange.searchString.includes(query.toLowerCase())
          )
            return false;

          // Only filter the activated data exchanges by the selected company
          if (
            dataExchange.overallStatus ===
              DataExchangeCompanyObject.STATUS.ACTIVATED.KEY &&
            selectedCompany &&
            selectedCompany !== dataExchange.receiverId
          ) {
            return false;
          }

          return true;
        },
      );

      setFilteredDataExchanges(newFilteredDataExchanges);
    }, [dataExchangesState, query, selectedCompany]);

    const handleChangeCompany = (companyId: UUID) => {
      Log.info(
        'Change filter value of company',
        {
          from: selectedCompany,
          to: companyId,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Filter company',
        Log.FEATURE.SUPPLIER_OVERVIEW,
      );

      dispatch(setDataExchanges_selectedCompany(companyId));
    };

    const exportExcel = () => {
      const data = filteredDataExchanges.map((dataExchange) => {
        return [
          dataExchange.name,
          dataExchange.address,
          DataExchangeCompanyObject.getStatusLabel(dataExchange.overallStatus),
          DataExchangeCompanyObject.getStatusLabel(
            dataExchange.deliveryNoteStatus,
          ),
          DataExchangeCompanyObject.getStatusLabel(dataExchange.invoiceStatus),
          dataExchange.activeCustomerIds,
          dataExchange.knownIssues,
          dataExchange.furtherInformation,
        ];
      });

      data.unshift([
        'Name',
        'Adresse',
        'Status',
        'Lieferungen',
        'Rechnungen',
        'Kundennummern',
        'Bekannte Probleme',
        'Weitere Informationen',
      ]);

      ExportService.exportExcel(data);
    };

    const requestInformation = () => {
      Log.info(
        'Request information',
        { requestInformationSelection: !requestInformationSelection },
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent(
        'Request information',
        Log.FEATURE.SUPPLIER_OVERVIEW,
      );

      setRequestInformationSelection(!requestInformationSelection);

      if (
        !(
          requestInformationSelection &&
          requestInformationSelectionList.length > 0
        )
      ) {
        return;
      }

      Log.info(
        'Send request information',
        {
          requestInformationSelectionList,
        },
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent(
        'Send request information',
        Log.FEATURE.SUPPLIER_OVERVIEW,
      );

      const body =
        'Bitte informieren Sie mich über den aktuellen Stand der Lieferanten:\n\n' +
        requestInformationSelectionList
          .map((id) => {
            const dataExchange = filteredDataExchanges.find(
              ({ senderId }) => senderId === id,
            );

            return `${dataExchange?.name} ( ${dataExchange?.address} )`;
          })
          .join('\n');

      globalThis.location = `mailto:${UniversalCommunicationObject.getSupportContact({ isStrabagAccount: UserUtils.isStrabagAccount(companyAccountInfo.id) }).email}?subject=Information Lieferanten Status&body=${encodeURIComponent(body)}`;
    };

    const handleRequestInformationSelectionChange = (id: UUID) => {
      if (requestInformationSelectionList.includes(id)) {
        Log.info(
          'Remove request information selection',
          { id },
          Log.BREADCRUMB.USER_ACTION.KEY,
        );
        Log.productAnalyticsEvent(
          'Remove request information selection',
          Log.FEATURE.SUPPLIER_OVERVIEW,
        );

        setRequestInformationSelectionList(
          requestInformationSelectionList.filter((index) => index !== id),
        );

        return;
      }

      Log.info(
        'Add request information selection',
        { id },
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent(
        'Add request information selection',
        Log.FEATURE.SUPPLIER_OVERVIEW,
      );

      setRequestInformationSelectionList([
        ...requestInformationSelectionList,
        id,
      ]);
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
      Log.info(
        'Change supplier overview warning accepted',
        { to: event.target.checked },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change supplier overview warning accepted',
        Log.FEATURE.SUPPLIER_OVERVIEW,
      );

      setSupplierOverviewWarningAccepted(event.target.checked);
    };

    const supplierOverviewWarningFormSuccess = (
      event: FormEvent<HTMLFormElement>,
    ) => {
      event.preventDefault();
      event.stopPropagation();

      updateSettingsMutation({
        hasAcceptedSupplierOverviewWarning: Boolean(
          supplierOverviewWarningAccepted,
        ),
      });

      setSupplierOverviewWarningOpen(false);
    };

    const supplierOverviewWarningFormAbort = () => {
      setSupplierOverviewWarningOpen(false);
    };

    useEffect(() => {
      globalState.trigger.setPageTitle({
        pageTitle: 'Lieferantenübersicht',
      });

      loadData();
    }, []);

    useEffect(() => {
      if (hasAcceptedSupplierOverviewWarning === false) {
        setSupplierOverviewWarningOpen(true);
      }
    }, [hasAcceptedSupplierOverviewWarning]);

    useEffect(() => {
      loadData();
    }, [dataExchangesLegacy, dataExchangeCompanies, selectedCompany]);

    useEffect(() => {
      filterDataExchanges();
    }, [query, selectedCompany, dataExchangesState]);

    const getCurrentIssuesNotification = useMemo(() => {
      if (!filteredDataExchanges.some(({ currentIssues }) => currentIssues)) {
        return;
      }

      return (
        <div className="bg-error100 mt-4 flex w-fit items-center gap-2 rounded-lg p-2 text-red-700">
          <InfoIcon />
          <div>
            Im Moment gibt es Übertragungsschwierigkeiten bei verschiedenen
            Lieferanten.
          </div>
        </div>
      );
    }, [filteredDataExchanges]);

    const getRequestInformationSelectionIconButton = useMemo(() => {
      if (!requestInformationSelection) {
        return (
          <IconButton
            size="large"
            tooltipTitle="Nähere Informationen zu den ausgewählten Lieferanten einholen."
            onClick={requestInformation}
          >
            <QuestionMarkIcon className="text-primary500 size-6" />
          </IconButton>
        );
      }

      return (
        <IconButton
          size="large"
          tooltipTitle="E-Mail öffnen."
          onClick={requestInformation}
        >
          <CheckIcon className="text-primary500 size-6" />
        </IconButton>
      );
    }, [requestInformationSelection, requestInformation]);

    if (isLoadingData) {
      return (
        <Spinner className="absolute my-auto" title="Lade Lieferantendaten…" />
      );
    }

    if (isErrorData) {
      return (
        <div className="flex size-full items-center justify-center">
          <ErrorIcon className="mr-2" />
          <div>Lieferantenübersicht konnte nicht geladen werden.</div>
        </div>
      );
    }

    return (
      <div className="flex size-full flex-col">
        <div className="main-padding">
          <div className="mb-4 flex items-center justify-between">
            <div className="w-80">
              <SearchInput
                productAnalyticsFeature={Log.FEATURE.SUPPLIER_OVERVIEW}
                value={query}
                autoFocus
                onChange={(params) => dispatch(setDataExchanges_query(params))}
              />
            </div>
            <div className="flex items-center gap-4">
              <IconButton
                size="large"
                tooltipTitle="Excel Download"
                onClick={exportExcel}
              >
                <BackupTableIcon className="text-primary500 size-6" />
              </IconButton>
              {getRequestInformationSelectionIconButton}
            </div>
          </div>
          {!externalSupplierOverview && (
            <>
              <div className="max-w-80">
                <div className="mb-2 font-bold">Für welche Firma?</div>
                <SelectCompany
                  placeholder="Alle Firmen"
                  value={selectedCompany}
                  onChange={(companyId) => {
                    handleChangeCompany(companyId);
                  }}
                />
              </div>
              {getCurrentIssuesNotification}
              <div className="mt-4 mb-4 min-h-px w-full bg-gray-200" />
            </>
          )}
          <DataExchangeTiles
            filteredDataExchanges={filteredDataExchanges}
            requestInformationSelection={requestInformationSelection}
            requestInformationSelectionList={requestInformationSelectionList}
            onChangeRequestInformationSelection={
              handleRequestInformationSelectionChange
            }
          />
          <div
            className="min-h-8"
            /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
            /* A better solution would be to make the parent container min-h-fit so that the padding of main-padding is applied. */
            /* However, min-h-fit seems to not work with h-fill or generally with flexbox and flex-1. */
          />
        </div>
        <BasicForm
          formAbort={supplierOverviewWarningFormAbort}
          formSuccess={supplierOverviewWarningFormSuccess}
          open={supplierOverviewWarningOpen}
          submitButtonTitle="Verstanden"
          title="Ansprechpartner bei unseren Lieferanten"
        >
          <div>
            Wir erhalten die Lieferscheine über eine direkte Verbindung zwischen
            dem System der Lieferanten/Hersteller und dem VESTIGAS-System.
          </div>
          <div>
            Durch den automatisierten Ablauf können viele Mitarbeitende der
            Lieferanten/Hersteller (z. B. im Verkaufsraum oder an der Waage)
            VESTIGAS nicht kennen.
          </div>
          <div>
            Solltest du dich mit Lieferanten/Herstellern über VESTIGAS
            austauschen wollen, können wir dir gerne die richtigen
            Ansprechpersonen vermitteln.
          </div>
          <div className="text-bold my-2 flex items-center gap-1">
            <InfoIcon className="text-warningBase" />
            Hiermit habe ich verstanden, dass nur ein ausgewählter Kreis an
            Mitarbeitenden bei den Lieferanten VESTIGAS kennt.
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={supplierOverviewWarningAccepted}
                onChange={handleCheckboxChange}
              />
            }
            label="Diesen Hinweis nicht mehr anzeigen"
          />
        </BasicForm>
      </div>
    );
  },
  'Data Exchanges konnten nicht geladen werden.',
);

DataExchanges.displayName = 'DataExchanges';

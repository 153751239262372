import {
  type FallbackRender,
  ErrorBoundary as SentryErrorBoundary,
} from '@sentry/react';
import { type PropsWithChildren, type ReactElement } from 'react';

type P = PropsWithChildren & {
  readonly fallback?: ReactElement | FallbackRender | undefined;
};

/**
 * ErrorBoundary component that wraps its children with a Sentry.ErrorBoundary.
 *
 * @param props - The props for the ErrorBoundary component.
 * @param props.children - The children to be wrapped.
 * @param props.fallback - The fallback component to be rendered when an error occurs.
 * @return The ErrorBoundary component.
 */
export const ErrorBoundary = ({ children, fallback }: P) => (
  <SentryErrorBoundary fallback={fallback} showDialog={false}>
    {children}
  </SentryErrorBoundary>
);

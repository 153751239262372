import { type UniversalCommunication } from './types';

export const DEFAULT_UNIVERSAL_COMMUNICATION: UniversalCommunication = {
  phone: '',
  email: '',
};

export const INVALID_CONTACT_MESSAGE = 'Invalid communication information';

export const STRABAG_SUPPORT_CONTACT: UniversalCommunication = {
  phone: '00800 44 66 77 00',
  email: 'itservice.de@strabag.com',
};

export const VESTIGAS_SUPPORT_CONTACT: UniversalCommunication = {
  phone: '+49 89 904215125',
  email: 'support@vestigas.com',
};

export const SALES_CONTACT: UniversalCommunication = {
  phone: '+49 89 904215121',
  email: 'kontakt@vestigas.com',
};

import { mergeWith } from 'lodash-es';
import { validate as uuidvalidate } from 'uuid';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { fetchCostCenter } from '~/data/costCenter';
import { type DeliveryNoteListItem } from '~/data/deliveryNote/types';
import { fetchSite } from '~/data/site';
import { fetchUser } from '~/data/user';

import store from '~/redux/store';
import { saveUserCompany } from '~/redux/userinfoSlice';

import UserService from '~/services/user.service';

import { AcceptStateCalculatorObject } from '~/models/acceptState';
import { BilledItemObject } from '~/models/billingState';
import { CustomDataObject } from '~/models/customData';
import { FILTER_DATA_TYPE_BACKEND } from '~/models/filters/constants';
import { CompanyObject } from '~/models/masterdata/Company';
import { CostCenterObject } from '~/models/masterdata/CostCenter';
import { SiteObject } from '~/models/masterdata/Site';
import { TradeContactObject } from '~/models/masterdata/TradeContact';
import { UserObject } from '~/models/masterdata/User';
import { type UserActions } from '~/models/userActions';

import { unique } from '~/utils/array';
import { dayjs } from '~/utils/datetime';
import { Log } from '~/utils/logging';
import { clone } from '~/utils/object';
import {
  promiseAllSettledThrottled,
  promiseAllThrottled,
} from '~/utils/promise';
import { promiseHandler } from '~/utils/promiseHandler';

import DeliveryNoteParser from '~/parser/DeliveryNoteParser';

import { CHANGES_LIST_INCLUDED_PATHS } from '../constants/changes-list-included-paths';
import { DeliveryNoteAttachmentHandlerObject } from '../deliveryNoteAttachmentHandlerUtils';
import { ShippingMarkObject } from '../shippingMarkUtils';
import { ValueGroupObject } from '../valueGroup';
import { ValueObject } from '../valueUtils';

import {
  COMBINED_STATE,
  CONTRACT_ROLE,
  INCOTERMS,
  MERGE_EXCLUDED_KEYS,
  PROCESS_CATEGORY,
  PROCESS_ROLE,
  PROCESS_STATE,
  PROPERTY,
} from './constants';
import { INITIAL_DLN_VALUES } from './constants/initialDlnValues';
import {
  calculateCombinedState,
  calculateProcessState,
  calculateTotalWeight,
  getArticles,
  getBuyerOrderReferences,
  getConstructionComponents,
  getConstructionPlans,
  getDeliveryDescription,
  getMainArticle,
  getProcessCategory,
  getSellerOrderReferences,
  initCustomData,
  setChangesForPath,
} from './utils';

// Support both snake_case and camelCase keys
const getField = (
  object: Record<string, any>,
  snakeCase: string,
  camelCase: string,
) => {
  return object?.[snakeCase] ?? object?.[camelCase];
};

export const DeliveryNoteObject = {
  ...INITIAL_DLN_VALUES,

  create(assetMain: Partial<DeliveryNoteListItem> = {}) {
    const instance = clone(this);
    DeliveryNoteParser.parseAssetMain(assetMain);

    instance.id = assetMain?.id ?? '';
    instance.number = assetMain?.assetState?.body?.header?.id ?? '';
    instance.dlnDate = assetMain?.assetState?.body?.header?.date ?? '';
    instance.createdOn = getField(assetMain, 'created_on', 'createdOn') ?? '';
    instance.deliveryDate =
      getField(assetMain, 'ts_arrived', 'tsArrived') ??
      getField(assetMain, 'ts_delivered', 'tsDelivered') ??
      getField(assetMain, 'created_on', 'createdOn') ??
      '';
    instance.modifiedOn =
      getField(assetMain, 'modified_on', 'modifiedOn') ?? '';

    instance.sOus = assetMain?.sOus ?? []; // Special field for Strabag organizational units only: returns OUs only up to a particular level.

    instance.sellerOrderReferences = getSellerOrderReferences(assetMain);
    instance.buyerOrderReferences = getBuyerOrderReferences(assetMain);
    instance.constructionPlans = getConstructionPlans(assetMain);
    instance.constructionComponents = getConstructionComponents(assetMain);
    instance.shippingMarks = (
      assetMain?.assetState?.body?.transaction?.delivery?.shippingMarks ?? []
    ).map((shippingMark) => ShippingMarkObject.create(shippingMark));

    const deliveryTerms =
      assetMain?.assetState?.body?.transaction?.agreement?.deliveryTerms;
    instance.deliveryType = instance.computeDeliveryType_safe(
      deliveryTerms?.code,
    );

    const shipFrom =
      assetMain?.assetState?.body?.transaction?.delivery?.shipFrom;
    const shipTo = assetMain?.assetState?.body?.transaction?.delivery?.shipTo;

    instance.fromSite = SiteObject.create({
      address: shipFrom?.tradeAddress,
      issuerAssignedId: shipFrom?.tradeAddress?.issuerAssignedId ?? null,
      name: shipFrom?.tradeAddress?.lineOne ?? '',
      tradeContact: shipFrom?.tradeContact,
    });

    instance.toSiteSupplier = SiteObject.create({
      address: shipTo?.tradeAddress,
      id: shipTo?.trade_address?.id ?? shipTo?.tradeAddress?.id,
      name: shipTo?.tradeAddress?.lineOne ?? '',
      tradeContact: shipTo?.tradeContact,
    });

    instance.toSiteRecipient = instance.getToSiteRecipient(assetMain);
    instance.permittedToSites = instance.getPermittedToSites(assetMain);
    instance.permittedCostCenters = instance.getPermittedCostCenters(assetMain);

    const movement = shipFrom?.consignment?.movement;
    instance.movementMeans = movement?.means ?? '';
    instance.carrierLicensePlate =
      movement?.registrationId?.map((r) => r.id)?.join(', ') ?? '';
    instance.carrierVehicleNumber =
      movement?.registrationId?.map((r) => r.issuerAssignedId)?.join(', ') ??
      '';
    instance.driver = TradeContactObject.create(movement?.driver);

    const agreement = assetMain?.assetState?.body?.transaction?.agreement;

    instance.seller = CompanyObject.create(
      agreement?.seller?.legalOrganization,
      agreement?.seller?.tradeContact,
    );

    instance.buyer = CompanyObject.create(
      agreement?.buyer?.legalOrganization,
      agreement?.buyer?.tradeContact,
    );

    const settlement = assetMain?.assetState?.body?.transaction?.settlement;
    instance.buyerId = settlement?.salesAccount?.name ?? '';

    instance.project = shipTo?.tradeAddress?.issuerAssignedId ?? undefined;

    const header = assetMain?.assetState?.body?.header;
    instance.customData = CustomDataObject.create(header?.additionalPartyData);

    instance.carrierId =
      shipFrom?.consignment?.carrier?.legalOrganization?.issuerAssignedId ?? '';

    instance.issuer = CompanyObject.create(
      header?.issuer?.legalOrganization,
      header?.issuer?.tradeContact,
    );

    instance.recipient = CompanyObject.create(
      shipTo?.legalOrganization,
      shipTo?.tradeContact,
    );

    instance.carrier = CompanyObject.create(
      shipFrom?.consignment?.carrier?.legalOrganization,
      shipFrom?.consignment?.carrier?.tradeContact,
    );

    instance.supplier = CompanyObject.create(
      shipFrom?.legalOrganization,
      shipFrom?.tradeContact,
    );

    instance.trader = CompanyObject.create(
      agreement?.trader?.legalOrganization,
      agreement?.trader?.tradeContact,
    );

    const freightForwarder = movement?.freightForwarder;
    instance.freightForwarder = CompanyObject.create(
      freightForwarder?.legalOrganization,
      freightForwarder?.tradeContact,
    );

    instance.processRolesOfUserCompany =
      instance.getProcessRolesOfUserCompany();
    instance.processCategory = instance.getProcessCategory();

    instance.articles = getArticles(assetMain);
    instance.deliveredArticles = instance.articles.filter(
      (article) => article.amount.value >= 0,
    );
    instance.returnedArticles = instance.articles.filter(
      (article) => article.amount.value < 0,
    );

    instance.mainArticle = getMainArticle(instance.articles);

    instance.totalWeightDeliveredArticles = calculateTotalWeight(
      instance.deliveredArticles,
      'weight',
    );
    instance.totalWeightReturnedArticles = calculateTotalWeight(
      instance.returnedArticles,
      'weight',
    );
    instance.totalWeightNet = calculateTotalWeight(instance.articles, 'weight');
    instance.totalWeightGross = calculateTotalWeight(
      instance.articles,
      'weighingInformation.gross.weight',
    );
    instance.totalWeightTare = calculateTotalWeight(
      instance.articles,
      'weighingInformation.tare.weight',
    );

    const weight = calculateTotalWeight(instance.articles, 'weight', true);
    if (typeof weight === 'object' && weight !== null) {
      instance.weight = `${weight.amount} ${weight.unit}`;
      instance.weightAmount = weight.amount;
      instance.weightUnit = weight.unit;
    }

    const grossWeight = calculateTotalWeight(
      instance.articles,
      'weighingInformation.gross.weight',
      true,
    );
    if (typeof grossWeight === 'object' && grossWeight !== null) {
      instance.grossWeight = `${grossWeight.amount} ${grossWeight.unit}`;
      instance.grossWeightAmount = grossWeight.amount;
      instance.grossWeightUnit = grossWeight.unit;
    }

    const tareWeight = calculateTotalWeight(
      instance.articles,
      'weighingInformation.tare.weight',
      true,
    );
    if (typeof tareWeight === 'object' && tareWeight !== null) {
      instance.tareWeight = `${tareWeight.amount} ${tareWeight.unit}`;
      instance.tareWeightAmount = tareWeight.amount;
      instance.tareWeightUnit = tareWeight.unit;
    }

    instance.deliveryDescription = instance.getDeliveryDescription();

    instance.processState = calculateProcessState(assetMain);
    instance.acceptStateSupplier =
      AcceptStateCalculatorObject.calculateOverallAcceptStateFromArticles(
        instance.articles.map(
          (article) => article.acceptArticleSupplier.acceptState,
        ),
      );
    instance.acceptStateCarrier =
      AcceptStateCalculatorObject.calculateOverallAcceptStateFromArticles(
        instance.articles.map(
          (article) => article.acceptArticleCarrier.acceptState,
        ),
      );
    instance.acceptStateRecipient =
      AcceptStateCalculatorObject.calculateOverallAcceptStateFromArticles(
        instance.articles.map(
          (article) => article.acceptArticleRecipient.acceptState,
        ),
      );
    instance.acceptStateOnBehalfSupplier =
      AcceptStateCalculatorObject.calculateOverallAcceptStateFromArticles(
        instance.articles.map(
          (article) => article.acceptArticleOnBehalfSupplier.acceptState,
        ),
      );
    instance.acceptStateOnBehalfCarrier =
      AcceptStateCalculatorObject.calculateOverallAcceptStateFromArticles(
        instance.articles.map(
          (article) => article.acceptArticleOnBehalfCarrier.acceptState,
        ),
      );
    instance.acceptStateOnBehalfRecipient =
      AcceptStateCalculatorObject.calculateOverallAcceptStateFromArticles(
        instance.articles.map(
          (article) => article.acceptArticleOnBehalfRecipient.acceptState,
        ),
      );
    instance.acceptState =
      AcceptStateCalculatorObject.calculateOverallAcceptStateFromParties({
        acceptStateCarrier: instance.acceptStateCarrier,
        acceptStateOnBehalfCarrier: instance.acceptStateOnBehalfCarrier,
        acceptStateOnBehalfRecipient: instance.acceptStateOnBehalfRecipient,
        acceptStateOnBehalfSupplier: instance.acceptStateOnBehalfSupplier,
        acceptStateRecipient: instance.acceptStateRecipient,
        acceptStateSupplier: instance.acceptStateSupplier,
      });

    instance.referencedInvoices = unique(
      instance.articles.flatMap(({ billedItem }) => billedItem.invoiceIds),
    );
    instance.settledStatus = BilledItemObject.calculateCombinedSettledStatus(
      instance.articles.map(({ billedItem }) => billedItem.settledStatus),
    );
    instance.combinedState = calculateCombinedState(
      instance.acceptState,
      instance.processState,
    );
    instance.costCenters = unique(
      instance.articles.map(({ costCenter }) => costCenter).filter(Boolean),
    );

    const execution =
      assetMain?.assetState?.body?.transaction?.delivery?.execution ?? {};

    instance.execution = {
      arrived: execution.arrived,
      arrivedPlanned: execution.arrivedPlanned,
      beginExecution: execution.beginExecution,
      beginExecutionPlanned: execution.beginExecutionPlanned,
      beginLoading: execution.beginLoading,
      beginLoadingPlanned: execution.beginLoadingPlanned,
      beginUnloading: execution.beginUnloading,
      beginUnloadingPlanned: execution.beginUnloadingPlanned,
      departure: execution.departure,
      departurePlanned: execution.departurePlanned,
      endExecution: execution.endExecution,
      endExecutionPlanned: execution.endExecutionPlanned,
      endLoading: execution.endLoading,
      endLoadingPlanned: execution.endLoadingPlanned,
      endUnloading: execution.endUnloading,
      endUnloadingPlanned: execution.endUnloadingPlanned,
    };

    instance.assignNotes(assetMain);

    // The user that is responsible for the change of the process state.
    instance.processStateChangeUser = {
      arrived: UserObject.create({
        id: getField(assetMain, 'us_arrived', 'usArrived')?.split('_')?.pop(),
      }),
      cancelled: UserObject.create({
        id: getField(assetMain, 'us_cancelled', 'usCancelled')
          ?.split('_')
          ?.pop(),
      }),
      delivered: UserObject.create({
        id: getField(assetMain, 'us_delivered', 'usDelivered')
          ?.split('_')
          ?.pop(),
      }),
      inTransport: UserObject.create({
        id: getField(assetMain, 'us_inTransport', 'usInTransport')
          ?.split('_')
          ?.pop(),
      }),
      readyForOutput: UserObject.create({
        id: getField(assetMain, 'us_readyForOutput', 'usReadyForOutput')
          ?.split('_')
          ?.pop(),
      }),
    };

    instance.assetChain = assetMain?.assetChain ?? [];
    instance.changesListIncludedPaths = clone(CHANGES_LIST_INCLUDED_PATHS);

    instance.attachmentHandler = DeliveryNoteAttachmentHandlerObject.create();

    instance.fixParserErrors();

    return instance;
  },

  // Execute all async processes in parallel that are needed to fill the delivery note data
  async asyncInit(customFields: Array<Record<string, any>> = []) {
    // Don't run asyncInit multiple times as it can lead to weird bugs such as custom fields being displayed multiple time in the dln history.
    // For example, asyncInit is executed during dln bulk load and when loading dln in dln detail view.
    if (this.asyncInitiated) {
      return;
    }

    this.asyncInitiated = true;

    const processCategoryPromise = this.updateProcessCategory();
    const toSiteRecipientPromise = this.initToSiteRecipient();
    const permittedCostCentersPromise = this.initPermittedCostCenters();
    const permittedToSitesPromise = this.initPermittedToSites();
    const customDataDeliveryNotePromise =
      this.initCustomDataDeliveryNote(customFields);
    const customDataArticlePromise = this.initCustomDataArticles(customFields);
    const processStateChangeUserPromise = this.initProcessStateChangeUser();

    return promiseAllThrottled([
      processCategoryPromise,
      toSiteRecipientPromise,
      permittedToSitesPromise,
      permittedCostCentersPromise,
      customDataDeliveryNotePromise,
      customDataArticlePromise,
      processStateChangeUserPromise,
    ]);
  },

  // Execute all async processes in parallel that are needed to fill the delivery note data
  async asyncInitWithBulkEntities(
    sites: Array<Record<string, any>>,
    costCenters: Array<Record<string, any>>,
    users: Array<Record<string, any>>,
    customFields: Array<Record<string, any>>,
  ) {
    // Don't run asyncInit multiple times as it can lead to weird bugs such as custom fields being displayed multiple time in the dln history.
    // For example, asyncInit is executed during dln bulk load and when loading dln in dln detail view.
    if (this.asyncInitiated) {
      return;
    }

    this.asyncInitiated = true;
    const processCategoryPromise = this.updateProcessCategory();
    const toSiteRecipientPromise = this.initToSiteRecipientWithBulkSites(sites);
    const permittedToSitesPromise =
      this.initPermittedToSitesWithBulkSites(sites);
    const permittedCostCentersPromise =
      this.initPermittedCostCentersWithBulkCostCenters(costCenters);
    const customDataDeliveryNotePromise =
      this.initCustomDataDeliveryNote(customFields);
    const customDataArticlePromise = this.initCustomDataArticles(customFields);
    const processStateChangeUserPromise =
      this.initProcessStateChangeUserWithBulkUsers(users);

    return promiseAllThrottled([
      processCategoryPromise,
      toSiteRecipientPromise,
      permittedToSitesPromise,
      permittedCostCentersPromise,
      customDataDeliveryNotePromise,
      customDataArticlePromise,
      processStateChangeUserPromise,
    ]);
  },

  assignNotes(assetMain: Partial<DeliveryNoteListItem> = {}) {
    const notes = assetMain?.assetState?.body?.transaction?.delivery?.note;

    if (notes)
      for (const note of notes) {
        if (note.type === 'comment_note') {
          this.comments.push(note.note?.content?.comment);
        }

        if (note.type === 'waiting_time_note') {
          this.waitingTime = note.note?.content?.time;
        }

        if (note.type === 'regie_time_note') {
          this.regieTime = note.note?.content?.time;
        }

        if (note.type === 'amount_pallet_note') {
          this.palletAmount = note.note?.content?.amount;
        }
      }
  },

  getDeliveryDescription() {
    return getDeliveryDescription({
      deliveredArticles: this.deliveredArticles,
      returnedArticles: this.returnedArticles,
      totalWeightDelivered: this.totalWeightDeliveredArticles,
      totalWeightReturned: this.totalWeightReturnedArticles,
    });
  },

  hasMultipleLogisticsPackages() {
    const logisticsPackage = ValueGroupObject.getCurrentValue(
      this.articles?.[0]?.logisticsPackage,
    );

    if (!logisticsPackage) {
      return false;
    }

    for (const article of this.articles) {
      if (
        ValueGroupObject.getCurrentValue(article.logisticsPackage) !==
        logisticsPackage
      ) {
        return true; // Found a different logistics package
      }
    }

    return false; // All logistics packages are the same
  },

  computeDeliveryType_safe(code: string) {
    if (!code) {
      return '';
    }

    return this.computeDeliveryType(code);
  },

  // computes human-readable string that can be displayed
  computeDeliveryType(code: string) {
    const incoterm = Object.keys(this.INCOTERMS).find(
      (x) => this.INCOTERMS[x].KEY === code,
    );

    if (!incoterm) {
      return code;
    }

    return this.INCOTERMS[incoterm].DESCRIPTION;
  },

  getDeliveryTypeCode(description: string) {
    const incoterm = Object.keys(this.INCOTERMS).find(
      (x) => this.INCOTERMS[x].DESCRIPTION === description,
    );

    if (!incoterm) {
      return null;
    }

    return this.INCOTERMS[incoterm].KEY;
  },

  // the mapped site name (from the buyer site) is stored in purchase_account after the user has signed the dln before
  // that, the buyer site id can be retrieved as fallback from the sgw field

  // This is shown as "Bestätigter Lieferort" - this concept doesn't exist in our dln - we use the settlement info as a
  // proxy. this is almost always only written when the user signs. it could theoretically be written by the supplier,
  // but this is never the case.
  getToSiteRecipient(assetMain: Partial<DeliveryNoteListItem> = {}) {
    if (!assetMain) {
      return SiteObject.create();
    }

    const settlementId =
      assetMain?.assetState?.body?.transaction?.logisticsPackage?.[0]
        ?.lineItem?.[0]?.settlement?.purchaseAccount?.id;

    if (settlementId) {
      return SiteObject.create({
        id: uuidvalidate(settlementId) ? settlementId : null, // validation is necessary because earlier, an id written by customers was used in this field
        name: assetMain?.assetState?.body?.transaction?.logisticsPackage?.[0]
          ?.lineItem?.[0]?.settlement?.purchaseAccount?.name,
      });
    }

    // return empty site so nothing is shown in table
    return SiteObject.create({});
  },

  getPermittedToSites(assetMain: Partial<DeliveryNoteListItem> = {}) {
    if (!assetMain?.sgw) {
      return [];
    }

    const sgwIds = assetMain?.sgw
      ?.filter((item) => item.type === 'pr')
      ?.map((item) => item.typeId);

    return sgwIds.map((id) => SiteObject.create({ id }));
  },

  getPermittedCostCenters(assetMain: Partial<DeliveryNoteListItem> = {}) {
    if (!assetMain?.sgw) {
      return [];
    }

    const sgwIds = assetMain?.sgw
      ?.filter((item) => item.type === 'cc')
      ?.map((item) => item.typeId);

    return sgwIds.map((id) => CostCenterObject.create({ id }));
  },

  fixParserErrors() {
    this.carrierLicensePlate =
      this.carrierLicensePlate === 'null' ? '' : this.carrierLicensePlate;
  },

  // TODO: this is just a translation function; it could as well take the translated string from a simple object {supplier: 'Ausgehende Lieferung',}
  getCategoryDescription() {
    const processCategory = Object.keys(this.PROCESS_CATEGORY).find(
      (x) => this.PROCESS_CATEGORY[x].KEY === this.processCategory,
    );

    if (!processCategory) {
      throw new EnumValueNotFoundException(
        'Invalid process role: ' + this.processCategory,
      );
    }

    return this.PROCESS_CATEGORY[processCategory].STRING;
  },

  getProcessRole(roleKey) {
    const processRole = Object.keys(this.PROCESS_ROLE).find(
      (x) => this.PROCESS_ROLE[x].KEY === roleKey,
    );

    if (!processRole) {
      throw new EnumValueNotFoundException('Invalid process role: ' + roleKey);
    }

    return this.PROCESS_ROLE[processRole].STRING;
  },

  getProcessCategoryKeyFromString(category) {
    const processCategory = Object.keys(this.PROCESS_CATEGORY).find(
      (x) => this.PROCESS_CATEGORY[x].STRING === category,
    );

    return this.PROCESS_CATEGORY[processCategory].KEY;
  },

  getProcessRolesOfUserCompany() {
    const company = store.getState()?.userinfo?.userinfo
      ?.company as unknown as Record<string, any>;

    const processRoles = {
      carrier: company?.id === this.carrier?.id,
      recipient: company?.id === this.recipient?.id,
      supplier: company?.id === this.supplier?.id,
    };

    if (
      processRoles.supplier ||
      processRoles.carrier ||
      processRoles.recipient
    ) {
      return processRoles;
    }

    // as fallback check via company account
    return {
      carrier: company?.companyAccount === this.carrier?.companyAccount,
      recipient: company?.companyAccount === this.recipient?.companyAccount,
      supplier: company?.companyAccount === this.supplier?.companyAccount,
    };
  },

  getProcessCategory() {
    return getProcessCategory(this.processRolesOfUserCompany);
  },

  // if process category is none, this could be due to the user company not being loaded yet
  // therefore, try again asynchronously and load the user company from the backend
  async updateProcessCategory() {
    if (this.processCategory !== this.PROCESS_CATEGORY.NONE.KEY) {
      return;
    }

    if (!store.getState()?.userinfo?.userinfo?.company) {
      const [company, error] = await promiseHandler(UserService.getCompany());

      if (error) {
        throw error;
      }

      store.dispatch(saveUserCompany(company));
    }

    this.processRolesOfUserCompany = this.getProcessRolesOfUserCompany();
    this.processCategory = this.getProcessCategory();
  },

  async initToSiteRecipient() {
    if (this.toSiteRecipient.name || !this.toSiteRecipient.id) {
      return;
    }

    const [site, error] = await promiseHandler(
      fetchSite(this.toSiteRecipient.id),
    );

    if (error) {
      throw error;
    }

    if (site === null) {
      return;
    }

    const newSite = SiteObject.create(site);

    this.toSiteRecipient = newSite;
  },

  async initToSiteRecipientWithBulkSites(
    sites: Array<Record<string, any>> = [],
  ) {
    if (this.toSiteRecipient.name || !this.toSiteRecipient.id) {
      return;
    }

    const site = sites.find(({ id }) => id === this.toSiteRecipient.id);

    if (!site) {
      return;
    }

    this.toSiteRecipient = site;
  },

  async initPermittedToSites() {
    for (let index = 0; index < this.permittedToSites.length; index++) {
      const [site, error] = await promiseHandler(
        fetchSite(this.permittedToSites[index].id),
      );

      if (error) {
        throw error;
      }

      if (site === null) {
        return;
      }

      const newSite = SiteObject.create(site);

      this.permittedToSites[index] = newSite;
    }
  },

  async initPermittedToSitesWithBulkSites(
    sites: Array<Record<string, any>> = [],
  ) {
    for (let index = 0; index < this.permittedToSites.length; index++) {
      const site = sites.find(
        ({ id }) => id === this.permittedToSites[index].id,
      );

      if (!site) {
        return;
      }

      this.permittedToSites[index] = site;
    }
  },

  async initPermittedCostCenters() {
    for (let index = 0; index < this.permittedCostCenters.length; index++) {
      const [costCenter, error] = await promiseHandler(
        fetchCostCenter(this.permittedCostCenters[index].id),
      );

      if (error) {
        throw error;
      }

      if (costCenter === null) {
        return;
      }

      const newCostCenter = CostCenterObject.create(costCenter);

      this.permittedCostCenters[index] = newCostCenter;
    }
  },

  async initPermittedCostCentersWithBulkCostCenters(
    costCenters: Array<Record<string, any>> = [],
  ) {
    for (let index = 0; index < this.permittedCostCenters.length; index++) {
      const costCenter = costCenters.find(
        ({ id }) => id === this.permittedCostCenters[index].id,
      );

      if (!costCenter) {
        return;
      }

      this.permittedCostCenters[index] = costCenter;
    }
  },

  async initPermittedUsers(newPermittedUsers: Array<Record<string, any>>) {
    this.permittedUsers = newPermittedUsers;
  },

  async initCustomDataDeliveryNote(customFields: Array<Record<string, any>>) {
    const paths = await initCustomData(
      this.customData,
      this.changesListIncludedPaths,
      customFields,
    );

    this.changesListIncludedPaths = paths;
  },

  async initCustomDataArticles(customFields: Array<Record<string, any>>) {
    const promises = this.articles.map(async ({ customData }) => {
      const paths = await initCustomData(
        customData,
        this.changesListIncludedPaths,
        customFields,
      );

      this.changesListIncludedPaths = paths;
    });

    return promiseAllThrottled(promises);
  },

  async initProcessStateChangeUser() {
    const promises: Array<Promise<unknown>> = [];
    const keyMap = new Map(); // Keep track of which promise corresponds to which key

    for (const [key, { id }] of Object.entries(this.processStateChangeUser)) {
      if (!id) {
        // Skip if the process state hasn't been set yet by a user but by the system.
        continue;
      }

      if (id === UserObject.ASSET_CREATOR_USER_ID) {
        // The "asset creator" is a special user that is used as first user in the delivery chain.
        // It is not a real user and should not be displayed in the delivery note.
        // Trying to load it from the backend will return a 403 error.
        continue;
      }

      keyMap.set(promises.length, key); // Store the key mapped to the promise index

      promises.push(async () => {
        const response = await fetchUser(id);
        return UserObject.create(response);
      });
    }

    const results = await promiseAllSettledThrottled(promises);

    for (const [index, result] of results.entries()) {
      const key = keyMap.get(index);
      if (result.status === 'fulfilled' && result.value) {
        this.processStateChangeUser[key] = result.value;
      }
      // Skip rejected promises or null values
    }
  },

  async initProcessStateChangeUserWithBulkUsers(
    users: Array<Record<string, any>> = [],
  ) {
    for (const [key, value] of Object.entries(this.processStateChangeUser)) {
      if (!value.id) {
        // Skip if the process state hasn't been set yet by a user but by the system.
        continue;
      }

      const user = users.find(({ id }) => id === value.id);

      if (!user) {
        // Case can happen, e.g. if the user is from another company account.
        continue;
      }

      this.processStateChangeUser[key] = user;
    }
  },

  initUserActions(userActions: UserActions = {}) {
    this.userActions.requestSignatures =
      userActions?.[this.id]?.requestSignatures ?? [];
    this.userActions.shareDeliveryNote =
      userActions?.[this.id]?.shareDeliveryNote ?? [];
  },

  addChainToHistory(
    assetChain: Record<string, any>,
    company: Record<string, any>,
    initial: boolean,
  ) {
    const convertAssetChainToMain = ({ body, ...rest }) => ({
      assetState: {
        body,
      },
      ...rest,
    });

    const updateAssetMain = (
      assetMain: DeliveryNoteListItem,
      assetChain: string[],
    ) =>
      mergeWith({}, assetMain, assetChain, (mainValue, chainValue) => {
        // if asset chain contains null, it has NOT been updated
        if (chainValue === null || chainValue === undefined) {
          return mainValue;
        }
      });

    const assetMain = convertAssetChainToMain(assetChain);
    this.recalculatedMain = updateAssetMain(this.recalculatedMain, assetMain);

    const updatedDln = this.create(this.recalculatedMain);

    if (initial) {
      this.history = updatedDln;
      return;
    }

    this.mergeWithContext(
      this.history,
      updatedDln,
      assetChain.createdOn,
      company,
    );
  },

  mergeWithContext(
    currentObject: Record<string, any>,
    updatedObject: Record<string, any>,
    datetime: string,
    company: Record<string, any>,
  ) {
    mergeWith(
      currentObject,
      updatedObject,
      (currentValue, updatedValue, key) => {
        // If currentValue is a function, return it without modification
        if (typeof currentValue === 'function') {
          return currentValue;
        }

        if (this.MERGE_EXCLUDED_KEYS.includes(key)) {
          return currentValue;
        }

        if (!ValueObject.isSimpleValue(updatedValue)) {
          this.mergeWithContext(currentValue, updatedValue, datetime, company);
          return currentValue;
        }

        const updatedValueObject = ValueObject.create(
          updatedValue,
          datetime,
          company,
        );

        if (!ValueGroupObject.isValueGroup(currentValue)) {
          const currentValueObject = ValueObject.create(
            currentValue,
            undefined,
            undefined,
          );
          currentValue = ValueGroupObject.create();
          currentValue.initial = currentValueObject;
        }

        if (!ValueGroupObject.latestValueEquals(currentValue, updatedValue)) {
          currentValue.history.push(updatedValueObject);
        }

        return currentValue;
      },
    );
  },

  mergeHistory() {
    mergeWith(this, this.history, this.mergeHistoryCustomizer.bind(this));
  },

  mergeHistoryCustomizer(currentValue: any, historyValue: any, key: string) {
    // currentValue can be a function or an array; in that case, we do not change it
    if (typeof currentValue === 'function' || Array.isArray(currentValue)) {
      return currentValue;
    }

    // due to object being passed by reference, history and current values might have already been merged (e.g. mainArticle and article.*, or Article.amount and Article.weight)
    if (ValueGroupObject.isValueGroup(currentValue)) {
      return currentValue;
    }

    if (this.MERGE_EXCLUDED_KEYS.includes(key)) {
      return currentValue;
    }

    if (!ValueGroupObject.isValueGroup(historyValue)) {
      if (!ValueObject.isSimpleValue(currentValue)) {
        mergeWith(
          currentValue,
          historyValue,
          this.mergeHistoryCustomizer.bind(this),
        );
      }

      return currentValue;
    }

    if (
      ValueObject.isSimpleValue(currentValue) ||
      // if the current value is an empty array and the corresponding value from the history is a value group, it is treated as a "real value".
      // this is needed for the comments prop as it can be an empty array in this (dln) so that it is not clear whether this array should be a ValueGroupObject (which is checked by this special condition)
      (Array.isArray(currentValue) &&
        currentValue.length === 0 &&
        ValueGroupObject.isValueGroup(historyValue))
    ) {
      if (!ValueGroupObject.isValueGroup(historyValue)) {
        return currentValue;
      }

      historyValue.current = ValueObject.create(currentValue, null);
      return historyValue;
    }
  },

  setChanges(ignoreInitialValues: boolean) {
    let changes = [...this.changes];

    for (const includedPath of this.changesListIncludedPaths) {
      changes = [
        ...setChangesForPath({
          changes,
          object: this.history,
          path: includedPath.PATH,
          name: includedPath.NAME,
          formatter: includedPath.FORMATTER,
          ignoreInitialValue:
            ignoreInitialValues && includedPath.IGNORE_INITIAL_VALUE,
        }),
      ];
    }

    this.changes = changes;
  },

  getPropertyString(key: string) {
    const property = Object.keys(this.PROPERTY).find(
      (x) => this.PROPERTY[x].KEY === key,
    );

    if (!property) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid property: ' + key),
      );
      return key;
    }

    return this.PROPERTY[property].STRING;
  },

  getDataType(key: string) {
    const property = Object.keys(this.PROPERTY).find(
      (x) => this.PROPERTY[x].KEY === key,
    );

    if (!property) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid property: ' + key),
      );
      return key;
    }

    return this.PROPERTY[property].DATA_TYPE ?? FILTER_DATA_TYPE_BACKEND.STRING;
  },

  getProcessStateString(key: string) {
    const processState = Object.keys(this.PROCESS_STATE).find(
      (x) => this.PROCESS_STATE[x].API_KEY === key,
    );

    if (!processState) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid process state: ' + key),
      );
      return key;
    }

    return this.PROCESS_STATE[processState].STRING;
  },

  getProcessStateKeyByString(string: string) {
    const processState = Object.keys(this.PROCESS_STATE).find(
      (x) => this.PROCESS_STATE[x].STRING === string,
    );

    if (!processState) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid process state: ' + string),
      );
      return string;
    }

    return this.PROCESS_STATE[processState].API_KEY;
  },

  getProcessStateOptions() {
    return [
      this.PROCESS_STATE.READY_FOR_OUTPUT.STRING,
      this.PROCESS_STATE.IN_TRANSPORT.STRING,
      this.PROCESS_STATE.ARRIVED.STRING,
      this.PROCESS_STATE.CANCELLED.STRING,
      this.PROCESS_STATE.DELIVERED.STRING,
    ];
  },

  getBackendFormat() {
    return {
      context: 'DELNTE',
      header: {
        date: this.dlnDate ? dayjs(this.dlnDate).toISOString() : '',
        id: this.number,
        issuer: this.issuer
          ? CompanyObject.getBackendFormat(this.issuer)
          : null,
      },
      transaction: {
        agreement: {
          buyer: this.buyer ? CompanyObject.getBackendFormat(this.buyer) : null,
          delivery_terms: this.deliveryType
            ? { code: this.getDeliveryTypeCode(this.deliveryType) }
            : null,
          seller: this.seller
            ? CompanyObject.getBackendFormat(this.seller)
            : null,
        },
        delivery: {
          note: this.comments.map((comment) => {
            return {
              note: { content: { comment } },
              type: 'comment_note',
            };
          }),
          ship_from: {
            ...(this.supplier
              ? CompanyObject.getBackendFormat(this.supplier)
              : {}),
            consignment: {
              carrier: this.carrier
                ? CompanyObject.getBackendFormat(this.carrier)
                : null,
              movement: {
                freight_forwarder: this.freightForwarder
                  ? CompanyObject.getBackendFormat(this.freightForwarder)
                  : null,
                means: this.movementMeans,
                registration_id:
                  this.carrierLicensePlate || this.carrierVehicleNumber
                    ? [
                        {
                          id: this.carrierLicensePlate,
                          issuer_assigned_id: this.carrierVehicleNumber,
                        },
                      ]
                    : null,
              },
            },
            trade_address: this.fromSite.name
              ? { line_one: this.fromSite.name }
              : null,
          },
          ship_to: {
            ...(this.recipient
              ? CompanyObject.getBackendFormat(this.recipient)
              : {}),
            trade_address: {
              issuer_assigned_id: this.project,
              line_one: this.toSiteSupplier.name,
            },
          },
        },
        logistics_package: [
          {
            id: '1',
            line_item: this.articles.map((article, index) => {
              return {
                ...article.getBackendFormat(
                  this.costCenters[0],
                  this.sellerOrderReferences,
                  this.buyerOrderReferences,
                  this.constructionComponents,
                  this.constructionPlans,
                ),
                id: index + 1,
              };
            }),
          },
        ],
        settlement: {
          sales_account: {
            name: this.buyerId,
            type_code: 'AVC',
          },
        },
      },
    };
  },

  COMBINED_STATE,
  CONTRACT_ROLE,
  INCOTERMS,
  MERGE_EXCLUDED_KEYS,
  PROCESS_CATEGORY,
  PROCESS_ROLE,
  PROCESS_STATE,
  PROPERTY,
};

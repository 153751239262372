import {
  type GridSortDirection,
  type GridSortModel,
} from '@mui/x-data-grid-pro';
import { useCallback, useState } from 'react';

type SortingModel = {
  orderBy: string | undefined;
  sortOrder: GridSortDirection;
};

export const useReportSortingModel = () => {
  const [sortingModel, setSortingModel] = useState<SortingModel>({
    orderBy: undefined,
    sortOrder: undefined,
  });

  const handleSortingModelChange = useCallback(
    (newModel: GridSortModel) => {
      if (
        newModel[0] &&
        (newModel[0]?.sort !== sortingModel.sortOrder ||
          newModel[0]?.field !== sortingModel.orderBy)
      ) {
        setSortingModel({
          sortOrder: newModel[0]?.sort,
          orderBy: newModel[0]?.field,
        });
      } else if (!newModel[0]) {
        setSortingModel({
          sortOrder: undefined,
          orderBy: undefined,
        });
      }
    },
    [sortingModel],
  );

  return {
    handleSortingModelChange,
    sortingModel,
  };
};

import { promiseHandler } from './promiseHandler';

class FunctionUtils {
  constructor() {
    this.timeouts = [];
  }

  timer = (ms) => new Promise((res) => setTimeout(res, ms));
  // The id is needed so that function calls can be differentiated and delayed.
  delayFunction = (id, callback, callbackArguments = [], delay = 2000) => {
    if (this.timeouts[id]) {
      clearTimeout(this.timeouts[id]);
    }

    this.timeouts[id] = setTimeout(() => {
      callback(...callbackArguments);
    }, delay);
  };
  repeatAsyncFunction = async (callback, repetitions, msDelay) => {
    for (let index = 0; index < repetitions; index++) {
      const [response, error] = await promiseHandler(callback());

      if (response) {
        return response;
      }

      if (error && index === repetitions - 1) {
        throw error;
      }

      await this.timer(msDelay);
    }

    throw null;
  };
}

const functionUtilsInstance = new FunctionUtils();

export default functionUtilsInstance;

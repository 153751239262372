import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { Log } from '~/utils/logging';

import { SHIPPING_MARK_CODE, SHIPPING_MARK_FALLBACK_STRING } from './constants';
import { type ShippingMark, type ShippingMarkCodeValues } from './types';

export const ShippingMarkObject = {
  CODE: SHIPPING_MARK_CODE,

  FALLBACK_STRING: SHIPPING_MARK_FALLBACK_STRING,

  /**
   * Creates a ShippingMark object with the given shipping mark data.
   * @param {object} shippingMark - The shipping mark object.
   * @returns {object} - The created ShippingMark object.
   */
  create(shippingMark: Partial<ShippingMark> = {}) {
    return {
      value: shippingMark?.value,
      code: shippingMark?.code,
    };
  },

  /**
   * Gets the shipping mark string based on the given code.
   * @param {string} code - The shipping mark code.
   * @returns {string|null} - The corresponding shipping mark string or null if not found.
   */
  getShippingMarkString(code: string) {
    const shippingMark = Object.keys(this.CODE).find(
      (x) => this.CODE[x as ShippingMarkCodeValues].KEY === code,
    );

    if (!shippingMark) {
      Log.error(
        null,
        new EnumValueNotFoundException(
          `Invalid ShippingMark code. code: ${code}`,
        ),
      );
      return null;
    }

    return this.CODE[shippingMark as ShippingMarkCodeValues].STRING;
  },
};

export const ALL_TYPES_OF_PERMISSIONS = [
  'company',
  'costCenter',
  'deliveryNote',
  'invoice',
  'organisationalGroup',
  'permission',
  'site',
  'user',
  'userGroup',
  'vehicle',
];

export const PERMISSIONS_INITIAL = {
  DELIVERY_NOTE: {
    accessIncoming: false,
    accessOutgoing: false,
    accessTransported: false,
  },
  INVOICE: {
    accessIncoming: false,
    accessOutgoing: false,
  },
  USER: {
    create: false,
    read: false,
    write: false,
  },
  SITE: {
    create: false,
    read: false,
    write: false,
  },
  COST_CENTER: {
    create: false,
    read: false,
    write: false,
  },
  VEHICLE: {
    create: false,
    read: false,
    write: false,
  },
  COMPANY: {
    create: false,
    read: false,
    write: false,
  },
  ORGANIZATIONAL_UNIT: {
    create: false,
    delete: false,
    member: false,
    read: false,
    write: false,
  },
  USER_GROUP: {
    create: false,
    delete: false,
    member: false,
    read: false,
    write: false,
  },
  PERMISSION: {
    grant: false,
  },
} as const;

export const PERMISSIONS_AVAILABLE = {
  ACCESS_INCOMING_DELIVERY_NOTE: {
    BACKEND: ['dln_in.read'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um eingehende Lieferungen einsehen und signieren zu können.',
    WEBAPP: 'deliveryNote.accessIncoming',
  },
  ACCESS_INCOMING_INVOICE: {
    BACKEND: ['invoice_in.read'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um eingehende Rechnungen und die Ergebnisse der automatisierten Rechnungsprüfung einsehen zu können.',
    WEBAPP: 'invoice.accessIncoming',
  },
  ACCESS_OUTGOING_DELIVERY_NOTE: {
    BACKEND: ['dln_out.read'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um ausgehende Lieferungen einsehen und signieren zu können.',
    WEBAPP: 'deliveryNote.accessOutgoing',
  },
  ACCESS_OUTGOING_INVOICE: {
    BACKEND: ['invoice_out.read'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um ausgehende Rechnungen und die Ergebnisse der automatisierten Rechnungsprüfung einsehen zu können.',
    WEBAPP: 'invoice.accessOutgoing',
  },
  ACCESS_TRANSPORTED_DELIVERY_NOTE: {
    BACKEND: ['dln_trans.read'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um transportierte Lieferungen einsehen und signieren zu können.',
    WEBAPP: 'deliveryNote.accessTransported',
  },
  CREATE_COMPANY: {
    BACKEND: ['company.create'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Firmen anlegen zu können.',
    WEBAPP: 'company.create',
  },
  CREATE_COST_CENTER: {
    BACKEND: ['accounting_reference.create'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Kostenstellen anlegen zu können.',
    WEBAPP: 'costCenter.create',
  },
  CREATE_ORGANISATIONAL_GROUP: {
    BACKEND: ['org_unit.create'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Organisations-Gruppen anlegen zu können.',
    WEBAPP: 'organisationalGroup.create',
  },
  CREATE_SITE: {
    BACKEND: ['site.create'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Standorte anlegen zu können.',
    WEBAPP: 'site.create',
  },
  CREATE_USER: {
    BACKEND: ['user.create'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Benutzer anlegen zu können.',
    WEBAPP: 'user.create',
  },
  CREATE_USER_GROUP: {
    BACKEND: ['user_group.create'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Benutzer-Gruppen anlegen zu können.',
    WEBAPP: 'userGroup.create',
  },
  CREATE_VEHICLE: {
    BACKEND: ['vehicle.create'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Fahrzeuge anlegen zu können.',
    WEBAPP: 'vehicle.create',
  },
  DELETE_ORGANISATIONAL_GROUP: {
    BACKEND: ['org_unit.delete'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Organisations-Gruppen löschen zu können.',
    WEBAPP: 'organisationalGroup.delete',
  },
  DELETE_USER_GROUP: {
    BACKEND: ['user_group.delete'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Benutzer-Gruppen löschen zu können.',
    WEBAPP: 'userGroup.delete',
  },
  GRANT_PERMISSION: {
    BACKEND: ['access_control.grant'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Berechtigungen vergeben zu können.',
    WEBAPP: 'permission.grant',
  },
  MEMBER_ORGANISATIONAL_GROUP: {
    BACKEND: ['org_unit.member'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Stammdaten zu Organisations-Gruppen hinzufügen zu können.',
    WEBAPP: 'organisationalGroup.member',
  },
  MEMBER_USER_GROUP: {
    BACKEND: ['user_group.member'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Benutzer zu Benutzer-Gruppen hinzufügen zu können.',
    WEBAPP: 'userGroup.member',
  },
  READ_COMPANY: {
    BACKEND: ['company.read'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Firmen einsehen zu können.',
    WEBAPP: 'company.read',
  },
  READ_COST_CENTER: {
    BACKEND: ['accounting_reference.read'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Kostenstellen einsehen zu können (u.a. wenn einer Lieferung eine Kostenstelle zugewiesen wird, sobald diese signiert wird).',
    WEBAPP: 'costCenter.read',
  },
  READ_ORGANISATIONAL_GROUP: {
    BACKEND: ['org_unit.read'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Organisations-Gruppen einsehen zu können.',
    WEBAPP: 'organisationalGroup.read',
  },
  READ_SITE: {
    BACKEND: ['site.read'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Standorte einsehen zu können (u.a. wenn einer Lieferung ein Standort zugewiesen wird, sobald diese signiert wird).',
    WEBAPP: 'site.read',
  },
  READ_USER: {
    BACKEND: ['user.read'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Benutzer einsehen zu können.',
    WEBAPP: 'user.read',
  },
  READ_USER_GROUP: {
    BACKEND: ['user_group.read'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Benutzer-Gruppen einsehen zu können.',
    WEBAPP: 'userGroup.read',
  },
  READ_VEHICLE: {
    BACKEND: ['vehicle.read'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Fahrzeuge einsehen zu können.',
    WEBAPP: 'vehicle.read',
  },
  WRITE_COMPANY: {
    BACKEND: ['company.write'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Firmen ändern zu können.',
    WEBAPP: 'company.write',
  },
  WRITE_COST_CENTER: {
    BACKEND: ['accounting_reference.write'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Kostenstellen ändern zu können.',
    WEBAPP: 'costCenter.write',
  },
  WRITE_ORGANISATIONAL_GROUP: {
    BACKEND: ['org_unit.write'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Organisations-Gruppen ändern zu können.',
    WEBAPP: 'organisationalGroup.write',
  },
  WRITE_SITE: {
    BACKEND: ['site.write'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Standorte ändern zu können.',
    WEBAPP: 'site.write',
  },
  WRITE_USER: {
    BACKEND: ['user.write'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Benutzer ändern zu können.',
    WEBAPP: 'user.write',
  },
  WRITE_USER_GROUP: {
    BACKEND: ['user_group.write'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Benutzer-Gruppen ändern zu können.',
    WEBAPP: 'userGroup.write',
  },
  WRITE_VEHICLE: {
    BACKEND: ['vehicle.write'],
    DESCRIPTION:
      'Diese Berechtigung wird benötigt, um Fahrzeuge ändern zu können.',
    WEBAPP: 'vehicle.write',
  },
} as const;

export const DEFAULT_ROLE = {
  ACCOUNT_ADMIN: {
    DESCRIPTION:
      'Die Account-Administrator Rolle wird üblicherweise einem Unternehmens-Account zugewiesen und' +
      'enthält alle verfügbaren Berechtigungen. Ein Account-Administrator kann somit alle Lieferungen und' +
      'Rechnungen einsehen sowie alle Stammdaten administrieren. Diese Rolle sollte nur an Benutzer vergeben' +
      'werden, welche die umfassenden Berechtigungen zur Administration der VESTIGAS Anwendung benötigen (z. B. IT-Admin).',
    NAME: 'Account-Administrator',
    PERMISSIONS: [
      'company.create',
      'company.read',
      'company.write',
      'costCenter.create',
      'costCenter.read',
      'costCenter.write',
      'deliveryNote.accessIncoming',
      'deliveryNote.accessOutgoing',
      'deliveryNote.accessTransported',
      'invoice.accessIncoming',
      'invoice.accessOutgoing',
      'organisationalGroup.create',
      'organisationalGroup.delete',
      'organisationalGroup.member',
      'organisationalGroup.read',
      'organisationalGroup.write',
      'permission.grant',
      'site.create',
      'site.read',
      'site.write',
      'user.create',
      'user.read',
      'user.write',
      'userGroup.create',
      'userGroup.delete',
      'userGroup.member',
      'userGroup.read',
      'userGroup.write',
      'vehicle.create',
      'vehicle.read',
      'vehicle.write',
    ],
  },
  CONSTRUCTION_MANAGER: {
    DESCRIPTION:
      'Die Rolle der Bauleitung wird üblicherweise einer Firma oder einem Standort zugewiesen. Ein' +
      'Benutzer mit dieser Rolle kann Lieferungen und eingehende Rechnungen einsehen. Zudem können Standorte' +
      'und Kostenstellen eingesehen und angelegt werden. Diese Rolle sollte an Benutzer vergeben werden, welche' +
      'auf Abnehmer Seite vollen Zugriff auf Standorte sowie deren Materialflüsse und Kostenstellen benötigen.',
    NAME: 'Bauleitung',
    PERMISSIONS: [
      'costCenter.create',
      'costCenter.read',
      'deliveryNote.accessIncoming',
      'deliveryNote.accessOutgoing',
      'deliveryNote.accessTransported',
      'invoice.accessIncoming',
      'site.create',
      'site.read',
    ],
  },
  DRIVER: {
    DESCRIPTION:
      'Ein Fahrer kann transportierte Lieferungen einsehen. Diese Rolle sollte an Benutzer vergeben' +
      'werden, welche Lieferungen transportieren und übergeben.',
    NAME: 'Fahrer',
    PERMISSIONS: ['deliveryNote.accessTransported', 'vehicle.read'],
  },
  EMPLOYEE: {
    DESCRIPTION:
      'Die Mitarbeiter Rolle wird üblicherweise einem Unternehmens-Account zugewiesen. Ein Benutzer mit' +
      'dieser Rolle kann Standorte und Kostenstelle einsehen und anlegen.',
    NAME: 'Mitarbeiter',
    PERMISSIONS: [
      'costCenter.create',
      'costCenter.read',
      'site.create',
      'site.read',
      'user.read',
    ],
  },
  FOREMAN: {
    DESCRIPTION:
      'Die Polier/Lagerist Rolle wird üblicherweise einem Standort zugewiesen. Diese Rolle ermöglicht es einem' +
      'Benutzer, Lieferungen einzusehen, entgegenzunehmen und die angenommenen Lieferungen dem entsprechenden' +
      'Standort und einer Kostenstelle zuzuweisen.',
    NAME: 'Polier/Lagerist',
    PERMISSIONS: [
      'costCenter.create',
      'costCenter.read',
      'deliveryNote.accessIncoming',
      'deliveryNote.accessOutgoing',
      'deliveryNote.accessTransported',
      'site.create',
      'site.read',
    ],
  },
  GOODS_ISSUE: {
    DESCRIPTION:
      'Die Rolle der Warenausgabe wird üblicherweise einem Standort zugewiesen. Diese Rolle ermöglicht' +
      'es einem Benutzer, ausgehende Lieferungen einzusehen, auszugeben und die angenommenen Lieferungen dem' +
      'entsprechenden Standort und einer Kostenstelle zuzuweisen.',
    NAME: 'Warenausgabe',
    PERMISSIONS: [
      'deliveryNote.accessOutgoing',
      'deliveryNote.accessTransported',
    ],
  },
  INVOICE_CHECKING: {
    DESCRIPTION:
      'Die Rolle der Rechnungsprüfung wird üblicherweise einer Firma zugewiesen. Ein Benutzer mit dieser' +
      'Rolle kann eingehende Lieferungen und Rechnungen einsehen. Zudem können Standorte und Kostenstellen dieser' +
      'Firma eingesehen und angelegt werden. Diese Rolle sollte an Benutzer vergeben werden, welche auf Abnehmer' +
      'Seite einen Überblick über eingehende Lieferungen benötigen und im Prozess der Rechnungsprüfung beteiligt sind.',
    NAME: 'Rechnungsprüfung',
    PERMISSIONS: [
      'costCenter.create',
      'costCenter.read',
      'deliveryNote.accessIncoming',
      'invoice.accessIncoming',
      'site.create',
      'site.read',
    ],
  },
} as const;

export const INDIVIDUAL_ROLE = 'Individuell';

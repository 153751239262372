import { type UUID } from '~/types/common';

import { type InvoicesQueryParams } from './useQueryInvoices';

/**
 * Query key factory for invoice-related queries.
 */
export const queryKeysInvoice = {
  base: () => 'invoices' as const,
  get: (invoiceId: UUID) =>
    [queryKeysInvoice.base(), 'detail', { invoiceId }] as const,
  getAll: (queryParams: Partial<InvoicesQueryParams>) =>
    [queryKeysInvoice.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<InvoicesQueryParams>) =>
    [queryKeysInvoice.base(), 'list', 'infinite', queryParams] as const,
} as const;

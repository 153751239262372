import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { validate as isUUID } from 'uuid';

import { ENDPOINT } from '~/constants/endpoints';
import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';
import { type UncefactUnitType } from '~/constants/units';

import { vestigasApi } from '~/services/kyClient';

import { withoutObjectKeysWhereValueIs } from '~/utils/object';
import { toSnakeCase } from '~/utils/string';

import { queryKeysDeliveryNote } from './queryKeys';
import { type FilterConfig, type ReportConfigItem } from './types';

export type ReportOrderItem =
  | 'amount'
  | 'article_name'
  | 'article_number'
  | 'billed_state'
  | 'confirmed_accounting_reference_id'
  | 'confirmed_accounting_reference_name'
  | 'confirmed_site_id'
  | 'confirmed_site_name'
  | 'custom_field_global_level'
  | 'custom_field_item_level'
  | 'dln_accept_state'
  | 'dln_date'
  | 'dln_nr'
  | 'loading_location'
  | 'process_state'
  | 'recipient_id'
  | 'recipient_name'
  | 'supplier_assigned_site_name'
  | 'supplier_id'
  | 'supplier_name'
  | 'unit_type';

export type SortableReportColumn =
  | 'amount'
  | 'articleName'
  | 'articleNumber'
  | 'billedState'
  | 'dlnAcceptState'
  | 'dlnDate'
  | 'dlnNr'
  | 'processState'
  | 'supplierName';

export type ReportOrder = ReportOrderItem[];

export type DeliveryNoteReportSearchParams = {
  filterConfig: FilterConfig;
  limit: number;
  offset: number;
  orderBy?: SortableReportColumn;
  reportConfig: ReportConfigItem[];
  reportOrder?: ReportOrder;
  sortOrder?: 'asc' | 'desc' | undefined;
  unitType: UncefactUnitType;
};

export const defaultSearchParamsReport: DeliveryNoteReportSearchParams = {
  filterConfig: {},
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  reportConfig: [],
  reportOrder: undefined,
  unitType: undefined,
};

export type DeliveryNoteReportValue = {
  article: string;
  total: number;
};

type DeliveryNoteReportResponse = {
  data: DeliveryNoteReportValue[];
  hasNextPage: boolean;
  paginatedCount: number;
  totalCount: number;
};

// Maximum number of results as allowed by the API.
const API_LIMIT = 500;

/**
 * Fetches report data for delivery notes based on the provided searchParams.
 *
 * @param {DeliveryNoteReportSearchParams} searchParams - Parameters for filtering and configuring the report
 * @returns {Promise<DeliveryNoteReportValue[]>} The report data values
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Analytics/operation/compute_delivery_note_analytics_report_analytics_report_post
 */
export const fetchDeliveryNoteReport = async (
  searchParams: DeliveryNoteReportSearchParams,
) => {
  try {
    const mergedParams = {
      ...defaultSearchParamsReport,
      ...searchParams,
    };

    const sp = withoutObjectKeysWhereValueIs(
      {
        ...mergedParams,
        limit: mergedParams.limit
          ? Math.min(mergedParams.limit, API_LIMIT)
          : undefined,
        reportConfig: mergedParams.reportConfig
          ? mergedParams.reportConfig.map((item) => ({
              ...item,
              name: toSnakeCase(item.name),
            }))
          : undefined,
        reportOrder: mergedParams.reportOrder
          ? mergedParams.reportOrder
              .map((item) => (isUUID(item) ? item : toSnakeCase(item)))
              .filter(Boolean)
          : undefined,
        sortOrder: mergedParams.sortOrder?.toUpperCase(),
        orderBy: toSnakeCase(mergedParams.orderBy),
      },
      [undefined],
    );

    const response = await vestigasApi
      .post(ENDPOINT.DELIVERY_NOTE.GET_REPORT(), {
        json: sp,
      })
      .json<Omit<DeliveryNoteReportResponse, 'totalCount'>>(); // totalCount is only faked

    return {
      data: response.values ?? [],
      hasNextPage: response.hasNextPage ?? false,
      paginatedCount: response.paginatedCount ?? 0,
      totalCount: 9999, // FIXME: totalCount is not returned by the API, but required for pagination in the datagrid
    } satisfies DeliveryNoteReportResponse;
  } catch (error) {
    console.error('Failed to get delivery note report data:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for fetching delivery note report data.
 *
 * @param {DeliveryNoteReportSearchParams} searchParams - Parameters for filtering and configuring the report
 * @param {Object} options - Additional options for the useQuery hook
 * @returns {UseQueryResult<DeliveryNoteReportValue[]>} The result of the useQuery hook containing the report data
 */
export const useQueryDeliveryNoteReport = (
  searchParams: DeliveryNoteReportSearchParams,
  options?: Omit<
    UseQueryOptions<DeliveryNoteReportValue[]>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery({
    queryFn: async () => fetchDeliveryNoteReport(searchParams),
    queryKey: queryKeysDeliveryNote.getReport(searchParams),
    ...options,
  });
};

export const HomeIcon = ({ className, style }: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 30V21H21V30H28.5V18H33L18 4.5L3 18H7.5V30H15Z"
      fill="currentColor"
    />
  </svg>
);

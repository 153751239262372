import { i18n } from '~/utils';

export const dateRangeOptions = {
  '30-days': i18n.t('date.range.last30Days', { ns: 'forms' }),
  '90-days': i18n.t('date.range.last90Days', { ns: 'forms' }),
  '365-days': i18n.t('date.range.last365Days', { ns: 'forms' }),
  '7-days': i18n.t('date.range.last7Days', { ns: 'forms' }),
  'last-month': i18n.t('date.range.lastMonth', { ns: 'forms' }),
  'last-week': i18n.t('date.range.lastWeek', { ns: 'forms' }),
  day: i18n.t('date.range.today', { ns: 'forms' }),
  month: i18n.t('date.range.thisMonth', { ns: 'forms' }),
  week: i18n.t('date.range.thisWeek', { ns: 'forms' }),
  year: i18n.t('date.range.thisYear', { ns: 'forms' }),
  yesterday: i18n.t('date.range.yesterday', { ns: 'forms' }),
};

export type DateRangeOption = keyof typeof dateRangeOptions;

export const dateRangesOrder: DateRangeOption[] = [
  'day',
  'yesterday',
  'week',
  'last-week',
  'month',
  'last-month',
  'year',
  '7-days',
  '30-days',
  '90-days',
  '365-days',
];

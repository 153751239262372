import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { DataExchangeObject } from '~/models/dataExchanges';

import { Log } from '~/utils/logging';

import { queryKeysDataExchange } from './queryKeys';

type DataExchangeLegacyItem = unknown;

type DataExchangeLegacyResponse = {
  items: DataExchangeLegacyItem[];
  nextLink: string;
};

export const fetchDataEchangesLegacy = async (initDataExchanges = true) => {
  try {
    const { items } = await vestigasApi
      .get(ENDPOINT.DATA_EXCHANGE.GET_ALL_LEGACY())
      .json<DataExchangeLegacyResponse>();

    if (initDataExchanges) {
      return items?.map((item) => DataExchangeObject.create(item));
    }

    return items;
  } catch (error) {
    Log.error('Error fetching data exchanges LEGACY', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

// This query uses an deprecated endpoint for fetching ALL Data Exchanges ever, which is not technically possible for large accounts.
export const useQueryDataExchangesLegacy = (
  initDataExchanges?: boolean,
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryFn: async () => fetchDataEchangesLegacy(initDataExchanges),
    queryKey: queryKeysDataExchange.getAllLegacy(),
    ...options,
  });

export const DataExchangeOnHoldIcon = ({
  className,
  style,
}: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M8.36364 2C8.24231 2.00001 8.12221 2.02431 8.01042 2.07146C7.89863 2.11861 7.79741 2.18766 7.71273 2.27455L2.25818 7.87091C2.09267 8.04067 2.00002 8.26837 2 8.50545V15.6364C2.00005 15.8775 2.09586 16.1086 2.26636 16.2791L7.72091 21.7336C7.89136 21.9041 8.12255 21.9999 8.36364 22H15.6364C15.8775 21.9999 16.1086 21.9041 16.2791 21.7336L21.7336 16.2791C21.9041 16.1086 21.9999 15.8775 22 15.6364V8.50545C22 8.26837 21.9073 8.04067 21.7418 7.87091L16.2873 2.27455C16.2026 2.18766 16.1014 2.11861 15.9896 2.07146C15.8778 2.02431 15.7577 2.00001 15.6364 2H8.36364ZM8.36364 11.0909C8.12253 11.0909 7.8913 11.1867 7.72081 11.3572C7.55032 11.5277 7.45455 11.7589 7.45455 12C7.45455 12.2411 7.55032 12.4723 7.72081 12.6428C7.8913 12.8133 8.12253 12.9091 8.36364 12.9091H15.6364C15.8775 12.9091 16.1087 12.8133 16.2792 12.6428C16.4497 12.4723 16.5455 12.2411 16.5455 12C16.5455 11.7589 16.4497 11.5277 16.2792 11.3572C16.1087 11.1867 15.8775 11.0909 15.6364 11.0909H8.36364Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

import pMap from 'p-map';

/**
 * Maximum number of concurrent requests.
 * Helpful for throttling requests to avoid '429 Too Many Requests' errors.
 */
export const MAX_CONCURRENT_REQUESTS = 6; // Defaults to 6 because we are currently restricted to http 1.0 by our Kong version

/**
 * Executes an array of promises with a concurrency limit and returns their settled results.
 * Similar to Promise.allSettled() but with concurrency control.
 *
 * @param promises - An array of promises to be executed
 * @param concurrency - Maximum number of promises to execute simultaneously (defaults to MAX_CONCURRENT_REQUESTS)
 * @returns An array of PromiseSettledResult objects containing the status ('fulfilled' or 'rejected') and value/reason
 *
 * @example
 * const promises = [fetch(url1), fetch(url2), fetch(url3)];
 * const results = await promiseAllSettledThrottled(promises, 2);
 */
export const promiseAllSettledThrottled = async <T>(
  promises: Array<Promise<T>>,
  concurrency: number = MAX_CONCURRENT_REQUESTS,
): Promise<Array<PromiseSettledResult<T>>> => {
  if (promises.length === 0) {
    return [];
  }

  try {
    const results = await pMap(
      promises,
      async (promise: Promise<T>) => {
        try {
          return await promise;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      { concurrency },
    );

    return results.map((result) => ({
      status: 'fulfilled',
      value: result,
    }));
  } catch (error) {
    return [
      {
        reason: error,
        status: 'rejected',
      },
    ];
  }
};

/**
 * Executes an array of promises with a concurrency limit.
 * Similar to Promise.all() but with concurrency control.
 * If any promise rejects, the entire operation rejects.
 *
 * @param promises - An array of promises to be executed
 * @param concurrency - Maximum number of promises to execute simultaneously (defaults to MAX_CONCURRENT_REQUESTS)
 * @returns An array of resolved values from the promises
 * @throws Will throw an error if any of the promises reject
 *
 * @example
 * const promises = [fetch(url1), fetch(url2), fetch(url3)];
 * const results = await promiseAllThrottled(promises, 2);
 */
export const promiseAllThrottled = async <T>(
  promises: Array<Promise<T>>,
  concurrency: number = MAX_CONCURRENT_REQUESTS,
): Promise<T[]> => {
  if (promises.length === 0) {
    return [];
  }

  try {
    const results = await pMap(
      promises,
      async (promise: Promise<T>) => {
        try {
          return await promise;
        } catch (error) {
          throw error;
        }
      },
      { concurrency },
    );

    return results;
  } catch (error) {
    throw error;
  }
};

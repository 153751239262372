import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import ms from 'ms';
import { z } from 'zod';

import { ENDPOINT } from '~/constants/endpoints';
import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { type UUID } from '~/types/common';

import { snakecaseKeysForApi, vestigasApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';

import { queryKeysDataExchange } from './queryKeys';

const zodQueryParams = z.object({
  limit: z.number().gte(1).optional().default(PAGINATION_PAGE_SIZE_DEFAULT),
  offset: z.number().gte(0).optional().default(0),
});

export type DataExchangeEligiblePeopleQueryParams = z.infer<
  typeof zodQueryParams
>;

type DataExchangeEligiblePerson = {
  id: UUID;
  email: string;
  firstName: string;
  lastName: string;
};

type DataExchangeEligiblePeopleResponse = {
  items: readonly DataExchangeEligiblePerson[];
  nextLink: string;
};

const defaultQueryParams: DataExchangeEligiblePeopleQueryParams = {
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
};

/**
 * Generates the query options for the data exchanges eligible people query so they can be shared between the useQuery hook and the prefetching.
 */
export const getDataExchangeEligiblePeopleQueryOptions = ({
  dataExchangeId,
  queryParams,
  options,
}: {
  dataExchangeId: UUID;
  queryParams: Partial<DataExchangeEligiblePeopleQueryParams>;
  options?: Omit<
    UseQueryOptions<DataExchangeEligiblePeopleResponse>,
    'queryKey' | 'queryFn'
  >;
}) => {
  try {
    const mergedParams = {
      ...defaultQueryParams,
      ...queryParams,
    };

    // Transform and validate the parameters
    const parsedQueryParameters = zodQueryParams.parse(mergedParams);

    return {
      queryFn: async () =>
        fetchDataExchangeEligiblePeople(dataExchangeId, parsedQueryParameters),
      queryKey: queryKeysDataExchange.getAllEligiblePeople(
        dataExchangeId,
        parsedQueryParameters,
      ),
      staleTime: ms('60s'),
      ...options,
    };
  } catch (error) {
    if (error instanceof z.ZodError) {
      Log.error('Invalid query parameters:', error.errors);
    }

    throw error;
  }
};

/**
 * Fetches all data exchange eligible people from the API.
 * @see https://app.dev.vestigas.com/redoc#tag/Data-Exchange/operation/get_eligible_responsible_persons_for_data_exchange_data_exchange__data_exchange_id__eligible_responsible_persons_get
 */
export const fetchDataExchangeEligiblePeople = async (
  dataExchangeId: UUID,
  queryParams: DataExchangeEligiblePeopleQueryParams,
) => {
  try {
    const response = await vestigasApi
      .get(
        ENDPOINT.DATA_EXCHANGE.GET_ELIGIBLE_RESPONSIBLE_PERSONS(dataExchangeId),
        {
          searchParams: snakecaseKeysForApi(queryParams), // TODO: vestigasApi should convert search params to snake_case
        },
      )
      .json<DataExchangeEligiblePeopleResponse>();

    return (
      response?.items?.map((item) => ({
        ...item,
        name: [item.firstName, item.lastName].filter(Boolean).join(' '),
      })) ?? {
        items: [],
        nextLink: '',
      }
    );
  } catch (error) {
    Log.error('Error fetching data exchange eligible people', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting all eligible people for the data exchange with the given id.
 */
export const useQueryDataExchangeEligiblePeople = (
  {
    dataExchangeId,
    queryParams,
  }: {
    dataExchangeId: UUID;
    queryParams: Partial<DataExchangeEligiblePeopleQueryParams>;
  },
  options?: Omit<
    UseQueryOptions<DataExchangeEligiblePeopleResponse>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery({
    ...getDataExchangeEligiblePeopleQueryOptions({
      dataExchangeId,
      options,
      queryParams,
    }),
    enabled: Boolean(dataExchangeId),
  });
};

import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import ms from 'ms';
import { useEffect } from 'react';
import snakecaseKeys from 'snakecase-keys';

import { ENDPOINT } from '~/constants/endpoints';
import {
  PAGINATION_PAGE_SIZE_DEFAULT,
  type PaginationSortDirection,
} from '~/constants/pagination';

import { type Address } from '~/types/address';
import { type UUID } from '~/types/common';
import { type SiteTypeType } from '~/types/site';

import { vestigasApi } from '~/services/kyClient';

import {
  createInfiniteQuery,
  type InfiniteQueryOptionsType,
} from '~/utils/createInfiniteQuery';
import { Log } from '~/utils/logging';
import { withoutObjectKeysWhereValueIs } from '~/utils/object';
import { toSnakeCase } from '~/utils/string';

import { queryKeysSite } from './queryKeys';

export type SitesQueryParams = {
  limit: number;
  offset: number;
  searchString?: string;
  filterActive?: boolean;
  filterCompany?: UUID;
  filterNotParentOu?: UUID;
  filterParentOu?: UUID;
  filterSiteType?: SiteTypeType;
  orderBy:
    | 'company_id'
    | 'company_name'
    | 'created_on'
    | 'end'
    | 'id'
    | 'is_active'
    | 'modified_on'
    | 'name'
    | 'start'
    | 'type';
  returnAccountingReferences: boolean;
  returnTotalCount: boolean;
  sort: PaginationSortDirection;
};

export type SiteListItem = {
  id: UUID;
  name: string;
  type: string;
  isActive: boolean;
  coords: {
    lat: number;
    long: number;
  };
  address: Address;
  companyId: UUID;
  companyName: string;
  createdOn: string;
  modifiedOn: string;
  start: string;
  end: string;
  accountingReferences: Array<{
    id: UUID;
    name: string;
  }>;
};

export type SitesResponse = {
  data: readonly SiteListItem[];
  hasNextPage: boolean;
  offset: number;
  paginatedCount: number;
  totalCount: number;
};

const defaultQueryParams: SitesQueryParams = {
  filterActive: undefined,
  filterCompany: undefined,
  filterNotParentOu: undefined,
  filterParentOu: undefined,
  filterSiteType: undefined,
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  orderBy: 'name',
  returnAccountingReferences: true,
  returnTotalCount: true,
  searchString: undefined,
  sort: 'ASC',
};

/**
 * Generates the query options for the sites query so they can be shared between the useQuery hook and the prefetching.
 */
export const getSitesQueryOptions = ({
  queryParams,
  options,
}: {
  queryParams: Partial<SitesQueryParams>;
  options?: Omit<UseQueryOptions<SitesResponse>, 'queryKey' | 'queryFn'>;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.sort = qp.sort?.toUpperCase();

  return {
    // prevent hitting the rate limit of 30 requests/s
    placeholderData: keepPreviousData,

    queryFn: async () => fetchSites(qp),

    queryKey: queryKeysSite.getAll(qp),
    staleTime: ms('60s'),
    ...options,
  };
};

/**
 * Fetches all sites from the API.
 * @param queryParams - The query parameters for fetching sites.
 * @returns The site data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/get_sites_site_get
 */
export const fetchSites = async (queryParams: Partial<SitesQueryParams>) => {
  const mergedParams = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const qp = withoutObjectKeysWhereValueIs(
    {
      ...mergedParams,
      orderBy: mergedParams.orderBy
        ? toSnakeCase(mergedParams.orderBy)
        : undefined,
      sort: mergedParams.sort ? mergedParams.sort.toUpperCase() : undefined,
    },
    [undefined],
  );

  try {
    const response = await vestigasApi
      .get(ENDPOINT.SITE.GET_ALL(), {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<SitesResponse>();

    return (
      response ?? {
        data: [],
        hasNextPage: false,
        paginatedCount: 0,
        totalCount: 0,
      }
    );
  } catch (error) {
    Log.error('Error fetching sites', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all sites with given query parameters.
 * Handles pagination and prefetches the next page of sites for better performance.
 */
export const useQuerySites = (
  queryParams: Partial<SitesQueryParams>,
  options?: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (queryParams?.searchString?.length > 0) {
      // Only prefetch if the user is not searching, to prevent firing lots of requests for costly queries.
      return;
    }

    queryClient.prefetchQuery(
      getSitesQueryOptions({
        options,
        queryParams: {
          ...queryParams,
          offset:
            (queryParams.offset ?? 0) +
            (queryParams.limit ?? PAGINATION_PAGE_SIZE_DEFAULT),
        },
      }),
    );
  }, [queryParams, options, queryClient]);

  return useQuery<SitesResponse>({
    ...getSitesQueryOptions({ options, queryParams }),
    placeholderData: keepPreviousData,
  });
};

/**
 * React Query based custom hook for getting the data for all sites with infinite scrolling.
 * Uses useInfiniteQuery to handle loading more data as the user scrolls.
 *
 * @param {Object} queryParams - The query parameters for fetching sites.
 * @param {Object} options - Additional options for the useInfiniteQuery hook.
 * @returns {UseInfiniteQueryResult} The result of the useInfiniteQuery hook.
 */
export const useQuerySitesInfinite = (
  queryParams: Partial<SitesQueryParams>,
  options: InfiniteQueryOptionsType<SitesResponse | undefined, Error>,
) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const infiniteQueryOptions = {
    queryFn: async ({ pageParam }) =>
      fetchSites({
        ...qp,
        offset: pageParam,
      }),
    queryKey: queryKeysSite.getAllInfinite(queryParams),
    ...options,
  };

  return createInfiniteQuery<SitesResponse>(qp, infiniteQueryOptions);
};

export const PERMISSION_GRANT_TYPE = {
  ENTITY: 'entity',
  SUBJECT: 'subject',
} as const;

export const PERMISSION_GRANT_SUBJECT_TYPE = {
  USER: 'user',
  USER_GROUP: 'user_group',
} as const;

export const PERMISSION_GRANT_ENTITY_TYPE = {
  COMPANY: 'company',
  COST_CENTER: 'accounting_reference',
  ORGANISATIONAL_GROUP: 'org_unit',
  SITE: 'site',
  USER: 'user',
  USER_GROUP: 'user_group',
  VEHICLE: 'vehicle',
} as const;

export const PERMISSION_GRANT_SUBJECT_TYPES = {
  user: 'Benutzer',
  user_group: 'Benutzer-Gruppe',
} as const;

export const PERMISSION_GRANT_ENTITY_TYPES = {
  accounting_reference: 'Kostenstelle',
  company: 'Firma',
  org_unit: 'Organisations-Gruppe',
  site: 'Standort',
  user: 'Benutzer',
  user_group: 'Benutzer-Gruppe',
  vehicle: 'Fahrzeug',
} as const;

import { AddressObject } from '../masterdata/Address';

import {
  DATA_EXCHANGE_COMPANY_STATUS,
  DATA_EXCHANGE_COMPANY_STATUS_ORDER,
  DATA_EXCHANGE_COMPANY_TYPE,
} from './constants';
import {
  type DataExchangeCompany,
  type DataExchangeCompanyStatusKeys,
} from './types';

export const DataExchangeCompanyObject = {
  TYPE: DATA_EXCHANGE_COMPANY_TYPE,
  STATUS: DATA_EXCHANGE_COMPANY_STATUS,
  STATUS_ORDER: DATA_EXCHANGE_COMPANY_STATUS_ORDER,

  /**
   * Creates a data exchange company with the provided data.
   * @param dataExchangeCompany - An object containing the data exchange company data.
   */
  create(dataExchangeCompany: Partial<DataExchangeCompany> = {}) {
    return {
      id: dataExchangeCompany?.companyId ?? '',
      companyAccountId: dataExchangeCompany?.companyAccountId ?? '',
      name: dataExchangeCompany?.name ?? '',
      address: AddressObject.create(dataExchangeCompany?.address),
      isSender: dataExchangeCompany?.isSender ?? '',
      isReceiver: dataExchangeCompany?.isReceiver ?? '',
      statusDeliveryNote: dataExchangeCompany?.statusDln ?? '',
      statusInvoice: dataExchangeCompany?.statusInvoice ?? '',
      internalStatusDeliveryNote: dataExchangeCompany?.internalStatusDln ?? '',
      internalStatusInvoice: dataExchangeCompany?.internalStatusInvoice ?? '',
      comment: dataExchangeCompany?.statusComment ?? '',
      internalComment: dataExchangeCompany?.internalStatusComment ?? '',
      knownIssues: dataExchangeCompany?.knownIssues ?? '',
      furtherInformation: dataExchangeCompany?.furtherInformation ?? '',
      rank: dataExchangeCompany?.rank ?? '',
      blacklisted: dataExchangeCompany?.blacklisted ?? false,
      currentIssues: dataExchangeCompany?.currentIssues ?? '',
    };
  },

  /**
   * Gets a status label from the data exchange company status.
   * @param statusKey - An string of the data exchange company data.
   */
  getStatusLabel(statusKey: string) {
    const status = Object.keys(this.STATUS).find(
      (x) => this.STATUS[x as DataExchangeCompanyStatusKeys].KEY === statusKey,
    );

    if (!status) {
      return null;
    }

    return this.STATUS[status as DataExchangeCompanyStatusKeys].LABEL;
  },

  /**
   * Gets a status abbreviated label from the data exchange company status.
   * @param statusKey - An string of the data exchange company data.
   */
  getStatusAbbreviatedLabel(statusKey: string) {
    const status = Object.keys(this.STATUS).find(
      (x) => this.STATUS[x as DataExchangeCompanyStatusKeys].KEY === statusKey,
    );

    if (!status) {
      return null;
    }

    return this.STATUS[status as DataExchangeCompanyStatusKeys]
      .ABBREVIATED_LABEL;
  },

  /**
   * Gets a status description from the data exchange company status.
   * @param statusKey - An string of the data exchange company data.
   */
  getStatusDescription(statusKey: string) {
    const status = Object.keys(this.STATUS).find(
      (x) => this.STATUS[x as DataExchangeCompanyStatusKeys].KEY === statusKey,
    );

    if (!status) {
      return null;
    }

    return this.STATUS[status as DataExchangeCompanyStatusKeys].DESCRIPTION;
  },

  /**
   * Gets a highest status from the data exchange company status.
   * @param statusKey - An string of the data exchange company data.
   */
  getHighestStatus(statusKeys: string) {
    let highestStatusKey = null;

    for (const [_, value] of Object.entries(this.STATUS)) {
      if (highestStatusKey) {
        continue;
      }

      if (statusKeys.includes(value.KEY)) {
        highestStatusKey = value.KEY;
      }
    }

    return highestStatusKey;
  },
};

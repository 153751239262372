import {
  useQueries,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import ms from 'ms';
import { useCallback, useEffect, useRef } from 'react';

import { ENDPOINT } from '~/constants/endpoints';
import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { vestigasApi } from '~/services/kyClient';

import { useInitDlns } from '~/models/deliveries/DeliveryNote/utils/hooks';

import { withoutObjectKeysWhereValueIs } from '~/utils/object';
import { promiseAllThrottled } from '~/utils/promise';
import { toSnakeCase } from '~/utils/string';

import {
  clearDeliveryNoteRowCache,
  mapDeliveryRow,
} from '~/components/deliveries/utils';

import { queryKeysDeliveryNote } from './queryKeys';
import {
  type DeliveryNoteListItem,
  type DeliveryNoteSearchParams,
} from './types';
import { fetchDeliveryNoteSearchCount } from './useQueryDeliveryNoteSearchCount';

type DeliveryNotesResponse = {
  assets: DeliveryNoteListItem[];
  hasNextPage: boolean;
  paginatedCount: number;
  sequenceNumber: string;
  totalCount: number;
};

export const defaultSearchParams: DeliveryNoteSearchParams = {
  filterConfig: undefined,
  includeChains: false,
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  modifiedAfter: '0',
  offset: 0,
  orderBy: 'dlnDate',
  sortOrder: 'desc',
};

/**
 * Fetches all delivery notes based on search parameters from the API.
 *
 * @param {DeliveryNoteSearchParams} searchParams - The search parameters for filtering delivery notes
 * @returns {Promise<DeliveryNotesResponse>} The matching delivery notes data
 * @see https://app.dev.vestigas.com/redoc#tag/Delivery-Note/operation/search_delivery_note_asset_delivery_note_search_post
 */
export const fetchDeliveryNotes = async (
  searchParams: DeliveryNoteSearchParams,
) => {
  try {
    const mergedParams = {
      ...defaultSearchParams,
      ...searchParams,
    };

    const sp = withoutObjectKeysWhereValueIs(
      {
        ...mergedParams,
        orderBy: toSnakeCase(
          mergedParams.orderBy ?? defaultSearchParams.orderBy,
        ),
        sortOrder: mergedParams.sortOrder
          ? mergedParams.sortOrder.toUpperCase()
          : defaultSearchParams.sortOrder.toUpperCase(),
      },
      [undefined],
    );

    const response = await vestigasApi
      .post(ENDPOINT.DELIVERY_NOTE.GET_ALL(), {
        json: sp,
      })
      .json<DeliveryNotesResponse>();

    return response;
  } catch (error) {
    console.error('Error fetching delivery notes:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all delivery notes with given search parameters.
 * Handles pagination.
 *
 * @param {DeliveryNoteSearchParams} searchParams - The search parameters for filtering delivery notes
 * @param {Object} options - Additional options for the useQuery hook
 * @returns {UseQueryResult<number>} The result of the useQuery hook containing the count
 */
export const useQueryDeliveryNotes = (
  searchParams: DeliveryNoteSearchParams,
  options?: Omit<UseQueryOptions<number>, 'queryKey' | 'queryFn'>,
  shouldUsePrefetching = false,
) => {
  const initDlns = useInitDlns();
  const previousDataRef = useRef<
    | {
        deliveryNotes: Awaited<ReturnType<typeof fetchDeliveryNotes>> & {
          rows: any[];
        };
        count: number | undefined;
      }
    | undefined
  >(undefined);

  const queryClient = useQueryClient();

  // FIXME VGS-7376: remove useEffect in DeliveryList page once useQueryDeliveryNotes only consists of a single request

  const getDLNQuery = useCallback(
    (customOffset?: number) => ({
      enabled: options?.enabled !== false,
      placeholderData: () => previousDataRef.current?.deliveryNotes,
      async queryFn() {
        const response = await fetchDeliveryNotes({
          ...searchParams,
          offset: customOffset ?? searchParams.offset,
        });
        const initializedAssets = response.assets?.length
          ? await initDlns(response.assets)
          : [];

        const rows = initializedAssets.map((deliveryNote) =>
          mapDeliveryRow(deliveryNote),
        );

        const result = {
          ...response,
          rows,
        };

        previousDataRef.current = {
          ...previousDataRef.current,
          deliveryNotes: result,
        };

        return result;
      },
      queryKey: queryKeysDeliveryNote.getAll({
        ...searchParams,
        offset: customOffset ?? searchParams.offset,
      }),
      staleTime: ms('10s'),
      gcTime: ms('30s'),
    }),
    [searchParams, options?.enabled],
  );

  const getDLNCountQuery = useCallback(() => {
    const countQueryParams = {
      filterConfig: searchParams?.filterConfig,
    };
    return {
      enabled: options?.enabled !== false,
      placeholderData: () => previousDataRef.current?.count,
      async queryFn() {
        const count = await fetchDeliveryNoteSearchCount(countQueryParams);

        previousDataRef.current = {
          ...previousDataRef.current,
          count,
        };

        return count;
      },
      queryKey: queryKeysDeliveryNote.getSearchCount(countQueryParams),
    };
  }, [searchParams?.filterConfig, options?.enabled]);

  const queries = useQueries({
    queries: [getDLNQuery(), getDLNCountQuery()],
  });

  const [deliveryNotesQuery, deliveryNotesCountQuery] = queries;

  const isLoading = queries.some(({ isLoading }) => isLoading);
  const isFetching = queries.some(({ isFetching }) => isFetching);
  const isError = queries.some(({ isError }) => isError);
  const isSuccess = queries.every(({ isSuccess }) => isSuccess);

  const data = {
    hasNextPage: deliveryNotesQuery.data?.hasNextPage,
    paginatedCount: deliveryNotesQuery.data?.paginatedCount,
    rows: deliveryNotesQuery.data?.rows ?? [],
    sequenceNumber: deliveryNotesQuery.data?.sequenceNumber,
    totalCount: deliveryNotesCountQuery.data,
  };

  const refetch = async () => {
    clearDeliveryNoteRowCache();
    await promiseAllThrottled(queries.map(async (query) => query.refetch()));
  };

  useEffect(() => {
    if (
      shouldUsePrefetching &&
      Number(searchParams.offset) + Number(searchParams.limit) <
        Number(data?.totalCount)
    ) {
      void queryClient.prefetchQuery(
        getDLNQuery(Number(searchParams.offset) + Number(searchParams.limit)),
      );
    }
  }, [searchParams.limit, searchParams.offset, data?.totalCount]);

  return {
    data,
    isError,
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  };
};

import { dateUtils } from '~/utils/dateUtils';
import { getAbbreviatedUnit } from '~/utils/unit';
import UnitUtils from '~/utils/unitUtils';

import { CompanyObject, type LegalOrganization } from '../masterdata/Company';

export const CUSTOM_FIELD_VISIBILITY = {
  ALWAYS: {
    KEY: 'always',
    STRING: 'Immer',
  },
  NEVER: {
    KEY: 'never',
    STRING: 'Nie',
  },
  WHEN_SET: {
    KEY: 'when_set',
    STRING: 'Wenn vorhanden',
  },
} as const;

export const CUSTOM_FIELD_LEVEL = {
  ARTICLE: {
    KEY: 'item',
    STRING: 'Artikel',
  },
  DELIVERY_NOTE: {
    KEY: 'global',
    STRING: 'Lieferung',
  },
} as const;

export const CUSTOM_FIELD_TYPE = {
  BOOLEAN: {
    FORMATTER: (value: boolean) => (value ? 'Ja' : 'Nein'),
    KEY: 'bool',
    STRING: 'Boolean',
  },
  DATETIME: {
    FORMATTER: (value: string) =>
      dateUtils.getFormattedDate(
        value,
        dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
      ),
    KEY: 'datetime',
    STRING: 'Datetime',
  },
  DICT: {
    DISABLED: true,
    KEY: 'dict',
    STRING: 'Dictionary', // Not yet supported.
  },
  DOUBLE: {
    FORMATTER: (value: number) => UnitUtils.formatDe_safe(value),
    KEY: 'double',
    STRING: 'Double',
  },
  ENUMERATION: {
    // In this case, an ID is given that is mapped to the corresponding custom field option in DeliveryNote.js when initializing a dln.
    FORMATTER: (value: string) => value,
    KEY: 'enumeration',
    STRING: 'Enumeration',
  },
  LEGAL_ORGANIZATION: {
    FORMATTER(value: Partial<LegalOrganization>) {
      const company = CompanyObject.create(value);
      return company.name;
    },
    KEY: 'legal_organization',
    STRING: 'Legal Organization (Firma)',
  },
  STRING: {
    FORMATTER: (value: string) => value,
    KEY: 'string',
    STRING: 'String',
  },
  VALUE_MEASURE: {
    FORMATTER: (value: { value: string; measure: string }) =>
      UnitUtils.formatValueUnitPairAsString_safe(
        value.value,
        value.measure,
        getAbbreviatedUnit,
      ),
    KEY: 'value_measure',
    STRING: 'Double + Einheit',
  },
} as const;

export const DIFFERENCE_VALUES = {
  ALLOWED_UNITS: 'Mögliche Einheiten',
  DEFAULT_UNIT: 'Default Einheit',
  DISPLAY_NAME: 'Anzeige-Name',
  HAS_OPTIONS: 'Dropdown',
  ID: 'ID',
  KEY: 'Key',
  LEVEL: 'Level',
  NAME: 'Name',
  OPTIONS: 'Dropdown Optionen',
  PUBLIC: 'Öffentlich/Privat',
  TYPE: 'Typ',
  USED_BY: 'In PDF-Lieferschein verwendet von den Firmen...',
  VISIBILITY: 'Sichtbarkeit',
} as const;

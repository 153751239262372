import { type Coordinates } from './types';

export const DEFAULT_COORDINATES: Coordinates = {
  latitude: undefined,
  longitude: undefined,
};

export const INVALID_COORDINATES_MESSAGE = [
  'Ungültige Eingabe: Koordinaten konnten nicht übernommen werden.',
];

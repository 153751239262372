export const IDS_CREDENTIALS_SHOP = {
  BAYWA: {
    COMPANY_ID: '3e8f17a3-24ba-4f70-9f61-5ca06c81014f',
    NAME: 'BayWa',
    REDIRECT_URL: 'https://www.baywa-baustoffe.de/b2b/de/integration/baywa-ids',
  },
  WUERTH: {
    COMPANY_ID: '3c28526d-16fc-49a3-a293-57f7277a1526',
    NAME: 'Würth',
    REDIRECT_URL:
      'https://eshop.wuerth.de/is-bin/INTERSHOP.enfinity/WFS/1401-B1-Site/de_DE/-/EUR/ViewIDSCatalogService-IDSInBound',
  },
} as const;

import { useCallback, useMemo } from 'react';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';
import { EMPTY_DROPDOWN_OPTION } from '~/constants/select';

import { fetchFilterSuggestions, type FilterConfig } from '~/data/deliveryNote';

import { toCamelCase } from '~/utils/string';

import { type Option, SelectServerDriven } from './SelectServerDriven';

type P = {
  readonly filterConfig: FilterConfig;
  readonly filterName: string;
  readonly formatOptionLabel?: (option: Option) => React.ReactNode;
  readonly hasEmptyOption?: boolean;
  readonly isDisabled?: boolean;
  readonly isMultiSelect?: boolean;
  readonly onChange: (
    value: Array<{ id: string }>,
    data: Array<Record<string, unknown>>,
  ) => void;
  readonly placeholder?: string;
  readonly value: string[];
} & ComponentStyling;

export const MultiSelectFilterValues = ({
  className,
  filterConfig,
  filterName = '',
  formatOptionLabel,
  hasEmptyOption = false,
  isDisabled = false,
  isMultiSelect = true,
  onChange,
  placeholder = 'Wert auswählen',
  style,
  value,
}: P) => {
  // FIXME VGS-7151: Try replacing this ugly mess with existing functionality for getting the corresponding backend filter name (FilterProps.getFieldBackend) >>>>>>>>>>>>>>>>>>
  const normalizedFilterName = toCamelCase(filterName)?.replace(
    /^selected/,
    '',
  );
  const frontendFilterNameToBackendFilterNameMap = {
    acceptState: 'dln_accept_state',
    article: 'article_name',
    articleNumber: 'article_number',
    articleProductUnit: 'article_product_unit',
    buyerId: 'buyer_id',
    buyerName: 'buyer_name',
    confirmedAccountingReferenceId: 'confirmed_accounting_reference_id',
    confirmedSiteId: 'confirmed_site_id',
    costCenter: 'confirmed_accounting_reference_name',
    fromSite: 'loading_location',
    issuerId: 'issuer_id',
    issuerName: 'issuer_name',
    ouId: 'ou_id',
    permittedCostCenters: 'authorized_accounting_reference_id',
    permittedToSites: 'authorized_site_id',
    processState: 'process_state',
    recipient: 'recipient_name',
    recipientId: 'recipient_id',
    sellerId: 'seller_id',
    sellerName: 'seller_name',
    settledStatus: 'settled_status',
    supplier: 'supplier_name',
    supplierId: 'supplier_id',
    toSiteRecipient: 'confirmed_site_name',
    toSiteSupplier: 'supplier_assigned_site_name',
  };
  const unnecessaryMappedFilterName =
    frontendFilterNameToBackendFilterNameMap[normalizedFilterName];
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // FIXME - Most likely needs to be refactored to use createLoadOptions from the utils
  const loadOptions = async (searchString, _, { page }) => {
    const queryParams = {
      entity: unnecessaryMappedFilterName,
      filterConfig,
      limit: PAGINATION_PAGE_SIZE_DEFAULT,
      offset: Math.max(0, page - 1) * PAGINATION_PAGE_SIZE_DEFAULT,
      searchValue: searchString,
    };

    const response = await fetchFilterSuggestions(queryParams);

    // Map primitive values to objects with label and value for consistency and for future-proofing this (i18n!).
    const options = (response ?? []).map((option) => ({
      data: option,
      label: option,
      value: option,
    }));

    const hasMoreOptions = options.length >= PAGINATION_PAGE_SIZE_DEFAULT;

    // If the list supposed to have an empty option, add it to the top of the list and only on the first page.
    if (hasEmptyOption && page === 1) {
      options.unshift({
        data: EMPTY_DROPDOWN_OPTION,
        label: EMPTY_DROPDOWN_OPTION,
        value: EMPTY_DROPDOWN_OPTION,
      });
    }

    return {
      options,
      hasMore: hasMoreOptions,
      additional: {
        filterActive: hasMoreOptions,
        page: page + 1,
      },
    };
  };

  const getItemData = async (value) => value;
  const getOptionLabel = (option) => option;

  // This is just a glue function b/c PermissionGrantMultiPicker currently expects to pick the ID from an object in its onChange function. Should be refactored.
  const handleChange = useCallback(
    (newValues: string[] | string, data: Array<Record<string, unknown>>) => {
      onChange(
        Array.isArray(newValues) ? newValues.map((value) => value) : newValues,
        data,
      );
    },
    [onChange],
  );

  // This is meant (setting a key) to force a re-render/fetch for the component when the filterConfig changes.
  // But only if any other filter changes and not the current one.
  const key = useMemo(() => {
    const filteredFilterConfig = filterConfig.filterGroups.map(
      (filterGroup) => {
        const filterClauses = filterGroup.filterClauses.filter(
          (filterClause) => {
            return filterClause.name !== unnecessaryMappedFilterName;
          },
        );
        return {
          ...filterGroup,
          filterClauses,
        };
      },
    );

    return JSON.stringify(filteredFilterConfig);
  }, [JSON.stringify(filterConfig), unnecessaryMappedFilterName]);

  return (
    <SelectServerDriven
      key={key}
      className={className}
      formatOptionLabel={formatOptionLabel}
      getItemData={getItemData}
      getOptionLabel={getOptionLabel}
      isDisabled={isDisabled}
      isMultiSelect={isMultiSelect}
      loadOptions={loadOptions}
      placeholder={placeholder}
      style={style}
      value={value}
      onChange={handleChange}
    />
  );
};

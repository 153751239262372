import { clone } from '~/utils/object';

class CacheService {
  constructor() {
    this.cached = [];
    this.error = [];
  }

  setCached(url, value) {
    const index = this.cached.findIndex((cache) => cache.url === url);

    if (index === -1) {
      this.cached.push({ url, value: this.clone(value) });
      return;
    }

    this.cached[index].value = this.clone(value);
  }

  setError(url, error) {
    const index = this.error.findIndex((error) => error.url === url);

    if (index === -1) {
      // Contrary to setCached, error isn't deep cloned because this converts the JS error to an empty object {}
      this.error.push({ url, value: error });
      return;
    }

    // don't deep clone JS error
    this.error[index].value = error;
  }

  // returns [value, error]
  getCached(url) {
    const value = this.cached.find((cache) => cache.url === url)?.value;

    if (value) {
      return [this.clone(value), null];
    }

    const error = this.error.find((error) => error.url === url)?.value;

    // don't deep clone JS error
    if (error) {
      return [null, error];
    }

    return [null, null];
  }

  clone(value) {
    // Blobs are reset to an empty JS object {} during lodash.cloneDeep; // TODO: hope this legacy code can be removed soon!
    if (value instanceof Blob) {
      return value;
    }

    return clone(value);
  }

  removeCached(url) {
    this.cached = this.cached.filter((cache) => cache.url !== url);
  }
}

const cacheServiceInstance = new CacheService();

export default cacheServiceInstance;

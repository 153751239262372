import { type UncefactUnitType } from '~/constants/units';

import { dayjs } from '~/utils/datetime';
import { dateUtils } from '~/utils/dateUtils';
import {
  dateFull,
  dateLocalizedWOYearShort,
  dateLong,
  dateMonthYearLong,
  dateMonthYearShort,
  timeHours,
} from '~/utils/l10n';
import { type Prettify } from '~/utils/lang';
import { getAbbreviatedUnit } from '~/utils/unit';
import UnitUtils from '~/utils/unitUtils';

import { type AggregationInterval } from '../types';

type TimeAggregationFormatter = {
  tooltipLabel: (
    params: { dataset: { label: string }; parsed: { y: number } },
    selectedUnit: UncefactUnitType,
  ) => string;
  tooltipTitle: (value: string) => string | undefined;
  xAxisLabel: (value: string) => string;
  yAxisLabel: (value: number, selectedUnit: string) => string;
};

type FormatterType = Prettify<keyof TimeAggregationFormatter>;

const tooltipLabelFormatter = (
  {
    dataset: { label },
    formattedValue,
  }: {
    dataset: { label: string };
    formattedValue: string;
  },
  selectedUnit: UncefactUnitType,
) => {
  const abbreviatedUnit = getAbbreviatedUnit(selectedUnit);

  return `${label}: ${formattedValue} ${abbreviatedUnit}`;
};

const yAxisLabelFormatter = (value: number, selectedUnit: UncefactUnitType) =>
  `${UnitUtils.formatDe_safe(value)} ${getAbbreviatedUnit(selectedUnit)}`;

const TIME_AGGREGATION_FORMATTERS = new Map<
  AggregationInterval,
  TimeAggregationFormatter
>([
  [
    'hour',
    {
      tooltipTitle(value: string) {
        const date = dayjs(value);

        if (!date.isValid()) {
          return null;
        }

        return `In der Stunde ab ${date.format(dateFull)}, ${date.format(timeHours)} Uhr`;
      },
      xAxisLabel(value: string) {
        if (!value) {
          return '-';
        }

        const oClock = 'Uhr';

        return `${dayjs(value).format(`H [${oClock}]`)}`;
      },
    },
  ],
  [
    'day',
    {
      tooltipTitle: (value: string) => `Am ${dayjs(value).format(dateLong)}`,
      xAxisLabel: (value: string) =>
        dateUtils.getFormattedDate_safe(value, dateLocalizedWOYearShort),
    },
  ],
  [
    'week',
    {
      tooltipTitle: (value: string) =>
        `In der Woche ab dem ${dayjs(value).format(dateLong)}`,
      xAxisLabel: (value: string) =>
        dateUtils.getFormattedDate_safe(value, dateLong),
    },
  ],
  [
    'month',
    {
      tooltipTitle: (value: string) => dayjs(value).format(dateMonthYearLong),
      xAxisLabel: (value: string) => dayjs(value).format(dateMonthYearShort),
    },
  ],
  [
    'quarter',
    {
      tooltipTitle: (value: string) =>
        `Im Quartal ab ${dayjs(value).format(dateMonthYearLong)}`,
      xAxisLabel: (value: string) => value,
    },
  ],
]);

/**
 * Returns a formatting function for chart tooltipTitles or x/y-axis labels based on the aggregation interval
 * @param formatterType - The type of formatter to return ('tooltipLabel', 'tooltipTitle', 'xAxisLabel', or 'yAxisLabel')
 * @param aggregateBy - The time interval used for data aggregation ('hour', 'day', 'week', 'month', 'quarter')
 * @returns A formatting function that converts date strings to display strings, or undefined if no formatter exists
 */
export const getChartFormatter = (
  formatterType: FormatterType,
  aggregateBy: AggregationInterval,
) => {
  if (formatterType === 'tooltipLabel') {
    return tooltipLabelFormatter;
  }

  if (formatterType === 'yAxisLabel') {
    return yAxisLabelFormatter;
  }

  const formatter =
    TIME_AGGREGATION_FORMATTERS.get(aggregateBy)?.[formatterType];
  return formatter;
};

import { apiUrl } from '~/constants/environment';
import { LOADING_STATE } from '~/constants/LoadingState';
import { ROUTE } from '~/constants/Route';

import { fetchUser, fetchUsersBulk } from '~/data/user';

import { CompanyObject } from '~/models/masterdata/Company';
import { PermissionGrantObject } from '~/models/masterdata/PermissionGrant';
import { UserObject } from '~/models/masterdata/User';

import axios from '~/utils/api-client';
import { Log } from '~/utils/logging';
import { promiseHandler } from '~/utils/promiseHandler';
import RouteUtils from '~/utils/routeUtils';

const API_URL = apiUrl + '/user';

class UserService {
  constructor() {
    this.usersLoading = LOADING_STATE.NOT_LOADED;
    this.users = [];
    this.usersBulk = [];
  }

  async getCompany() {
    return axios.get(API_URL + '/company').then((response) => {
      if (response?.status === 200) {
        return CompanyObject.create(response?.data);
      }

      throw response;
    });
  }

  getRequiredPermissions(pageUrl) {
    const bestFittingUrl = RouteUtils.getBestFittingUrls(pageUrl);

    let requiredPermissions = [];

    for (const [index, item] of Object.keys(ROUTE).entries()) {
      if (bestFittingUrl.includes(ROUTE[item].ROUTE)) {
        requiredPermissions = ROUTE[item].PERMISSIONS;
      }
    }

    return requiredPermissions;
  }

  getFeatureFlag(pageUrl) {
    const featureFlag = {};

    const bestFittingUrl = RouteUtils.getBestFittingUrls(pageUrl);

    for (const [index, item] of Object.keys(ROUTE).entries()) {
      if (bestFittingUrl.includes(ROUTE[item].ROUTE)) {
        featureFlag.requiredFeatureFlag = ROUTE[item].FEATURE_FLAG;
        featureFlag.disablingFeatureFlag = ROUTE[item].DISABLING_FEATURE_FLAG;
      }
    }

    return featureFlag;
  }

  async getPermittedUsersOfEntity(
    entityId,
    checkEntityExistenceCallback,
    fetchUserCallback,
  ) {
    // Check if the entity (site or cost center) is accessible by the user.
    // If not, it is also not useful to call the endpoint to load the single entity via loadEntityCallback as it would just throw an error.
    // This case happens when you are logged in as a supplier and try to access the sites or cost centers of the recipient.
    const [entityById, error] = await promiseHandler(
      checkEntityExistenceCallback(entityId),
    );

    if (error) {
      throw error;
    }

    if (!entityById) {
      return [];
    }

    const permittedUsers = [];

    for (
      let index = 0;
      index < entityById.permissionGrantsFrom.length;
      index++
    ) {
      const permissionGrantsFrom = entityById.permissionGrantsFrom[index];

      if (
        permissionGrantsFrom.subjectType !==
        PermissionGrantObject.SUBJECT_TYPE.USER
      ) {
        continue;
      }

      const [user, error2] = await promiseHandler(
        fetchUserCallback(permissionGrantsFrom.subjectId),
      );

      if (error2) {
        throw error2;
      }

      const userInstance = UserObject.create(user);

      const canReadDeliveryNotes = UserObject.canReadDeliveryNotes(
        userInstance.permissionGrantsOn,
      );

      if (
        canReadDeliveryNotes &&
        !permittedUsers.some(({ id }) => id === userInstance.id)
      ) {
        userInstance.permissionGrantsOn =
          userInstance.permissionGrantsOn.filter(
            (permissionGrant) => permissionGrant.entityId === entityId,
          );
        permittedUsers.push(userInstance);
      }
    }

    return permittedUsers;
  }
}

const userServiceInstance = new UserService();

export default userServiceInstance;

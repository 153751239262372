import {
  keepPreviousData,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import ms from 'ms';
import { z } from 'zod';

import { ENDPOINT } from '~/constants/endpoints';
import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { snakecaseKeysForApi, vestigasApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';
import { withoutObjectKeysWhereValueIs } from '~/utils/object';
import { toSnakeCase } from '~/utils/string';

import { queryKeysDataExchange } from './queryKeys';

const zodDataExchangeQueryParams = z
  .object({
    company_id: z.array(z.string().uuid()).optional(),
    limit: z.number().gte(1).optional().default(PAGINATION_PAGE_SIZE_DEFAULT),
    offset: z.number().gte(0).optional().default(0),
    order_by: z
      .enum(['alphabetically', 'invoice_status', 'last_contact'])
      .optional()
      .default('alphabetically'),
    responsible_person_id: z.array(z.string().uuid()).optional(),
    search_text: z.string().optional(),
    sort_order: z.enum(['DESC', 'ASC']).optional().default('ASC'),
    status: z
      .enum([
        'active',
        'live',
        'qr_code',
        'test_phase',
        'implementation_supplier',
        'implementation_vestigas',
        'test_data_exchanged',
        'confirmed',
        'on_hold_supplier',
        'in_qualification',
        'interested',
      ])
      .optional(),
  })
  .refine(
    (data) => {
      if (data.search_text) {
        return data.sort_order !== undefined;
      }

      return true;
    },
    {
      message: 'sortOrder is required when searchText is provided',
      path: ['sortOrder'],
    },
  )
  .refine(
    (data) => {
      if (data.offset || data.limit) {
        return data.offset >= 0 && data.limit >= 0;
      }

      return true;
    },
    {
      message: 'offset is required when limit is provided and vice versa',
      path: ['offset'],
    },
  );

export type DataExchangeQueryParams = z.infer<
  typeof zodDataExchangeQueryParams
>;

const defaultQueryParams: Partial<DataExchangeQueryParams> = {
  company_id: undefined,
  limit: 2000, // PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  order_by: 'alphabetically',
  responsible_person_id: undefined,
  search_text: undefined,
  sort_order: 'ASC',
  status: undefined,
};

export type DataExchangeItem = Record<string, any>;

type DataExchangeResponse = {
  items: readonly DataExchangeItem[];
  nextLink: string;
};

/**
 * Generates the query options for the data exchanges query so they can be shared between the useQuery hook and the prefetching.
 */
export const getDataExchangesQueryOptions = ({
  queryParams,
  options,
}: {
  queryParams: Partial<DataExchangeQueryParams>;
  options?: Omit<UseQueryOptions<DataExchangeResponse>, 'queryKey' | 'queryFn'>;
}) => {
  try {
    const mergedParams = {
      ...defaultQueryParams,
      ...queryParams,
    };

    // Transform and validate the parameters
    const parsedQueryParameters = zodDataExchangeQueryParams.parse(
      withoutObjectKeysWhereValueIs(
        snakecaseKeysForApi({
          ...mergedParams,
          search_text: mergedParams.searchString ?? mergedParams.search_text, // oh my... all of these inconsistencies...
          order_by: mergedParams.order_by
            ? toSnakeCase(mergedParams.order_by)
            : undefined,
          sort_order: mergedParams.sort_order
            ? mergedParams.sort_order.toUpperCase()
            : undefined,
        }),
        [undefined, ''],
      ),
    );

    const urlSearchParams = new URLSearchParams();

    // Use the validated parameters instead of raw qp
    for (const [key, value] of Object.entries(parsedQueryParameters)) {
      if (Array.isArray(value)) {
        for (const item of value) {
          urlSearchParams.append(key, item);
        }
      } else if (value !== undefined && value !== null) {
        urlSearchParams.set(key, value.toString());
      }
    }

    return {
      queryFn: async () => fetchDataExchangesSuppliers(urlSearchParams),
      queryKey: queryKeysDataExchange.getAllSuppliers(
        Object.fromEntries(urlSearchParams.entries()),
      ),
      staleTime: ms('60s'),
      ...options,
    };
  } catch (error) {
    if (error instanceof z.ZodError) {
      Log.error('Invalid query parameters:', error.errors);
    }

    throw error;
  }
};

/**
 * Fetches all data exchange companies (suppliers) from the API.
 * @see https://app.dev.vestigas.com/redoc#tag/Data-Exchange/operation/get_data_exchange_information_for_all_companies_with_filters_data_exchange_company_information_query_get
 */
export const fetchDataExchangesSuppliers = async (
  queryParams: URLSearchParams,
) => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.DATA_EXCHANGE.GET_COMPANIES_ALL(), {
        searchParams: queryParams, // this does not need to be converted to snake_case as it is using URLSearchParams
      })
      .json<DataExchangeResponse>();

    return (
      response ?? {
        items: [],
        nextLink: '',
      }
    );
  } catch (error) {
    Log.error('Error fetching data exchanges', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all data exchange companies (suppliers) with given query parameters.
 */
export const useQueryDataExchangesSuppliers = (
  queryParams: Partial<DataExchangeQueryParams>,
  options?: Omit<UseQueryOptions<DataExchangeResponse>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery({
    ...getDataExchangesQueryOptions({
      options,
      queryParams,
    }),
    placeholderData: keepPreviousData,
  });
};

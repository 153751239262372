export const BILLED_ITEM_SETTLED_STATUS = {
  FULLY_SETTLED: {
    DESCRIPTION: 'Komplett abgerechnet',
    KEY: 'fully-settled',
  },
  NOT_SETTLED: {
    DESCRIPTION: 'Nicht abgerechnet',
    KEY: 'not-settled',
  },
  PARTLY_SETTLED: {
    DESCRIPTION: 'Teilweise abgerechnet',
    KEY: 'partly-settled',
  },
};

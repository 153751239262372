import { validate as uuidvalidate } from 'uuid';

import { ROUTE } from '~/constants/Route';
import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { Log } from './logging';

class RouteUtils {
  getBestFittingUrls(pageUrl) {
    let bestFittingUrls = [];

    for (const [index, item] of Object.keys(ROUTE).entries()) {
      if (bestFittingUrls.length > 0 && bestFittingUrls[0] === pageUrl) {
        break;
      }

      if (
        pageUrl.includes(ROUTE[item].ROUTE) &&
        (bestFittingUrls.length === 0 ||
          ROUTE[item].ROUTE.length > bestFittingUrls[0].length)
      ) {
        bestFittingUrls = [ROUTE[item].ROUTE, ROUTE[item].MAIN_ROUTE];
      }
    }

    if (bestFittingUrls.length === 0) {
      throw new Error('No route found for the given URL');
    }

    return bestFittingUrls;
  }

  getBaseUrl(pageUrl) {
    return this.getBestFittingUrls(pageUrl)[0];
  }

  getMainRoute(pageUrl) {
    const mainRoute = this.getBestFittingUrls(pageUrl)[1];

    if (!mainRoute) {
      return null;
    }

    return mainRoute;
  }

  getRoute(pageUrl) {
    const route = Object.keys(ROUTE).find(
      (key) => this.getPageUrlWithoutUuid(pageUrl) === ROUTE[key].ROUTE,
    );

    if (!route) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid page URL: ' + pageUrl),
      );
      return null;
    }

    return ROUTE[route];
  }

  getPageUrlWithoutUuid(pageUrl) {
    // If the last 36 chars are a UUID, remove them including the leading /
    if (uuidvalidate(pageUrl.slice(-36))) {
      return pageUrl.slice(0, -37);
    }

    return pageUrl;
  }

  getUuid(pageUrl) {
    const uuid = pageUrl.slice(-36);

    if (!uuidvalidate) {
      return null;
    }

    return uuid;
  }
}

const routeUtilsInstance = new RouteUtils();

export default routeUtilsInstance;

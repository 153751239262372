import { GridToolbarContainer } from '@mui/x-data-grid';
import { forwardRef, useId } from 'react';

import { withErrorBoundary } from '~/ui/atoms';

import { DatagridToolbarCustomComponent } from './DatagridToolbarCustomComponent';

export type CustomGridToolbarComponent = {
  icon?:
    | 'csv'
    | 'excel'
    | 'mapDirectDeliveryNote'
    | 'pdf'
    | 'permissionGrant'
    | 'requestSignature'
    | 'shareDeliveryNote'
    | 'signature';
  menuItems?: Array<{
    isLoading?: boolean;
    name: string;
    onClick: () => void;
    selected?: boolean;
  }>;
  title?: string;
};

type P = {
  readonly components?: CustomGridToolbarComponent[];
};

export const DatagridToolbar = withErrorBoundary(
  forwardRef<HTMLDivElement, P>(({ components = [] }, ref) => {
    const id = useId();

    return (
      <GridToolbarContainer ref={ref} className="flex gap-2 px-2 pt-2">
        {components?.filter(Boolean).map(({ icon, menuItems, title }) => (
          <DatagridToolbarCustomComponent
            key={title ?? `custom-component-${id}`}
            buttonProps={{
              variant: 'outlined',
            }}
            icon={icon}
            menuItems={menuItems ?? []}
            title={title}
          />
        )) ?? null}
      </GridToolbarContainer>
    );
  }),
  'DatagridToolbar',
);

DatagridToolbar.displayName = 'DatagridToolbar';

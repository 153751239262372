import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { DataExchangeCompanyObject } from '~/models/dataExchanges';

import { Log } from '~/utils/logging';

import { queryKeysDataExchange } from './queryKeys';

type DataExchangeCompanyItem = unknown;

type DataExchangeCompaniesResponse = {
  nextLink: string;
  items: readonly DataExchangeCompanyItem[];
};

/**
 * Fetches a list of data exchange companies from the API.
 *
 * This function retrieves data exchange companies and optionally maps them into instances of `DataExchangeCompanyObject`
 * if `initDataExchangeCompanies` is set to `true`.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Data-Exchange/operation/get_data_exchange_information_for_all_companies_data_exchange_company_information_all_get
 * @returns {Promise<DataExchangeCompaniesResponse>} Response from the server.
 * @throws {Error} If the API request fails.
 */
export const fetchDataExchangeCompanies = async (
  initDataExchangeCompanies = true,
) => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.DATA_EXCHANGE.GET_COMPANIES())
      .json<DataExchangeCompaniesResponse>();

    if (initDataExchangeCompanies) {
      return response.items?.map((item) =>
        DataExchangeCompanyObject.create(item),
      );
    }

    return response.items;
  } catch (error) {
    Log.error('Error fetching data exchange excel data', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom React Query hook to fetch data exchange companies.
 * Automatically manages caching, re-fetching, and error handling.
 */
export const useQueryDataExchangeCompaniesLegacy = (
  initDataExchangeCompanies = true,
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryFn: async () => fetchDataExchangeCompanies(initDataExchangeCompanies),
    queryKey: queryKeysDataExchange.getCompanies(initDataExchangeCompanies),
    ...options,
  });

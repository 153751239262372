import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import {
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { type MouseEvent } from 'react';

import { ComponentTestIds } from '~/constants/test-ids';

type P = {
  readonly isFetching?: boolean;
  readonly paginationText?: string;
};

export const Pagination = ({ isFetching, paginationText }: P) => {
  const apiRef = useGridApiContext();

  const page = useGridSelector(apiRef, gridPageSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  const calculatedPageCount = Math.ceil(rowCount / pageSize);
  const firstRow = page * pageSize + 1;
  const lastRow = Math.min((page + 1) * pageSize, rowCount);

  const handlePageChange = (newPage: number) => (event: MouseEvent) => {
    event.preventDefault();

    apiRef.current.setPage(newPage);
  };

  const hasNextPage = page < calculatedPageCount - 1;
  const isFetchingNextPage = isFetching;
  const hasPreviousPage = page > 0;
  const isFetchingPreviousPage = false;

  const label = rowCount
    ? (paginationText ?? `${firstRow}-${lastRow} von ${rowCount}`)
    : '';

  return (
    <div
      className="flex items-center justify-end"
      data-testid={ComponentTestIds.TABLE.PAGINATION}
    >
      <Typography className="mr-2 select-none" variant="body2">
        {label}
      </Typography>
      <IconButton
        aria-label="Previous page"
        className="cursor-pointer"
        disabled={!hasPreviousPage || isFetchingPreviousPage}
        onMouseDown={handlePageChange(page - 1)}
      >
        <KeyboardArrowLeftIcon fontSize="small" />
      </IconButton>
      <IconButton
        aria-label="Next page"
        className="cursor-pointer"
        disabled={!hasNextPage || isFetchingNextPage}
        onMouseDown={handlePageChange(page + 1)}
      >
        <KeyboardArrowRightIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

import { type PropsWithChildren } from 'react';
import { useLocation } from 'react-router';

import { useGetUserUtils, useQueryUserData } from '~/data/user';

import { UnauthorizedPage } from '~/modules/router/components';

import { Log } from '~/utils/logging';
import RouteUtils from '~/utils/routeUtils';
import { usePrevious } from '~/utils/usePrevious';

import { Spinner } from '~/ui/atoms/Spinner';

type P = PropsWithChildren & {
  readonly loadingTitle?: string;
};

export const ProtectedRoute = ({ children, loadingTitle }: P) => {
  const { UserUtils } = useGetUserUtils();

  const { data: currentUser, isLoading: isLoadingCurrentUser } =
    useQueryUserData(true);
  const featureFlags = currentUser?.featureFlags ?? {};
  const userPermissions = currentUser?.userPermissions ?? [];

  const location = useLocation();

  const previousLocation = usePrevious(location);

  if (location.pathname !== previousLocation?.pathname) {
    try {
      Log.productAnalyticsEvent(
        RouteUtils.getBaseUrl(location.pathname),
        Log.FEATURE.NAVIGATION,
      );
    } catch (error) {
      Log.error('Failed to send page view to product analytics tool', error);
    }
  }

  if (isLoadingCurrentUser) {
    return <Spinner title={loadingTitle} />;
  }

  return UserUtils.userIsAuthorizedForPage(
    location.pathname,
    userPermissions,
    featureFlags,
  ) ? (
    children
  ) : (
    <UnauthorizedPage />
  );
};

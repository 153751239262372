import { useQueryClient } from '@tanstack/react-query';
import { type ReactNode, useCallback } from 'react';

import { EMPTY_DROPDOWN_OPTION } from '~/constants/select';

import { fetchSite, fetchSites, queryKeysSite, type Site } from '~/data/site';
import { useGetUserUtils } from '~/data/user';

import { type Option, SelectServerDriven } from './SelectServerDriven';
import { createGetItemData, createLoadOptions } from './utils';

const getOptionLabel = ({ name }) => name;

type P = {
  readonly filterActive?: boolean;
  readonly formatOptionLabel?: (option: Option<Site>) => ReactNode;
  readonly hasEmptyOption?: boolean;
  readonly isDisabled?: boolean;
  readonly isMultiSelect?: boolean;
  readonly onChange: (value: Array<{ id: string }>, data: Site[]) => void;
  readonly placeholder?: string;
  readonly value: string[];
} & ComponentStyling;

export const MultiSelectSites = ({
  className,
  filterActive = true,
  formatOptionLabel,
  hasEmptyOption = false,
  isDisabled = false,
  isMultiSelect = true,
  onChange,
  placeholder = 'Standorte',
  style,
  value,
}: P) => {
  const queryClient = useQueryClient();

  const { UserUtils } = useGetUserUtils();

  /**
   * Loads the options for the multi-select dropdown.
   * @param {string} searchString - The search string entered by the user.
   * @param {unknown} loadedOptions - The already loaded options.
   * @param {{ page: number }} - The current page number.
   */
  const loadOptions = createLoadOptions<Site>({
    createQueryKey: ({ page, ...queryParams }) =>
      queryKeysSite.getAll(queryParams),
    async fetchItems({ page, ...queryParams }) {
      const { data, hasNextPage } = await fetchSites(queryParams);

      if (hasEmptyOption) {
        return {
          data: [
            {
              id: EMPTY_DROPDOWN_OPTION,
              name: EMPTY_DROPDOWN_OPTION,
            },
            ...data,
          ],
          hasNextPage,
        };
      }

      return {
        data,
        hasNextPage,
      };
    },
    filterActive,
    getOptionLabel,
    queryClient,
  });

  /**
   * Gets site data for a site with a given siteId.
   * Attempts to get it from the query cache and fetches it from the API
   * if the site data is not in the cache.
   * @param {string} siteId - The ID of the site to get.
   */
  const getItemData = createGetItemData<Site>({
    createQueryKey: (siteId) => queryKeysSite.get(siteId),
    async fetchItem(id) {
      if (hasEmptyOption && id === EMPTY_DROPDOWN_OPTION) {
        return {
          id: EMPTY_DROPDOWN_OPTION,
          name: EMPTY_DROPDOWN_OPTION,
        };
      }

      return fetchSite(id);
    },
    queryClient,
    queryKeyBase: [queryKeysSite.base()],
  });

  // This is just a glue function b/c PermissionGrantMultiPicker currently expects to pick the ID from an object in its onChange function. Should be refactored.
  const handleChange = useCallback(
    (newValues: string[] | string, data: Site[] | Site) => {
      onChange(
        Array.isArray(newValues)
          ? newValues.map((id) => ({ id }))
          : { id: newValues },
        data,
      );
    },
    [onChange],
  );

  return (
    <SelectServerDriven<Site>
      className={className}
      formatOptionLabel={formatOptionLabel}
      getItemData={getItemData}
      getOptionLabel={getOptionLabel}
      isDisabled={isDisabled}
      isItemSelectionAllowed={UserUtils.isSiteReadAllowedUser}
      isMultiSelect={isMultiSelect}
      loadOptions={loadOptions}
      placeholder={placeholder}
      style={style}
      value={value}
      onChange={handleChange}
    />
  );
};

import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback } from 'react';

import {
  fetchOrganizationalUnit,
  fetchOrganizationalUnits,
  queryKeysOrganizationalUnit,
} from '~/data/organizationalUnit';
import { useGetUserUtils } from '~/data/user';

import { type Option, SelectServerDriven } from './SelectServerDriven';
import { createGetItemData, createLoadOptions } from './utils';

const getOptionLabel = ({ name }) => name;

// Define an OrganizationalUnit type based on the expected structure; TODO: this should come as response type from the request.
type OrganizationalUnit = {
  id: string;
  name: string;
};

type P = {
  readonly className?: string;
  readonly formatOptionLabel?: (
    option: Option<OrganizationalUnit>,
  ) => React.ReactNode;
  readonly isDisabled?: boolean;
  readonly isMultiSelect?: boolean;
  readonly onChange: (
    value: Array<{ id: string }> | { id: string },
    data: OrganizationalUnit[] | OrganizationalUnit,
  ) => void;
  readonly placeholder?: string;
  readonly style?: React.CSSProperties;
  readonly value: string[];
};

export const MultiSelectOrganizationalUnits = ({
  className,
  formatOptionLabel,
  isDisabled = false,
  isMultiSelect = true,
  onChange,
  placeholder = 'Organisations-Gruppen',
  style,
  value,
}: P) => {
  const queryClient = useQueryClient();

  const { UserUtils } = useGetUserUtils();

  /**
   * Loads the options for the multi-select dropdown.
   * @param {string} searchString - The search string entered by the user.
   * @param {unknown} loadedOptions - The already loaded options.
   * @param {{ page: number }} - The current page number.
   */
  const loadOptions = createLoadOptions<OrganizationalUnit>({
    createQueryKey: ({ page, ...queryParams }) =>
      queryKeysOrganizationalUnit.getAll(queryParams),
    async fetchItems({ page, ...queryParams }) {
      const { data, hasNextPage } = await fetchOrganizationalUnits(queryParams);

      return {
        data,
        hasNextPage,
      };
    },
    getOptionLabel,
    queryClient,
  });

  /**
   * Gets organizational unit data for an organizational unit with a given organizationalUnitId.
   * Attempts to get it from the query cache and fetches it from the API
   * if the organizational unit data is not in the cache.
   * @param {string} organizationalUnitId - The ID of the organizational unit to get.
   */
  const getItemData = createGetItemData<OrganizationalUnit>({
    createQueryKey: (organizationalUnitId) =>
      queryKeysOrganizationalUnit.get(organizationalUnitId),
    fetchItem: fetchOrganizationalUnit,
    queryClient,
    queryKeyBase: [queryKeysOrganizationalUnit.base()],
  });

  // This is just a glue function b/c PermissionGrantMultiPicker currently expects to pick the ID from an object in its onChange function. Should be refactored.
  const handleChange = useCallback(
    (
      newValues: string[] | string,
      data: OrganizationalUnit[] | OrganizationalUnit,
    ) => {
      onChange(
        Array.isArray(newValues)
          ? newValues.map((id) => ({ id }))
          : { id: newValues },
        data,
      );
    },
    [onChange],
  );

  return (
    <SelectServerDriven<OrganizationalUnit>
      className={className}
      formatOptionLabel={formatOptionLabel}
      getItemData={getItemData}
      getOptionLabel={getOptionLabel}
      isDisabled={isDisabled}
      isItemSelectionAllowed={UserUtils.isOrganisationalGroupReadAllowedUser}
      isMultiSelect={isMultiSelect}
      loadOptions={loadOptions}
      placeholder={placeholder}
      style={style}
      value={value}
      onChange={handleChange}
    />
  );
};

import { type DeliveryNoteListItem } from '~/data/deliveryNote/types';

export const getBuyerOrderReferences = (
  assetMain: Partial<DeliveryNoteListItem> = {},
) => {
  const buyerOrderReferences = [];

  if (assetMain?.assetState?.body?.transaction?.logisticsPackage)
    for (const logisticsPackage of assetMain?.assetState?.body?.transaction
      ?.logisticsPackage) {
      if (logisticsPackage?.lineItem)
        for (const lineItem of logisticsPackage?.lineItem) {
          if (
            lineItem?.agreement?.buyerOrder &&
            !buyerOrderReferences.includes(lineItem.agreement?.buyerOrder)
          ) {
            buyerOrderReferences.push(lineItem.agreement.buyerOrder);
          }
        }
    }

  return buyerOrderReferences.join(', ');
};

import { type PropsWithChildren } from 'react';

import { cn } from '~/utils/tailwind';

type P = PropsWithChildren<{
  readonly contentClassName?: string;
  readonly show: boolean;
  readonly testId?: string;
}> &
  ComponentStyling;

/**
 * Reveal the children by animating from zero to full height when `show` is true.
 * Uses a CSS technique called grid reveal.
 */
export const Reveal = ({
  children,
  className = '',
  contentClassName,
  show,
  style,
  testId,
}: P) => (
  <div
    className={cn(
      'grid will-change-transform',
      {
        'grid-rows-[0fr] duration-200 ease-in': !show,
        'grid-rows-[1fr] duration-300 ease-out': show,
      },
      className,
    )}
    data-testid={testId}
    style={{
      transitionProperty: 'grid-template-rows',
      ...style,
    }}
  >
    <div className={cn('overflow-hidden', contentClassName)}>{children}</div>
  </div>
);

import { featureFlagMap } from './constants';
import { type FeatureFlagKey, type FeatureFlags } from './types';

export const UserFeatureFlagsObject = {
  /**
   * Creates a UserFeatureFlags object.
   * @param {Partial<FeatureFlags>} featureFlags - The feature flags data.
   * @returns {FeatureFlags} - The created UserFeatureFlags object.
   */
  create(featureFlags: Partial<FeatureFlags> = {}) {
    return {
      accessPermittedSites: featureFlags?.accessPermittedSites ?? false,
      concreteDiary: featureFlags?.concreteDiary ?? false,
      concreteEditingEnabled: featureFlags?.concreteEditingEnabled ?? false,
      ocrEnabled: featureFlags?.ocrEnabled ?? false,
      lineItemDetailsEditingDisabled:
        featureFlags?.lineItemDetailsEditingDisabled ?? false,
      recipientSetArrived: featureFlags?.recipientSetArrived ?? false,
      showOrderAtPreview: featureFlags?.showOrderAtPreview ?? false,
      userCannotSetAccepted: featureFlags?.userCannotSetAccepted ?? false,
      userCannotSetDeclined: featureFlags?.userCannotSetDeclined ?? false,
      userCannotSetDelivered: featureFlags?.userCannotSetDelivered ?? false,
      userMetrics: featureFlags?.userMetrics ?? false,
    };
  },

  /**
   * Compares two UserFeatureFlags objects to find the different values.
   * @param {FeatureFlags} userFeatureFlagsA - The first UserFeatureFlags object.
   * @param {FeatureFlags} userFeatureFlagsB - The second UserFeatureFlags object.
   * @returns {string[]} - A list of different values.
   */
  getDifferences(
    userFeatureFlagsA: FeatureFlags,
    userFeatureFlagsB: FeatureFlags,
  ) {
    const compareFeatureFlags = (
      flagA: FeatureFlags,
      flagB: FeatureFlags,
      key: FeatureFlagKey,
    ) => {
      if (flagA[key] !== flagB[key]) {
        return featureFlagMap[key];
      }

      return null;
    };

    return Object.keys(featureFlagMap)
      .map((key) =>
        compareFeatureFlags(
          userFeatureFlagsA,
          userFeatureFlagsB,
          key as FeatureFlagKey,
        ),
      )
      .filter(Boolean) as string[];
  },
};

import { useMemo } from 'react';
import { intersection } from 'remeda';

import { type UUID } from '~/types/common';

import AuthService from '~/services/auth.service';
import FeatureService from '~/services/feature.service';
import UserService from '~/services/user.service';

import { PermissionsObject } from '~/models/masterdata/Permissions';

import RouteUtils from '~/utils/routeUtils';

import {
  ANBS_COMPANIES,
  ANTON_EIREINER_COMPANIES,
  ANTON_EIREINER_USERS,
  ARGE_A3_COMPANIES,
  ATH_COMPANIES,
  BAYWA_SCREEN_USER,
  CONCRETE_DIARY_ALLOWED_USERS,
  DECHANT_COMPANIES,
  DELAYED_SIGNATURE_HOME_SCREEN_ALLOWED_USER,
  DEMO_ALLOWED_COMPANY_ACCOUNTS,
  DLN_DATE_COMPANY_ACCOUNTS,
  GROSSMANN_COMPANIES,
  HTI_SUPPORT_USERS,
  MAXI_KAUN_USERS,
  PERMITTED_SITE_ALLOWED_USERS,
  SCHICKER_COMPANY_ACCOUNTS,
  STRABAG_COMPANY_ACCOUNTS,
  STRABAG_SUPPORT_USERS,
  SUPPLIER_OVERVIEW_USER,
  USERS_WITH_CUSTOM_FIELDS,
  VESTIGAS_ACCOUNTS,
  WM_COMPANIES,
} from './constants';
import { useQueryUserData } from './useQueryUserData';

export const useGetUserUtils = () => {
  const { data: currentUser } = useQueryUserData(true);
  const userPermissions = currentUser?.userPermissions ?? [];
  const isVestigasSupport = Boolean(currentUser?.isVestigasSupport);
  const companyId = currentUser?.company?.id;

  const methods = useMemo(() => {
    return {
      ANBS_COMPANIES,
      ANTON_EIREINER_COMPANIES,
      ANTON_EIREINER_USERS,
      ARGE_A3_COMPANIES,
      ATH_COMPANIES,
      BAYWA_SCREEN_USER,
      CONCRETE_DIARY_ALLOWED_USERS,
      DECHANT_COMPANIES,
      DELAYED_SIGNATURE_HOME_SCREEN_ALLOWED_USER,
      DEMO_ALLOWED_COMPANY_ACCOUNTS,
      DLN_DATE_COMPANY_ACCOUNTS,
      GROSSMANN_COMPANIES,
      HTI_SUPPORT_USERS,
      MAXI_KAUN_USERS,
      PERMITTED_SITE_ALLOWED_USERS,
      SCHICKER_COMPANY_ACCOUNTS,
      STRABAG_COMPANY_ACCOUNTS,
      STRABAG_SUPPORT_USERS,
      SUPPLIER_OVERVIEW_USER,
      USERS_WITH_CUSTOM_FIELDS,
      VESTIGAS_ACCOUNTS,
      WM_COMPANIES,

      isVestigasSupportAccount() {
        return isVestigasSupport;
      },
      isVestigasAccount() {
        const accessToken = AuthService.getAccessToken();
        if (!accessToken) {
          return false;
        }

        const accessTokenPayload = AuthService.getJwtPayload(accessToken);

        if (!accessTokenPayload) {
          return false;
        }

        return (
          this.isVestigasSupportAccount() ||
          VESTIGAS_ACCOUNTS.includes(accessTokenPayload.preferred_username) ||
          accessTokenPayload.preferred_username
            ?.toLowerCase()
            ?.includes('support') ||
          accessTokenPayload.email?.toLowerCase()?.includes('support') ||
          accessTokenPayload.email?.includes('oleksiy.tsebriy@chisw.com')
        );
      },
      isVestigasAdminAccount() {
        const accessToken = AuthService.getAccessToken();

        if (!accessToken) {
          return false;
        }

        const accessTokenPayload = AuthService.getJwtPayload(accessToken);

        if (!accessTokenPayload) {
          return false;
        }

        return accessTokenPayload.preferred_username === 'vestigas-admin';
      },
      isSchickerAccount(companyAccountId: UUID) {
        return this.SCHICKER_COMPANY_ACCOUNTS.includes(companyAccountId);
      },
      // Should we render the DLN date as full date time instead of just the date?
      showFullDlnDateDateTime(companyAccountId: UUID) {
        return this.DLN_DATE_COMPANY_ACCOUNTS.includes(companyAccountId);
      },

      // Should we render a user feedback button?
      showUserFeedbackButton() {
        return (
          AuthService.getUserIdFromAccessToken() !== this.BAYWA_SCREEN_USER
        );
      },

      isStrabagAccount(companyAccountId: UUID) {
        return this.STRABAG_COMPANY_ACCOUNTS.includes(companyAccountId);
      },

      isGrossmannAccount(companyId: UUID) {
        return this.GROSSMANN_COMPANIES.includes(companyId);
      },

      isAntonEireinerAccount(companyId: UUID) {
        return this.ANTON_EIREINER_COMPANIES.includes(companyId);
      },

      isDechantAccount(companyId: UUID) {
        return this.DECHANT_COMPANIES.includes(companyId);
      },

      isWMAccount(companyId: UUID) {
        return this.WM_COMPANIES.includes(companyId);
      },

      isArgeA3Account(companyId: UUID) {
        return this.ARGE_A3_COMPANIES.includes(companyId);
      },

      isANBSAccount(companyId: UUID) {
        return this.ANBS_COMPANIES.includes(companyId);
      },

      isATHAccount(companyId: UUID) {
        return this.ATH_COMPANIES.includes(companyId);
      },

      isMaxiKaunUser() {
        return this.MAXI_KAUN_USERS.includes(
          AuthService.getUserIdFromAccessToken(),
        );
      },

      isStrabagSupportUser() {
        return this.STRABAG_SUPPORT_USERS.includes(
          AuthService.getUserIdFromAccessToken(),
        );
      },

      isHtiSupportUser() {
        return this.HTI_SUPPORT_USERS.includes(
          AuthService.getUserIdFromAccessToken(),
        );
      },

      isArchiveModeAllowedUser() {
        if (FeatureService.accessArchive()) {
          return true;
        }

        if (!companyId) return false;

        if (this.isGrossmannAccount(companyId)) {
          return true;
        }

        if (this.isAntonEireinerAccount(companyId)) {
          return true;
        }

        return false;
      },

      // user can see demo tab in settings
      isDemoAllowedCompanyAccount(companyAccountId: UUID) {
        return this.DEMO_ALLOWED_COMPANY_ACCOUNTS.includes(companyAccountId);
      },

      // user can see permitted/mapped sites and cost centers
      isPermittedSiteAllowedUser() {
        return (
          this.isVestigasAccount() ||
          this.PERMITTED_SITE_ALLOWED_USERS.includes(
            AuthService.getUserIdFromAccessToken(),
          )
        );
      },

      isDelayedSignatureHomeScreenAllowedUser(
        userId: UUID,
        featureFlags: Record<string, boolean>,
      ) {
        if (this.STRABAG_SUPPORT_USERS.includes(userId)) {
          return false;
        }

        if (featureFlags?.disableInvoices) {
          return false;
        }

        if (this.isVestigasAccount()) {
          return true;
        }

        return this.DELAYED_SIGNATURE_HOME_SCREEN_ALLOWED_USER.includes(userId);
      },
      isInvoiceExcelAllowedCompany(companyId: UUID) {
        return this.GROSSMANN_COMPANIES.includes(companyId);
      },

      isInvoiceExcelAllowedUser() {
        if (!companyId) return false;
        return this.isInvoiceExcelAllowedCompany(companyId);
      },
      isUserReadAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.READ_USER.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },
      isUserWriteAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.WRITE_USER.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isUserCreateAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.CREATE_USER.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isSiteReadAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.READ_SITE.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isSiteWriteAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.WRITE_SITE.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isSiteCreateAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.CREATE_SITE.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isCostCenterReadAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.READ_COST_CENTER.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isCostCenterWriteAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.WRITE_COST_CENTER.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isCostCenterCreateAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.CREATE_COST_CENTER.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isVehicleReadAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.READ_VEHICLE.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isVehicleWriteAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.WRITE_VEHICLE.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isVehicleCreateAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.CREATE_VEHICLE.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isCompanyReadAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.READ_COMPANY.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isCompanyWriteAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.WRITE_COMPANY.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isCompanyCreateAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.CREATE_COMPANY.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isOrganisationalGroupReadAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.READ_ORGANISATIONAL_GROUP.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isOrganisationalGroupWriteAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.WRITE_ORGANISATIONAL_GROUP.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isOrganisationalGroupCreateAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.CREATE_ORGANISATIONAL_GROUP.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isOrganisationalGroupDeleteAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.DELETE_ORGANISATIONAL_GROUP.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isUserGroupReadAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.READ_USER_GROUP.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isUserGroupWriteAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.WRITE_USER_GROUP.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isUserGroupCreateAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.CREATE_USER_GROUP.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isUserGroupDeleteAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.DELETE_USER_GROUP.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },

      isPermissionGrantAllowedUser() {
        const requiredPermissions =
          PermissionsObject.PERMISSION.GRANT_PERMISSION.BACKEND;

        return intersection(userPermissions, requiredPermissions).length > 0;
      },
      userIsAuthorizedForPage(
        pageUrl: string,
        userPermissions: string[],
        featureFlags: Record<string, boolean>,
      ) {
        if (
          RouteUtils.getRoute(pageUrl)?.ONLY_ACCESS_BY_VESTIGAS_SUPPORT &&
          !this.isVestigasAccount()
        ) {
          return false;
        }

        let authorized = false;

        const requiredPermissions = UserService.getRequiredPermissions(pageUrl);

        if (requiredPermissions) {
          for (const userPermission of userPermissions) {
            for (const routePermission of requiredPermissions) {
              if (userPermission.includes(routePermission)) {
                authorized = true;
              }
            }
          }
        } else {
          authorized = true;
        }

        const { disablingFeatureFlag, requiredFeatureFlag } =
          UserService.getFeatureFlag(pageUrl);

        if (requiredFeatureFlag && !featureFlags?.[requiredFeatureFlag]) {
          return false;
        }

        if (disablingFeatureFlag && featureFlags?.[disablingFeatureFlag]) {
          return false;
        }

        return authorized;
      },
    };
  }, [userPermissions, isVestigasSupport, companyId]);

  return { UserUtils: methods };
};

export const DataExchangeTestDataIcon = ({
  className,
  style,
}: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 9L17 5V8H10V10H17V13M7 11L3 15L7 19V16H14V14H7V11Z"
      fill="currentColor"
    />
  </svg>
);

/**
 * Remeda: fully type-safe functional utilities.
 * @see https://remedajs.com/docs
 */
import { countBy, firstBy, pipe, prop, sortBy, unique } from 'remeda';

import InvalidFunctionParameterException from '~/errors/InvalidFunctionParameterException';

export { intersection, pipe, sortBy, sumBy, unique, uniqueBy } from 'remeda';

/**
 * Returns all values of array1 that are not in array2 (return = array1 - array2).
 * @param array1 - The first array
 * @param array2 - The second array
 * @returns The difference between array1 and array2
 */
export const subtract = <T>(array1: T[], array2: T[]) => {
  if (!array1 || !array2) {
    return [];
  }

  return array1.filter((x) => !array2.includes(x));
};

/**
 * Compares two arrays and returns both the removed and added values between them.
 * The first array returned contains elements that were in oldArray but not in newArray (removed).
 * The second array returned contains elements that were in newArray but not in oldArray (added).
 *
 * @param oldArray - The original array to compare against
 * @param newArray - The new array to compare with
 * @returns A tuple of [removedValues, addedValues]
 *
 * @example
 * difference([1, 2, 3], [2, 3, 4]) // returns [[1], [4]]
 * difference(['a', 'b'], ['b', 'c']) // returns [['a'], ['c']]
 */
export const difference = <T>(oldArray: T[] = [], newArray: T[] = []) => {
  const removedValues = subtract(oldArray, newArray);
  const addedValues = subtract(newArray, oldArray);

  return [removedValues, addedValues];
};

/**
 * Sorts an array of objects by a specified key in ascending or descending order.
 * @param array - The array of objects to sort
 * @param key - The key to sort by
 * @param sortDescending - Whether to sort in descending order (default: false)
 * @returns The sorted array
 *
 * @example
 * const users = [{name: 'Alice', age: 30}, {name: 'Bob', age: 25}];
 * sortByKey(users, 'age') // returns [{name: 'Bob', age: 25}, {name: 'Alice', age: 30}]
 * sortByKey(users, 'name', true) // returns [{name: 'Bob', age: 25}, {name: 'Alice', age: 30}]
 */
export const sortByKey = <T>(array: T[], key: string, sortDescending = false) =>
  sortBy(array, [prop(key), sortDescending ? 'desc' : 'asc']);

/**
 * Sorts an array by a key based on a list of values.
 * @param array - The array to sort
 * @param sortedValues - The list of values to sort by
 * @param key - The key to sort by
 * @returns The sorted array
 *
 * @example
 * array = [{name: 'Moritz', id: 1}, ...]
 * sortedValues = [1, 5, 8, 3, 2]
 */
export const sortByKeyValues = <T>(
  array: T[],
  sortedValues: T[],
  key: string,
) => {
  if (sortedValues.length !== unique(sortedValues).length) {
    throw new InvalidFunctionParameterException(
      'Invalid array sortedValues. sortedValues must not contain duplicates.',
    );
  }

  const sortedArray = [];

  for (const sortedValue of sortedValues) {
    const items = array.filter((item) => item[key] === sortedValue);

    sortedArray.push(...items);
  }

  return sortedArray;
};

/**
 * Returns the most frequently occurring value in an array.
 * In case of a tie, returns the first value encountered.
 *
 * @param array - The input array to analyze
 * @returns The most frequent value in the array, or undefined if the array is empty
 *
 * @example
 * mostFrequentValue([1, 2, 2, 3]) // returns 2
 * mostFrequentValue([]) // returns undefined
 */
export const mostFrequentValue = <T>(array: T[]) => {
  if (!array?.length) {
    return undefined;
  }

  return pipe(array, (array_) =>
    firstBy(array_, (value) => -countBy(array_, (x) => x)[value]),
  );
};

/**
 * Moves an item from one position to another within an array.
 * Note: This function mutates the original array.
 *
 * @param array - The array to modify
 * @param fromIndex - The index of the item to move
 * @param toIndex - The destination index the item should be moved to
 * @returns The modified array
 *
 * @example
 * moveItem([1, 2, 3, 4], 1, 3) // returns [1, 3, 4, 2]
 */
export const moveItem = <T>(array: T[], fromIndex: number, toIndex: number) => {
  if (
    !array?.length ||
    fromIndex < 0 ||
    toIndex < 0 ||
    fromIndex >= array.length ||
    toIndex >= array.length
  ) {
    return array;
  }

  const itemToMove = array.splice(fromIndex, 1)[0] as T;

  array.splice(toIndex, 0, itemToMove);

  return array;
};

/**
 * Joins an array of React nodes with a separator, creating a new array suitable for React rendering.
 * Required when rendering a list of components with a separator, because Array.join() would try to convert the components to strings, which would not work correctly and could result in "[object Object]"
 *
 * @param array - Array of React nodes to join
 * @param separator - The separator to insert between elements (default: ', ')
 * @returns An array of alternating elements and separators, or null if the input array is empty
 *
 * @example
 * joinComponents([<span>A</span>, <span>B</span>])
 * // returns [<span>A</span>, ', ', <span>B</span>]
 *
 * joinComponents([], '-') // returns null
 */
export const joinComponents = (array: React.ReactNode[], separator = ', ') => {
  if (!array?.length) {
    return null;
  }

  const result = [array[0]];

  for (const element of array.slice(1)) {
    result.push(separator, element);
  }

  return result;
};

/**
 * Updates an object in an array if the value of a given key matches a given value.
 * @param array - The array to update
 * @param key - The key to update
 * @param currentValue - The current value of the item to update
 * @param newValue - The new value to update the item with
 * @returns The updated array
 */
export const updateByKey = <T>(
  array: T[],
  key: keyof T,
  currentValue: T[keyof T],
  newValue: T,
) => {
  const index = array.findIndex((item) => item[key] === currentValue);

  if (index === -1) {
    return array;
  }

  return [...array.slice(0, index), newValue, ...array.slice(index + 1)];
};

/**
 * Removes an object from an array if the value of a given key matches a specified value.
 * @param array - The array to modify
 * @param key - The key to match
 * @param value - The value to match for removal
 * @returns A new array with the specified object removed
 */
export const removeByKey = <T>(
  array: T[],
  key: keyof T,
  value: T[keyof T],
): T[] => {
  return array.filter((item) => item[key] !== value);
};

import camelcaseKeys from 'camelcase-keys';
import ky, {
  type Input,
  type Options,
  type ResponsePromise,
  type SearchParamsOption,
} from 'ky';
import snakecaseKeys from 'snakecase-keys';
import urlcat from 'urlcat';
import { ZodError, type ZodSchema } from 'zod';

import { apiUrl, sgwUrl } from '~/constants/environment';

import { Log } from '~/utils/logging';

import AuthService from './auth.service';

const trailingSlashRegex = /\/+$/;
const startsWithUuidRegex = /^[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}/i;

type KyValidationOptions = {
  body?: ZodSchema;
  searchParams?: ZodSchema;
};

type KyRequestOptions = Options & {
  validate?: KyValidationOptions;
};

type KyHookOptions = KyRequestOptions & {
  withCaseConverter?: boolean;
  useIdToken?: boolean;
};

// Extend the ky instance type to include our custom options
type VestigasKyInstance = Omit<typeof ky, 'create'> & {
  <T = unknown>(input: Input | URL, options?: KyRequestOptions): Promise<T>;
  get: <T = unknown>(
    input: Input | URL,
    options?: KyRequestOptions,
  ) => ResponsePromise<T>;
  post: <T = unknown>(
    input: Input | URL,
    options?: KyRequestOptions,
  ) => ResponsePromise<T>;
  put: <T = unknown>(
    input: Input | URL,
    options?: KyRequestOptions,
  ) => ResponsePromise<T>;
  patch: <T = unknown>(
    input: Input | URL,
    options?: KyRequestOptions,
  ) => ResponsePromise<T>;
  head: <T = unknown>(
    input: Input | URL,
    options?: KyRequestOptions,
  ) => ResponsePromise<T>;
  delete: <T = unknown>(
    input: Input | URL,
    options?: KyRequestOptions,
  ) => ResponsePromise<T>;
};

/**
 * Converts the keys of an object to camelCase. Keeps object keys that start with a UUID as they are, because the conversion would break the UUID.
 * @param data - The object to convert.
 * @returns The converted object with camelCase keys.
 */
export const camelcaseKeysFromApi = (data: any) =>
  camelcaseKeys(data, {
    deep: true,
    exclude: [startsWithUuidRegex], // exclude custom fields from conversion, which use `UUID_field_name` as key
  });

/**
 * Converts the keys of an object to snake_case. Keeps object keys that start with a UUID as they are, because the conversion would break the UUID.
 * @param data - The object to convert.
 * @returns The converted object with snake_case keys.
 */
export const snakecaseKeysForApi = (data: any) =>
  snakecaseKeys(data, {
    deep: true,
    exclude: [startsWithUuidRegex], // exclude custom fields from conversion, which use UUID or `UUID_field_name` as key
  });

/**
 * Processes a request body and validates it using a Zod schema, if provided.
 * @param body - The request body to process.
 * @param validation - The Zod schema to validate the request body against.
 * @param withCaseConverter - Whether to convert the request body to snake_case.
 * @returns The processed request body.
 */
function processRequestBody(
  body: BodyInit | undefined,
  validation?: ZodSchema,
  withCaseConverter = true,
): BodyInit | undefined {
  if (!body) {
    return undefined;
  }

  let jsonBody: unknown;

  if (typeof body === 'string') {
    try {
      jsonBody = JSON.parse(body);
    } catch {
      return body;
    }
  } else {
    jsonBody = body;
  }

  if (validation) {
    // Validate and sanitize the body. Also strip all unknown keys. Throw a ZodError if validation fails.
    jsonBody = validation.parse(jsonBody);
  }

  if (!withCaseConverter) {
    return JSON.stringify(jsonBody);
  }

  // Skip conversion for arrays of strings/numbers
  if (
    Array.isArray(jsonBody) &&
    jsonBody.every(
      (item) => typeof item === 'string' || typeof item === 'number',
    )
  ) {
    return JSON.stringify(jsonBody);
  }

  return JSON.stringify(snakecaseKeysForApi(jsonBody));
}

/**
 * Processes URL search parameters and validates them using a Zod schema, if provided.
 * @param searchParams - The search parameters object to process.
 * @param validation - The Zod schema to validate the search parameters against.
 * @returns The processed search parameters.
 */
function processSearchParams(
  searchParams: SearchParamsOption,
  validation?: ZodSchema,
): Record<string, string> | undefined {
  if (!searchParams) {
    return undefined;
  }

  if (!validation) {
    return searchParams as Record<string, string>;
  }

  // Validate and sanitize the search params. Also strip all unknown keys. Throw a ZodError if validation fails.
  return validation.parse(searchParams) as Record<string, string>;
}

/**
 * Constructs the final URL with search parameters
 * @param url - The URL to build.
 * @param searchParams - The search parameters object to add to the URL.
 * @returns The final URL.
 */
function buildUrl(url: string, searchParams?: Record<string, string>): string {
  const cleanUrl = url.replace(trailingSlashRegex, '');
  // If there are no new params to add, return the original clean URL (Might include its own params)
  if (!searchParams) {
    return cleanUrl;
  }

  // There are a few cases where searchParams are passed as an instance of URLSearchParams.
  // If that's the case we need to convert them to a plain object.
  if (searchParams instanceof URLSearchParams) {
    searchParams = Object.fromEntries(searchParams.entries());
  }

  // If we pass searchParams to ky, it strips the existing search params from the URL anyway.
  // So we build a new URL.
  const urlObject = new URL(cleanUrl);
  const baseUrl = urlcat(urlObject.origin, urlObject.pathname);

  return urlcat(baseUrl, searchParams);
}

/**
 * Creates a ky instance with a base URL based on the provided API path and environment.
 * The instance is configured to
 * - include credentials in requests
 * - refresh the access token on 401 (unauthorized) responses
 * - remove trailing slashes from URLs
 * - convert request bodies to snake_case
 * - convert response data to camelCase (if case converter is enabled)
 * - disable default timeouts and retries as those are handled by react-query
 * - accept validation schemas for request bodies and search parameters and validate them
 * - log errors and throw them to the calling function
 */
const createKyInstance = ({
  baseUrl = apiUrl,
  withCaseConverter = false,
  useIdToken = false,
}: {
  baseUrl?: string;
  withCaseConverter?: boolean;
  useIdToken?: boolean;
}): VestigasKyInstance => {
  const kyInstance = ky.create({
    hooks: {
      afterResponse: [
        async (
          request: Request,
          _options: KyHookOptions,
          response: Response,
        ) => {
          if (response.status === 401) {
            try {
              // Attempt to refresh the token
              const accessToken = await AuthService.refreshTokens();

              if (!accessToken) {
                throw new Error('Token refresh failed');
              }

              // Retry the original request with the new token
              const retryHeaders = new Headers(request.headers);
              retryHeaders.set('Authorization', `Bearer ${accessToken}`);
              const retryRequest = new Request(request, {
                headers: retryHeaders,
              });

              const retryResponse = await ky(retryRequest, {
                prefixUrl: baseUrl,
                retry: 0,
                timeout: false,
              });

              return retryResponse;
            } catch (refreshError) {
              console.error('Token refresh error:', refreshError);

              throw refreshError; // Re-throw the error to propagate the 401 error if refresh fails.
            }
          }

          // Convert the response data to camelCase if case converter is enabled.
          if (
            withCaseConverter &&
            response.headers.get('content-type')?.includes('application/json')
          ) {
            const responseBody = await response.json();
            const camelCasedBody = camelcaseKeysFromApi(responseBody);

            return new Response(JSON.stringify(camelCasedBody), {
              headers: response.headers,
              status: response.status,
              statusText: response.statusText,
            });
          }

          return response;
        },
      ],
      beforeError: [
        (error: Error) => {
          Log.error('Ky request error', error);

          throw error; // Re-throw the error to propagate it to the calling function and handle it there.
        },
      ],
      beforeRequest: [
        async (request: Request, options: KyHookOptions) => {
          try {
            // Add Authorization header with the appropriate token.
            const headers = new Headers(request.headers);
            const token = useIdToken
              ? AuthService.getIdToken()
              : AuthService.getAccessToken();
            headers.set('Authorization', `Bearer ${token}`);

            const processedBody = processRequestBody(
              options.body,
              options.validate?.body,
              withCaseConverter,
            );

            const processedSearchParams = processSearchParams(
              options.searchParams,
              options.validate?.searchParams,
            );

            const finalUrl = buildUrl(request.url, processedSearchParams);

            // Create a new request with the modified URL, headers, and body
            return new Request(finalUrl, {
              ...options,
              body: processedBody,
              headers,
            });
          } catch (error) {
            if (error instanceof ZodError && 'errors' in error) {
              // This is a Zod validation error
              Log.error('ZOD_VALIDATION_ERROR:', {
                message: 'Request validation failed',
                errors: error.errors,
                context: {
                  hasBody: Boolean(options.body),
                  hasSearchParams: Boolean(options.searchParams),
                  hasBodyValidation: Boolean(options.validate?.body),
                  hasSearchParamsValidation: Boolean(
                    options.validate?.searchParams,
                  ),
                  url: request.url,
                  values: {
                    body:
                      options.body && typeof options.body === 'string'
                        ? JSON.parse(options.body)
                        : options.body,
                    searchParams: options.searchParams,
                  },
                },
              });

              // Strip Zod error details and throw a simple validation error.
              throw new Error('Request validation failed');
            }

            throw error;
          }
        },
      ],
    },
    prefixUrl: baseUrl,
    retry: 0, // Disable retries (managed by react-query)
    timeout: false, // Disable timeouts (managed by react-query)
  });

  return kyInstance as VestigasKyInstance;
};

/**
 * ky instance for working with the VESTIGAS API in the current environment.
 */
export const vestigasApi = createKyInstance({
  baseUrl: apiUrl,
  withCaseConverter: true,
});

/**
 * ky instance for working with the VESTIGAS sync gateway in the current environment.
 */
export const vestigasSyncGateway = createKyInstance({
  baseUrl: sgwUrl,
  useIdToken: true,
  withCaseConverter: true,
});

import { Tooltip } from '~/ui/atoms/Tooltip';

export const InvoiceCheckListTooltip = ({ children, items, title }) => {
  const body = (
    <ul className="pr-2">
      {items.map((item) => (
        <li key={item}>
          <span className="text-sm font-bold">{item}</span>
        </li>
      ))}
    </ul>
  );

  const content = (
    <div>
      <div className="mb-2 flex w-full items-center justify-center border-b border-gray-400 p-1 text-sm font-bold">
        {title}
      </div>
      {body}
    </div>
  );

  return (
    <Tooltip colorScheme="dark" title={content} width="4xl">
      <span className="cursor-pointer">{children}</span>
    </Tooltip>
  );
};

import {
  AccountCircle as AccountCircleIcon,
  ArrowBack as ArrowBackIcon,
  FactCheck as FactCheckIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { useSelector } from '@xstate/store/react';
import { useAuth } from 'oidc-react';
import { memo, useCallback, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router';

import { redirectUrl } from '~/constants/environment';
import { ROUTE } from '~/constants/Route';
import { ComponentTestIds } from '~/constants/test-ids';

import { useQueryCompanyLogo } from '~/data/company';
import { useGetUserUtils, useQueryUserProfilePicture } from '~/data/user';

import { globalState } from '~/modules/applicationState';

import {
  activeApiName,
  isDevelopmentStagingEnvironment,
  isProductionEnvironment,
} from '~/utils/environment';
import { Log } from '~/utils/logging';
import RouteUtils from '~/utils/routeUtils';

import { withErrorBoundary } from '~/ui/atoms';
import { Tooltip } from '~/ui/atoms/Tooltip';
import { HotkeysLegend } from '~/ui/organisms/HotkeysLegend';

import { GlobalSitesAndCostCentersFilter } from './GlobalSitesAndCostCentersFilter';

type P = {
  readonly drawerWidth: number;
};

export const TopBar = withErrorBoundary(
  memo(({ drawerWidth }: P) => {
    const pageTitle = useSelector(
      globalState,
      (state) => state.context.pageTitle,
    );
    const userName = useSelector(
      globalState,
      (state) => state.context.currentUser?.name,
    );
    const position = useSelector(
      globalState,
      (state) => state.context.currentUser?.position,
    );
    const featureFlags = useSelector(
      globalState,
      (state) => state.context.currentUser?.featureFlags,
    );
    const permissions = useSelector(
      globalState,
      (state) => state.context.currentUser?.permissions,
    );
    const company = useSelector(
      globalState,
      (state) => state.context.currentUser?.company,
    );
    const hasLoggedInViaSso = useSelector(
      globalState,
      (state) => state.context.currentUser?.hasLoggedInViaSso,
    );

    const { UserUtils } = useGetUserUtils();

    const { data: companyLogo } = useQueryCompanyLogo(company?.id);

    const { data: profilePicture, isSuccess: isSuccessProfilePicture } =
      useQueryUserProfilePicture();

    const [anchorElement, setAnchorElement] = useState(null);
    const isMenuOpen = Boolean(anchorElement);

    const auth = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    const logout = useCallback(async () => {
      Log.productAnalyticsEvent('Logout', Log.FEATURE.AUTHENTICATION);

      // Clear all local storage items on logout.
      localStorage.clear();

      await auth.signOutRedirect({
        post_logout_redirect_uri: redirectUrl,
      });
    }, [auth]);

    const handleMenuClose = () => {
      Log.productAnalyticsEvent('Close burger menu', Log.FEATURE.MENU);

      setAnchorElement(null);
    };

    const handleMenuOpen = (event) => {
      Log.productAnalyticsEvent('Open burger menu', Log.FEATURE.MENU);

      setAnchorElement(event.currentTarget);
    };

    const handleNavigateBack = () => {
      Log.productAnalyticsEvent('Navigate back', Log.FEATURE.MENU);

      navigate(-1);
    };

    const handleClickDeliveryDataQuality = () => {
      Log.productAnalyticsEvent(
        'Open delivery note data quality',
        Log.FEATURE.DELIVERY_NOTE,
      );
      navigate(
        `${ROUTE.DELIVERY_NOTE_DATA_QUALITY.ROUTE}/${RouteUtils.getUuid(location.pathname)}`,
      );
    };

    const menuId = 'account-menu';
    const renderMenu = useMemo(
      () => (
        <Menu
          MenuListProps={{
            sx: { padding: 0 },
          }}
          anchorEl={anchorElement}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          id={menuId}
          open={isMenuOpen}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          keepMounted
          onClose={handleMenuClose}
        >
          <div className="max-w-48">
            <div className="flex flex-col items-center gap-4 p-4 text-center">
              {isSuccessProfilePicture && profilePicture ? (
                <div className="size-24 rounded-full border-2 border-gray-200 bg-white p-0.5">
                  <img
                    className="size-full rounded-full"
                    src={profilePicture}
                  />
                </div>
              ) : (
                <AccountCircleIcon className="my-4 size-16" fontSize="large" />
              )}
              <div>
                {userName ? <div className="font-bold">{userName}</div> : null}
                {position ? <div>{position}</div> : null}
                {company?.name ? <div>{company?.name}</div> : null}
              </div>
            </div>
            <div className="border-b border-gray-200" />
          </div>
          {UserUtils.userIsAuthorizedForPage(
            ROUTE.DASHBOARD.ROUTE,
            permissions ?? [],
            featureFlags,
          ) ? (
            <MenuItem
              component={Link}
              style={{ paddingBottom: 11, paddingTop: 11 }}
              to="/dashboard"
              onClick={handleMenuClose}
            >
              Statistiken
            </MenuItem>
          ) : null}
          {/* workaround time: MenuItem with link to external site didn't work. Hence this beautiful solution of an a tag, styled the same as a MenuItem */}
          {hasLoggedInViaSso ? null : (
            <MenuItem>
              <a
                className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters"
                href="https://login.vestigas.com/auth/realms/vestigas/account/password"
                rel="noreferrer"
                target="_blank"
              >
                Passwort ändern
              </a>
            </MenuItem>
          )}
          <MenuItem
            className="py-2"
            component={Link}
            to={ROUTE.SETTINGS.ROUTE}
            onClick={handleMenuClose}
          >
            Benutzerdaten ändern
          </MenuItem>
          <MenuItem>
            <a
              className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters"
              href="https://vestigas.notion.site/Herzlich-Willkommen-in-einer-Welt-ohne-Lieferscheine-c4db904d5ff4405fa1feb1f5cf8bd0c2"
              rel="noreferrer"
              target="_blank"
            >
              Zur Schulung
            </a>
          </MenuItem>
          <MenuItem
            className="py-2"
            component={Link}
            to={ROUTE.SETTINGS.ROUTE}
            onClick={handleMenuClose}
          >
            Einstellungen/Admin
          </MenuItem>
          <MenuItem
            className="py-2"
            component={Link}
            to={ROUTE.SETTINGS_IMPRINT.ROUTE}
            onClick={handleMenuClose}
          >
            Impressum
          </MenuItem>
          <MenuItem
            className="border-t border-gray-200 pt-2"
            style={{ borderTop: '1px solid rgb(209, 213, 219)' }}
            onClick={logout}
          >
            Abmelden
          </MenuItem>
        </Menu>
      ),
      [
        anchorElement,
        company?.name,
        featureFlags,
        hasLoggedInViaSso,
        isMenuOpen,
        isSuccessProfilePicture,
        logout,
        permissions,
        position,
        profilePicture,
        userName,
        UserUtils,
      ],
    );

    return (
      <AppBar
        className="bg-white pr-0! text-[#343a4d] transition-[width] duration-200 ease-out"
        position="fixed"
        sx={{
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Toolbar>
          <IconButton size="large" onClick={handleNavigateBack}>
            <ArrowBackIcon />
          </IconButton>
          <div className="flex flex-1 items-center gap-4 pl-4">
            <span
              className="truncate text-3xl"
              data-testid={ComponentTestIds.TOPBAR.PAGETITLE}
            >
              {pageTitle}
            </span>
            {RouteUtils.getBaseUrl(location.pathname) ===
              ROUTE.DELIVERY_NOTE.ROUTE && isDevelopmentStagingEnvironment ? (
              <div>
                <Tooltip title="Öffne die Datenansicht der Lieferung und überprüfe, ob die Informationen der Lieferung der gewünschten Datenqualität entsprechen.">
                  <Button
                    component="span"
                    startIcon={<FactCheckIcon />}
                    variant="outlined"
                    onClick={handleClickDeliveryDataQuality}
                  >
                    Datenqualität prüfen
                  </Button>
                </Tooltip>
              </div>
            ) : null}
          </div>
          <div className="flex items-center gap-4">
            <HotkeysLegend />
            <GlobalSitesAndCostCentersFilter />
            <div className="h-12 w-px bg-gray-300" />
            {isProductionEnvironment ? null : (
              <>
                <div className="text-sm font-bold text-green-600">
                  {activeApiName}
                </div>
                <div className="h-12 w-px bg-gray-300" />
              </>
            )}
            {companyLogo ? (
              <>
                <img className="hidden h-12 xl:flex" src={companyLogo} />
                <div className="hidden h-12 w-px bg-gray-300 xl:flex" />
              </>
            ) : null}
            <Button
              aria-controls={menuId}
              aria-haspopup="true"
              aria-label="account of current user"
              color="inherit"
              edge="end"
              endIcon={<KeyboardArrowDownIcon fontSize="large" />}
              startIcon={
                isSuccessProfilePicture && profilePicture ? (
                  <img
                    alt="Profile"
                    className="size-8 rounded-full"
                    src={profilePicture}
                  />
                ) : (
                  <AccountCircleIcon fontSize="large" />
                )
              }
              onClick={handleMenuOpen}
            >
              {userName ?? 'Menü'}
            </Button>
            {renderMenu}
          </div>
        </Toolbar>
      </AppBar>
    );
  }),
  null,
);

TopBar.displayName = 'TopBar';

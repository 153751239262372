type P = {
  readonly fill?: string;
} & ComponentStyling;

export const OrderReadyIcon = ({
  className,
  fill = 'currentColor',
  style,
}: P) => (
  <svg
    className={className}
    fill={fill}
    style={style}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M7.2 28.1c.2-.3.6-.4.8 0l1.3 2c.1.2.1.5-.1.6-.2.1-.5.1-.6-.1l-.6-.7v3.5c0 .6-.9.6-.9 0v-3.5l-.5.7c-.1.2-.4.3-.6.1-.2-.1-.3-.4-.1-.6l1.3-2zm32.4-1.3v-13c0-.2-.2-.4-.4-.4l-18-4.2H21L4 13.4c-.2.1-.4.2-.4.5l-.1 20.7c0 .2.1.4.3.4l17.7 4.7h.2l9.1-2.4c.9 3.9 4.4 6.8 8.5 6.8 4.8 0 8.7-3.9 8.7-8.7 0-4.6-3.7-8.4-8.4-8.6zm-9 9.7L22 38.7v-20l5.9-1.6v9.1c0 .4.6.6.8.2l1.7-2.4 1.6 1.3c.3.2.7 0 .7-.4v-9.3l5.9-1.6v12.4c-4.8.7-8.5 5.1-8 10.1zM44.8 30c3 3 3 8 0 11s-8 3-11 0-3-8 0-11c3-3.1 8-3.1 11 0zM8.6 17.3l8.8 2.4c.2.1.3.2.3.4v3.1c0 .3-.3.5-.6.4l-8.8-2.4c-.2.1-.3-.1-.3-.3v-3.1c0-.3.3-.5.6-.5zm8.2 3.2v2.2l-7.9-2.1v-2.2l7.9 2.1zm7.1 12.3c-.6.2-.8-.7-.2-.9l3.7-1c.6-.2.8.7.2.9l-3.7 1zm-.2.9 5.4-1.5c.3-.1.6.1.6.4v2.2c0 .2-.2.4-.4.4l-5.4 1.5c-.3.1-.6-.1-.6-.4v-2.2c.1-.2.2-.3.4-.4zm5.1-.5v1.2l-4.5 1.3v-1.2l4.5-1.3zM31.9 16v8.2l-1.3-1c-.2-.2-.5-.1-.7.1l-1.2 1.6v-8.2c0-.2-.2-.4-.4-.4l-15-4.4 2.8-.7L31.9 16zm-14-5.2 3.2-.8 16.2 3.8-5.1 1.4-14.3-4.4zm8.5 5.9L21.6 18 5.9 13.8l5.8-1.4 14.7 4.3zm-22 17.6 16.7 4.5v-20L4.5 14.4l-.1 19.9zm31.3 1.6c-.2-.2-.1-.5.1-.6.2-.2.5-.1.6.1l1.8 2.3 4.8-4.8c.4-.4 1.1.2.6.6l-5.2 5.2c-.2.2-.5.2-.7 0l-2-2.8z"
      fillRule="evenodd"
    />
  </svg>
);

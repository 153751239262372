import {
  useQueries,
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';

import { queryKeysUser } from './queryKeys';

export type User = Record<string, unknown>;

/**
 * Fetches user data from the API.
 * @param {UUID} userId - The ID of the user to fetch.
 * @returns {Promise<User|undefined>} The user data if successful, null otherwise.
 * @see https://app.dev.vestigas.com/redoc#tag/User/operation/get_user_by_id_user__user_id__get
 */
export const fetchUser = async (userId: UUID) => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.USER.GET(userId))
      .json<User>();

    return response;
  } catch (error) {
    Log.error('Error fetching user', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Generates the query options for the user query.
 *
 * @param {UUID} userId - The ID of the user to fetch.
 * @param {Object} options - Additional options for the query.
 * @returns {Object} The query options including queryKey, queryFn, and other settings.
 */
export const getUserQueryOptions = ({
  userId,
  options,
}: {
  userId: UUID;
  options?: Parameters<typeof useQuery>[0];
}) => ({
  queryFn: async () => fetchUser(userId),
  queryKey: queryKeysUser.get(userId),
  ...options,
});

/**
 * React Query based custom hook for getting the data for a user with a given userId.
 * @param {UUID} userId - The ID of the user to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<User | null>} The result of the useQuery hook.
 */
export const useQueryUser = (
  userId: UUID,
  options?: Omit<UseQueryOptions<User>, 'queryKey' | 'queryFn'>,
) => useQuery(getUserQueryOptions({ options, userId }));

/**
 * React Query based custom hook fetching multiple users using React Query's `useQueries`.
 * @param {UUID[]} userIds - Array of user IDs.
 * @param {Object} options - Additional options for each user query.
 * @param {Function} combine - Function to combine the query results.
 * @returns {Array<UseQueryResult<User | undefined>> | CombinedResult} Query results.
 */
export const useQueriesUsers = (
  userIds: UUID[],
  options?: Parameters<typeof useQueries>[0],
  combine?: (results: Array<UseQueryResult<User | undefined>>) => unknown,
) =>
  useQueries({
    combine,
    queries: userIds.map((userId) => ({
      enabled: Boolean(userId),
      queryFn: async () => fetchUser(userId),
      queryKey: queryKeysUser.get(userId),
      ...options,
    })),
  });

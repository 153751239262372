import { AcceptStateCalculatorObject } from '~/models/acceptState';

// const i18nMap = {
//   accepted: 'Angenommen',
//   open: 'Offen',
//   rejected: 'Abgelehnt',
// };

type AcceptState = 'accepted' | 'open' | 'rejected';
type ProcessState =
  | 'arrived'
  | 'cancelled'
  | 'default'
  | 'delivered'
  | 'imported'
  | 'inTransport'
  | 'readyForOutput'
  | 'settled';
type CombinedState = 'declined' | 'signature-needed' | 'other';

export const calculateCombinedState = (
  acceptState: AcceptState,
  processState: ProcessState,
): CombinedState => {
  if (acceptState === AcceptStateCalculatorObject.ACCEPT_STATE.DECLINED) {
    return 'declined';
  }

  if (
    acceptState === AcceptStateCalculatorObject.ACCEPT_STATE.OPEN &&
    processState === 'delivered'
  ) {
    return 'signature-needed';
  }

  return 'other';
};

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { type UUID } from '~/types/common';

import ObjectUtils from '~/utils/objectUtils';

import { IDS_CREDENTIALS_SHOP } from './constants';
import { type IdsCredentialsShopKeys } from './types';

export const IdsCredentialsObject = {
  /**
   * Creates an IdsCredentials object from input data.
   * @param {string} companyId - The company ID.
   * @param {object} [ids] - The IDS data.
   * @returns {object} - The formatted IdsCredentials object.
   */
  create(companyId: UUID, ids: Record<string, any> = {}) {
    return {
      companyId,
      kndnr: ids?.query_parameters?.kndnr ?? null,
      nameKunde: ids?.query_parameters?.name_kunde ?? null,
      pwKunde: ids?.query_parameters?.pw_kunde ?? null,
      queryParams: this.getQueryParams(ids?.query_parameters),
      redirectUrl: this.getRedirectUrl(companyId),
    };
  },

  /**
   * Extracts query parameters from IDS data.
   * @param {Record<string, any>} ids - The IDS query parameters.
   * @returns {Array<[string, any]>} - An array of key-value pairs.
   */
  getQueryParams(ids: Record<string, any>) {
    if (!ids) {
      return [];
    }

    return ObjectUtils.entries(ids).map((entry) => entry);
  },

  /**
   * Retrieves the redirect URL based on the company ID.
   * @param {string | null} companyId - The company ID.
   * @returns {string} - The corresponding redirect URL.
   * @throws {EnumValueNotFoundException} - If the company ID is invalid.
   */
  getRedirectUrl(companyId: UUID) {
    const shopKey = Object.keys(IdsCredentialsObject.SHOP).find(
      (key) =>
        IdsCredentialsObject.SHOP[key as IdsCredentialsShopKeys].COMPANY_ID ===
        companyId,
    );

    if (!shopKey) {
      throw new EnumValueNotFoundException(`Invalid company id: ${companyId}`);
    }

    return IdsCredentialsObject.SHOP[shopKey as IdsCredentialsShopKeys]
      .REDIRECT_URL;
  },

  SHOP: IDS_CREDENTIALS_SHOP,
};

import {
  Add as AddIcon,
  Check as CheckIcon,
  Edit as EditIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { Button, Chip } from '@mui/material';
import clsx from 'clsx';
import { type FormEvent, type MouseEvent } from 'react';

import { type UUID } from '~/types/common';

import FeatureService from '~/services/feature.service';

import BasicForm from '~/components/BasicForm';
import { TextField } from '~/components/deliveries/deliveryNoteForm/TextField';
import { ClientPortalTooltip } from '~/components/salesPackages/clientPortal/ClientPortalTooltip';
import { PackageBasicRestrictionTooltip } from '~/components/salesPackages/packageBasicRestriction/PackageBasicRestrictionTooltip';
import { withErrorBoundary } from '~/ui/atoms';
import { Tooltip } from '~/ui/atoms/Tooltip';

import { Badge } from './Badge';
import { type FilterGroupMark, type FilterGroupObject } from './types';

type P = {
  abortNewFilterGroup: () => void;
  baseTestId: string | undefined;
  filterGroupHasChanges?: boolean;
  filterGroupMarks: FilterGroupMark[];
  filterGroups: FilterGroupObject[];
  hideEditNameIcon?: boolean;
  hideFilterRows?: boolean;
  isCollapsed?: boolean;
  isCreating?: boolean;
  isSubmitting?: boolean;
  onChangeName: (newValue: string | undefined) => void;
  onClickChangeFilterGroupName: (event: MouseEvent<SVGSVGElement>) => void;
  onClickExpandFilterGroup: (event: MouseEvent<HTMLDivElement>) => void;
  onClickFilterGroup: (filterGroupId: UUID) => void;
  onClickNewFilterGroup: (event: MouseEvent<HTMLButtonElement>) => void;
  saveNewFilterGroup: (event: FormEvent<HTMLFormElement>) => void;
  selectedFilterGroup: string;
  state: {
    formOpen: boolean;
    formType: string;
    newFilterGroupName: string | undefined;
  };
  withExpandableFilterGroup?: boolean;
};

export const FilterGroupsList = withErrorBoundary(
  ({
    abortNewFilterGroup,
    baseTestId,
    filterGroupHasChanges,
    filterGroupMarks,
    filterGroups,
    hideEditNameIcon,
    hideFilterRows,
    isCollapsed,
    isCreating,
    isSubmitting,
    onChangeName,
    onClickChangeFilterGroupName,
    onClickExpandFilterGroup,
    onClickFilterGroup,
    onClickNewFilterGroup,
    saveNewFilterGroup,
    selectedFilterGroup,
    state,
    withExpandableFilterGroup,
  }: P) => {
    const getFilterGroupMark = (
      filterGroupMark: FilterGroupMark | undefined,
    ) => {
      if (!filterGroupMark) {
        return null;
      }

      return filterGroupMark.tooltipTitle ? (
        <Tooltip title={filterGroupMark.tooltipTitle}>
          <Badge text={filterGroupMark.mark} />
        </Tooltip>
      ) : (
        <Badge text={filterGroupMark.mark} />
      );
    };

    const getLabel = (id: UUID, name: string) => {
      const filterGroupMark = filterGroupMarks?.find(
        (filterGroupMark) => filterGroupMark.id === id,
      );

      return (
        <div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <div>{name}</div>
              {id === selectedFilterGroup && !hideEditNameIcon ? (
                <EditIcon
                  className="cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onMouseDown={onClickChangeFilterGroupName}
                />
              ) : null}
            </div>
            {id === selectedFilterGroup &&
            withExpandableFilterGroup &&
            !hideFilterRows ? (
              <div
                className="flex cursor-pointer items-center border-l border-white/80 pl-4"
                data-testid={`${baseTestId}_filter_group_expand_button`}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onMouseDown={onClickExpandFilterGroup}
              >
                <div className="w-28 font-bold underline">
                  {isCollapsed ? 'Filter einblenden' : 'Filter ausblenden'}
                </div>
                <ExpandLessIcon
                  className={clsx('transition-transform', {
                    'rotate-180': isCollapsed,
                  })}
                />
              </div>
            ) : null}
          </div>
          {getFilterGroupMark(filterGroupMark)}
        </div>
      );
    };

    return (
      <div
        className="flex items-center gap-4"
        data-testid={`${baseTestId}_container`}
      >
        <div className="flex flex-1 items-center gap-2 overflow-x-auto py-4">
          {filterGroups.map(({ disabled, id, name }, index) => (
            <Chip
              key={index}
              className={clsx(
                'cursor-pointer',
                id === selectedFilterGroup ? 'bg-primary500 text-white' : '',
              )}
              data-testid={`${baseTestId}_group_${name?.replaceAll(' ', '_')}`}
              disabled={disabled}
              icon={
                id === selectedFilterGroup ? (
                  <CheckIcon className="ml-2 text-white" />
                ) : undefined
              }
              label={getLabel(id, name)}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onMouseDown={() => {
                onClickFilterGroup(id);
              }}
            />
          ))}
        </div>

        <PackageBasicRestrictionTooltip>
          <ClientPortalTooltip>
            <Button
              className="flex-none cursor-pointer"
              data-testid={`${baseTestId}_new_group_button`}
              disabled={
                FeatureService.clientPortal() ||
                FeatureService.packageBasicRestriction()
              }
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={onClickNewFilterGroup}
            >
              Neue Filtergruppe
            </Button>
          </ClientPortalTooltip>
        </PackageBasicRestrictionTooltip>

        <BasicForm
          formAbort={abortNewFilterGroup}
          formSuccess={saveNewFilterGroup}
          open={state.formOpen}
          submittingForm={isSubmitting}
          testId={`${baseTestId}_form_modal`}
          title={
            isCreating ? 'Filtergruppe speichern' : 'Filtergruppe umbenennen'
          }
        >
          <div className="w-96">
            <TextField
              autoComplete="off"
              data-testid={`${baseTestId}_group_name_input`}
              placeholder="Bitte eingeben"
              title="Name"
              value={state.newFilterGroupName}
              autoFocus
              onChange={(event) => {
                onChangeName(event.target.value);
              }}
            />
          </div>
        </BasicForm>
      </div>
    );
  },
  'Filtergruppen konnten nicht geladen werden.',
);

FilterGroupsList.displayName = 'FilterGroupsList';

import { apiUrl } from '~/constants/environment';
import { LOADING_STATE } from '~/constants/LoadingState';

import { CompanyObject } from '~/models/masterdata/Company';
import { UserObject } from '~/models/masterdata/User';

import axios from '~/utils/api-client';
import { Log } from '~/utils/logging';
import { promiseHandler } from '~/utils/promiseHandler';

import CacheService from './cache.service';

const API_URL = apiUrl + '/company';

class CompanyService {
  // This endpoint is actually deprecated. However, it is needed for the dirty workaround in DeliveryNoteAction.js
  async getEmployeesOfCompany(id, include_info = false, ignoreCache) {
    const url = API_URL + '/' + id + '/employees';

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url, {
        params: { include_info },
      })
      .then((response) => {
        if (response?.status === 200) {
          const users = response.data.users?.map((user) => {
            return UserObject.create({ ...user, companyId: id });
          });
          CacheService.setCached(url, users);
          return users;
        }

        CacheService.setCached(url, []);
        return [];
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  }
}

const companyServiceInstance = new CompanyService();

export default companyServiceInstance;

import ms from 'ms';
import { useTranslation } from 'react-i18next';
import { useRegisterSW } from 'virtual:pwa-register/react';

import AuthService from '~/services/auth.service';

import { Log } from '~/utils/logging';

import { Button } from '~/ui/molecules/Button';

export const UpdatePrompt = () => {
  const { t } = useTranslation('pwa');

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(_swUrl, registration) {
      if (registration) {
        setInterval(async () => {
          try {
            if (AuthService.isAccessTokenValid()) {
              await registration.update();
            }
          } catch (error) {
            // If it's the redirect error, just log it at debug level since it's expected
            if (
              error instanceof Error &&
              error.message?.includes('behind a redirect')
            ) {
              Log.info(
                'Service worker update skipped due to authentication redirect',
              );
            } else {
              Log.error('Service worker update failed', error);
            }
          }
        }, ms('1m'));
      }
    },
    onRegisterError(error) {
      Log.error('Service worker registration error', error);
    },
  });

  const handleUpdate = async () => {
    try {
      await updateServiceWorker(true);
      setNeedRefresh(false);
    } catch (error) {
      Log.error('Failed to update service worker', error);
    }
  };

  // const handleDismiss = () => {
  //   setNeedRefresh(false);
  // };

  return (
    <div className="size-0">
      {needRefresh && (
        <div className="fixed right-2 bottom-2 z-50 w-64 space-y-2 rounded-md bg-gray-900 p-4 text-white shadow-lg">
          <h2 className="font-semibold">
            {t('updatePrompt.heading', { ns: 'pwa' })}
          </h2>
          <p className="text-sm">{t('updatePrompt.body', { ns: 'pwa' })}</p>
          <div className="mt-4 flex gap-4">
            {needRefresh && (
              <Button
                className="w-full"
                variant="contained"
                onClick={handleUpdate}
              >
                {t('updatePrompt.action.update', { ns: 'pwa' })}
              </Button>
            )}
            {/* <Button variant="text" onClick={handleDismiss}>
              {t('updatePrompt.action.ignore', { ns: 'pwa' })}
            </Button> */}
          </div>
        </div>
      )}
    </div>
  );
};

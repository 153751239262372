import { type UseQueryOptions } from '@tanstack/react-query';
import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useGetUserUtils, useQueryUserData, type User } from '~/data/user';

import { saveUserId, setUserHasLoggedInViaSSO } from '~/redux/userinfoSlice';

import AuthService from '~/services/auth.service';
import ThirdPartyService from '~/services/thirdParty.service';

import { globalState } from '~/modules/applicationState';
import { DeliveryNoteUpdatesHandler } from '~/modules/deliveryNote/DeliveryNoteUpdatesHandler';
import { PickupScreenBayWa } from '~/modules/pickupScreen';

import { ExternalSupplierOverview } from '~/components/dataExchanges/ExternalSupplierOverview';
import { DeactivatedAccountModal } from '~/components/DeactivatedAccountModal';
import { AppLayout } from '~/components/menu/AppLayout';
import { withErrorBoundary } from '~/ui/atoms';
import { Spinner } from '~/ui/atoms/Spinner';

import { useHandleAuthentication } from './useHandleAuthentication';
import { useInitIncomingInvoices } from './useInitIncomingInvoices';
import { useInitOutgoingInvoices } from './useInitOutgoingInvoices';
import { useLoadData } from './useLoadData';
import { useNotifyDataExchangeIssues } from './useNotifyDataExchangeIssues';

/**
 * AppInitializer
 *
 * This component is responsible for initializing the application, handling user authentication,
 * loading necessary data, and rendering the appropriate view based on the user's status and role.
 *
 * It manages the following tasks:
 * - Handles user authentication
 * - Loads required data upon successful login
 * - Initializes third-party services (e.g., Sentry)
 * - Dispatches user information to Redux store
 * - Initializes incoming and outgoing invoices
 * - Notifies about data exchange issues
 * - Renders special views for specific user roles (supplier overview, BayWa screen)
 * - Renders the main application view (AppLayout and DeactivatedAccountModal) for regular users
 *
 * The component is wrapped with an error boundary to handle potential errors during initialization.
 */
export const AppInitializer = withErrorBoundary(() => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { UserUtils } = useGetUserUtils();

  const { loadData } = useLoadData();

  const isInitialized = useRef(false);

  const [loadingMessage, setLoadingMessage] = useState('');

  useHandleAuthentication(() => {}, setLoadingMessage);

  const userId = AuthService.getUserIdFromAccessToken();

  const isLoggedIn = Boolean(userId);

  const { data: currentUser } = useQueryUserData(true, {
    enabled: isLoggedIn,
    select: (data) => data,
  } as UseQueryOptions<User>);

  useLayoutEffect(() => {
    if (!isLoggedIn || !currentUser) {
      return;
    }

    globalState.trigger.setCurrentUser({
      currentUser,
      hasLoggedInViaSso: AuthService.accessTokenContainsFicKey(),
    });

    if (isInitialized.current) {
      return;
    }

    // Load all required data once when the user logs in.
    loadData();

    dispatch(setUserHasLoggedInViaSSO(AuthService.accessTokenContainsFicKey()));

    isInitialized.current = true;
  }, [currentUser, isLoggedIn]);

  useInitIncomingInvoices();
  useInitOutgoingInvoices();
  useNotifyDataExchangeIssues();

  useLayoutEffect(() => {
    if (userId) {
      dispatch(saveUserId(userId));
    } else if (AuthService.isAccessTokenExpired()) {
      setLoadingMessage(t('error.auth.refresh.message'));
    }

    return () => {
      setLoadingMessage('');
    };
  }, [userId, dispatch]);

  if (!isLoggedIn) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner title={loadingMessage} />
      </div>
    );
  }

  if (isLoggedIn) {
    ThirdPartyService.initSentryUser(UserUtils);

    if (userId === UserUtils.SUPPLIER_OVERVIEW_USER) {
      // Render overview of connected suppliers for related special-purpose user.
      return <ExternalSupplierOverview />;
    }

    if (userId === UserUtils.BAYWA_SCREEN_USER) {
      // Render custom pickup screen for related special-purpose BayWa user.
      return <PickupScreenBayWa />;
    }
  }

  return (
    <>
      <AppLayout />
      <DeliveryNoteUpdatesHandler />
      <DeactivatedAccountModal />
    </>
  );
}, 'Web App konnte nicht geladen werden.');

AppInitializer.displayName = 'AppInitializer';

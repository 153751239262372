export const DeliveryOutlinedIcon = ({
  className,
  style,
}: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.125 11.25H15.75L16.875 13.5H2.25L1.125 11.25ZM2.625 15.75H17.25L18.375 18H3.75L2.625 15.75ZM27 27.75C28.245 27.75 29.25 26.745 29.25 25.5C29.25 24.255 28.245 23.25 27 23.25C25.755 23.25 24.75 24.255 24.75 25.5C24.75 26.745 25.755 27.75 27 27.75ZM29.25 14.25H25.5V18H32.19L29.25 14.25ZM12 27.75C13.245 27.75 14.25 26.745 14.25 25.5C14.25 24.255 13.245 23.25 12 23.25C10.755 23.25 9.75 24.255 9.75 25.5C9.75 26.745 10.755 27.75 12 27.75ZM30 12L34.5 18V25.5H31.5C31.5 27.99 29.49 30 27 30C24.51 30 22.5 27.99 22.5 25.5H16.5C16.5 27.99 14.475 30 12 30C9.51 30 7.5 27.99 7.5 25.5H4.5V20.25H7.5V22.5H8.64C9.465 21.585 10.665 21 12 21C13.335 21 14.535 21.585 15.36 22.5H22.5V9H4.5C4.5 7.335 5.835 6 7.5 6H25.5V12H30Z"
      fill="currentColor"
    />
  </svg>
);

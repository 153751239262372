export const INVOICE_CHECK_RESULT_STATUS = {
  DELAYED_SUCCESS: 'delayed-success',
  ERROR: 'error',
  MANUAL: 'manual',
  NA: 'na',
  NO_CHECKING_POSSIBLE: 'no-checking-possible',
  SUCCESS: 'success',
  WARNING: 'warning',
} as const;

export const INVOICE_CHECK_IGNORED_ARTICLE_DIFFERENT_VALUES = {
  ID: 'ID',
  SELLER_ASSIGNED_ID: 'Artikelnummer',
  NAME: 'Artikelname',
  REASON: 'Grund',
  SELLER_COMPANIES: 'Auftragnehmer',
  BUYER_COMPANIES: 'Auftraggeber',
} as const;

export const ARTICLE_NAME_KEY = 'articleName';
export const DLN_NUMBER_KEY = 'dlnNumber';

export const INVOICE_CHECK_CATEGORY_CATEGORIES = {
  AMOUNT_APPROVED_CHECK: {
    CHECKS: ['AcceptedPartialDeliveryQuantityMatches'],
    ERROR_NAME: 'Reklamationen',
    KEY: 'amount-approved-check',
    SUCCESS_NAME: 'Keine Reklamationen',
  },
  AMOUNT_CHECK: {
    CHECKS: ['DeliveryNoteQuantityMatches'],
    ERROR_NAME: 'Mengenabgleich',
    KEY: 'amount-check',
    SUCCESS_NAME: 'Mengenabgleich',
  },
  ARTICLE_EXISTS_CHECK: {
    CHECKS: ['DeliveryNoteMaterialMatches'],
    ERROR_NAME: 'Artikel fehlen',
    KEY: 'item-exists-check',
    SUCCESS_NAME: 'Artikel vorhanden',
  },
  DLN_CHECK: {
    CHECKS: [
      'DeliveryNoteExists',
      'DeliveryNoteNotAlreadySettled',
      'DeliveryNoteReferenceExists',
      'DeliveryNoteItemAlreadyBilled',
    ],
    ERROR_NAME: 'Lieferscheinprüfung',
    KEY: 'dln-check',
    SUCCESS_NAME: 'Lieferscheinprüfung',
  },
  FORMAL_CHECK: {
    CHECKS: [
      // according to §14 UStG (Abs. 4)
      'SenderNameExists',
      'SenderAddressExists',
      'ReceiverNameExists',
      'ReceiverAddressExists',
      'TaxIDExists',
      'InvoiceDateExists',
      'InvoiceIDExists',
      'DeliveryDateExists',
      'TotalPriceExists',
      // calculation inside of invoice
      'TaxAmountExists',
      'TaxRateExists',
      'TaxRateCheck',
      'TotalTaxAmountCalculation',
      'TotalGrossPriceCalculation',
      'TotalNetPriceCalculation',
      // cross-check with VESTIGAS data
      // "MatchingRecipientData", // not provided by backend because checking master data is not necessary
      // "MatchingSenderData", // not provided by backend because checking master data is not necessary
      'UniqueInvoiceID',
      // checking if items have all data
      // "ItemIdentificationExists", // Check is unnecessary since we don’t parse invoices with missing data
      // "ItemCountExists", // Check is unnecessary since we don’t parse invoices with missing data
      // "ItemUnitTypeExists", // Check is unnecessary since we don’t parse invoices with missing data
      // "ItemTaxRateExists", // Check is unnecessary since we don’t parse invoices with missing data
      'MaterialNetPriceCalculation',
      'MaterialTaxRateCheck',
    ],
    ERROR_NAME: 'Formale Prüfung',
    KEY: 'formal-check',
    SUCCESS_NAME: 'Formale Prüfung',
  },
  PRICE_CHECK: {
    CHECKS: [],
    ERROR_NAME: 'Preisprüfung',
    KEY: 'price-check',
    SUCCESS_NAME: 'Preisprüfung',
  },
  SIGNATURE_CHECK: {
    CHECKS: ['DeliveryNoteAuthorized'],
    ERROR_NAME: 'Signaturen',
    KEY: 'signature-check',
    SUCCESS_NAME: 'Signaturen',
  },
} as const;

// order matters because in this order, the delivery notes from the articles are assigned to the categories in case of multiple matches
export const INVOICE_CHECK_CATEGORY_CHART_CATEGORIES = {
  ALREADY_SETTLED: {
    CHECKS: ['DeliveryNoteNotAlreadySettled'],
    NAME: 'Bereits abgerechnet',
  },
  APPROVED: {
    CHECKS: [],
    NAME: 'Bestätigt',
  },
  NOT_APPROVED_DECLINED: {
    CHECKS: ['AcceptedPartialDeliveryQuantityMatches'],
    NAME: 'Unbestätigter oder abgelehnter Artikel',
  },
  NOT_APPROVED_NOT_EXISTING: {
    CHECKS: ['DeliveryNoteExists'],
    NAME: 'Unbestätigt, liegt nicht vor',
  },
  NOT_APPROVED_NOT_SIGNED: {
    CHECKS: ['DeliveryNoteAuthorized', 'DeliveryNoteChangesAuthorized'],
    NAME: 'Unbestätigt, nicht signiert',
  },
  NOT_APPROVED_WRONG_ARTICLE: {
    CHECKS: ['DeliveryNoteMaterialMatches', 'DeliveryNoteQuantityMatches'],
    NAME: 'Unbestätigt, fehlerhafter Artikel',
  },
} as const;

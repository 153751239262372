export const DeliveryIcon = ({ className, style }: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 20.25L3.375 18H11.25L10.35 15.75H3L1.875 13.5H13.575L12.675 11.25H1.665L0.375 9H6C6 8.20435 6.31607 7.44129 6.87868 6.87868C7.44129 6.31607 8.20435 6 9 6H27V12H31.5L36 18V25.5H33C33 26.6935 32.5259 27.8381 31.682 28.682C30.8381 29.5259 29.6935 30 28.5 30C27.3065 30 26.1619 29.5259 25.318 28.682C24.4741 27.8381 24 26.6935 24 25.5H18C18 26.6935 17.5259 27.8381 16.682 28.682C15.8381 29.5259 14.6935 30 13.5 30C12.3065 30 11.1619 29.5259 10.318 28.682C9.47411 27.8381 9 26.6935 9 25.5H6V20.25H4.5ZM28.5 27.75C29.0967 27.75 29.669 27.5129 30.091 27.091C30.5129 26.669 30.75 26.0967 30.75 25.5C30.75 24.9033 30.5129 24.331 30.091 23.909C29.669 23.4871 29.0967 23.25 28.5 23.25C27.9033 23.25 27.331 23.4871 26.909 23.909C26.4871 24.331 26.25 24.9033 26.25 25.5C26.25 26.0967 26.4871 26.669 26.909 27.091C27.331 27.5129 27.9033 27.75 28.5 27.75ZM30.75 14.25H27V18H33.69L30.75 14.25ZM13.5 27.75C14.0967 27.75 14.669 27.5129 15.091 27.091C15.5129 26.669 15.75 26.0967 15.75 25.5C15.75 24.9033 15.5129 24.331 15.091 23.909C14.669 23.4871 14.0967 23.25 13.5 23.25C12.9033 23.25 12.331 23.4871 11.909 23.909C11.4871 24.331 11.25 24.9033 11.25 25.5C11.25 26.0967 11.4871 26.669 11.909 27.091C12.331 27.5129 12.9033 27.75 13.5 27.75Z"
      fill="currentColor"
    />
  </svg>
);

import {
  InvoiceCheckCategoryObject,
  InvoiceCheckResultObject,
} from '~/models/invoices';

import { InvoiceCheckSummaryText } from '~/modules/invoice/detail/InvoiceCheck/invoiceCheckSummary/InvoiceCheckSummaryText';

import ComponentUtils from '~/utils/componentUtils';
import { getAbbreviatedUnit } from '~/utils/unit';
import UnitUtils from '~/utils/unitUtils';

class MapperService {
  addSearchString(row, properties) {
    row.searchString = properties
      .map((property) =>
        row[property] === null ||
        row[property] === undefined ||
        row[property] === ''
          ? ''
          : row[property],
      )
      .join(';')
      .toLowerCase();

    return row;
  }

  addSearchStringWithValues(row, values) {
    row.searchString = values.join(';').toLowerCase();

    return row;
  }
}

const mapperServiceInstance = new MapperService();

export default mapperServiceInstance;

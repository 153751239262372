import Papa from 'papaparse';

/**
 * Utility function to read a file as text asynchronously.
 * @param {File} file - The file to be read.
 * @returns {Promise<string>} - A promise that resolves with the file content as text.
 */
export const readFileAsTextAsync = async (file: File) => {
  if (!file) {
    throw new Error('No file selected. Please choose a file.');
  }

  if (!file.type.startsWith('text')) {
    throw new Error('Unsupported file type. Please select a text file.');
  }

  try {
    const fileText = await file.text();

    return fileText?.trim();
  } catch (error) {
    throw new Error(
      `Failed to read file: ${error instanceof Error ? error.message : String(error)}`,
    );
  }
};

export const readCsvFileAsync = async (
  file: File,
  options: Papa.ParseConfig,
) => {
  if (!file) {
    throw new Error('No file selected. Please choose a file.');
  }

  const text = await readFileAsTextAsync(file);

  const parsed = Papa.parse(text, {
    header: true,
    skipEmptyLines: true,
    ...options,
  });

  return parsed;
};

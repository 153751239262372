import { mergeWith } from 'lodash-es';

class ObjectUtils {
  /**
   * Map the array from Object.entries to a key/value pair so that it is more readable when coding
   *
   * @deprecated Just use Object.entries instead!
   */
  entries(object) {
    return Object.entries(object).map(([key, value]) => ({
      key,
      value,
    }));
  }

  /**
   * @deprecated replace with getNestedProperty from utils/object.ts
   */
  accessObjectRecursively(object, pathArray) {
    return pathArray.reduce(
      (accumulator, key) =>
        accumulator && accumulator[key] !== 'undefined'
          ? accumulator[key]
          : undefined,
      object,
    );
  }
}

const objectUtilsInstance = new ObjectUtils();

export default objectUtilsInstance;

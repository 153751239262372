import { CircularProgress, IconButton as MuiIconButton } from '@mui/material';
import { type PropsWithChildren } from 'react';

import { Tooltip } from '~/ui/atoms/Tooltip';

type P = PropsWithChildren<{
  readonly isLoading?: boolean;
  readonly onClick: () => void;
  readonly tooltipTitle?: string;
}>;

export const IconButton = ({
  children,
  isLoading,
  onClick,
  tooltipTitle,
}: P) => {
  const iconButton = (
    <div className="size-10 rounded-full bg-white shadow-lg">
      <MuiIconButton disabled={isLoading} onClick={onClick}>
        {isLoading ? <CircularProgress size={24} /> : children}
      </MuiIconButton>
    </div>
  );

  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle} width="xl">
        {iconButton}
      </Tooltip>
    );
  }

  return iconButton;
};

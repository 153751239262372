import { type UUID } from '~/types/common';

import { type CountAffectedDlnsOfSiteIdentifierData } from './useQueryCountAffectedDlnsOfSiteIdentifier';
import { type SitesQueryParams } from './useQuerySites';

/**
 * Query key factory for site-related queries.
 */
export const queryKeysSite = {
  base: () => 'sites' as const,
  get: (siteId: UUID) => [queryKeysSite.base(), 'detail', { siteId }] as const,
  getAll: (queryParams: Partial<SitesQueryParams>) =>
    [queryKeysSite.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<SitesQueryParams>) =>
    [queryKeysSite.base(), 'list', 'infinite', queryParams] as const,
  getAllLegacy: () => [queryKeysSite.base(), 'list', 'LEGACY'] as const,
  getBulk: (ids: UUID[]) => [queryKeysSite.base(), 'bulk', { ids }] as const,
  getCountAffectedDlnsOfSiteIdentifier: (
    supplierId: UUID,
    queryParams: CountAffectedDlnsOfSiteIdentifierData,
  ) => [queryKeysSite.base(), 'affectedDlns', supplierId, queryParams] as const,
  getSiteIdentifierProposals: () =>
    [queryKeysSite.base(), 'siteIdentifierProposals'] as const,
  updateSettings: (siteId: UUID) =>
    [queryKeysSite.base(), 'settings', siteId] as const,
} as const;

import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { memo, useEffect, useRef } from 'react';

import { sortByKey } from '~/utils/array';
import { type FilterContext, getFilterContext } from '~/utils/filters';
import { toCamelCase } from '~/utils/string';

import { Select } from '~/ui/molecules/Select';

import { FilterInput } from './FilterInput';

export type Filter = {
  allOptions: string[];
  disabled: boolean;
  filteredOptions: string[];
  id: string;
  name: string;
  type?: 'bool' | 'enumeration';
};

type P = {
  readonly filterContext: FilterContext;
  readonly hideDeleteButton: boolean;
  readonly isDisabled: boolean;
  readonly onChangeProperty: (event: unknown) => void;
  readonly onChangeValue: (event: unknown, value: string[] | boolean) => void;
  readonly onDeleteRow: () => void;
  readonly selectableFilters: Filter[];
  readonly selectedFilter: Filter;
  readonly selectedValues: string[] | boolean;
  readonly testId?: string;
};

export const FilterRow = memo(
  ({
    filterContext = getFilterContext(),
    hideDeleteButton,
    isDisabled,
    onChangeProperty,
    onChangeValue,
    onDeleteRow,
    selectableFilters,
    selectedFilter,
    selectedValues,
    testId,
  }: P) => {
    const filterOptions = sortByKey(
      selectableFilters.map(({ disabled, id, name }) => ({
        data: {
          disabled,
        },
        label: name,
        value: id,
      })) ?? [],
      'label',
    );

    const filterName = selectedFilter?.id?.replace(/^selected/, '');
    const normalizedFilterName = toCamelCase(filterName) ?? '';

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }, [selectedFilter.id]);

    return (
      <div className="flex gap-2" data-testid={testId}>
        <div className="min-w-80">
          <Select
            isClearable={false}
            options={filterOptions}
            placeholder="Filter auswählen"
            testId={`${testId}_operator_select`}
            value={selectedFilter.id}
            onChange={onChangeProperty}
          />
        </div>
        <FilterInput
          filterContext={filterContext}
          filterName={normalizedFilterName}
          isDisabled={isDisabled}
          selectableFilters={selectableFilters}
          selectedValues={selectedValues}
          testId={testId}
          onChangeValue={onChangeValue}
        />
        {hideDeleteButton ? null : (
          <IconButton
            data-testid={`${testId}_delete_button`}
            size="small"
            onClick={onDeleteRow}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    );
  },
);

FilterRow.displayName = 'FilterRow';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { hasDifference } from '~/models/utils';

import { AddressObject } from '../Address';
import { CoordinatesObject } from '../Coordinates';
import {
  PermissionGrantObject,
  type PermissionGrantTargetType,
} from '../PermissionGrant';
import { SiteIdentifierObject } from '../SiteIdentifier';
import { TradeContactObject } from '../TradeContact';

import {
  DIFFERENCE_VALUES,
  SITE_CSV_ALLOWED_COLUMNS,
  SITE_CSV_WITH_COST_CENTERS_ALLOWED_COLUMNS,
  SITE_TYPE,
} from './constants';
import { type Site } from './types';

/**
 * SiteObject
 * Utility object for creating and managing `Site` objects,
 * handling site types, and identifying differences between sites.
 */
export const SiteObject = {
  TYPE: SITE_TYPE,
  CSV_ALLOWED_COLUMNS: SITE_CSV_ALLOWED_COLUMNS,
  CSV_WITH_COST_CENTERS_ALLOWED_COLUMNS:
    SITE_CSV_WITH_COST_CENTERS_ALLOWED_COLUMNS,

  /**
   * Creates a new `Site` object with default values.
   * @param {Partial<Site>} site - Partial `Site` object to initialize.
   * @param {boolean} additionalDataInitiated - Indicates whether additional data is initialized.
   * @returns {Site} - Fully initialized `Site` object.
   */
  create(site: Partial<Site> = {}, additionalDataInitiated?: boolean) {
    return {
      id: site?.id ?? undefined,
      issuerAssignedId:
        site?.issuerAssignedId ?? site?.issuerAssignedId ?? undefined,
      name: site?.name ?? '',
      type: site?.type ?? SiteObject.TYPE.CONSTRUCTION_SITE?.KEY,
      active: site?.isActive ?? true,
      isActive: site?.isActive ?? true,
      address: AddressObject.create(site?.address),
      coords: CoordinatesObject.create(site?.coords),
      costCenters: site?.accountingReferenceList ?? [],
      company: site?.companyId ?? '',
      companyId: site?.companyId ?? '',
      tradeContact: TradeContactObject.create(site?.tradeContact),
      references: site?.identifierList
        ? site.identifierList.map((item) => SiteIdentifierObject.create(item))
        : [],
      start: site?.start ?? undefined,
      end: site?.end ?? undefined,
      createdOn: site?.createdOn ?? '',
      modifiedOn: site?.modifiedOn ?? '',
      organisationalGroups: site?.orgUnits ?? [],
      parentOrganizationalUnits: site?.parentOrganizationalUnits ?? [],
      organisationalGroupPaths: site?.orgUnitPaths ?? [],
      parentOrganizationalUnitPaths: site?.parentOrganizationalUnitPaths ?? [],
      permissionGrantsFrom:
        site?.permissionsFrom?.map((permissionGrant) =>
          PermissionGrantObject.create({
            ...permissionGrant,
            targetId: site.id,
            targetType: PermissionGrantObject.ENTITY_TYPE
              .SITE as PermissionGrantTargetType,
          }),
        ) ?? [],
      additionalDataInitiated,
    };
  },

  /**
   * Retrieves the human-readable string representation of a site type by its key.
   * @param {string} typeKey - Key of the site type.
   * @throws {EnumValueNotFoundException} - Throws if the typeKey is invalid.
   * @returns {string} - Human-readable string of the site type.
   */
  getSiteType(typeKey: string) {
    const siteType = Object.keys(SiteObject.TYPE).find(
      (x) => SiteObject.TYPE[x]?.KEY === typeKey,
    );

    if (!siteType) {
      throw new EnumValueNotFoundException('invalid site type: ' + typeKey);
    }

    return SiteObject.TYPE[siteType]?.STRING;
  },

  /**
   * Retrieves all available site types as an array of objects.
   * Each object contains the type's key (`id`) and human-readable string (`name`).
   * @returns {Array<{id: string, name: string}>} - Array of site types.
   */
  getSiteTypes() {
    return Object.keys(SiteObject.TYPE).map((x) => {
      return {
        id: SiteObject.TYPE[x]?.KEY,
        name: SiteObject.TYPE[x]?.STRING,
      };
    });
  },

  /**
   * Compares two `Site` objects and returns the fields with differing values.
   * @param {Site} siteA - The first `Site` object to compare.
   * @param {Site} siteB - The second `Site` object to compare.
   * @returns {string[]} - Array of differing field names (from `DIFFERENCE_VALUES`).
   */
  getDifferences(siteA: Site, siteB: Site) {
    const differentValues = [];

    if (siteA?.id !== siteB?.id) {
      differentValues.push(DIFFERENCE_VALUES.ID);
    }

    if (siteA?.name !== siteB?.name) {
      differentValues.push(DIFFERENCE_VALUES.NAME);
    }

    if (siteA?.type !== siteB?.type) {
      differentValues.push(DIFFERENCE_VALUES.TYPE);
    }

    if (siteA?.active !== siteB?.active) {
      differentValues.push(DIFFERENCE_VALUES.ACTIVE);
    }

    if (siteA?.company !== siteB?.company) {
      differentValues.push(DIFFERENCE_VALUES.COMPANY);
    }

    if (
      AddressObject.getDifferences(siteA?.address, siteB?.address).length > 0
    ) {
      differentValues.push(DIFFERENCE_VALUES.ADDRESS);
    }

    if (
      CoordinatesObject.getDifferences(siteA?.coords, siteB?.coords).length > 0
    ) {
      differentValues.push(DIFFERENCE_VALUES.COORDINATES);
    }

    const isDifferentCostCenters = hasDifference(
      siteA?.costCenters,
      siteB?.costCenters,
    );
    if (isDifferentCostCenters) {
      differentValues.push(DIFFERENCE_VALUES.COST_CENTERS);
    }

    const isDifferentOrganisationalGroups = hasDifference(
      siteA?.organisationalGroups,
      siteB?.organisationalGroups,
    );
    if (isDifferentOrganisationalGroups) {
      differentValues.push(DIFFERENCE_VALUES.ORG_GROUPS);
    }

    return differentValues;
  },
};

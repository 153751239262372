import { type PropsWithChildren, useState } from 'react';

import FeatureService from '~/services/feature.service';

import { Tooltip } from '~/ui/atoms/Tooltip';

import { ModuleInvoiceRestrictionMessage } from './ModuleInvoiceRestrictionMessage';

type P = PropsWithChildren & ComponentStyling;

export const ModuleInvoiceRestrictionTooltip = ({
  children,
  className,
  style,
}: P) => {
  const [isShown, setIsShown] = useState(false);

  if (!children) {
    return null;
  }

  if (!FeatureService.moduleInvoiceRestriction()) {
    return children;
  }

  return (
    <Tooltip
      enterDelay={1500}
      enterNextDelay={1500}
      open={isShown}
      title={<ModuleInvoiceRestrictionMessage />}
      onClose={() => {
        setIsShown(false);
      }}
      onOpen={() => {
        setIsShown(true);
      }}
    >
      <span
        className={className}
        style={style}
        onClick={() => {
          setIsShown(true);
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
};

/**
 * Filter operators supported both in the frontend and the backend with their respective names.
 */
const FILTER_OPERATOR_COMMON = {
  CONTAINS: 'contains',
} as const;

/**
 * Filter operators supported in the frontend (by the datagrid) with their respective names.
 */
export const FILTER_OPERATOR_DATAGRID = {
  ...FILTER_OPERATOR_COMMON,
  AFTER: 'after',
  BEFORE: 'before',
  ENDS_WITH: 'endsWith',
  EQUALS: 'equals',
  IS: 'is',
  IS_ANY_OF: 'isAnyOf',
  IS_EMPTY: 'isEmpty',
  IS_NOT_EMPTY: 'isNotEmpty',
  MATH_EQUALS: '=',
  MATH_GREATER_THAN: '>',
  MATH_GREATER_THAN_OR_EQUALS: '>=',
  MATH_LESS_THAN: '<',
  MATH_LESS_THAN_OR_EQUALS: '<=',
  MATH_NOT_EQUALS: '!=',
  NOT: 'not',
  NOT_EQUALS: 'notEquals',
  ON_OR_AFTER: 'onOrAfter',
  ON_OR_BEFORE: 'onOrBefore',
  STARTS_WITH: 'startsWith',
} as const;

/**
 * Filter operators supported in the backend with their respective names.
 */
export const FILTER_OPERATOR_BACKEND = {
  ...FILTER_OPERATOR_COMMON,
  ENDS_WITH: 'endswith',
  EQUALS: 'eq',
  GREATER_THAN: 'gt',
  GREATER_THAN_OR_EQUALS: 'ge',
  IS_ANY_OF: 'in',
  LESS_THAN: 'lt',
  LESS_THAN_OR_EQUALS: 'le',
  NOT_CONTAINS: 'not_contains',
  NOT_ENDS_WITH: 'not_endswith',
  NOT_EQUALS: 'ne',
  NOT_STARTS_WITH: 'not_startswith',
  STARTS_WITH: 'startswith',
} as const;

export const BOOLEAN_OPERATOR = {
  AND: 'and',
  OR: 'or',
} as const;

export const FILTER_DATA_TYPE_BACKEND = {
  BOOLEAN: 'boolean',
  DATE: 'date',
  NUMBER: 'number',
  STRING: 'string',
  STRING_ARRAY: 'stringArray',
  UUID: 'uuid',
} as const;

export const FILTER_DATA_TYPE_DATAGRID = {
  ACTIONS: 'actions',
  BOOLEAN: 'boolean',
  DATE: 'date',
  DATE_TIME: 'dateTime',
  NUMBER: 'number',
  SINGLE_SELECT: 'singleSelect',
  STRING: 'string',
} as const;

export const FILTER_FIELD_NAME_BACKEND = {
  ARTICLE_NAME: 'article_name',
  ARTICLE_NUMBER: 'article_number',
  AUTHORIZED_ACCOUNTING_REFERENCE_ID: 'authorized_accounting_reference_id',
  AUTHORIZED_SITE_ID: 'authorized_site_id',
  CATEGORY: 'category',
  CONFIRMED_ACCOUNTING_REFERENCE_ID: 'confirmed_accounting_reference_id',
  CONFIRMED_SITE_ID: 'confirmed_site_id',
  CONFIRMED_SITE_NAME: 'confirmed_site_name',
  COST_CENTER: 'confirmed_accounting_reference_name',
  CUSTOMER_NUMBER: 'customer_number',
  DLN_ACCEPT_STATE: 'dln_accept_state',
  DLN_NR: 'dln_nr',
  DLN_SETTLED_STATUS: 'billed_state',
  LICENSE_PLATE_NUMBER: 'license_plate_number',
  LOADING_LOCATION: 'loading_location',
  OU_ID: 'ou_id',
  PROCESS_STATE: 'process_state',
  QUERY: 'search',
  RECIPIENT_NAME: 'recipient_name',
  SUPPLIER_ASSIGNED_SITE_NAME: 'supplier_assigned_site_name',
  SUPPLIER_NAME: 'supplier_name',
} as const;

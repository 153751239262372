import { validate as uuidvalidate } from 'uuid';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { Log } from '~/utils/logging';

import {
  CUSTOM_FIELD_LEVEL,
  CUSTOM_FIELD_TYPE,
  CUSTOM_FIELD_VISIBILITY,
  DIFFERENCE_VALUES,
} from './constants';
import { CustomFieldOptionObject } from './customFieldOptionUtils';
import { type CustomField } from './types';

export const CustomFieldObject = {
  VISIBILITY: CUSTOM_FIELD_VISIBILITY,

  LEVEL: CUSTOM_FIELD_LEVEL,

  TYPE: CUSTOM_FIELD_TYPE,

  /**
   * Creates a new custom field object with provided options.
   * @param customField - A partial representation of a custom field, containing key-value pairs like `id`, `name`, etc.
   * @param customFieldOptions - An array of options for the custom field, each formatted as a key-value object.
   * @returns A fully-formed custom field object with all default values and transformed options.
   */
  create(
    customField: Partial<CustomField> = {},
    customFieldOptions: any[] = [],
  ) {
    return {
      id: customField.id ?? null,
      name: customField.name ?? null,
      key: customField.id?.slice(0, 36) ?? null, // Old customField keys might consist of UUID + label; only get the UUID
      displayName: customField.displayName ?? null,
      level: customField.level ?? CustomFieldObject.LEVEL.DELIVERY_NOTE.KEY,
      helpText: customField.helpText ?? null,
      public: customField.public ?? false,
      visibility:
        customField.visibility ?? CustomFieldObject.VISIBILITY.ALWAYS.KEY,
      hasOptions: customField.hasOptions ?? false,
      type: customField.type ?? CustomFieldObject.TYPE.STRING.KEY,
      options: customFieldOptions.map((option) =>
        CustomFieldOptionObject.create(option),
      ),
      hardcoded: customField.hardcoded ?? false,
      allowedUnits: customField.allowedUnits ?? [],
      defaultUnit: customField.defaultUnit ?? null,
      usedBy: customField.usedBy ?? [],
    };
  },

  /**
   * Extracts the ID from a custom field key.
   * @param key - A string containing the unique key of a custom field, typically including an ID as a prefix.
   * @returns The UUID extracted from the key if valid, otherwise `null`.
   */
  getIdFromKey(key: string) {
    if (typeof key !== 'string') {
      return null;
    }

    const id = key.slice(0, 36);
    return uuidvalidate(id) ? id : null;
  },

  /**
   * Checks if a custom field value is empty.
   * @param value - The value to check, which could be of any type.
   * @returns `true` if the value is empty (`null` or an empty string), otherwise `false`.
   */
  valueIsEmpty(value: any) {
    return value === '' || value === null;
  },

  /**
   * Retrieves all available custom field types.
   * @returns An array of objects, each containing `id`, `name`, and `disabled` status for a custom field type.
   */
  getCustomFieldTypes() {
    return Object.keys(this.TYPE).map((key) => ({
      disabled: this.TYPE[key].DISABLED,
      id: this.TYPE[key].KEY,
      name: this.TYPE[key].STRING,
    }));
  },

  /**
   * Retrieves all available custom field levels.
   * @returns An array of objects, each containing `id` and `name` for a custom field level.
   */
  getCustomFieldLevels() {
    return Object.keys(this.LEVEL).map((key) => ({
      id: this.LEVEL[key].KEY,
      name: this.LEVEL[key].STRING,
    }));
  },

  /**
   * Retrieves all available visibility options.
   * @returns An array of objects, each containing `id` and `name` for a visibility option.
   */
  getCustomFieldVisibilities() {
    return Object.keys(this.VISIBILITY).map((key) => ({
      id: this.VISIBILITY[key].KEY,
      name: this.VISIBILITY[key].STRING,
    }));
  },

  /**
   * Gets the string representation of a custom field type by its key.
   * @param key - The unique key of a custom field type.
   * @returns The string representation of the type if the key is valid, otherwise `null`.
   */
  getTypeString(key: string) {
    const type = Object.keys(this.TYPE).find((x) => this.TYPE[x].KEY === key);
    if (!type) {
      Log.error(
        null,
        new EnumValueNotFoundException(`Invalid custom field type: ${key}`),
      );
      return null;
    }

    return this.TYPE[type].STRING;
  },

  /**
   * Gets the formatter function for a specific custom field type.
   * @param key - The unique key of a custom field type.
   * @returns A function that formats values for the specified type, or `null` if the key is invalid.
   */
  getFormatter(key: string) {
    const type = Object.keys(this.TYPE).find((x) => this.TYPE[x].KEY === key);
    if (!type) {
      Log.error(
        null,
        new EnumValueNotFoundException(`Invalid custom field type: ${key}`),
      );
      return null;
    }

    return this.TYPE[type].FORMATTER;
  },

  /**
   * Gets the string representation of a custom field level by its key.
   * @param key - The unique key of a custom field level.
   * @returns The string representation of the level if the key is valid, otherwise `null`.
   */
  getLevelString(key: string) {
    const level = Object.keys(this.LEVEL).find(
      (x) => this.LEVEL[x].KEY === key,
    );
    if (!level) {
      Log.error(
        null,
        new EnumValueNotFoundException(`Invalid custom field level: ${key}`),
      );
      return null;
    }

    return this.LEVEL[level].STRING;
  },

  /**
   * Gets the string representation of a custom field visibility by its key.
   * @param key - The unique key of a custom field visibility.
   * @returns The string representation of the visibility if the key is valid, otherwise `null`.
   */
  getVisibilityString(key: string) {
    const visibility = Object.keys(this.VISIBILITY).find(
      (x) => this.VISIBILITY[x].KEY === key,
    );
    if (!visibility) {
      Log.error(
        null,
        new EnumValueNotFoundException(
          `Invalid custom field visibility: ${key}`,
        ),
      );
      return null;
    }

    return this.VISIBILITY[visibility].STRING;
  },

  /**
   * Compares two custom fields and returns their differences.
   * @param customFieldA - The first custom field object to compare.
   * @param customFieldB - The second custom field object to compare.
   * @returns An array of strings representing the keys of differing properties between the two fields.
   */
  getDifferences(
    customFieldA: Record<string, any>,
    customFieldB: Record<string, any>,
  ) {
    const differentValues: string[] = [];
    if (customFieldA.id !== customFieldB.id) {
      differentValues.push(DIFFERENCE_VALUES.ID);
    }

    if (customFieldA.name !== customFieldB.name) {
      differentValues.push(DIFFERENCE_VALUES.NAME);
    }

    if (customFieldA.key !== customFieldB.key) {
      differentValues.push(DIFFERENCE_VALUES.KEY);
    }

    if (customFieldA.displayName !== customFieldB.displayName) {
      differentValues.push(DIFFERENCE_VALUES.DISPLAY_NAME);
    }

    if (customFieldA.type !== customFieldB.type) {
      differentValues.push(DIFFERENCE_VALUES.TYPE);
    }

    if (customFieldA.hasOptions !== customFieldB.hasOptions) {
      differentValues.push(DIFFERENCE_VALUES.HAS_OPTIONS);
    }

    if (customFieldA.level !== customFieldB.level) {
      differentValues.push(DIFFERENCE_VALUES.LEVEL);
    }

    if (customFieldA.public !== customFieldB.public) {
      differentValues.push(DIFFERENCE_VALUES.PUBLIC);
    }

    if (customFieldA.visibility !== customFieldB.visibility) {
      differentValues.push(DIFFERENCE_VALUES.VISIBILITY);
    }

    if (
      JSON.stringify(customFieldA.options) !==
        JSON.stringify(customFieldB.options) &&
      customFieldA.options &&
      customFieldB.options
    ) {
      differentValues.push(DIFFERENCE_VALUES.OPTIONS);
    }

    if (
      JSON.stringify(customFieldA.allowedUnits) !==
      JSON.stringify(customFieldB.allowedUnits)
    ) {
      differentValues.push(DIFFERENCE_VALUES.ALLOWED_UNITS);
    }

    if (
      JSON.stringify(customFieldA.defaultUnit) !==
      JSON.stringify(customFieldB.defaultUnit)
    ) {
      differentValues.push(DIFFERENCE_VALUES.DEFAULT_UNIT);
    }

    if (
      JSON.stringify(customFieldA.usedBy) !==
      JSON.stringify(customFieldB.usedBy)
    ) {
      differentValues.push(DIFFERENCE_VALUES.USED_BY);
    }

    return differentValues;
  },
};

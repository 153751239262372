import { camelcaseKeysFromApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';

class LocalStorageService {
  constructor() {
    // for table configuration
    this.ARTICLE_TABLE = 'article_table';
    this.BAYWA_SCREEN = 'baywa_screen';
    this.CATEGORY_TABLE = 'category_table';
    this.COMPANY_TABLE = 'company_table';
    this.CONTACT_POINTS_TABLE = 'contact_points_table';
    this.COST_CENTER_TABLE = 'cost_center_table';
    this.CUSTOM_FIELD_TABLE = 'custom_field_table';
    this.CUSTOMER_SUBSCRIPTION_TABLE = 'customer_subscription_table';
    this.DASHBOARD = 'dashboard';
    this.DASHBOARD_REPORT = 'dashboard_report';
    this.DATA_EXCHANGES = 'data_exchanges';
    this.DATA_SUBSCRIPTIONS_TABLE = 'data_subscriptions_table';
    this.DELAYED_SIGNATURES_INVOICES_TILE = 'delayed_signatures_invoices_tile';
    this.DELIVERY_CHANGES = 'delivery_changes';
    this.DELIVERY_LIST = 'delivery_list';
    this.DELIVERY_LIST_PINNED_COLUMNS = 'delivery_list_pinned_columns';
    this.INVOICE_CHECK_IGNORED_ARTICLE_TABLE =
      'invoice_check_ignored_article_table';
    this.INVOICE_OVERVIEW = 'invoice_overview';
    this.INVOICE_REFERENCED_DELIVERY_NOTES =
      'invoice_referenced_delivery_notes';
    this.MISSING_SIGNATURES_INVOICES_TILE = 'missing_signatures_invoices_tile';
    this.NOTIFICATION_POLICIES_TABLE = 'notification_policies_table';
    this.ORGANISATIONAL_GROUP_TABLE = 'organisational_group_table';
    this.PDF_TEMPLATE_TABLE = 'pdf_template_table';
    this.SIGNATURE_FIELD_TABLE = 'signature_field_table';
    this.SITE_TABLE = 'site_table';
    this.USER_GROUP_TABLE = 'user_group_table';
    this.USERS_TABLE = 'users_table';
    this.VEHICLE_TABLE = 'vehicle_table';

    // for filters
    this.FILTER_DASHBOARD_ACCEPT_STATE = 'filter_dashboard_acceptState';
    this.FILTER_DASHBOARD_ARTICLE = 'filter_dashboard_article';
    this.FILTER_DASHBOARD_ARTICLE_NUMBER = 'filter_dashboard_articleNumber';
    this.FILTER_DASHBOARD_CATEGORY = 'filter_dashboard_category';
    this.FILTER_DASHBOARD_COST_CENTER = 'filter_dashboard_costCenter';
    this.FILTER_DASHBOARD_CUSTOM_FIELDS = 'filter_dashboard_customFields';
    this.FILTER_DASHBOARD_CUSTOMER_NUMBER = 'filter_dashboard_customerNumber';
    this.FILTER_DASHBOARD_DATE_RANGE = 'filter_dashboard_dateRange';
    this.FILTER_DASHBOARD_FILTER_GROUPS = 'filter_dashboard_filterGroups';
    this.FILTER_DASHBOARD_FILTER_ROWS = 'filter_dashboard_filterRows';
    this.FILTER_DASHBOARD_FROM_SITE = 'filter_dashboard_fromSite';
    this.FILTER_DASHBOARD_INDIVIDUAL_DATE_RANGE =
      'filter_dashboard_individualDateRange';
    this.FILTER_DASHBOARD_OU_ID = 'filter_dashboard_ouId';
    this.FILTER_DASHBOARD_PERMITTED_COST_CENTERS =
      'filter_dashboard_permittedCostCenters';
    this.FILTER_DASHBOARD_PERMITTED_TO_SITES =
      'filter_dashboard_permittedToSites';
    this.FILTER_DASHBOARD_PREDEFINED_DATE_RANGE =
      'filter_dashboard_predefinedDateRange';
    this.FILTER_DASHBOARD_PROCESS_STATE = 'filter_dashboard_processState';
    this.FILTER_DASHBOARD_RECIPIENT = 'filter_dashboard_recipient';
    this.FILTER_DASHBOARD_SELECTABLE_COLUMNS =
      'filter_dashboard_selectableColumns';
    this.FILTER_DASHBOARD_SELECTED_COLUMNS = 'filter_dashboard_selectedColumns';
    this.FILTER_DASHBOARD_SELECTED_FILTER_GROUP =
      'filter_dashboard_selectedFilterGroup';
    this.FILTER_DASHBOARD_SETTLED_STATUS = 'filter_dashboard_settledStatus';
    this.FILTER_DASHBOARD_SITE_RECIPIENT = 'filter_dashboard_siteRecipient';
    this.FILTER_DASHBOARD_SITE_SUPPLIER = 'filter_dashboard_siteSupplier';
    this.FILTER_DASHBOARD_SITE_SUPPLIER_TRADE_CONTACT =
      'filter_dashboard_siteSupplierTradeContact';
    this.FILTER_DASHBOARD_SUPPLIER = 'filter_dashboard_supplier';
    this.FILTER_DASHBOARD_UNIT = 'filter_dashboard_unit';
    this.FILTER_DATA_EXCHANGES_COMPANY = 'filter_data_exchanges_company';
    this.FILTER_DATA_SELECTED_COST_CENTERS =
      'filter_data_selected_cost_centers';
    this.FILTER_DATA_SELECTED_SITES = 'filter_data_selected_sites';
    this.FILTER_DATA_SELECTED_SUPPLIERS_COMPANY =
      'filter_data_selected_suppliers_company';
    this.FILTER_DATA_SUPPLIERS_RESPONSIBLE_PEOPLE =
      'filter_data_suppliers_responsible_people';
    this.FILTER_DATA_SUPPLIERS_SORT_MODEL = 'filter_data_suppliers_sort_model';
    this.FILTER_DELIVERY_ACCEPT_STATE = 'filter_delivery_acceptState';
    this.FILTER_DELIVERY_ARTICLE = 'filter_delivery_article';
    this.FILTER_DELIVERY_ARTICLE_NUMBER = 'filter_delivery_articleNumber';
    this.FILTER_DELIVERY_CATEGORY = 'filter_delivery_category';
    this.FILTER_DELIVERY_COST_CENTER = 'filter_delivery_costCenter';
    this.FILTER_DELIVERY_CUSTOM_FIELDS = 'filter_delivery_customFields';
    this.FILTER_DELIVERY_CUSTOMER_NUMBER = 'filter_delivery_customerNumber';
    this.FILTER_DELIVERY_DATE_RANGE = 'filter_delivery_dateRange';
    this.FILTER_DELIVERY_FILTER_GROUP_OPEN = 'filter_delivery_filterGroupOpen';
    this.FILTER_DELIVERY_FILTER_GROUPS = 'filter_delivery_filterGroups';
    this.FILTER_DELIVERY_OU_ID = 'filter_delivery_ouId';
    this.FILTER_DELIVERY_FILTER_ROWS = 'filter_delivery_filterRows';
    this.FILTER_DELIVERY_FROM_SITE = 'filter_delivery_fromSite';
    this.FILTER_DELIVERY_INDIVIDUAL_DATE_RANGE =
      'filter_delivery_individualDateRange';
    this.FILTER_DELIVERY_PERMITTED_COST_CENTERS =
      'filter_delivery_permittedCostCenters';
    this.FILTER_DELIVERY_PERMITTED_TO_SITES =
      'filter_delivery_permittedToSites';
    this.FILTER_DELIVERY_PREDEFINED_DATE_RANGE =
      'filter_delivery_predefinedDateRange';
    this.FILTER_DELIVERY_PROCESS_STATE = 'filter_delivery_processState';
    this.FILTER_DELIVERY_RECIPIENT = 'filter_delivery_recipient';
    this.FILTER_DELIVERY_SELECTED_FILTER_GROUP =
      'filter_delivery_selectedFilterGroup';
    this.FILTER_DELIVERY_SETTLED_STATUS = 'filter_delivery_settledStatus';
    this.FILTER_DELIVERY_SITE_RECIPIENT = 'filter_delivery_siteRecipient';
    this.FILTER_DELIVERY_SITE_SUPPLIER = 'filter_delivery_siteSupplier';
    this.FILTER_DELIVERY_SUPPLIER = 'filter_delivery_supplier';
    this.FILTER_DELIVERY_TAB = 'filter_delivery_tab';
    this.FILTER_HOME_DATE_RANGE = 'filter_home_dateRange';
    this.FILTER_HOME_INDIVIDUAL_DATE_RANGE = 'filter_home_individualDateRange';
    this.FILTER_HOME_PREDEFINED_DATE_RANGE = 'filter_home_predefinedDateRange';
    this.FILTER_INVOICE_BUYER = 'filter_invoice_buyer';
    this.FILTER_INVOICE_DATE_RANGE = 'filter_invoice_dateRange';
    this.FILTER_INVOICE_FILTER_GROUP_OPEN = 'filter_invoice_filterGroupOpen';
    this.FILTER_INVOICE_FILTER_GROUPS = 'filter_invoice_filterGroups';
    this.FILTER_INVOICE_FILTER_ROWS = 'filter_invoice_filterRows';
    this.FILTER_INVOICE_INDIVIDUAL_DATE_RANGE =
      'filter_invoice_individualDateRange';
    this.FILTER_INVOICE_NUMBER = 'filter_invoice_number';
    this.FILTER_INVOICE_PREDEFINED_DATE_RANGE =
      'filter_invoice_predefinedDateRange';
    this.FILTER_INVOICE_SELECTED_FILTER_GROUP =
      'filter_invoice_selectedFilterGroup';
    this.FILTER_INVOICE_SELLER = 'filter_invoice_seller';
    this.FILTER_INVOICE_STATUS = 'filter_invoice_status';
    this.FILTER_INVOICE_TO_SITE = 'filter_invoice_toSite';
    this.SELECTED_COST_CENTERS = 'selected_cost_centers';
    this.SELECTED_SITES = 'selected_sites';

    // others
    this.DISPLAY_HOME_INCOMING_INVOICE_TILE =
      'display_home_incoming_invoice_tile';
    this.DISPLAY_HOME_OUTGOING_INVOICE_TILE =
      'display_home_outgoing_invoice_tile';
    this.HIDE_DRAWER = 'hide_drawer';
    this.INVOICE_REFERENCED_DELIVERY_NOTES_EXPANDED =
      'invoice_referenced_delivery_notes_expanded';
    this.SENTRY_GOOGLE_ANALYTICS_DISABLED = 'analytics_disabled';

    this.TRUE = 'true';
    this.FALSE = 'false';

    this.SEPARATOR = '|||';
  }

  setLocalStorage(name, value) {
    localStorage.setItem(name, value);
  }

  getLocalStorage(name) {
    const localStorageItem = localStorage.getItem(name);

    // Items are saved as strings in local storage. Thus, we need to convert 'null' and 'undefined'.
    if (localStorageItem === 'null') {
      return null;
    }

    if (localStorageItem === 'undefined') {
      return undefined;
    }

    return localStorageItem;
  }

  setMetaDataLocalStorage(name, value) {
    value = JSON.stringify({
      createdOn: new Date(),
      data: value,
    });

    this.setLocalStorage(name, value);
  }

  getNumberFromLocalStorage(name) {
    const cookie = this.getLocalStorage(name);

    if (!cookie) {
      return null;
    }

    return Number(cookie);
  }

  setObjectAsLocalStorage(name, value) {
    this.setLocalStorage(name, JSON.stringify(value));
  }

  getObjectFromLocalStorage(name) {
    const data = this.getLocalStorage(name);

    if (!data) {
      return null;
    }

    return camelcaseKeysFromApi(JSON.parse(data));
  }

  setArrayAsLocalStorage(name, value) {
    this.setLocalStorage(name, value.join(this.SEPARATOR));
  }

  getArrayFromLocalStorage(name) {
    const data = this.getLocalStorage(name);

    if (data === null) {
      return null;
    }

    if (data === '') {
      return [];
    }

    if (!data) {
      Log.error('Invalid value of local storage array data. data: ' + data);
      return null;
    }

    return data.split(this.SEPARATOR);
  }

  getBooleanFromLocalStorage(name, defaultValue) {
    const data = this.getLocalStorage(name);

    if (!data && defaultValue) {
      return true;
    }

    if (!data && !defaultValue) {
      return false;
    }

    return data === this.TRUE;
  }

  // Based on feature flag sentry_google_analytics_disabled.
  // The feature flag is stored in cookies so that the information is already available when page is initialized and not only when user data has been loaded from backend.
  sentryGoogleAnalyticsDisabled() {
    return (
      this.getLocalStorage(this.SENTRY_GOOGLE_ANALYTICS_DISABLED) === this.TRUE
    );
  }

  enableSentryGoogleAnalytics() {
    this.setLocalStorage(this.SENTRY_GOOGLE_ANALYTICS_DISABLED, this.FALSE);
  }

  disableSentryGoogleAnalytics() {
    this.setLocalStorage(this.SENTRY_GOOGLE_ANALYTICS_DISABLED, this.TRUE);
  }
}

const localStorageServiceInstance = new LocalStorageService();

export default localStorageServiceInstance;

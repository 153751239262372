import ReactDOMServer from 'react-dom/server';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { ROUTE } from '~/constants/Route';

import { closeBackdrop, openBackdrop } from '~/redux/backdropSlice';

import { joinComponents } from '~/utils/array';

import { Tooltip } from '~/ui/atoms/Tooltip';

import BrowserUtils from './browserUtils';

export const MissingPermissionsTooltip = ({ children }) => (
  <Tooltip
    title="Für diese Aktion fehlen dir die notwendigen Berechtigungen."
    width="xl"
    shouldWrapChildren
  >
    {children}
  </Tooltip>
);

export const FunctionalLink = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    event.preventDefault();

    if (location?.pathname === props.to) {
      return;
    }

    if (!props.backdropDuration) {
      navigate(props.to);
      return;
    }

    const id = uuidv4();

    dispatch(
      openBackdrop({
        id,
        message: props.backdropMessage ?? '',
      }),
    );

    setTimeout(() => {
      navigate(props.to);
      dispatch(closeBackdrop({ id }));
    }, props.backdropDuration ?? 0);
  };

  // setTimeout can't be used because browser would block window.open because of popup
  const onContextMenu = (event) => {
    event.preventDefault();
    BrowserUtils.openNewTab(props.to);
  };

  return (
    <Link
      className={props.className}
      style={{
        textDecoration: 'none',
      }}
      to={props.to}
      onClick={handleClick}
      onContextMenu={onContextMenu}
    >
      {props.message}
    </Link>
  );
};

export const DeliveryNoteLink = (props) => {
  if (!props.id) {
    return `Lieferung ${props.number ?? ''}`;
  }

  return (
    <FunctionalLink
      className={props.className ?? 'text-primary500 font-bold'}
      message={`Lieferung ${props.number ?? ''}`}
      to={`${ROUTE.DELIVERY_NOTE.ROUTE}/${props.id}`}
    />
  );
};

export const DeliveryNoteLinks = (props) => {
  const displayLink = props.deliveryNotes.every(({ id }) => id);

  if (!displayLink) {
    return `Lieferung ${props.deliveryNotes.map((deliveryNote) => deliveryNote.number).join(', ')}`;
  }

  const deliveryNoteLinks = props.deliveryNotes.map((deliveryNote, index) =>
    index === 0 ? (
      <FunctionalLink
        key={deliveryNote.id}
        className={props.className ?? 'text-primary500 font-bold'}
        message={`Lieferung ${deliveryNote.number ?? ''}`}
        to={`${ROUTE.DELIVERY_NOTE.ROUTE}/${deliveryNote.id}`}
      />
    ) : (
      <FunctionalLink
        key={deliveryNote.id}
        className={props.className ?? 'text-primary500 font-bold'}
        message={deliveryNote.number ?? ''}
        to={`${ROUTE.DELIVERY_NOTE.ROUTE}/${deliveryNote.id}`}
      />
    ),
  );

  return joinComponents(deliveryNoteLinks);
};

export const InvoiceLink = (props) => {
  if (!props.id) {
    return `Rechnung ${props.number ?? ''}`;
  }

  return (
    <FunctionalLink
      className={props.className ?? 'text-primary500 font-bold'}
      message={`Rechnung ${props.number ?? ''}`}
      to={`${ROUTE.INVOICE.ROUTE}/${props.id}`}
    />
  );
};

export const InvoiceLinks = (props) => {
  const displayLink = props.invoices.every(({ id }) => id);

  if (!displayLink) {
    return `Rechnung ${props.invoices.map(({ number }) => number).join(', ')}`;
  }

  const invoiceLinks = props.invoices.map((invoice, index) =>
    index === 0 ? (
      <FunctionalLink
        key={invoice.id}
        className={props.className ?? 'text-primary500 font-bold'}
        message={`Rechnung ${invoice.number ?? ''}`}
        to={`${ROUTE.INVOICE.ROUTE}/${invoice.id}`}
      />
    ) : (
      <FunctionalLink
        key={invoice.id}
        className={props.className ?? 'text-primary500 font-bold'}
        message={invoice.number ?? ''}
        to={`${ROUTE.INVOICE.ROUTE}/${invoice.id}`}
      />
    ),
  );

  return joinComponents(invoiceLinks);
};

class ComponentUtils {
  getStringFromComponent(component) {
    let string = ReactDOMServer.renderToString(component);

    string = string.replace('<!-- -->', '');

    return string;
  }

  // Finds out if flex box has wrapped by comparing if DOM elements with the same class name have a different top position.
  detectWrap(className) {
    const wrappedItems = [];
    let previousItem = {};
    let currentItem = {};

    const items = document.getElementsByClassName(className);

    for (const item of items) {
      currentItem = item.getBoundingClientRect();
      if (previousItem && previousItem.top < currentItem.top) {
        wrappedItems.push(item);
      }

      previousItem = currentItem;
    }

    return wrappedItems;
  }
}

const componentUtilsInstance = new ComponentUtils();

export default componentUtilsInstance;

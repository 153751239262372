export const DataExchangeSupplierIcon = ({
  className,
  style,
}: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.56877 17.1909C3.64064 17.4532 3.91564 17.6106 4.18127 17.5396L10.9438 15.7497C11.2094 15.6788 11.3688 15.4072 11.2969 15.1449L9.74376 9.42048C9.67189 9.15818 9.39689 9.0008 9.13126 9.07177L6.71876 9.71365L7.49376 12.5743L5.56251 13.0835L4.78751 10.2228L2.37189 10.8616C2.10627 10.9326 1.94689 11.2042 2.01877 11.4665L3.56877 17.1909ZM14 5.6001C13.4469 5.6001 13 6.04139 13 6.5876V16.5737L2.18439 19.4868C2.05002 19.5238 1.97189 19.6596 2.00939 19.7892L2.40314 21.218C2.44064 21.3507 2.57814 21.4279 2.70939 21.3908L15.0125 18.0765C15.0875 19.9219 16.6125 21.4001 18.5 21.4001C20.4344 21.4001 22 19.854 22 17.9438V5.6001H14ZM18.5 19.4251C17.6719 19.4251 17 18.7616 17 17.9438C17 17.1261 17.6719 16.4626 18.5 16.4626C19.3281 16.4626 20 17.1261 20 17.9438C20 18.7616 19.3281 19.4251 18.5 19.4251Z"
      fill="currentColor"
    />
    <path
      d="M2 5.83929L2 3.16071L7.0505 3.16071L7.0505 1L12 4.5L7.05051 8L7.05051 5.83929L2 5.83929Z"
      fill="currentColor"
    />
  </svg>
);

import { type SortableReportColumn } from '~/data/deliveryNote/useQueryDeliveryNoteReport';

import { dateUtils, parseDate } from '~/utils/dateUtils';

export const SELECTABLE_COLUMNS = [
  {
    field: 'processState',
    headerName: 'Status',
  },
  {
    field: 'dlnAcceptState',
    headerName: 'Angenommen',
    width: 120,
  },
  {
    field: 'billedState',
    headerName: 'Abgerechnet',
    width: 120,
  },
  {
    field: 'dlnDate',
    headerName: 'LFS-Datum',
    type: 'date',
    valueFormatter: (value: Date) =>
      dateUtils.getFormattedDate_safe(value, dateUtils.DATE_FORMAT.DD_MM_YYYY),
    valueGetter: (value: string) => parseDate(value),
  },
  {
    field: 'dlnNr',
    headerName: 'LFS-Nr.',
    width: 180,
  },
  {
    field: 'supplierName',
    headerName: 'Lieferant',
    width: 240,
  },
  {
    field: 'recipientName',
    headerName: 'Abnehmer',
    width: 240,
  },
  {
    field: 'confirmedSiteName',
    headerName: 'Bestätigter Lieferort',
    width: 240,
  },
  {
    field: 'supplierAssignedSiteName',
    headerName: 'Lieferort (Lieferantenbezeichnung)',
    width: 240,
  },
  {
    field: 'confirmedAccountingReferenceName',
    headerName: 'Kostenstelle',
    width: 180,
  },
  {
    field: 'articleNumber',
    headerName: 'Artikel-Nr.',
    width: 120,
  },
  {
    field: 'articleName',
    headerName: 'Artikel',
    width: 240,
  },
];

export const SORTABLE_COLUMNS: SortableReportColumn[] = [
  'amount',
  'articleName',
  'articleNumber',
  'billedState',
  'dlnAcceptState',
  'dlnDate',
  'dlnNr',
  'processState',
  'supplierName',
] as const;

/**
 * The names of the standard (i.e. non-custom) fields that can be selected in a report.
 * Can be used for validation.
 */
export const selectableReportFields = new Set(
  SELECTABLE_COLUMNS.map(({ field }) => field),
);

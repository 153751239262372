import { createSelector } from '@reduxjs/toolkit';

export const selectInvoices = (state) => state.invoices;

export const selectFilters = (state) => state.filters;

export const selectInvoiceFilterGroups = createSelector(
  [selectFilters],
  (filters) => ({
    filterGroupOpen: filters.invoice_filterGroupOpen,
    filterGroups: filters.invoice_filterGroups,
    filterModel: filters.invoice_filterModel,
    filterRows: filters.invoice_filterRows,
    sortModel: filters.invoice_sortModel,
  }),
);

export const selectInvoiceFilters = createSelector(
  [selectFilters],
  (filters) => ({
    dateRange: filters.invoice_dateRange,
    individualDateRange: filters.invoice_individualDateRange,
    predefinedDateRange: filters.invoice_predefinedDateRange,
    query: filters.invoice_query,
    selectedBuyer: filters.invoice_selectedBuyer,
    selectedFilterGroup: filters.invoice_selectedFilterGroup,
    selectedNumber: filters.invoice_selectedNumber,
    selectedSeller: filters.invoice_selectedSeller,
    selectedStatus: filters.invoice_selectedStatus,
    selectedToSite: filters.invoice_selectedToSite,
    selectField: filters.invoice_selectField,
  }),
);

import ms from 'ms';

import { ENDPOINT } from '~/constants/endpoints';
import { apiUrl } from '~/constants/environment';
import { LOADING_STATE } from '~/constants/LoadingState';
import { EMPTY_DROPDOWN_OPTION } from '~/constants/select';

import {
  replaceInvoiceCheckIgnoredArticles,
  saveIncomingInvoicesFromBackend,
  saveOutgoingInvoicesFromBackend,
  setInvoiceCheckIgnoredArticlesLoading,
} from '~/redux/invoicesSlice';
import store from '~/redux/store';

import {
  InvoiceCheckCategoryObject,
  InvoiceCheckResultObject,
} from '~/models/invoices';
import InvoiceModel from '~/models/invoices/Invoice';

import axios from '~/utils/api-client';
import { dateUtils } from '~/utils/dateUtils';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import { Log } from '~/utils/logging';
import { promiseHandler } from '~/utils/promiseHandler';

import ToastService from './toast.service';

const INVOICE_VERIFICATION_IGNORED_ARTICLE_API_URL =
  apiUrl + '/invoice_verification/ignored_article';

// time to wait before next query
const waitTime = ms('5m');

const limit = 1000;

class InvoiceService {
  async getAllIncomingInvoices(
    url = '/asset/invoice?limit=' + limit + '&incoming=true',
  ) {
    return axios
      .get(apiUrl + url)
      .then(async (response) => {
        const { data } = response;

        const invoices = [];

        // Process the initial batch of invoices
        for (let index = 0; index < data.assets?.length; index++) {
          try {
            const invoice = new InvoiceModel(
              data.assets[index],
              InvoiceModel.DIRECTION.INCOMING,
            );
            invoice.initWithReferencedDeliveryNotes(true, true);

            invoices.push(invoice);
          } catch (error) {
            Log.error(
              `Failed to initialize invoice. id: ${data.assets[index]?._id}`,
              error,
            );
            Log.productAnalyticsEvent(
              'Failed to initialize invoice',
              Log.FEATURE.INVOICE,
              Log.TYPE.ERROR,
            );
          }
        }

        // Save the first batch to Redux
        store.dispatch(saveIncomingInvoicesFromBackend(invoices));

        // Check for updateLink (data updates)
        const { updateLink } = data;
        if (updateLink) {
          setTimeout(
            function () {
              this.runIncomingInvoiceUpdater(updateLink);
            }.bind(this),
            waitTime,
          );
        }

        // Check for nextLink (pagination)
        const { nextLink } = data;
        if (nextLink) {
          this.getIncomingInvoiceNextLink(nextLink);
        }
      })
      .catch((error) => {
        Log.error('Failed to load invoices.', error);
        Log.productAnalyticsEvent(
          'Failed to load invoice',
          Log.FEATURE.INVOICE,
          Log.TYPE.ERROR,
        );
        throw error;
      });
  }

  async runIncomingInvoiceUpdater(url) {
    axios
      .get(apiUrl + url)
      .then(async (response) => {
        const { data } = response;

        const { updateLink } = data;

        if (!updateLink) {
          return;
        }

        const invoices = [];

        for (let index = 0; index < data.assets?.length; index++) {
          try {
            const invoice = new InvoiceModel(
              data.assets[index],
              InvoiceModel.DIRECTION.INCOMING,
            );
            invoice.initWithReferencedDeliveryNotes(true, true);

            invoices.push(invoice);
          } catch (error) {
            Log.error(
              `Failed to initialize invoice. id: ${data.assets[index]?._id}`,
              error,
            );
            Log.productAnalyticsEvent(
              'Failed to initialize invoice',
              Log.FEATURE.INVOICE,
              Log.TYPE.ERROR,
            );
          }
        }

        store.dispatch(saveIncomingInvoicesFromBackend(invoices));

        setTimeout(
          function () {
            this.runIncomingInvoiceUpdater(updateLink);
          }.bind(this),
          waitTime,
        );
      })
      .catch((error) => {
        Log.error('Failed to load invoices.', error);
        Log.productAnalyticsEvent(
          'Failed to load invoices',
          Log.FEATURE.INVOICE,
          Log.TYPE.ERROR,
        );
      });
  }

  async getIncomingInvoiceNextLink(
    url = ENDPOINT.INVOICE.GET_ALL({ incoming: true, limit }),
  ) {
    try {
      const response = await axios.get(apiUrl + url);
      const { data } = response;
      const assets = data.assets || [];
      const invoices = [];
      let index = 0;
      const batchSize = 4;

      const processNextBatch = () => {
        return new Promise((resolve) => {
          requestAnimationFrame(() => {
            const endIndex = Math.min(index + batchSize, assets.length); // Process <batchSize> invoices per frame

            while (index < endIndex) {
              try {
                const invoice = new InvoiceModel(
                  assets[index],
                  InvoiceModel.DIRECTION.INCOMING,
                );
                invoice.initWithReferencedDeliveryNotes(true, true);
                invoices.push(invoice);
              } catch (error) {
                Log.error(
                  `Failed to initialize invoice. id: ${assets[index]?._id}`,
                  error,
                );
                Log.productAnalyticsEvent(
                  'Failed to initialize invoice',
                  Log.FEATURE.INVOICE,
                  Log.TYPE.ERROR,
                );
              }

              index++;
            }

            if (index < assets.length) {
              // Schedule next batch with setTimeout to give UI a chance to respond
              setTimeout(() => {
                processNextBatch().then(resolve);
              }, 0);
            } else {
              resolve();
            }
          });
        });
      };

      await processNextBatch();

      store.dispatch(saveIncomingInvoicesFromBackend(invoices));

      const { nextLink } = data;
      if (nextLink) {
        this.getIncomingInvoiceNextLink(nextLink);
      }
    } catch (error) {
      ToastService.warning([
        'Eingangsrechnungen konnten nicht vollständig geladen werden.',
      ]);

      Log.error('Failed to load invoices.', error);
      Log.productAnalyticsEvent(
        'Failed to load invoices',
        Log.FEATURE.INVOICE,
        Log.TYPE.ERROR,
      );
    }
  }

  async getAllOutgoingInvoices(
    url = '/asset/invoice?limit=' + limit + '&outgoing=true',
  ) {
    return axios
      .get(apiUrl + url)
      .then(async (response) => {
        const { data } = response;

        const { updateLink } = data;

        if (!updateLink) {
          return;
        }

        const invoices = [];

        for (let index = 0; index < data.assets?.length; index++) {
          try {
            const invoice = new InvoiceModel(
              data.assets[index],
              InvoiceModel.DIRECTION.OUTGOING,
            );
            invoice.initWithReferencedDeliveryNotes(true, true);

            invoices.push(invoice);
          } catch (error) {
            Log.error(
              'Failed to initialize invoice. id: ' + data.assets[index]?._id,
              error,
            );
            Log.productAnalyticsEvent(
              'Failed to initialize invoice',
              Log.FEATURE.INVOICE,
              Log.TYPE.ERROR,
            );
          }
        }

        store.dispatch(saveOutgoingInvoicesFromBackend(invoices));

        setTimeout(
          function () {
            this.runOutgoingInvoiceUpdater(updateLink);
          }.bind(this),
          waitTime,
        );

        const { nextLink } = data;
        if (nextLink) {
          this.getOutgoingInvoiceNextLink(nextLink);
        }
      })
      .catch((error) => {
        Log.error('Failed to load invoices.', error);
        Log.productAnalyticsEvent(
          'Failed to load invoices',
          Log.FEATURE.INVOICE,
          Log.TYPE.ERROR,
        );
        throw error;
      });
  }

  async runOutgoingInvoiceUpdater(url) {
    axios
      .get(apiUrl + url)
      .then(async (response) => {
        const { data } = response;

        const invoices = [];

        for (let index = 0; index < data.assets?.length; index++) {
          try {
            const invoice = new InvoiceModel(
              data.assets[index],
              InvoiceModel.DIRECTION.OUTGOING,
            );
            invoice.initWithReferencedDeliveryNotes(true, true);

            invoices.push(invoice);
          } catch (error) {
            Log.error(
              'Failed to initialize invoice. id: ' + data.assets[index]?._id,
              error,
            );
            Log.productAnalyticsEvent(
              'Failed to initialize invoice',
              Log.FEATURE.INVOICE,
              Log.TYPE.ERROR,
            );
          }
        }

        store.dispatch(saveOutgoingInvoicesFromBackend(invoices));

        const { updateLink } = data;
        if (updateLink) {
          setTimeout(
            function () {
              this.runOutgoingInvoiceUpdater(updateLink);
            }.bind(this),
            waitTime,
          );
        }
      })
      .catch((error) => {
        Log.error('Failed to load invoices.', error);
        Log.productAnalyticsEvent(
          'Failed to load invoices',
          Log.FEATURE.INVOICE,
          Log.TYPE.ERROR,
        );
      });
  }

  async getOutgoingInvoiceNextLink(
    url = '/asset/invoice?limit=' + limit + '&outgoing=true',
  ) {
    axios
      .get(apiUrl + url)
      .then(async (response) => {
        const { data } = response;

        const invoices = [];

        for (let index = 0; index < data.assets?.length; index++) {
          try {
            const invoice = new InvoiceModel(
              data.assets[index],
              InvoiceModel.DIRECTION.OUTGOING,
            );
            invoice.initWithReferencedDeliveryNotes(true, true);

            invoices.push(invoice);
          } catch (error) {
            Log.error(
              'Failed to initialize invoice. id: ' + data.assets[index]?._id,
              error,
            );
            Log.productAnalyticsEvent(
              'Failed to initialize invoice',
              Log.FEATURE.INVOICE,
              Log.TYPE.ERROR,
            );
          }
        }

        store.dispatch(saveOutgoingInvoicesFromBackend(invoices));

        const { nextLink } = data;
        if (nextLink) {
          this.getOutgoingInvoiceNextLink(nextLink);
        }
      })
      .catch((error) => {
        ToastService.warning([
          'Ausgangsrechnungen konnten nicht vollständig geladen werden.',
        ]);
        Log.error('Failed to load invoices.', error);
        Log.productAnalyticsEvent(
          'Failed to load invoices',
          Log.FEATURE.INVOICE,
          Log.TYPE.ERROR,
        );
      });
  }
}

const invoiceServiceInstance = new InvoiceService();

export default invoiceServiceInstance;

/* eslint-disable import/no-unassigned-import */
import 'chart.js/auto';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { useCallback, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

import { type UncefactUnitType } from '~/constants/units';

import FeatureService from '~/services/feature.service';

import { LOCALE_INFO } from '~/utils/l10n';
import { cn } from '~/utils/tailwind';

import { ClientPortalMessage } from '~/components/salesPackages/clientPortal/ClientPortalMessage';
import { PackageBasicRestrictionMessage } from '~/components/salesPackages/packageBasicRestriction/PackageBasicRestrictionMessage';
import { withErrorBoundary } from '~/ui/atoms';
import { Spinner } from '~/ui/atoms/Spinner';

import { type AggregationInterval } from '../types';
import { getChartFormatter } from '../utils';

type P = {
  aggregationInterval: AggregationInterval;
  data: {
    datasets: any[];
  };
  isLoading: boolean;
  selectedUnit: UncefactUnitType;
} & ComponentStyling;

export const TimeSeriesChart = withErrorBoundary(
  ({
    aggregationInterval,
    className,
    data,
    isLoading,
    selectedUnit,
    style,
  }: P) => {
    const formatTooltipLabel = useCallback(
      (context) => {
        const formatter = getChartFormatter(
          'tooltipLabel',
          aggregationInterval,
        );
        return formatter
          ? formatter(context, selectedUnit)
          : context.dataset.label;
      },
      [aggregationInterval, selectedUnit],
    );

    const formatTooltipTitle = useCallback(
      (title) => {
        if (title?.[0]?.label === undefined) {
          return undefined;
        }

        const formatter = getChartFormatter(
          'tooltipTitle',
          aggregationInterval,
        );
        return formatter ? formatter(title[0].label) : title[0].label;
      },
      [aggregationInterval],
    );

    const formatXAxisLabel = useCallback(
      (label) => {
        const formatter = getChartFormatter('xAxisLabel', aggregationInterval);
        return formatter ? formatter(label) : label;
      },
      [aggregationInterval],
    );

    const formatYAxisLabel = useCallback(
      (value: number) => {
        const formatter = getChartFormatter('yAxisLabel', aggregationInterval);
        return formatter ? formatter(value, selectedUnit) : value;
      },
      [aggregationInterval, selectedUnit],
    );

    const renderedDatasetsCount = data?.datasets?.[0]?.data?.length;

    const chartOptions = useMemo(
      () => ({
        locale: LOCALE_INFO.name,
        maintainAspectRatio: false,
        maxBarThickness: 40,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            boxPadding: 6,
            callbacks: {
              label: formatTooltipLabel,
              title: formatTooltipTitle,
            },
            itemSort(contextA, contextB) {
              return contextB.parsed.y - contextA.parsed.y;
            },
            padding: {
              x: 10,
              y: 8,
            },
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            stacked: true,
            ticks: {
              callback: formatXAxisLabel,
              stepSize: renderedDatasetsCount < 32 ? 1 : 3, // Render only every 3rd tick for large datasets
            },
            time: {
              tooltipFormat: 'YYYY-MM-DD[T]HH:mm:ss', // Force ISO format with time to avoid date parsing issues
              unit: aggregationInterval,
            },
            type: 'time',
          },
          yAxisID: {
            min: 0,
            border: {
              display: false,
            },
            ticks: {
              callback: formatYAxisLabel,
            },
          },
        },
      }),
      [aggregationInterval, renderedDatasetsCount, selectedUnit],
    );

    const clientPortalOverlay = FeatureService.clientPortal() ? (
      <div className="absolute flex size-full items-center justify-center backdrop-blur-md">
        <div className="rounded-md bg-white p-4 shadow-lg">
          <ClientPortalMessage />
        </div>
      </div>
    ) : null;

    const packageBasicRestrictionOverlay =
      FeatureService.packageBasicRestriction() ? (
        <div className="absolute flex size-full items-center justify-center backdrop-blur-md">
          <div className="rounded-md bg-white p-4 shadow-lg">
            <PackageBasicRestrictionMessage />
          </div>
        </div>
      ) : null;

    return (
      <div
        className={cn('relative h-[40vh] min-h-96', className)}
        style={style}
      >
        {isLoading && <Spinner className="absolute inset-0" />}
        {clientPortalOverlay}
        {packageBasicRestrictionOverlay}
        <Bar data={data} options={chartOptions} />
      </div>
    );
  },
  'Zeitlicher Verlauf konnte nicht geladen werden.',
);

TimeSeriesChart.displayName = 'TimeSeriesChart';

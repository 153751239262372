export const COLUMN_SEPARATOR = ' § ';
export const LIST_SEPARATOR = ' | ';

export const DATA_TYPE = {
  BOOLEAN: 'boolean',
  LIST: 'list',
  NUMBER: 'number',
  TEXT: 'text',
  UUID: 'uuid',
  UUID_LIST: 'uuid_list',
} as const;

export const VALIDATION_ERROR = {
  INVALID_BOOLEAN: 'Ungültiger Boolean',
  INVALID_NUMBER: 'Ungültige Nummer',
  INVALID_UUID: 'Ungültige UUID',
  INVALID_UUID_LIST: 'Liste enthält ungültige UUIDs',
  TOO_MANY_VALUES: 'Ungültige Zeile mit mehr Einträgen als Spalten',
  UNKNOWN_COLUMN: 'Unbekannte Spalte',
} as const;

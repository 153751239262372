export const CreateDeliveryIcon = ({ className, style }: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6 13C9.31371 13 12 10.3137 12 7C12 5.90714 11.7078 4.88252 11.1973 4H18V8H21L24 12V17H22C22 17.7956 21.6839 18.5587 21.1213 19.1213C20.5587 19.6839 19.7956 20 19 20C18.2044 20 17.4413 19.6839 16.8787 19.1213C16.3161 18.5587 16 17.7956 16 17H10C10 17.7956 9.68393 18.5587 9.12132 19.1213C8.55871 19.6839 7.79565 20 7 20C6.20435 20 5.44129 19.6839 4.87868 19.1213C4.31607 18.5587 4 17.7956 4 17H2V11.4722C3.06151 12.4223 4.46329 13 6 13ZM17.9393 18.0607C18.2206 18.342 18.6022 18.5 19 18.5C19.3978 18.5 19.7794 18.342 20.0607 18.0607C20.342 17.7794 20.5 17.3978 20.5 17C20.5 16.6022 20.342 16.2206 20.0607 15.9393C19.7794 15.658 19.3978 15.5 19 15.5C18.6022 15.5 18.2206 15.658 17.9393 15.9393C17.658 16.2206 17.5 16.6022 17.5 17C17.5 17.3978 17.658 17.7794 17.9393 18.0607ZM22.46 12L20.5 9.5H18V12H22.46ZM5.93934 18.0607C6.22064 18.342 6.60218 18.5 7 18.5C7.39782 18.5 7.77936 18.342 8.06066 18.0607C8.34196 17.7794 8.5 17.3978 8.5 17C8.5 16.6022 8.34196 16.2206 8.06066 15.9393C7.77936 15.658 7.39782 15.5 7 15.5C6.60218 15.5 6.22064 15.658 5.93934 15.9393C5.65804 16.2206 5.5 16.6022 5.5 17C5.5 17.3978 5.65804 17.7794 5.93934 18.0607Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M5.42857 11V7.57143H2V6.42857H5.42857V3H6.57143V6.42857H10V7.57143H6.57143V11H5.42857Z"
      fill="currentColor"
    />
  </svg>
);

import { hasDifference } from '~/models/utils';

import { AddressObject } from '../Address';
import {
  PermissionGrantObject,
  type PermissionGrantTargetType,
} from '../PermissionGrant';
import { type TradeContact, TradeContactObject } from '../TradeContact';
import { UniversalCommunicationObject } from '../UniversalCommunication';

import { DIFFERENCE_VALUES } from './constants';
import { type LegalOrganization } from './types';

export const CompanyObject = {
  /**
   * Creates a Company object.
   * @param {Partial<Company>} legalOrganization - The legal organization data.
   * @param {Partial<TradeContact>} tradeContact - The trade contact data.
   * @param {boolean} [additionalDataInitiated=true] - Flag indicating if additional data has been initiated.
   * @returns {Company} - The created Company object.
   */
  create(
    legalOrganization: Partial<LegalOrganization> = {},
    tradeContact?: TradeContact,
    additionalDataInitiated = true,
  ) {
    const company = {
      id: legalOrganization?.id ?? '',
      name: legalOrganization?.name ?? '',
      companyAccount: legalOrganization?.companyAccountId ?? '',
      address: AddressObject.create(legalOrganization?.address),
      universalCommunication: UniversalCommunicationObject.create(
        legalOrganization?.universalCommunication,
      ),
      tradeContact: TradeContactObject.create(tradeContact),
      organisationalGroups: legalOrganization?.orgUnits ?? [],
      parentOrganizationalUnits:
        legalOrganization?.parentOrganizationalUnits ?? [],
      organisationalGroupPaths: legalOrganization?.orgUnitPaths ?? [],
      parentOrganizationalUnitPaths:
        legalOrganization?.parentOrganizationalUnitPaths ?? [],
      permissionGrantsFrom:
        legalOrganization?.permissionsFrom?.map((permissionGrant) =>
          PermissionGrantObject.create({
            ...permissionGrant,
            targetId: legalOrganization.id ?? '',
            targetType: PermissionGrantObject.ENTITY_TYPE
              .COMPANY as PermissionGrantTargetType,
          }),
        ) ?? [],
      additionalDataInitiated,
    };

    if (!additionalDataInitiated) {
      company.organisationalGroupPaths = [];
    }

    company.name = company.name.includes('Undifinied') ? '' : company.name;

    return company;
  },

  /**
   * Compares two Company objects to find the different values.
   * @param {Company} companyA - The first Company object.
   * @param {Company} companyB - The second Company object.
   * @returns {string[]} - A list of different values.
   */
  getDifferences(companyA: Record<string, any>, companyB: Record<string, any>) {
    const differentValues: string[] = [];

    if (companyA?.id !== companyB?.id) {
      differentValues.push(DIFFERENCE_VALUES.ID);
    }

    if (companyA?.name !== companyB?.name) {
      differentValues.push(DIFFERENCE_VALUES.NAME);
    }

    if (
      AddressObject.getDifferences(companyA?.address, companyB?.address)
        .length > 0
    ) {
      differentValues.push(DIFFERENCE_VALUES.ADDRESS);
    }

    const isOrganisationalGroupDiff = hasDifference(
      companyA?.organisationalGroups,
      companyB?.organisationalGroups,
    );
    if (isOrganisationalGroupDiff) {
      differentValues.push(DIFFERENCE_VALUES.ORGANISATIONAL_GROUPS);
    }

    return differentValues;
  },

  getBackendFormat(company: Record<string, any>) {
    const id = company.id ?? null;
    const companyAccount = company.companyAccount ?? null;

    return {
      id,
      legalOrganization: {
        address: AddressObject.create(company.address),
        companyAccountId: companyAccount,
        id,
        name: company.name,
      },
    };
  },
};

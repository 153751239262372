import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { Log } from '~/utils/logging';

import { CompanyObject } from '../masterdata/Company';

import {
  CONCRETE_LARGEST_GRAINS,
  CONCRETE_MONITORING_CLASS_2,
  CONCRETE_STRENGTH_CLASSES,
  CONCRETE_STRENGTH_DEVELOPMENT,
} from './constants';
import {
  type ConcreteProduct,
  type ConcreteStrengthDevelopmentKeys,
} from './types';

export const ConcreteObject = {
  LARGEST_GRAINS: CONCRETE_LARGEST_GRAINS,
  MONITORING_CLASS_2: CONCRETE_MONITORING_CLASS_2,
  STRENGTH_CLASSES: CONCRETE_STRENGTH_CLASSES,
  STRENGTH_DEVELOPMENT: CONCRETE_STRENGTH_DEVELOPMENT,

  /**
   * Creates a concrete object with the provided data.
   * @param concreteId - Unique identifier for the concrete.
   * @param concrete - An object containing concrete data.
   * @returns ConcreteProduct data object with default values and processed components.
   */
  create(concreteId: string | number, concrete: Partial<ConcreteProduct> = {}) {
    return {
      chlorideClass: concrete.chlorideClass ?? '',
      components: this.getComponents(concrete.components),
      concreteId: concreteId ?? '',
      consistency: concrete.consistency ?? '',
      exposureClass: concrete.exposureClass ?? '',
      largestGrain: concrete.largestGrain ?? '',
      moistureClass: concrete.moistureClass ?? '',
      posteriorAdded: this.getPosteriorAdded(concrete.posteriorAdded),
      strengthClass: concrete.strengthClass ?? '',
      strengthDevelopment: concrete.strengthDevelopment ?? '',
      type: concrete.type ?? '',
      usage: concrete.usage ?? '',
      waterCementRatio: concrete.waterCementRatio ?? null,
    };
  },

  /**
   * Processes components and returns an array of processed components.
   * @param components - Array of component data.
   * @returns Array of processed components with manufacturer and quantity details.
   */
  getComponents(components: any[] = []) {
    const newComponents = [];
    for (const component of components) {
      const manufacturer = CompanyObject.create(
        component.manufacturer?.legal_organization,
      );
      newComponents.push({
        comment: component.comment,
        manufacturer,
        name: component.name,
        quantity: {
          unit: component.quantity?.measure,
          value: component.quantity?.value,
        },
        type: component.type,
        volumeRequested: {
          unit: component.requested?.measure,
          value: component.requested?.value,
        },
      });
    }

    return newComponents;
  },

  /**
   * Processes posterior added items and returns an array of processed items.
   * @param posteriorAdded - Array of posterior added item data.
   * @returns Array of processed posterior added items with manufacturer and remaining concrete details.
   */
  getPosteriorAdded(posteriorAdded: any[] = []) {
    const newPosteriorAdded = [];
    for (const item of posteriorAdded) {
      const manufacturer = CompanyObject.create(
        item.manufacturer?.legal_organization,
      );

      newPosteriorAdded.push({
        comment: item.comment,
        manufacturer,
        name: item.name,
        quantity: {
          unit: item.quantity?.measure,
          value: item.quantity?.value,
        },
        reason: item.reason,
        remainingConcrete: {
          unit: item.remaining_concrete?.measure,
          value: item.remaining_concrete?.value,
        },
        result: item.result,
        type: item.type,
      });
    }

    return newPosteriorAdded;
  },

  /**
   * Checks if the concrete should be monitored based on strength class and exposure class.
   * @returns True if concrete needs monitoring, otherwise false.
   */
  isMonitoringConcrete(concrete: ConcreteProduct) {
    if (
      CONCRETE_STRENGTH_CLASSES.indexOf(concrete.strengthClass as any) >=
      CONCRETE_STRENGTH_CLASSES.indexOf(
        CONCRETE_MONITORING_CLASS_2.MINIMUM_STRENGTH_CLASS,
      )
    ) {
      return true;
    }

    for (const monitoredExposureClass of CONCRETE_MONITORING_CLASS_2.EXPOSURE_CLASSES) {
      if (concrete.exposureClass.includes(monitoredExposureClass)) {
        return true;
      }
    }

    return false;
  },

  /**
   * Gets a descriptive label for strength development.
   * @param strengthDevelopment - Standardized strength development type.
   * @returns A descriptive label for the given strength development.
   */
  getDescriptiveStrengthDevelopment(strengthDevelopment: string) {
    if (!strengthDevelopment) {
      return null;
    }

    const strengthDevelopmentConst = Object.keys(
      CONCRETE_STRENGTH_DEVELOPMENT,
    ).find(
      (x) =>
        CONCRETE_STRENGTH_DEVELOPMENT[x as ConcreteStrengthDevelopmentKeys]
          .STANDARDISED === strengthDevelopment,
    );

    if (!strengthDevelopmentConst) {
      Log.error(
        null,
        new EnumValueNotFoundException(
          'Invalid strength development. strengthDevelopment: ' +
            strengthDevelopment,
        ),
      );
      return strengthDevelopment;
    }

    return CONCRETE_STRENGTH_DEVELOPMENT[
      strengthDevelopmentConst as ConcreteStrengthDevelopmentKeys
    ].DESCRIPTIVE;
  },
};

import {
  type UseMutateAsyncFunction,
  useMutation,
  type UseMutationOptions,
} from '@tanstack/react-query';
import { z } from 'zod';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';
import { withoutObjectKeysWhereValueIs } from '~/utils/object';

const zodCreateUserSchema = z.object({
  companyId: z.string().uuid(),
  email: z.string().email(),
  firstName: z.string(),
  isActive: z.boolean(),
  lastName: z.string(),
  notificationSettings: z
    .object({
      delayDuration: z.coerce.number().nonnegative().optional(),
      sendEmail: z.boolean().optional(),
    })
    .optional(),
  orgUnits: z.array(z.string().uuid()).optional(),
  password: z.string(), // TODO: improve requirements
  permittedSignatureTypes: z.array(z.string()), // TODO: snake_case values of SIGNATURE_ROLE keys
  position: z.union([z.literal(''), z.string()]).optional(),
  username: z.union([z.literal(''), z.string()]).optional(),
  userType: z.union([z.literal(''), z.string()]).nullish(),
});

type CreateUserPayload = z.infer<typeof zodCreateUserSchema>;

type CreateUserResponse = {
  code: number;
  id: UUID;
  message: string;
};

type CreateUserMutationOptions = UseMutationOptions<
  CreateUserResponse,
  Error,
  CreateUserPayload
>;

export type CreateUserMutateAsyncFunction = UseMutateAsyncFunction<
  CreateUserResponse,
  Error,
  CreateUserPayload
>;

/**
 * Creates a new user.
 *
 * @param {Object} userData - The user data for creating a new user.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Admin/operation/create_user_admin_users_post
 */
export const createUser = async (userData: CreateUserPayload) => {
  try {
    const filteredPayload = withoutObjectKeysWhereValueIs(userData, [
      undefined,
    ]);

    const validatedRequestBody = zodCreateUserSchema.parse(filteredPayload);

    const response = await vestigasApi
      .post(ENDPOINT.USER.CREATE(), {
        json: validatedRequestBody,
      })
      .json<CreateUserResponse>();

    return response;
  } catch (error) {
    Log.error('Error creating user', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for creating a new user.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationCreateUser = (options?: CreateUserMutationOptions) => {
  return useMutation({
    mutationFn: async (userData: CreateUserPayload) => createUser(userData),
    onError() {
      Log.productAnalyticsEvent(
        'Failed to create user',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    ...options,
  });
};

import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import ms from 'ms';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';

import { queryKeysInvoice } from './queryKeys';

export type InvoiceResponse = Record<string, unknown>;

/**
 * Fetches invoice by id from the API.
 * @param companyId - The ID of the invoice to fetch.
 * @returns The invoice data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Delivery-Note/operation/get_invoice_asset_invoice__id__get
 */
export const fetchInvoice = async (invoiceId: UUID) => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.INVOICE.GET(invoiceId))
      .json<InvoiceResponse>();

    return response;
  } catch (error) {
    Log.error('Error fetching invoice', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for a invoice with a given invoiceId.
 * @param invoiceId - The ID of the invoice to fetch.
 * @param options - Additional options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
export const useQueryInvoice = (
  invoiceId: UUID,
  options: Omit<UseQueryOptions<InvoiceResponse>, 'queryKey' | 'queryFn'> = {},
) => {
  return useQuery<InvoiceResponse>({
    queryFn: async () => fetchInvoice(invoiceId),
    queryKey: queryKeysInvoice.get(invoiceId),
    enabled: Boolean(invoiceId),
    staleTime: ms('60s'),
    ...options,
  });
};

import { Tooltip } from '@mui/material';
import { getGridStringOperators } from '@mui/x-data-grid';

import LocalStorageService from '~/services/localStorage.service';

class DatagridUtils {
  constructor() {
    this.TABLE_HEIGHT_10_ROWS = '668px';

    this.ROW_HEIGHT = {
      STANDARD: 52,
      THIN: 35,
      VERY_THIN: 25,
      WIDE: 65,
    };

    this.EMPTY_FILTER_MODEL = {
      items: [],
      logicOperator: 'and',
    };
  }

  /**
   * Render a tooltip with the cell value on hover.
   * Used to make it easier to read if the cell is too narrow to display the full value.
   */
  displayCellTooltip(params) {
    let { value } = params;
    if (typeof value === 'boolean') {
      value = value ? 'Ja' : 'Nein';
    }

    return (
      <Tooltip title={value ?? ''}>
        <span>{value}</span>
      </Tooltip>
    );
  }

  displayCellTooltipControlled(renderValue, hoverValue) {
    return (
      <Tooltip title={hoverValue ?? ''}>
        <span>{renderValue}</span>
      </Tooltip>
    );
  }

  customColumnTypeArray() {
    return {
      filterOperators: getGridStringOperators().map((operator) => {
        let getApplyFilterFunction = operator.getApplyFilterFn;

        switch (operator.value) {
          case 'contains': {
            getApplyFilterFunction = (filterItem) => (value) =>
              !filterItem.value ||
              value?.some((item) =>
                item
                  .toString()
                  .toLowerCase()
                  .includes(filterItem.value.toLowerCase()),
              );
            break;
          }

          case 'equals': {
            getApplyFilterFunction = (filterItem) => (value) =>
              !filterItem.value ||
              value?.some(
                (item) =>
                  item.toString().toLowerCase() ===
                  filterItem.value.toLowerCase(),
              );
            break;
          }

          case 'startsWith': {
            getApplyFilterFunction = (filterItem) => (value) =>
              !filterItem.value ||
              value?.some((item) =>
                item
                  .toString()
                  .toLowerCase()
                  .startsWith(filterItem.value.toLowerCase()),
              );
            break;
          }

          case 'endsWith': {
            getApplyFilterFunction = (filterItem) => (value) =>
              !filterItem.value ||
              value?.some((item) =>
                item
                  .toString()
                  .toLowerCase()
                  .endsWith(filterItem.value.toLowerCase()),
              );
            break;
          }

          case 'isEmpty': {
            getApplyFilterFunction = (filterItem) => (value) =>
              value?.length === 0;
            break;
          }

          case 'isNotEmpty': {
            getApplyFilterFunction = (filterItem) => (value) =>
              value?.length > 0;
            break;
          }

          case 'isAnyOf': {
            getApplyFilterFunction = (filterItem) => (value) =>
              !filterItem.value ||
              value?.some((item) =>
                filterItem.value?.find(
                  (searchItem) =>
                    searchItem.toLowerCase() === item.toString().toLowerCase(),
                ),
              );
            break;
          }

          default: {
            break;
          }
        }

        return {
          ...operator,
          getApplyFilterFn: getApplyFilterFunction,
        };
      }),
      renderCell: (params) =>
        this.displayCellTooltipControlled(
          params.value.join(', '),
          params.value.join(', '),
        ),
      sortComparator: (v1, v2) => v1.join(', ').localeCompare(v2.join(', ')),
    };
  }

  getRowCountByTableHeight(totalHeight, cookieName) {
    return (totalHeight - 110) / this.getCookieRowHeight(cookieName);
  }

  getCookieRowHeight(cookieName) {
    const cookie = LocalStorageService.getObjectFromLocalStorage(cookieName);

    if (!cookie?.rowHeight) {
      return this.ROW_HEIGHT.THIN;
    } // default data grid row height

    return Number(cookie?.rowHeight);
  }

  resizeText(rowHeight, renderFunction) {
    const fontClass = rowHeight <= this.ROW_HEIGHT.THIN ? 'text-sm' : '';

    return {
      renderCell: (params) => (
        <span className={fontClass}>
          {renderFunction
            ? renderFunction(params)
            : this.displayCellTooltip(params)}
        </span>
      ),
    };
  }

  getColumnWidthFactor(rowHeight) {
    return [this.ROW_HEIGHT.VERY_THIN, this.ROW_HEIGHT.THIN].includes(rowHeight)
      ? 0.8
      : 1;
  }
}

const datagridUtilsInstance = new DatagridUtils();

export default datagridUtilsInstance;

import { CSVValidatorObject } from '../CSVValidator';

import { type SiteType } from './types';

export const SITE_TYPE: Record<string, SiteType> = {
  CONSTRUCTION_SITE: {
    KEY: 'construction_site',
    STRING: 'Baustelle',
  },
  DISPOSAL: {
    KEY: 'disposal',
    STRING: 'Entsorgung',
  },
  FACTORY: {
    KEY: 'factory',
    STRING: 'Fabrik',
  },
  PLANT: {
    KEY: 'plant',
    STRING: 'Werk',
  },
  WAREHOUSE: {
    KEY: 'warehouse',
    STRING: 'Lager',
  },
} as const;

export const SITE_CSV_ALLOWED_COLUMNS = [
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.NUMBER,
    NAME: 'latitude',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.NUMBER,
    NAME: 'longitude',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'city',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'country',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'post_code',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'street_name',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'building_number',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.UUID_LIST,
    NAME: 'accounting_reference_list',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'name',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'type',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.UUID,
    NAME: 'company_id',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.UUID_LIST,
    NAME: 'org_units',
  },
] as const;

export const SITE_CSV_WITH_COST_CENTERS_ALLOWED_COLUMNS = [
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.LIST,
    NAME: 'cost_centers',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'name',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'city',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'country',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'post_code',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'street_name',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'building_number',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.UUID,
    NAME: 'company_id',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.UUID_LIST,
    NAME: 'org_units',
  },
] as const;

export const DIFFERENCE_VALUES = {
  ACTIVE: 'Aktiv',
  ADDRESS: 'Adresse',
  COMPANY: 'Firma',
  COORDINATES: 'Koordinaten',
  COST_CENTERS: 'Kostenstellen',
  ID: 'ID',
  NAME: 'Name',
  ORG_GROUPS: 'Organisations-Gruppen',
  TYPE: 'Art',
} as const;

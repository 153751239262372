import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import ms from 'ms';
import { useEffect } from 'react';
import snakecaseKeys from 'snakecase-keys';

import { ENDPOINT } from '~/constants/endpoints';
import {
  PAGINATION_PAGE_SIZE_DEFAULT,
  PAGINATION_PAGE_SIZE_DEFAULT_STRABAG,
  type PaginationSortDirection,
} from '~/constants/pagination';

import { type Address } from '~/types/address';
import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import {
  createInfiniteQuery,
  type InfiniteQueryOptionsType,
} from '~/utils/createInfiniteQuery';
import { Log } from '~/utils/logging';
import { withoutObjectKeysWhereValueIs } from '~/utils/object';
import { toSnakeCase } from '~/utils/string';

import { queryKeysCompany } from './queryKeys';

export type CompaniesQueryParams = {
  limit: number;
  offset: number;
  filterNotParentOu?: UUID;
  filterParentOu?: UUID;
  orderBy: 'name' | 'id' | 'city' | 'country';
  returnTotalCount: boolean;
  searchString?: string;
  sort: PaginationSortDirection;
};

export type CompanyData = {
  addDlnsToInvoice?: boolean;
  addInvoiceProtocolToInvoice?: boolean;
  address: Address;
  dlnSimplifiedJsonsToInvoice?: boolean;
  dlnsMultiFileToInvoice?: boolean;
  emailReceivedTimeToSubject?: boolean;
  invoiceReceiverList?: any[];
  name: string;
  orgUnits?: UUID[];
  sendInvoiceExternalXml?: boolean;
};

export type CompanyListItem = {
  id: UUID;
  name: string;
  data: CompanyData;
};

type CompaniesResponse = {
  data: readonly CompanyListItem[];
  hasNextPage: boolean;
  paginatedCount: number;
  totalCount: number;
};

const defaultQueryParams: CompaniesQueryParams = {
  filterNotParentOu: undefined,
  filterParentOu: undefined,
  // Tmp change until we removed old Strabag Direktionen
  limit: PAGINATION_PAGE_SIZE_DEFAULT_STRABAG,
  offset: 0,
  orderBy: 'name',
  returnTotalCount: true,
  searchString: undefined,
  sort: 'ASC',
};

/**
 * Generates the query options for the companies query so they can be shared between the useQuery hook and the prefetching.
 */
export const getCompaniesQueryOptions = ({
  queryParams,
  options = {},
}: {
  queryParams: Partial<CompaniesQueryParams>;
  options?: Omit<UseQueryOptions<CompaniesResponse>, 'queryKey' | 'queryFn'>;
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.sort = qp.sort?.toUpperCase();

  return {
    queryFn: async () => fetchCompanies(qp),
    queryKey: queryKeysCompany.getAll(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all companies from the API.
 * @param queryParams - The query parameters for fetching companies.
 * @returns The company data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Company/operation/get_companies_company_get
 */
export const fetchCompanies = async (
  queryParams: Partial<CompaniesQueryParams>,
) => {
  const mergedParams = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const qp = withoutObjectKeysWhereValueIs(
    {
      ...mergedParams,
      orderBy: mergedParams.orderBy
        ? toSnakeCase(mergedParams.orderBy)
        : undefined,
      sort: mergedParams.sort ? mergedParams.sort.toUpperCase() : undefined,
    },
    [undefined],
  );

  try {
    const response = await vestigasApi
      .get(ENDPOINT.COMPANY.GET_ALL(), {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<CompaniesResponse>();

    return (
      response ?? {
        data: [],
        hasNextPage: false,
        paginatedCount: 0,
        totalCount: 0,
      }
    );
  } catch (error) {
    Log.error('Error fetching companies', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all companies with given query parameters.
 * Handles pagination and prefetches the next page of companies for better performance.
 *
 * @param {Object} queryParams - The query parameters for fetching companies.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryCompanies = (
  queryParams: Partial<CompaniesQueryParams>,
  options?: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (queryParams?.searchString?.length > 0) {
      // Only prefetch if the user is not searching, to prevent firing lots of requests for costly queries.
      return;
    }

    queryClient.prefetchQuery(
      getCompaniesQueryOptions({
        options,
        queryParams: {
          ...queryParams,
          offset:
            (queryParams.offset ?? 0) +
            (queryParams.limit ?? PAGINATION_PAGE_SIZE_DEFAULT),
        },
      }),
    );
  }, [queryParams, options, queryClient]);

  return useQuery<CompaniesResponse>({
    ...getCompaniesQueryOptions({ options, queryParams }),
    placeholderData: keepPreviousData,
  });
};

/**
 * React Query based custom hook for getting the data for all companies with infinite scrolling.
 * Uses useInfiniteQuery to handle loading more data as the user scrolls.
 *
 * @param {Object} queryParams - The query parameters for fetching companies.
 * @param {Object} options - Additional options for the useInfiniteQuery hook.
 * @returns {UseInfiniteQueryResult} The result of the useInfiniteQuery hook.
 */
export const useQueryCompaniesInfinite = (
  queryParams: Partial<CompaniesQueryParams>,
  options: InfiniteQueryOptionsType<CompaniesResponse, Error>,
) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const infiniteQueryOptions = {
    queryFn: async ({ pageParam: pageParameter = 0 }) =>
      fetchCompanies({
        ...qp,
        offset: pageParameter,
      }),
    queryKey: queryKeysCompany.getAllInfinite(queryParams),
    ...options,
  };

  return createInfiniteQuery<CompaniesResponse>(qp, infiniteQueryOptions);
};

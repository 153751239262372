import { COMPANY, COMPANY_ACCOUNT, USER_ACCOUNT } from '~/constants/Masterdata';

import { type UUID } from '~/types/common';

import { CustomFieldObject } from '~/models/customData';

export const USERS_WITH_CUSTOM_FIELDS = [
  {
    customFields: [
      CustomFieldObject.create({
        displayName: 'Zufuhrart',
        hardcoded: true,
        id: 'Zufuhrart',
        visibility: CustomFieldObject.VISIBILITY.ALWAYS.KEY,
      }),
    ],
    userId: 'ad191288-cb8f-4c87-96f2-131aa9f43db1', // vestigas-support-eireiner@vestigas.com
  },
  {
    customFields: [
      CustomFieldObject.create({
        displayName: 'Zufuhrart',
        hardcoded: true,
        id: 'Zufuhrart',
        visibility: CustomFieldObject.VISIBILITY.ALWAYS.KEY,
      }),
    ],
    userId: 'bb56ca3b-80e0-47f9-83e4-bc5a0bfa2fac', // baumann.philip@anton-eireiner.de
  },
] as const;

export const STRABAG_SUPPORT_USERS = [
  'd0ca33e3-54b3-4c14-a8af-3fd81eb92fa3',
] as const;

export const HTI_SUPPORT_USERS = [
  'c83792b3-45ad-451a-aa18-e6f1c82b0dfb',
] as const;

export const SUPPLIER_OVERVIEW_USER = 'befc3a91-e8be-42f7-9559-16a8cd9cc3e8';

export const BAYWA_SCREEN_USER = '018df28e-5247-428f-8ca1-0e79865a3da9';

export const ANTON_EIREINER_USERS = [
  'ad191288-cb8f-4c87-96f2-131aa9f43db1',
  'bb56ca3b-80e0-47f9-83e4-bc5a0bfa2fac',
] as const;

export const MAXI_KAUN_USERS = [
  'cea92bd5-203b-4ab1-9e35-a890fd717d98',
] as const;

export const VESTIGAS_ACCOUNTS = [
  USER_ACCOUNT.DEMO_LIEFERANT,
  USER_ACCOUNT.DEMO_POLIER,
  USER_ACCOUNT.DEMO_SPEDITEUR,
  USER_ACCOUNT.LIEFERANT_DEV,
  USER_ACCOUNT.LIEFERANT_IDP,
  USER_ACCOUNT.MUSTER_POLIER,
  USER_ACCOUNT.POLIER_DEV,
  USER_ACCOUNT.SPEDITEUR_DEV,
] as const;

export const SCHICKER_COMPANY_ACCOUNTS = [COMPANY_ACCOUNT.SCHICKER] as const;

export const DLN_DATE_COMPANY_ACCOUNTS = [
  COMPANY_ACCOUNT.SCHICKER,
  COMPANY_ACCOUNT.JOSEF_RAEDLINGER,
  COMPANY_ACCOUNT.STAATLICHES_BAUAMT_BAYREUTH,
] as const;

export const STRABAG_COMPANY_ACCOUNTS = [COMPANY_ACCOUNT.STRABAG] as const;

export const GROSSMANN_COMPANIES = [COMPANY.GROSSMANN] as const;

export const DECHANT_COMPANIES = [COMPANY.DECHANT] as const;

export const WM_COMPANIES = [COMPANY.WM] as const;

export const ARGE_A3_COMPANIES = [COMPANY.ARGE_A3] as const;

export const ANBS_COMPANIES = [COMPANY.ANBS] as const;

export const ATH_COMPANIES = [
  COMPANY.ATH,
  COMPANY.ARGE_OBERIRDISCHE_WEST,
] as const;

export const ANTON_EIREINER_COMPANIES = [
  COMPANY.ANTON_EIREINER_GMBH,
  COMPANY.STEINWERKE_ANTON_EIREINER,
] as const;

export const DEMO_ALLOWED_COMPANY_ACCOUNTS = [
  COMPANY_ACCOUNT.DEMO_ALLOWED_01,
  COMPANY_ACCOUNT.DEMO_ALLOWED_02,
  COMPANY_ACCOUNT.DEMO_ALLOWED_03,
  COMPANY_ACCOUNT.DEMO_ALLOWED_04,
  COMPANY_ACCOUNT.DEMO_ALLOWED_05,
  COMPANY_ACCOUNT.DEMO_ALLOWED_06,
  COMPANY_ACCOUNT.DEMO_ALLOWED_07,
  COMPANY_ACCOUNT.DEMO_ALLOWED_08,
  COMPANY_ACCOUNT.DEMO_ALLOWED_09,
  COMPANY_ACCOUNT.DEMO_ALLOWED_10,
  COMPANY_ACCOUNT.DEMO_ALLOWED_11,
  COMPANY_ACCOUNT.DEMO_ALLOWED_12,
  COMPANY_ACCOUNT.DEMO_ALLOWED_13,
  COMPANY_ACCOUNT.DEMO_ALLOWED_14,
  COMPANY_ACCOUNT.DEMO_ALLOWED_15,
  COMPANY_ACCOUNT.DEMO_ALLOWED_16,
  COMPANY_ACCOUNT.DEMO_ALLOWED_17,
  COMPANY_ACCOUNT.DEMO_ALLOWED_18,
] as const;

export const CONCRETE_DIARY_ALLOWED_USERS = [
  // polier-dev, muster-polier
  '830acde4-ef26-4411-a9b1-150957cb3a83',
  '0d9ece68-9750-4a38-8c09-37ba3319ecad',
  // grossmann: support, vizethum, wallner, polier-wallner, henken, prinz, schuster, keranovic
  '0501aefe-9d0e-490f-b347-c160ac49fab8',
  '2a3c5c0d-cb57-46ea-a6a3-739ca9e0cf7d',
  '264858ae-98ca-43ae-84ff-9fcbd92df2bd',
  '28831097-274d-41db-a5c9-97a3699ffa12',
  'f865f22e-cdbb-4e8c-b64e-eae044c10e7f',
  '9c919144-dd39-4692-8017-a0c585ac4d18',
  'fb59cbb3-082e-4969-8b81-ca194ac39ab6',
  '86197910-de17-477e-9173-9a5a4ae734ad',
  // dechant: support, hüttner, sielaff
  '451b53b2-3317-48a1-ba03-61e34f5841da',
  '2def63c0-1637-48f8-8cc4-90fc89440bb7',
  '6b90ec85-6b31-44eb-8d3b-f4301af02e5a',
] as const;

export const PERMITTED_SITE_ALLOWED_USERS = [
  '114cdae9-b1ad-4dbc-82db-49739382bc7d',
  'e3c981df-3645-4086-9051-73d34b90182b', // grossmann: dettendorfer, hülle
] as const;

// Users that are allowed to see the delayed signature tiles in the home screen.
export const DELAYED_SIGNATURE_HOME_SCREEN_ALLOWED_USER = [
  '114cdae9-b1ad-4dbc-82db-49739382bc7d',
  'e3c981df-3645-4086-9051-73d34b90182b', // Grossmann: Dettendorfer, Hülle
  'cd7b03e6-e5eb-4fac-a6a1-2d0e9c96f574', // Ochs: Giesbrecht
  'e75355e4-3fd4-4786-a56f-d5da4e111ac3', // dechant: Schmidtlein
  'ee8c1f30-f472-41e0-9c37-69fc46e55630', // Thannhauser: Burkhardt
] as const;

// Temporary function since some large class components (which will be refactored later) cannot use React hooks
export const isANBSAccount = (companyId: UUID) => {
  return ANBS_COMPANIES.includes(companyId);
};

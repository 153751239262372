import { type GridColDef } from '@mui/x-data-grid';

import { clone } from '~/utils/object';

import { type ColumnWidthModel } from '../types';

export const sizeColumns = (
  columns: GridColDef[],
  columnWidthModel: ColumnWidthModel,
) => {
  const newColumns = clone(columns);

  for (const [index, column] of newColumns.entries()) {
    const customWidth = columnWidthModel[column.field];
    if (customWidth) {
      newColumns[index].width = customWidth;
    }
  }

  return newColumns;
};

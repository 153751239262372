import { useTranslation } from 'react-i18next';
import { isRouteErrorResponse, useRouteError } from 'react-router';

import { EmptyStateIllustration } from '~/assets/illustrations';

import { ErrorPage } from './ErrorPage';

/**
 * Renders an error boundary component at the root level of the router.
 * Errors bubble up in react-router and are handled by this error boundary
 * if a route does not have its own error element.
 * @see https://reactrouter.com/en/main/route/error-element
 *
 * @return {JSX.Element} The rendered error boundary component.
 */
export const RootErrorBoundary = () => {
  const error = useRouteError();
  const { t } = useTranslation('common');

  if (!isRouteErrorResponse(error)) {
    return (
      <ErrorPage
        message={t('error.generic.message')}
        title={t('error.generic.title')}
      />
    );
  }

  if (error.status === 404) {
    return (
      <ErrorPage
        className="absolute inset-0"
        illustration={<EmptyStateIllustration className="h-64" />}
        message={t('error.404.message')}
        title={t('error.404.title')}
      />
    );
  }

  if (error.status === 401) {
    return (
      <ErrorPage
        className="absolute inset-0"
        illustration={<EmptyStateIllustration className="h-64" />}
        message={t('error.401.message')}
        title={t('error.401.title')}
      />
    );
  }

  if (error.status === 503) {
    return (
      <ErrorPage
        className="absolute inset-0"
        illustration={<EmptyStateIllustration className="h-64" />}
        message={t('error.503.message')}
        title={t('error.503.title')}
      />
    );
  }
};

import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Merges class names with a combination of clsx and tailwind-merge.
 * This allows for replacing conflicting Tailwind classes while supporting conditional class names (through clsx).
 *
 * @param inputs - The class names to merge.
 * @returns The merged class names.
 */
export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

// This class is a workaround. It is used as a storage for all global variables that need to be set synchronously.
// Asynchronous variables should be stored in the redux store.
class GlobalStorage {
  constructor() {
    this.productAnalyticsToolsInitialized = false;
  }
}

const globalStorageInstance = new GlobalStorage();

export default globalStorageInstance;

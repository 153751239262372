import { ValueGroupObject } from '~/models/deliveries/valueGroup';

import {
  DEFAULT_UNIVERSAL_COMMUNICATION,
  SALES_CONTACT,
  STRABAG_SUPPORT_CONTACT,
  VESTIGAS_SUPPORT_CONTACT,
} from './constants';
import {
  type GetSupportContactParams,
  type UniversalCommunication,
} from './types';

export const UniversalCommunicationObject = {
  /**
   * Creates a universal communication object, with email and phone.
   * If no data is provided, returns the default universal communication.
   * @param {UniversalCommunication} [universalCommunication] - The input universal communication object.
   * @returns {UniversalCommunication} - The created universal communication object.
   */
  create(
    universalCommunication?: UniversalCommunication,
  ): UniversalCommunication {
    if (!universalCommunication) {
      return DEFAULT_UNIVERSAL_COMMUNICATION;
    }

    return {
      email: universalCommunication.email || '',
      phone: universalCommunication.phone || '',
    };
  },

  /**
   * Checks if the phone has a valid value.
   * @param {string} phone - The phone number to check.
   * @returns {boolean} - True if the phone has a valid value.
   */
  hasPhone(phone?: string) {
    return ValueGroupObject.getCurrentValue(phone);
  },

  /**
   * Checks if the email has a valid value.
   * @param {string} email - The email to check.
   * @returns {boolean} - True if the email has a valid value.
   */
  hasEmail(email?: string) {
    return ValueGroupObject.getCurrentValue(email);
  },

  /**
   * Checks if the universal communication has any valid phone or email.
   * @param {UniversalCommunication} universalCommunication - The communication object to check.
   * @returns {boolean} - True if either phone or email is valid.
   */
  hasCommunicationInformation(universalCommunication?: UniversalCommunication) {
    return (
      UniversalCommunicationObject.hasPhone(universalCommunication?.phone) ||
      UniversalCommunicationObject.hasEmail(universalCommunication?.email)
    );
  },

  /**
   * Concatenates phone and email into a single string, separated by ' | '.
   * @param {Object} universalCommunication - The communication object.
   * @param {string} universalCommunication.phone - The phone number.
   * @param {string} universalCommunication.email - The email address.
   * @returns {string} - Concatenated phone and email, or an empty string if neither is valid.
   */
  getConcatenatedCommunicationInformation(universalCommunication: {
    phone: string;
    email: string;
  }): string {
    return [
      ValueGroupObject.getCurrentValue(universalCommunication.phone),
      ValueGroupObject.getCurrentValue(universalCommunication.email),
    ]
      .filter(Boolean)
      .join(' | ');
  },

  /**
   * Returns the support contact information based on whether it's a Strabag account.
   * @param {boolean} [isStrabagAccount] - Whether this is a Strabag account
   * @returns {UniversalCommunication} The support contact information - either Strabag or Vestigas support details
   */
  getSupportContact({ isStrabagAccount }: GetSupportContactParams = {}) {
    if (isStrabagAccount) {
      return STRABAG_SUPPORT_CONTACT;
    }

    return VESTIGAS_SUPPORT_CONTACT;
  },

  /**
   * Returns the sales contact information.
   * @returns {UniversalCommunication} - The sales contact information.
   */
  getSalesContact(): UniversalCommunication {
    return SALES_CONTACT;
  },
};

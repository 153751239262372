import { intersection } from '~/utils/array';
import { clone } from '~/utils/object';

import {
  DEFAULT_ROLE,
  INDIVIDUAL_ROLE,
  PERMISSIONS_AVAILABLE,
  PERMISSIONS_INITIAL,
} from './constants';
import {
  type PermissionCompanyKeys,
  type PermissionCostCenterKeys,
  type PermissionDeliveryNoteKeys,
  type PermissionInvoiceKeys,
  type PermissionOrganizationalUnitKeys,
  type PermissionPermissionAvaliableKeys,
  type PermissionPermissionKeys,
  type PermissionSiteKeys,
  type PermissionUserGroupKeys,
  type PermissionUserKeys,
  type PermissionVehicleKeys,
} from './types';

const arePermissionsEqual = (a: string[], b: string[]) => {
  if (a.length !== b.length) {
    return false;
  }

  return a
    .toSorted()
    .every((permission, index) => permission === b.toSorted()[index]);
};

export const PermissionsObject = {
  company: PERMISSIONS_INITIAL.COMPANY,
  costCenter: PERMISSIONS_INITIAL.COST_CENTER,
  deliveryNote: PERMISSIONS_INITIAL.DELIVERY_NOTE,
  invoice: PERMISSIONS_INITIAL.INVOICE,
  organisationalGroup: PERMISSIONS_INITIAL.ORGANIZATIONAL_UNIT,
  permission: PERMISSIONS_INITIAL.PERMISSION,
  site: PERMISSIONS_INITIAL.SITE,
  user: PERMISSIONS_INITIAL.USER,
  userGroup: PERMISSIONS_INITIAL.USER_GROUP,
  vehicle: PERMISSIONS_INITIAL.VEHICLE,

  /**
   * Create a new instance of the PermissionsObject with frontend permissions from the given backend permissions.
   */
  create(backendPermissions: string[] = []) {
    const instance = clone(this);
    if (backendPermissions.length === 0) {
      return instance;
    }

    for (const x of Object.keys(this.PERMISSION)) {
      if (
        intersection(
          this.PERMISSION[x as PermissionPermissionAvaliableKeys].BACKEND,
          backendPermissions,
        ).length === 0
      ) {
        continue;
      }

      const frontendPermission =
        this.PERMISSION[x as PermissionPermissionAvaliableKeys].WEBAPP.split(
          '.',
        );

      instance[frontendPermission[0]][frontendPermission[1]] = true;
    }

    return instance;
  },

  /**
   * Get granted frontend permissions.
   */
  getGrantedPermissions() {
    const grantedPermissions: string[] = [];

    const permissionGroups = {
      company: this.company as Record<PermissionCompanyKeys, boolean>,
      costCenter: this.costCenter as Record<PermissionCostCenterKeys, boolean>,
      deliveryNote: this.deliveryNote as Record<
        PermissionDeliveryNoteKeys,
        boolean
      >,
      invoice: this.invoice as Record<PermissionInvoiceKeys, boolean>,
      organisationalGroup: this.organisationalGroup as Record<
        PermissionOrganizationalUnitKeys,
        boolean
      >,
      permission: this.permission as Record<PermissionPermissionKeys, boolean>,
      site: this.site as Record<PermissionSiteKeys, boolean>,
      user: this.user as Record<PermissionUserKeys, boolean>,
      userGroup: this.userGroup as Record<PermissionUserGroupKeys, boolean>,
      vehicle: this.vehicle as Record<PermissionVehicleKeys, boolean>,
    };

    for (const [entity, permissions] of Object.entries(permissionGroups)) {
      for (const [action, hasPermission] of Object.entries(permissions)) {
        if (hasPermission) {
          grantedPermissions.push(`${entity}.${action}`);
        }
      }
    }

    return grantedPermissions.sort();
  },

  /**
   * Check if any permission is granted.
   */
  permissionGranted() {
    return this.getGrantedPermissions().length > 0;
  },

  /**
   * Get backend permissions based on granted frontend permissions.
   */
  getBackendPermissions() {
    const backendPermissions: string[] = [];

    for (const permissionKey of Object.keys(this.PERMISSION)) {
      const [entity, action] =
        this.PERMISSION[
          permissionKey as PermissionPermissionAvaliableKeys
        ].WEBAPP.split('.');

      if (this[entity][action] === true) {
        const newBackendPermission =
          this.PERMISSION[permissionKey as PermissionPermissionAvaliableKeys]
            .BACKEND;

        backendPermissions.push(...newBackendPermission);
      }
    }

    return backendPermissions;
  },

  initPermissions(backendPermissions: string[] = []) {
    if (!backendPermissions) {
      return;
    }

    for (const x of Object.keys(this.PERMISSION)) {
      if (
        intersection(
          this.PERMISSION[x as PermissionPermissionAvaliableKeys].BACKEND,
          backendPermissions,
        ).length === 0
      ) {
        continue;
      }

      const frontendPermission =
        this.PERMISSION[x as PermissionPermissionAvaliableKeys].WEBAPP.split(
          '.',
        );

      this[frontendPermission[0]][frontendPermission[1]] = true;
    }
  },

  /**
   * Set the permission values based on a given default role.
   */
  initWithDefaultRole(defaultRoleName: string) {
    if (!defaultRoleName) {
      return;
    }

    const defaultPermissions = Object.values(this.DEFAULT_ROLE).find(
      (defaultRole) => defaultRole.NAME === defaultRoleName,
    )?.PERMISSIONS;

    if (defaultPermissions) {
      for (const defaultPermission of defaultPermissions) {
        const frontendPermission = defaultPermission.split('.');

        this[frontendPermission[0]][frontendPermission[1]] = true;
      }
    }
  },

  getDefaultRoleName() {
    const grantedPermissions = this.getGrantedPermissions();

    if (grantedPermissions.length === 0) {
      return null;
    }

    const defaultRole = Object.entries(this.DEFAULT_ROLE).find(([_, value]) =>
      arePermissionsEqual(grantedPermissions, value.PERMISSIONS),
    )?.[1];

    if (defaultRole) {
      return defaultRole.NAME;
    }

    return this.INDIVIDUAL_ROLE;
  },

  /**
   * Get selectable roles.
   */
  getPickableRoles() {
    const pickableRoles = [
      {
        id: this.INDIVIDUAL_ROLE,
        name: this.INDIVIDUAL_ROLE,
      },
    ];

    for (const defaultRole of Object.values(this.DEFAULT_ROLE)) {
      pickableRoles.push({
        id: defaultRole.NAME,
        name: defaultRole.NAME,
      });
    }

    return pickableRoles;
  },

  /**
   * Check if the user can read (view) delivery notes.
   */
  canReadDeliveryNotes() {
    return (
      this.deliveryNote.accessIncoming ||
      this.deliveryNote.accessOutgoing ||
      this.deliveryNote.accessTransported
    );
  },

  // Static mapping of frontend permissions to backend permissions
  PERMISSION: PERMISSIONS_AVAILABLE,

  // Default roles and their associated permissions
  DEFAULT_ROLE,

  // Default role for individual users
  INDIVIDUAL_ROLE,
};

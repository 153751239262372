import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/LoadingState';

const dataExchangesSlice = createSlice({
  initialState: {
    columnCount: {},
    currentIssues: false,
    dataExchangeCompanies: [],
    dataExchangeCompaniesLoading: LOADING_STATE.NOT_LOADED,
    dataExchanges: [],
    dataExchangesLoading: LOADING_STATE.NOT_LOADED,
    eligiblePeople: [],
    // for new supplier portal view
    responsiblePeople: [],
    suppliers: [],
    suppliersLoading: LOADING_STATE.NOT_LOADED,
  },
  name: 'dataExchanges',
  reducers: {
    setColumnCount(state, parameter) {
      const { payload } = parameter;
      state.columnCount = payload;
    },
    setCurrentIssues(state, parameter) {
      const { payload } = parameter;
      state.currentIssues = payload;
    },
    setDataExchangesLoading(state, parameter) {
      const { payload } = parameter;

      state.dataExchangesLoading = payload;
    },
    setEligiblePeople(state, parameter) {
      const { payload } = parameter;
      state.eligiblePeople = payload;
    },

    setResponsiblePeople(state, parameter) {
      const { payload } = parameter;
      state.responsiblePeople = payload;
    },

    setSuppliersLoading(state, parameter) {
      const { payload } = parameter;
      state.suppliersLoading = payload;
    },

    setUpdatedSuppliers(state, parameter) {
      const { payload } = parameter;

      state.suppliers = payload;
      state.suppliersLoading = LOADING_STATE.SUCCEEDED;
    },
  },
});

const { actions, reducer } = dataExchangesSlice;
export const {
  setColumnCount,
  setCurrentIssues,
  setDataExchangesLoading,
  setEligiblePeople,
  setResponsiblePeople,
  setSuppliersLoading,
  setUpdatedSuppliers,
} = actions;
export default reducer;

export const ACCEPT_ARTICLE_ACCEPT_STATUS = {
  DAMAGED: {
    DESCRIPTION: 'beschädigt',
    KEY: 'damaged',
  },
  DECLINED: {
    DESCRIPTION: 'storniert',
    KEY: 'declined',
  },
  DELTA: {
    DESCRIPTION: 'überschüssig/fehlt',
    KEY: 'delta',
  },
  VALID: {
    DESCRIPTION: 'erhalten',
    KEY: 'valid',
  },
  WRONG: {
    DESCRIPTION: 'falsch',
    KEY: 'wrong',
  },
} as const;

export const ACCEPT_STATE_CALCULATOR_ACCEPT_STATE = {
  APPROVED: 'Angenommen',
  DECLINED: 'Abgelehnt',
  OPEN: 'Offen',
} as const;

export const ACCEPT_STATE_BACKEND_ACCEPT_STATE_KEY = {
  APPROVED: 'accepted',
  DECLINED: 'rejected',
  OPEN: 'open',
} as const;

export const PROCESS_STATE_OPTIONS = [
  'In Transport',
  'Übergabe',
  'Storniert',
  'Geliefert',
] as const;

export const PROCESS_STATE_GELIEFERT = 'Geliefert';

export const ACCEPT_STATE_DESCRIPTION = {
  APPROVED: 'Zeige Lieferungen an, welche vom Abnehmer angenommen wurden.',
  DECLINED:
    'Zeige Lieferungen an, welche von mindestens einer Partei abgelehnt wurden.',
  OPEN: 'Zeige Lieferungen an, bei denen die Signatur des Abnehmers oder des Lieferanten noch aussteht.',
} as const;

import {
  useQueries,
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';
import { type Permission } from '~/types/permission';

import { vestigasApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';

import { queryKeysOrganizationalUnit } from './queryKeys';

export type OrganizationalUnit = {
  accountingReferences: UUID[]; // aka cost centers
  childOus: UUID[];
  companies: UUID[];
  id: UUID;
  name: string;
  orgUnitPaths: UUID[][];
  parentOus: UUID[];
  sites: UUID[];
  userGroups: UUID[];
  users: UUID[];
  vehicles: UUID[];
  permissionsFrom: Array<{
    id: number;
    subjectId: UUID;
    subjectType:
      | 'accounting_reference'
      | 'company'
      | 'org_unit'
      | 'site'
      | 'user_group'
      | 'user'
      | 'vehicle';
    permissions: Permission[];
  }>;
};

export const fetchOrganizationalUnit = async (organizationalUnitId: UUID) => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.ORGANIZATIONAL_UNIT.GET(organizationalUnitId))
      .json<OrganizationalUnit>();

    return response;
  } catch (error) {
    Log.error('Error fetching organizational unit', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

export const getOrganizationalUnitQueryOptions = ({
  organizationalUnitId,
  options,
}: {
  organizationalUnitId: UUID;
  options?: Parameters<typeof useQuery>[0];
}) => ({
  queryFn: async () => fetchOrganizationalUnit(organizationalUnitId),
  queryKey: queryKeysOrganizationalUnit.get(organizationalUnitId),
  ...options,
});

export const useQueryOrganizationalUnit = (
  organizationalUnitId: UUID,
  options?: Omit<
    UseQueryOptions<OrganizationalUnit | undefined>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery(
    getOrganizationalUnitQueryOptions({ options, organizationalUnitId }),
  );

/**
 * React Query based custom hook for getting data for multiple organizational units based on a list of organizational unit IDs.
 * @param {UUID[]} organizationalUnitIds - Array of organizational unit IDs to fetch.
 * @param {Object} options - Additional options for the useQueries hook.
 * @param {Function} combine - Optional function to process the results of the different queries.
 * @returns {UseQueryResult<OrganizationalUnit | undefined>[]} Array of query results for each organizational unit.
 *                                                  If a combine function is provided, the results will be transformed
 *                                                  according to that function.
 */
export const useQueriesOrganizationalUnits = (
  organizationalUnitIds: UUID[],
  options?: Omit<
    UseQueryOptions<OrganizationalUnit | undefined>,
    'queryKey' | 'queryFn'
  >,
  combine?: (
    results: Array<UseQueryResult<OrganizationalUnit | undefined>>,
  ) => unknown,
) =>
  useQueries({
    combine,
    queries: organizationalUnitIds.map((organizationalUnitId) => ({
      enabled: Boolean(organizationalUnitId),
      queryFn: async () => fetchOrganizationalUnit(organizationalUnitId),
      queryKey: queryKeysOrganizationalUnit.get(organizationalUnitId),
      ...options,
    })),
  });

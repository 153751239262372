import BasicForm from './BasicForm';

type P = {
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
  readonly isOpen: boolean;
};

export const BasicFormConfirmAbort = ({ onCancel, onConfirm, isOpen }: P) => {
  return (
    <BasicForm
      formAbort={onCancel}
      formSuccess={onConfirm}
      open={isOpen}
      submitButtonTitle="Fortfahren"
      title="Ungespeicherte Änderungen"
    >
      Ungespeicherte Änderungen gehen verloren. Willst du wirklich fortfahren?
    </BasicForm>
  );
};

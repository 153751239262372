export const ARTICLE_MATERIAL = {
  AC: {
    DESCRIPTIVE: 'Zusatzmittel Erhärtungs-/Erstarrungsbeschleuniger',
    STANDARDISED: 'AC',
  },
  AD: {
    DESCRIPTIVE: 'Zusatzmittel Luftentzieher',
    STANDARDISED: 'AD',
  },
  CE: {
    DESCRIPTIVE: 'Zement',
    STANDARDISED: 'CE',
  },
  CP: {
    DESCRIPTIVE: 'Zusatzmittel Stabilisierer',
    STANDARDISED: 'CP',
  },
  DEFAULT: {
    // as fallback if no other material fits
    DESCRIPTIVE: 'Inhaltsstoff',
  },
  DP: {
    DESCRIPTIVE: 'Zusatzmittel Dichtungsmittel',
    STANDARDISED: 'DP',
  },
  GF: {
    DESCRIPTIVE: 'Zusatzmittel Luftporenbildner',
    STANDARDISED: 'GF',
  },
  OA: {
    DESCRIPTIVE: 'Zusatzstoff',
    STANDARDISED: 'OA',
  },
  OM: {
    DESCRIPTIVE: 'Andere Zusatzmittel',
    STANDARDISED: 'OM',
  },
  PZ: {
    DESCRIPTIVE: 'Zusatzmittel Puzzolanzement',
    STANDARDISED: 'PZ',
  },
  RT: {
    DESCRIPTIVE: 'Zusatzmittel Verzögerer',
    STANDARDISED: 'RT',
  },
  SP: {
    DESCRIPTIVE: 'Zusatzmittel Fließmittel',
    STANDARDISED: 'SP',
  },
  WR: {
    DESCRIPTIVE: 'Zusatzmittel Betonverflüssiger',
    STANDARDISED: 'WR',
  },
  WT: {
    DESCRIPTIVE: 'Wasser',
    STANDARDISED: 'WT',
  },
} as const;

export const ARTICLE_DELIVERY_DIRECTION = {
  DELIVERED_ARTICLES: {
    KEY: 'delivered_articles',
    STRING: 'Auslieferung',
  },
  RETURNED_ARTICLES: {
    KEY: 'returned_articles',
    STRING: 'Retoure',
  },
} as const;

// exclude some paths when evaluating whether an article has been edited
export const ARTICLE_CHANGES_BOOLEAN_EXCLUDED_PATHS = [
  ['number'], // number is already shown in article list and thus doesn't need extra highlighting by edited icon
  ['type'], // same as for number
  ['costCenter'], // cost center is common to be updated, so it's excluded from evaluating whether there were "real" changes to the article
  ['amount'], // same as for number
  ['weight'], // same as for number
] as const;

export const CONCRETE_STRENGTH_CLASSES = [
  'C8/10',
  'C12/15',
  'C16/20',
  'C20/25',
  'C25/30',
  'C30/37',
  'C35/45',
  'C40/50',
  'C45/55',
  'C50/60',
  'C55/67',
  'C60/75',
  'C70/85',
  'C80/95',
  'C90/105',
  'C100/115',
] as const;

export const CONCRETE_LARGEST_GRAINS = [
  '1',
  '2',
  '4',
  '8',
  '16',
  '22',
  '32',
] as const;

export const CONCRETE_MONITORING_CLASS_2 = {
  EXPOSURE_CLASSES: ['XS', 'XD', 'XA', 'XM', 'XF2', 'XF3', 'XF4'],
  MINIMUM_STRENGTH_CLASS: 'C30/37',
} as const;

export const CONCRETE_STRENGTH_DEVELOPMENT = {
  FAST: {
    DESCRIPTIVE: 'schnell',
    STANDARDISED: 'fast',
  },
  MIDDLE: {
    DESCRIPTIVE: 'mittel',
    STANDARDISED: 'middle',
  },
  SLOW: {
    DESCRIPTIVE: 'langsam',
    STANDARDISED: 'slow',
  },
} as const;

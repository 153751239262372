import { type GridApi } from '@mui/x-data-grid-pro';

import ExportService from '~/services/export.service';

import { type EntityType } from '~/components/settings/SettingsTable/types';

import { type ToolbarItem } from './DatagridServerDriven';

export const exportCsvClientSide = (entity: EntityType): ToolbarItem => ({
  id: 'export-csv-client-side',
  icon: 'excel',
  title: 'Daten exportieren',
  actions: [
    {
      name: 'Exportieren als CSV',
      onClick(apiRef: GridApi) {
        apiRef.current.exportDataAsCsv({
          fileName: ExportService.generateFileName({
            entityType: ExportService.ENTITIES[entity],
            format: '',
          }),
        });
      },
    },
  ],
});

import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  mapSpecialKeyCharacter,
  specialKeysOfHotkey,
  useHotkeysContext,
} from '~/utils/useHotkeys';

import { Icon } from '~/ui/atoms/Icon';
import { KeyboardKey } from '~/ui/atoms/KeyboardKey';
import { Button } from '~/ui/molecules/Button';

/**
 * Renders an icon button that opens a modal with a list of available hotkeys.
 *
 * @return The rendered hotkeys legend.
 */
export const HotkeysLegend = ({ className, style }: ComponentStyling) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const { hotkeys } = useHotkeysContext();

  if (!hotkeys.map(({ description }) => description).some(Boolean)) {
    // No descriptions to display.
    return null;
  }

  return (
    <>
      <Button
        className={clsx(
          'transition-opacity duration-300',
          {
            '!hidden opacity-0': hotkeys.length === 0,
          },
          className,
        )}
        icon={<Icon metaphor="keyboard" />}
        style={style}
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <DialogTitle>{t('hotkey.legend.title')}</DialogTitle>
        <DialogContent>
          <table className="table-auto border-separate border-spacing-y-1">
            <tbody>
              {hotkeys.map((hotkey) => {
                if (!hotkey.description) {
                  // No description to display.
                  return null;
                }

                // Show special keys first
                const keys = [
                  ...specialKeysOfHotkey(hotkey),
                  ...(hotkey.keys ?? []),
                ].map((key) => mapSpecialKeyCharacter(key));

                return (
                  <tr key={keys.join('')} className="py-0.5">
                    <td className="space-x-1 pr-4 whitespace-nowrap">
                      {keys.map((key) => (
                        <KeyboardKey key={key}>{key}</KeyboardKey>
                      ))}
                    </td>
                    <td>{hotkey.description}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
    </>
  );
};

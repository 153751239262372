export const DATA_EXCHANGE_COMPANY_TYPE = {
  DATA_EXCHANGE: 'dataExchange',
  DATA_EXCHANGE_COMPANY: 'dataExchangeCompany',
} as const;

export const DATA_EXCHANGE_COMPANY_STATUS_ORDER = [
  'ACTIVATED',
  'LIVE',
  'QR_CODE',
  'TEST_PHASE',
  'IMPLEMENTATION_SUPPLIER',
  'IMPLEMENTATION_VESTIGAS',
  'TEST_DATA_EXCHANGED',
  'CONFIRMED',
  'ON_HOLD_SUPPLIER',
  'IN_QUALIFICATION',
  'INTERESTED',
  'NO_STATUS',
  'CURRENT_ISSUES',
] as const;

export const DATA_EXCHANGE_COMPANY_STATUS = {
  ACTIVATED: {
    ABBREVIATED_LABEL: 'Freigeschaltet',
    DESCRIPTION:
      'Die Schnittstelle wurde freigeschaltet und es können Daten ausgetauscht werden',
    HIGHLIGHTED: true,
    KEY: 'active', // backend sends "active"
    LABEL: 'Freigeschaltet',
  },
  CONFIRMED: {
    ABBREVIATED_LABEL: 'Zusage erteilt',
    DESCRIPTION:
      'Der Lieferant will mit der Umsetzung beginnen und VESTIGAS wartet auf erste Testdatensätze',
    KEY: 'confirmed',
    LABEL: 'Zusage erteilt',
  },
  CURRENT_ISSUES: {
    ABBREVIATED_LABEL: 'Aktuelle Probleme',
    DESCRIPTION:
      'Derzeit gibt es Übertragungsschwierigkeiten bei diesem Lieferanten',
    KEY: 'current_issues',
    LABEL: 'Aktuelle Probleme',
  },
  IMPLEMENTATION_SUPPLIER: {
    ABBREVIATED_LABEL: 'Impl. Lieferant',
    DESCRIPTION:
      'Ggf. notwendige Anpassungen an der Schnittstelle werden vom Lieferanten implementiert',
    KEY: 'implementation_supplier',
    LABEL: 'Implementierung Lieferant',
  },
  IMPLEMENTATION_VESTIGAS: {
    ABBREVIATED_LABEL: 'Impl. VESTIGAS',
    DESCRIPTION: 'Ein notwendiger Datenparser wird von VESTIGAS implementiert',
    KEY: 'implementation_vestigas',
    LABEL: 'Implementierung VESTIGAS',
  },
  IN_QUALIFICATION: {
    ABBREVIATED_LABEL: 'Gespräch mit Lieferant',
    DESCRIPTION:
      'Der Lieferant hat sich zurückgemeldet und fixiert ein Gespräch mit VESTIGAS',
    KEY: 'in_qualification',
    LABEL: 'Gespräch mit Lieferant',
  },
  INTERESTED: {
    ABBREVIATED_LABEL: 'Lieferant aufgenommen',
    DESCRIPTION:
      'Der Lieferant wurde von einem Abnehmer gemeldet und in VESTIGAS aufgenommen. Bei Interesse bitte den Lieferanten kontaktieren.',
    KEY: 'interested',
    LABEL: 'Lieferant aufgenommen',
  },
  LIVE: {
    ABBREVIATED_LABEL: 'Freischaltung möglich',
    DESCRIPTION: 'Produktive Anbindung',
    HIGHLIGHTED: true,
    KEY: 'live',
    LABEL: 'Freischaltung möglich',
  },
  NO_STATUS: {
    ABBREVIATED_LABEL: 'Kein Status',
    DESCRIPTION: 'Kein Status vergeben',
    KEY: 'non_existant',
    LABEL: 'Kein Status',
  },
  ON_HOLD_SUPPLIER: {
    ABBREVIATED_LABEL: 'Absprachen Lieferant',
    DESCRIPTION:
      'Lieferant benötigt Zeit, technische Möglichkeiten intern abzuklären oder die benötigten Ressourcen bereitzustellen',
    KEY: 'on_hold_supplier',
    LABEL: 'Interne Absprachen beim Lieferant',
  },
  QR_CODE: {
    ABBREVIATED_LABEL: 'QR-Code',
    DESCRIPTION: 'Lieferungen können per QR-Code Scan eingelesen werden.',
    HIGHLIGHTED: true,
    KEY: 'qr_code',
    LABEL: 'QR-Code (Freischaltung möglich)',
  },
  TEST_DATA_EXCHANGED: {
    ABBREVIATED_LABEL: 'Testdaten',
    DESCRIPTION:
      'Erste Testdaten wurden zwischen VESTIGAS und dem Lieferanten ausgetauscht',
    KEY: 'test_data_exchanged',
    LABEL: 'Testdaten wurden ausgetauscht',
  },
  TEST_PHASE: {
    ABBREVIATED_LABEL: 'Testphase',
    DESCRIPTION:
      'Die Schnittstelle wurde hergestellt, befindet sich aber noch in einer Testphase (bevor ein Dauerbetrieb möglich ist)',
    KEY: 'test_phase',
    LABEL: 'Testphase',
  },
} as const;

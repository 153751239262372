import { sortByKey, sortByKeyValues, unique } from '~/utils/array';

import {
  INVOICE_CHECK_CATEGORY_CATEGORIES,
  INVOICE_CHECK_CATEGORY_CHART_CATEGORIES,
} from './constants';
import { InvoiceCheckResultObject } from './invoiceCheckResultUtils';
import {
  type InvoiceCheckCategory,
  type InvoiceCheckResultCreatedObject,
} from './types';

const sortResults = (
  checkResults: InvoiceCheckResultCreatedObject[],
  sortedChecks: string[],
) => {
  const resultsWithDlnNumber = checkResults.map((result) => ({
    ...result,
    dlnNumber: result.deliveryNotes[0]?.number,
  }));

  return sortByKeyValues(
    sortByKey(resultsWithDlnNumber, 'articleName'),
    sortedChecks,
    'name',
  );
};

export const InvoiceCheckCategoryObject = {
  CATEGORIES: INVOICE_CHECK_CATEGORY_CATEGORIES,
  CHART_CATEGORIES: INVOICE_CHECK_CATEGORY_CHART_CATEGORIES,

  /**
   * Creates an invoice check category object by filtering and categorizing check results.
   * @param {InvoiceCheckCategory} category - The category object which contains the check names and other properties.
   * @param {CheckResult[]} checkResults - An array of check results that need to be categorized.
   * @returns {Object} - The categorized invoice check results.
   */
  create(
    category: InvoiceCheckCategory,
    checkResults: InvoiceCheckResultCreatedObject[],
  ) {
    const categorizedChecks = checkResults.reduce(
      (accumulator, checkResult) => {
        if (!category.CHECKS.includes(checkResult.name)) {
          return accumulator;
        }

        const { status } = checkResult;
        switch (status) {
          case InvoiceCheckResultObject.STATUS.ERROR: {
            accumulator.errorChecks.push(checkResult);

            break;
          }

          case InvoiceCheckResultObject.STATUS.SUCCESS: {
            accumulator.successChecks.push(checkResult);

            break;
          }

          case InvoiceCheckResultObject.STATUS.NA: {
            accumulator.manualChecks.push(checkResult);

            break;
          }

          // No default
        }

        return accumulator;
      },
      {
        errorChecks: [] as InvoiceCheckResultCreatedObject[],
        manualChecks: [] as InvoiceCheckResultCreatedObject[],
        successChecks: [] as InvoiceCheckResultCreatedObject[],
      },
    );

    const { errorChecks, manualChecks, successChecks } = categorizedChecks;
    const delayedSuccessChecks: any[] = [];

    const name =
      errorChecks.length > 0 ? category.ERROR_NAME : category.SUCCESS_NAME;

    const status = this.getStatus(
      category.KEY,
      errorChecks.length,
      successChecks.length,
    );

    const sortedChecks = category.CHECKS;
    const sortedResults = this.sortCheckResults(
      {
        errorChecks,
        manualChecks,
        successChecks,
      },
      sortedChecks,
    );

    return {
      delayedSuccessChecks,
      errorChecks: sortedResults.errorChecks,
      key: category.KEY,
      manualChecks: sortedResults.manualChecks,
      name,
      status,
      successChecks: sortedResults.successChecks,
    };
  },

  /**
   * Determines the status of the invoice check category based on the number of error and success checks.
   * The status can be ERROR, SUCCESS, WARNING, or MANUAL, depending on the category and the number of checks in each status.
   *
   * @param {string} key - The key of the check category.
   * @param {number} errorChecksLength - The number of error checks for the category.
   * @param {number} successChecksLength - The number of success checks for the category.
   * @returns {string} - The status of the invoice check category.
   */
  getStatus(
    key: string,
    errorChecksLength: number,
    successChecksLength: number,
  ) {
    if (
      key === this.CATEGORIES.AMOUNT_APPROVED_CHECK.KEY &&
      errorChecksLength === 0
    ) {
      return InvoiceCheckResultObject.STATUS.SUCCESS;
    }

    if (key === this.CATEGORIES.SIGNATURE_CHECK.KEY && errorChecksLength > 0) {
      return InvoiceCheckResultObject.STATUS.WARNING;
    }

    if (errorChecksLength > 0) {
      return InvoiceCheckResultObject.STATUS.ERROR;
    }

    if (successChecksLength > 0) {
      return InvoiceCheckResultObject.STATUS.SUCCESS;
    }

    return InvoiceCheckResultObject.STATUS.MANUAL;
  },

  /**
   * Sorts the check results (error, success, and manual) based on delivery note number and article name.
   * The results are sorted first by `dlnNumber` (delivery note number), then by `articleName`, and finally by the order of the checks defined in the `sortedChecks` array.
   *
   * @param {Object} checkResults - An object containing arrays of check results categorized as error, success, and manual.
   * @param {string[]} sortedChecks - An array of check names that defines the order in which the checks should be sorted.
   * @returns {Object} - An object containing the sorted check results for each category (error, success, and manual).
   */
  sortCheckResults(
    { errorChecks, successChecks, manualChecks }: any,
    sortedChecks: string[],
  ) {
    return {
      errorChecks: sortResults(errorChecks, sortedChecks),
      successChecks: sortResults(successChecks, sortedChecks),
      manualChecks: sortResults(manualChecks, sortedChecks),
    };
  },

  /**
   * Retrieves the IDs of unsigned delivery notes from the error checks.
   * It filters the error checks to find those related to 'DeliveryNoteAuthorized' and then extracts the IDs of the delivery notes that are not signed (i.e., those without an ID).
   *
   * @param {any[]} errorChecks - An array of error check results to search through.
   * @returns {string[]} - An array of unsigned delivery note IDs.
   */
  getUnsignedDeliveryNoteIds(errorChecks: any[]) {
    return unique(
      errorChecks
        .filter(({ name }) => name === 'DeliveryNoteAuthorized')
        .flatMap(({ deliveryNotes }) =>
          deliveryNotes.map(({ id }) => id).filter(Boolean),
        ),
    );
  },
};

import {
  setDashboard_selectedAcceptState,
  setDashboard_selectedArticle,
  setDashboard_selectedArticleNumber,
  setDashboard_selectedCostCenter,
  setDashboard_selectedCustomerNumber,
  setDashboard_selectedCustomFields,
  setDashboard_selectedFromSite,
  setDashboard_selectedPermittedCostCenters,
  setDashboard_selectedPermittedToSites,
  setDashboard_selectedProcessState,
  setDashboard_selectedRecipient,
  setDashboard_selectedSettledStatus,
  setDashboard_selectedSupplier,
  setDashboard_selectedToSiteRecipient,
  setDashboard_selectedToSiteSupplier,
  setDashboard_selectedToSiteSupplierTradeContact,
  setDelivery_selectedAcceptState,
  setDelivery_selectedArticle,
  setDelivery_selectedArticleNumber,
  setDelivery_selectedCategory,
  setDelivery_selectedCostCenter,
  setDelivery_selectedCustomerNumber,
  setDelivery_selectedCustomFields,
  setDelivery_selectedFromSite,
  setDelivery_selectedNumber,
  setDelivery_selectedOuId,
  setDelivery_selectedPermittedCostCenters,
  setDelivery_selectedPermittedToSites,
  setDelivery_selectedProcessState,
  setDelivery_selectedRecipient,
  setDelivery_selectedSettledStatus,
  setDelivery_selectedSupplier,
  setDelivery_selectedToSiteRecipient,
  setDelivery_selectedToSiteSupplier,
} from '~/redux/filtersSlice';

const FILTER = {
  SELECTED_ACCEPT_STATE: 'selectedAcceptState',
  SELECTED_ARTICLE_NUMBER: 'selectedArticleNumber',
  SELECTED_ARTICLE: 'selectedArticle',
  SELECTED_BUYER: 'selectedBuyer',
  SELECTED_CATEGORY: 'selectedCategory',
  SELECTED_COST_CENTER: 'selectedCostCenter',
  SELECTED_CUSTOM_FIELDS: 'selectedCustomFields',
  SELECTED_CUSTOMER_NUMBER: 'selectedCustomerNumber',
  SELECTED_FROM_SITE: 'selectedFromSite',
  SELECTED_NUMBER: 'selectedNumber',
  SELECTED_OU_ID: 'selectedOuId',
  SELECTED_PERMITTED_COST_CENTERS: 'selectedPermittedCostCenters',
  SELECTED_PERMITTED_TO_SITES: 'selectedPermittedToSites',
  SELECTED_PROCESS_STATE: 'selectedProcessState',
  SELECTED_RECIPIENT: 'selectedRecipient',
  SELECTED_SELLER: 'selectedSeller',
  SELECTED_SETTLED_STATUS: 'selectedSettledStatus',
  SELECTED_STATUS: 'selectedStatus',
  SELECTED_SUPPLIER: 'selectedSupplier',
  SELECTED_TO_SITE_RECIPIENT: 'selectedToSiteRecipient',
  SELECTED_TO_SITE_SUPPLIER_TRADE_CONTACT: 'selectedToSiteSupplierTradeContact',
  SELECTED_TO_SITE_SUPPLIER: 'selectedToSiteSupplier',
  SELECTED_TO_SITE: 'selectedToSite',
};

const DATA_TYPE_MAPPING = [
  ['SELECTED_ACCEPT_STATE'],
  ['SELECTED_ARTICLE'],
  ['SELECTED_BUYER'],
  ['SELECTED_CATEGORY'],
  ['SELECTED_COST_CENTER'],
  ['SELECTED_CUSTOM_FIELDS'],
  ['SELECTED_FROM_SITE'],
  ['SELECTED_NUMBER'],
  ['SELECTED_OU_ID'],
  ['SELECTED_PERMITTED_COST_CENTERS'],
  ['SELECTED_PERMITTED_TO_SITES'],
  ['SELECTED_PROCESS_STATE'],
  ['SELECTED_RECIPIENT'],
  ['SELECTED_SELLER'],
  ['SELECTED_SETTLED_STATUS'],
  ['SELECTED_STATUS'],
  ['SELECTED_SUPPLIER'],
  ['SELECTED_TO_SITE_RECIPIENT'],
  ['SELECTED_TO_SITE_SUPPLIER_TRADE_CONTACT'],
  ['SELECTED_TO_SITE_SUPPLIER'],
  ['SELECTED_TO_SITE'],
].map(([filter, dataType = 'string']) => ({
  DATA_TYPE: dataType,
  FILTER_GROUP_FILTER: FILTER[filter],
}));

const REDUX_VARIABLE = {
  DASHBOARD_SELECTED_ACCEPT_STATE: 'dashboard_selectedAcceptState',
  DASHBOARD_SELECTED_ARTICLE: 'dashboard_selectedArticle',
  DASHBOARD_SELECTED_ARTICLE_NUMBER: 'dashboard_selectedArticleNumber',
  DASHBOARD_SELECTED_COST_CENTER: 'dashboard_selectedCostCenter',
  DASHBOARD_SELECTED_CUSTOMER_NUMBER: 'dashboard_selectedCustomerNumber',
  DASHBOARD_SELECTED_CUSTOM_FIELDS: 'dashboard_selectedCustomFields',
  DASHBOARD_SELECTED_FROM_SITE: 'dashboard_selectedFromSite',
  DASHBOARD_SELECTED_PERMITTED_COST_CENTERS:
    'dashboard_selectedPermittedCostCenters',
  DASHBOARD_SELECTED_PERMITTED_TO_SITES: 'dashboard_selectedPermittedToSites',
  DASHBOARD_SELECTED_PROCESS_STATE: 'dashboard_selectedProcessState',
  DASHBOARD_SELECTED_RECIPIENT: 'dashboard_selectedRecipient',
  DASHBOARD_SELECTED_SETTLED_STATUS: 'dashboard_selectedSettledStatus',
  DASHBOARD_SELECTED_SUPPLIER: 'dashboard_selectedSupplier',
  DASHBOARD_SELECTED_TO_SITE_RECIPIENT: 'dashboard_selectedToSiteRecipient',
  DASHBOARD_SELECTED_TO_SITE_SUPPLIER: 'dashboard_selectedToSiteSupplier',
  DASHBOARD_SELECTED_TO_SITE_SUPPLIER_TRADE_CONTACT:
    'dashboard_selectedToSiteSupplierTradeContact',
  DELIVERY_SELECTED_ACCEPT_STATE: 'delivery_selectedAcceptState',
  DELIVERY_SELECTED_ARTICLE: 'delivery_selectedArticle',
  DELIVERY_SELECTED_ARTICLE_NUMBER: 'delivery_selectedArticleNumber',
  DELIVERY_SELECTED_CATEGORY: 'delivery_selectedCategory',
  DELIVERY_SELECTED_COST_CENTER: 'delivery_selectedCostCenter',
  DELIVERY_SELECTED_CUSTOMER_NUMBER: 'delivery_selectedCustomerNumber',
  DELIVERY_SELECTED_CUSTOM_FIELDS: 'delivery_selectedCustomFields',
  DELIVERY_SELECTED_FROM_SITE: 'delivery_selectedFromSite',
  DELIVERY_SELECTED_NUMBER: 'delivery_selectedNumber',
  SELECTED_OU_ID: 'delivery_selectedOuId',
  DELIVERY_SELECTED_PERMITTED_COST_CENTERS:
    'delivery_selectedPermittedCostCenters',
  DELIVERY_SELECTED_PERMITTED_TO_SITES: 'delivery_selectedPermittedToSites',
  DELIVERY_SELECTED_PROCESS_STATE: 'delivery_selectedProcessState',
  DELIVERY_SELECTED_RECIPIENT: 'delivery_selectedRecipient',
  DELIVERY_SELECTED_SETTLED_STATUS: 'delivery_selectedSettledStatus',
  DELIVERY_SELECTED_SUPPLIER: 'delivery_selectedSupplier',
  DELIVERY_SELECTED_TO_SITE_RECIPIENT: 'delivery_selectedToSiteRecipient',
  DELIVERY_SELECTED_TO_SITE_SUPPLIER: 'delivery_selectedToSiteSupplier',
};

const REDUX_SETTER_FUNCTION = {
  SET_DASHBOARD_SELECTED_ACCEPT_STATE: setDashboard_selectedAcceptState,
  SET_DASHBOARD_SELECTED_ARTICLE: setDashboard_selectedArticle,
  SET_DASHBOARD_SELECTED_ARTICLE_NUMBER: setDashboard_selectedArticleNumber,
  SET_DASHBOARD_SELECTED_COST_CENTER: setDashboard_selectedCostCenter,
  SET_DASHBOARD_SELECTED_CUSTOMER_NUMBER: setDashboard_selectedCustomerNumber,
  SET_DASHBOARD_SELECTED_CUSTOM_FIELDS: setDashboard_selectedCustomFields,
  SET_DASHBOARD_SELECTED_FROM_SITE: setDashboard_selectedFromSite,
  SET_DASHBOARD_SELECTED_PERMITTED_COST_CENTERS:
    setDashboard_selectedPermittedCostCenters,
  SET_DASHBOARD_SELECTED_PERMITTED_TO_SITES:
    setDashboard_selectedPermittedToSites,
  SET_DASHBOARD_SELECTED_PROCESS_STATE: setDashboard_selectedProcessState,
  SET_DASHBOARD_SELECTED_RECIPIENT: setDashboard_selectedRecipient,
  SET_DASHBOARD_SELECTED_SETTLED_STATUS: setDashboard_selectedSettledStatus,
  SET_DASHBOARD_SELECTED_SUPPLIER: setDashboard_selectedSupplier,
  SET_DASHBOARD_SELECTED_TO_SITE_RECIPIENT:
    setDashboard_selectedToSiteRecipient,
  SET_DASHBOARD_SELECTED_TO_SITE_SUPPLIER: setDashboard_selectedToSiteSupplier,
  SET_DASHBOARD_SELECTED_TO_SITE_SUPPLIER_TRADE_CONTACT:
    setDashboard_selectedToSiteSupplierTradeContact,
  SET_DELIVERY_SELECTED_ACCEPT_STATE: setDelivery_selectedAcceptState,
  SET_DELIVERY_SELECTED_ARTICLE: setDelivery_selectedArticle,
  SET_DELIVERY_SELECTED_ARTICLE_NUMBER: setDelivery_selectedArticleNumber,
  SET_DELIVERY_SELECTED_COST_CENTER: setDelivery_selectedCostCenter,
  SET_DELIVERY_SELECTED_CUSTOM_FIELDS: setDelivery_selectedCustomFields,
  SET_DELIVERY_SELECTED_CUSTOMER_NUMBER: setDelivery_selectedCustomerNumber,
  SET_DELIVERY_SELECTED_CATEGORY: setDelivery_selectedCategory,
  SET_DELIVERY_SELECTED_FROM_SITE: setDelivery_selectedFromSite,
  SET_DELIVERY_SELECTED_NUMBER: setDelivery_selectedNumber,
  SET_DELIVERY_SELECTED_OU_ID: setDelivery_selectedOuId,
  SET_DELIVERY_SELECTED_PERMITTED_COST_CENTERS:
    setDelivery_selectedPermittedCostCenters,
  SET_DELIVERY_SELECTED_PERMITTED_TO_SITES:
    setDelivery_selectedPermittedToSites,
  SET_DELIVERY_SELECTED_PROCESS_STATE: setDelivery_selectedProcessState,
  SET_DELIVERY_SELECTED_RECIPIENT: setDelivery_selectedRecipient,
  SET_DELIVERY_SELECTED_SETTLED_STATUS: setDelivery_selectedSettledStatus,
  SET_DELIVERY_SELECTED_SUPPLIER: setDelivery_selectedSupplier,
  SET_DELIVERY_SELECTED_TO_SITE_RECIPIENT: setDelivery_selectedToSiteRecipient,
  SET_DELIVERY_SELECTED_TO_SITE_SUPPLIER: setDelivery_selectedToSiteSupplier,
};

export const FilterGroupFilter = {
  // When adding new filter group filter to this list, also add it to the FILTER_MAPPING.
  FILTER,
  DATA_TYPE_MAPPING,
  REDUX_VARIABLE,
  REDUX_SETTER_FUNCTION,
};

/**
 * Represents a utility object for managing custom data.
 */
export const CustomDataObject = {
  /**
   * Sets data for the custom data object by mapping all key-value pairs
   * from the provided additionalPartyData object.
   * @param additionalPartyData - An object containing key-value pairs to add to the custom data.
   */
  create(additionalPartyData: Record<string, any>) {
    if (!additionalPartyData) {
      return {};
    }

    const newData: Record<string, any> = {};

    // Add key-value pairs from additionalPartyData
    for (const [key, value] of Object.entries(additionalPartyData)) {
      newData[key.slice(0, 36)] = value; // Old customField keys might consist of UUID + label; only get the UUID
    }

    return newData;
  },

  /**
   * Retrieves all keys from the custom data object.
   * @returns An array of strings representing the keys in the custom data.
   */
  getKeys(customData: Record<string, any>) {
    return Object.keys(customData);
  },

  /**
   * Retrieves all values from the custom data object.
   * @returns An array of values corresponding to the keys in the custom data.
   */
  getValues(customData: Record<string, any>) {
    return Object.values(customData);
  },

  /**
   * Retrieves all entries (key-value pairs) from the custom data object.
   * @returns An array of entries, where each entry is a tuple containing a key and its corresponding value.
   */
  getEntries(customData: Record<string, any>) {
    if (!customData) return [];

    return Object.entries(customData);
  },

  /**
   * Retrieves the number of key-value pairs in the custom data object.
   * @returns The total count of key-value pairs.
   */
  getLength(customData: Record<string, any>) {
    if (!customData) return 0;

    return Object.keys(customData).length;
  },
};

import { type DeliveryNoteListItem } from '~/data/deliveryNote/types';

export const getConstructionComponents = (
  assetMain: Partial<DeliveryNoteListItem> = {},
) => {
  const constructionComponents = [];

  if (assetMain?.assetState?.body?.transaction?.logisticsPackage)
    for (const logisticsPackage of assetMain?.assetState?.body?.transaction
      ?.logisticsPackage) {
      if (logisticsPackage?.lineItem)
        for (const lineItem of logisticsPackage?.lineItem) {
          if (
            lineItem?.product?.constructionComponent &&
            !constructionComponents.includes(
              lineItem.product?.constructionComponent,
            )
          ) {
            constructionComponents.push(lineItem.product.constructionComponent);
          }
        }
    }

  return constructionComponents.join(', ');
};

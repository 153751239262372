export const IncomingInvoiceOutlinedIcon = ({
  className,
  style,
}: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2L4.5 3.5L3 2V22L4.5 20.5L6 22L7.5 20.5L9 22L10.1834 20.8166C10.0632 20.2298 10 19.6223 10 19H5V5H19V10C19.6874 10 20.3568 10.0771 21 10.223V2L19.5 3.5ZM10.223 17C10.3821 16.2987 10.6232 15.6285 10.9355 15H6V17H10.223ZM12.2917 13C13.0199 12.1864 13.894 11.506 14.8728 11H6V13H12.2917ZM18 7V9H6V7H18ZM14 19L19.5556 24V21H24V17H19.5556V14L14 19ZM18.4444 21.5L15.6667 19L18.4444 16.5V18H22.8889V20H18.4444V21.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

import { isObjectType } from '~/utils/object';

export const ValueObject = {
  /**
   * Creates a Value object with the given parameters.
   * @param {string} value - The value.
   * @param {string | null} datetime - The datetime associated with the value.
   * @param {object} company - The company associated with the value.
   * @returns {object} - The created Value object.
   */
  create(
    value: string,
    datetime?: string | undefined,
    company?: Record<string, any>,
  ) {
    return {
      value,
      datetime,
      company,
    };
  },

  /**
   * Checks if the data is a simple value.
   * @param {any} data - The data to check.
   * @returns {boolean} - True if the data is a simple value, otherwise false.
   */
  isSimpleValue(data: any) {
    if (!isObjectType(data)) {
      // if data is a "real value" (e.g. string, number, etc.) and not a nested object
      return true;
    }

    if (Array.isArray(data) && data.length > 0 && !isObjectType(data[0])) {
      // if data is an array containing "real values" (e.g. strings, such as comments array)
      return true;
    }

    return false;
  },
};

export const DEFAULT_TRADE_CONTACT = {
  personName: '',
  jobTitle: '',
  departmentName: '',
  universalCommunication: {
    email: '',
    phone: '',
  },
} as const;

export const INVALID_CONTACT_MESSAGE = 'Invalid contact information';

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Check as CheckIcon } from '@mui/icons-material';
import { Chip, Stack } from '@mui/material';

type P = {
  readonly onClick: (column: string) => void;
  readonly onReorderColumns: (
    sourceIndex: number,
    destinationIndex: number,
  ) => void;
  readonly selectableColumns: Array<{ headerName: string; field: string }>;
  readonly selectedColumns: string[];
};

export const DashboardReportChips = ({
  onClick,
  onReorderColumns,
  selectableColumns,
  selectedColumns,
}: P) => {
  // Helper function to check if a column is currently selected
  const isColumnSelected = (column) => selectedColumns.includes(column);

  // Handle the end of drag-and-drop operations
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    onReorderColumns(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable direction="horizontal" droppableId="columns">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="flex overflow-x-auto py-4"
          >
            <Stack direction="row" spacing={1}>
              {selectableColumns.map(({ field, headerName }, index) => (
                <Draggable key={field} draggableId={field} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Chip
                        className={
                          isColumnSelected(field)
                            ? 'bg-primary500 !text-white'
                            : null
                        }
                        icon={
                          isColumnSelected(field) ? (
                            <CheckIcon className="!text-white" />
                          ) : null
                        }
                        label={headerName}
                        style={
                          isColumnSelected(field) ? { cursor: 'grab' } : null
                        }
                        onClick={() => {
                          onClick(field);
                        }}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </Stack>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

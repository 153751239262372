export const PieChartOutlinedIcon = ({
  className,
  style,
}: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 16.5H29.925C29.555 13.75 28.41 11.42 26.49 9.51C24.58 7.6 22.25 6.455 19.5 6.075M16.5 29.925V6.075C13.5 6.455 11 7.775 9 10.035C7 12.295 6 14.95 6 18C6 21.05 7 23.705 9 25.965C11 28.235 13.5 29.555 16.5 29.925ZM19.5 29.925C22.25 29.575 24.59 28.44 26.52 26.52C28.44 24.59 29.575 22.25 29.925 19.5H19.5M18 33C15.93 33 13.98 32.605 12.15 31.815C10.33 31.035 8.745 29.97 7.395 28.62C6.045 27.27 4.975 25.68 4.185 23.85C3.395 22.03 3 20.08 3 18C3 15.92 3.395 13.97 4.185 12.15C4.975 10.33 6.045 8.745 7.395 7.395C8.745 6.045 10.33 4.975 12.15 4.185C13.98 3.395 15.93 3 18 3C20.07 3 22.015 3.395 23.835 4.185C25.645 4.975 27.23 6.05 28.59 7.41C29.96 8.77 31.035 10.355 31.815 12.165C32.605 13.985 33 15.93 33 18C33 20.05 32.605 21.99 31.815 23.82C31.035 25.64 29.97 27.23 28.62 28.59C27.27 29.96 25.68 31.035 23.85 31.815C22.03 32.605 20.08 33 18 33Z"
      fill="currentColor"
    />
  </svg>
);

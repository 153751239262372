export const DataExchangeConfirmedIcon = ({
  className,
  style,
}: ComponentStyling) => (
  <svg
    className={className}
    style={style}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3.70349C6.201 3.70349 1.5 7.22849 1.5 11.5785C1.5 13.0635 2.055 14.4532 3.012 15.639L1.5 18.954L5.70975 17.874C7.46475 18.861 9.6375 19.4535 12 19.4535C17.799 19.4535 22.5 15.9285 22.5 11.5785C22.5 7.22849 17.799 3.70349 12 3.70349ZM12.651 16.0312H11.1735V14.6137H12.651V16.0312ZM12.579 13.5712H11.2463L11.1143 7.50824H12.6982L12.579 13.5712Z"
      fill="currentColor"
    />
  </svg>
);

import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';

import { queryKeysSite } from './queryKeys';
import { type Site } from './useQuerySite';

type BulkSitesResponse = {
  items: Site[];
};

/**
 * Fetches multiple sites in bulk from the API.
 * @param {UUID[]} siteIds - Array of site IDs to fetch.
 * @returns {Promise<Site[]>} Array of site data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/query_sites_in_bulk_site_query_bulk_post
 */
export const fetchSitesBulk = async (siteIds: UUID[]) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.SITE.GET_BULK(), {
        json: { ids: siteIds },
      })
      .json<BulkSitesResponse>();

    return response?.items ?? [];
  } catch (error) {
    Log.error('Error fetching sites in bulk', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting multiple sites in bulk.
 * @param {UUID[]} siteIds - Array of site IDs to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<Site[]>} The query result containing array of sites.
 */
export const useQuerySitesBulk = (
  siteIds: UUID[],
  options?: Omit<UseQueryOptions<Site[]>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    enabled: siteIds.length > 0,
    queryFn: async () => fetchSitesBulk(siteIds),
    queryKey: queryKeysSite.getBulk(siteIds),
    ...options,
  });

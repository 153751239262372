import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/LoadingState';

import { UserFeatureFlagsObject } from '~/models/masterdata/UserFeatureFlags';

const userinfoSlice = createSlice({
  initialState: {
    articleColorMapping: [],
    articleColorMappingVersion: 0,
    deliveryTabs: [],

    userinfo: {
      company: null,
      firstName: null,
      id: null,
      isVestigasSupport: false,
      lastName: null,
      phone: null,
      position: null,
      profilePicture: null,
      userFeatureFlags: UserFeatureFlagsObject.create(),
      userHasLoggedInViaSSO: false,
      userType: null,
    },

    userinfoLoading: LOADING_STATE.NOT_LOADED,

    userPermissions: [],
    userPermissionsLoading: LOADING_STATE.NOT_LOADED,
  },
  name: 'userinfo',
  reducers: {
    saveDeliveryTabs(state, { payload }) {
      state.deliveryTabs = payload;
    },
    saveUserCompany(state, { payload }) {
      state.userinfo.company = payload;
    },
    saveUserFeatureFlags(state, { payload }) {
      state.userinfo.userFeatureFlags = payload;
    },
    saveUserId(state, { payload }) {
      state.userinfo.id = payload;
    },
    saveUserPermissions(state, { payload }) {
      state.userPermissions = payload;
      state.userPermissionsLoading = LOADING_STATE.SUCCEEDED;
    },
    saveUserType(state, { payload }) {
      state.userinfo.userType = payload;
    },
    setUserHasLoggedInViaSSO(state, { payload }) {
      state.userinfo.userHasLoggedInViaSSO = payload;
    },
    setUserinfoLoading(state, { payload }) {
      state.userinfoLoading = payload;
    },
    setUserPermissionsLoading(state, { payload }) {
      state.userPermissionsLoading = payload;
    },
    updateUserInfos(state, { payload }) {
      state.userinfo = {
        ...state.userinfo,
        firstName: payload.firstName,
        isVestigasSupport: payload.isVestigasSupport,
        lastName: payload.lastName,
        phone: payload.phone,
        position: payload.position,
      };
      state.userinfoLoading = LOADING_STATE.SUCCEEDED;
    },
  },
});

const { actions, reducer } = userinfoSlice;
export const {
  saveDeliveryTabs,
  saveUserCompany,
  saveUserFeatureFlags,
  saveUserId,
  saveUserPermissions,
  saveUserType,
  setUserHasLoggedInViaSSO,
  setUserPermissionsLoading,
  setUserinfoLoading,
  updateUserInfos,
} = actions;
export default reducer;

import { dateUtils } from '~/utils/dateUtils';
import { getAbbreviatedUnits } from '~/utils/unit';

export const SHIPPING_MARK_CODE = {
  COLLI_NUMBER: {
    KEY: 'COL',
    STRING: 'Collinummern',
  },
  NVE_NUMBER: {
    KEY: '39',
    STRING: 'NVE Nummern',
  },
} as const;

export const SHIPPING_MARK_FALLBACK_STRING = 'Versandnummern';

export const DELIVERY_NOTE_VALIDATION_RESULT_STATUS = {
  ERROR: 'err',
  WARNING: 'war',
} as const;

export const ATTACHMENT_TYPE = {
  DELIVERY: 'delivery', // for accept articles
  DELIVERY_PICTURE: 'delivery_picture', // for "kein Berechtigter vor Ort" feature
  SIGNATURE: 'signature', //
} as const;

export const ATTACHMENT_CONTENT_TYPE = {
  JPEG: 'image/jpeg',
  JSON: 'application/json',
  PDF: 'application/octet-stream',
  PNG: 'image/png',
  SVG: 'image/svg+xml',
} as const;

export const DELIVERY_NOTE_ACTION_DEFAULT_ACTION = {
  ARCHIVED: {
    ICON: 'archive',
    STRING: 'Lieferung archiviert',
  },
  ARRIVED: {
    ICON: 'arrow_forward',
    SHOW_TOAST: true,
    STRING: 'Lieferung am Ziel angekommen',
  },
  ASSIGNED: {
    ICON: 'assign',
    STRING: 'Lieferung zugewiesen',
  },
  CANCELLED: {
    ICON: 'cancel',
    SHOW_TOAST: true,
    STRING: 'Lieferung storniert',
  },
  CONFIRMED_CARRIER: {
    ICON: 'check',
    SHOW_TOAST: true,
    STRING: 'Lieferung bestätigt (Spediteur)',
  },
  CONFIRMED_ON_BEHALF_CARRIER: {
    ICON: 'check',
    SHOW_TOAST: true,
    STRING: 'Lieferung bestätigt (Bildschirmunterschrift für Spediteur)',
  },
  CONFIRMED_ON_BEHALF_RECIPIENT: {
    ICON: 'done_all',
    SHOW_TOAST: true,
    STRING: 'Lieferung bestätigt (Bildschirmunterschrift für Abnehmer)',
  },
  CONFIRMED_ON_BEHALF_SUPPLIER: {
    ICON: 'check',
    SHOW_TOAST: true,
    STRING: 'Lieferung bestätigt (Bildschirmunterschrift für Lieferant)',
  },
  CONFIRMED_RECIPIENT: {
    ICON: 'done_all',
    SHOW_TOAST: true,
    STRING: 'Lieferung bestätigt (Abnehmer)',
  },
  CONFIRMED_SUPPLIER: {
    ICON: 'check',
    SHOW_TOAST: true,
    STRING: 'Lieferung bestätigt (Lieferant)',
  },
  CREATED: {
    ICON: 'create_new_folder',
    SHOW_TOAST: true,
    STRING: 'Lieferung erstellt',
  },
  DECLINED_CARRIER: {
    ICON: 'declined_carrier',
    SHOW_TOAST: true,
    STRING: 'Lieferung abgelehnt (Spediteur)',
  },
  DECLINED_ON_BEHALF_CARRIER: {
    ICON: 'declined_on_behalf_carrier',
    SHOW_TOAST: true,
    STRING: 'Lieferung abgelehnt (Bildschirmunterschrift für Spediteur)',
  },
  DECLINED_ON_BEHALF_RECIPIENT: {
    ICON: 'declined_on_behalf_recipient',
    SHOW_TOAST: true,
    STRING: 'Lieferung abgelehnt (Bildschirmunterschrift für Abnehmer)',
  },
  DECLINED_ON_BEHALF_SUPPLIER: {
    ICON: 'declined_on_behalf_supplier',
    SHOW_TOAST: true,
    STRING: 'Lieferung abgelehnt (Bildschirmunterschrift für Lieferant)',
  },
  DECLINED_RECIPIENT: {
    ICON: 'declined_recipient',
    SHOW_TOAST: true,
    STRING: 'Lieferung abgelehnt (Abnehmer)',
  },
  DECLINED_SUPPLIER: {
    ICON: 'declined_supplier',
    SHOW_TOAST: true,
    STRING: 'Lieferung abgelehnt (Lieferant)',
  },
  EDITED: {
    ICON: 'create',
    SHOW_TOAST: true,
    STRING: 'Lieferung bearbeitet',
  },
  SHARED: {
    ICON: 'share',
    STRING: 'Lieferung geteilt',
  },
  UNARCHIVED: {
    ICON: 'archive',
    STRING: 'Lieferung unarchiviert',
  },
} as const;

export const DELIVERY_NOTE_ACTION_PROPERTY = {
  ACTION: {
    KEY: 'action',
    STRING: 'Aktion',
  },
  COMPANY: {
    KEY: 'company',
    STRING: 'Benutzerorganisation',
  },
  DATETIME: {
    KEY: 'datetime',
    STRING: 'Zeitpunkt',
  },
  ICON: {
    KEY: 'icon',
    STRING: '',
  },
  USER: {
    KEY: 'user',
    STRING: 'Benutzer',
  },
} as const;

export const DELIVERY_NOTE_QUALITY_UNIVERSAL_COMMUNICATION = {
  ITEMS: [
    {
      BACKEND_PATH: ['complete_number'],
      NAME: 'Telefon',
      PATH: ['phone'],
    },
    {
      BACKEND_PATH: ['email'],
      NAME: 'E-Mail',
      PATH: ['email'],
    },
  ],
} as const;
export const DELIVERY_NOTE_QUALITY_TRADE_CONTACT = {
  ITEMS: [
    {
      BACKEND_PATH: ['person_name'],
      NAME: 'Name',
      PATH: ['personName'],
    },
    {
      BACKEND_PATH: ['job_title'],
      NAME: 'Job',
      PATH: ['jobTitle'],
    },
    {
      BACKEND_PATH: ['department_name'],
      NAME: 'Bereich',
      PATH: ['departmentName'],
    },
    {
      BACKEND_PATH: ['universal_communication'],
      MODEL: DELIVERY_NOTE_QUALITY_UNIVERSAL_COMMUNICATION,
      NAME: null,
      PATH: ['universalCommunication'],
    },
  ],
} as const;

export const DELIVERY_NOTE_QUALITY_ADDRESS = {
  ITEMS: [
    {
      BACKEND_PATH: ['street_name'],
      NAME: 'Straße',
      PATH: ['streetName'],
    },
    {
      BACKEND_PATH: ['building_number'],
      NAME: 'Hausnummer',
      PATH: ['buildingNumber'],
    },
    {
      BACKEND_PATH: ['post_code'],
      NAME: 'PLZ',
      PATH: ['postCode'],
    },
    {
      BACKEND_PATH: ['city'],
      NAME: 'Ort',
      PATH: ['city'],
    },
    {
      BACKEND_PATH: ['country'],
      NAME: 'Land',
      PATH: ['country'],
    },
  ],
} as const;

export const DELIVERY_NOTE_QUALITY_SITE = {
  ITEMS: [
    {
      BACKEND_PATH: ['trade_address', 'line_one'],
      NAME: 'Lieferantenbezeichnung',
      PATH: ['name'],
    },
    {
      BACKEND_PATH: ['trade_address'],
      MODEL: DELIVERY_NOTE_QUALITY_ADDRESS,
      NAME: 'Adresse',
      PATH: ['address'],
    },
    {
      BACKEND_PATH: ['trade_contact'],
      MODEL: DELIVERY_NOTE_QUALITY_TRADE_CONTACT,
      NAME: 'Ansprechperson',
      PATH: ['tradeContact'],
    },
  ],
} as const;
export const DELIVERY_NOTE_QUALITY_COMPANY = {
  ITEMS: [
    {
      BACKEND_PATH: ['legal_organization', 'name'],
      NAME: 'Name',
      PATH: ['name'],
    },
    {
      BACKEND_PATH: ['legal_organization', 'address'],
      MODEL: DELIVERY_NOTE_QUALITY_ADDRESS,
      NAME: 'Adresse',
      PATH: ['address'],
    },
    // NAME is null as there is additional depth in the displayed path for universal communication
    {
      BACKEND_PATH: ['legal_organization', 'universal_communication'],
      MODEL: DELIVERY_NOTE_QUALITY_UNIVERSAL_COMMUNICATION,
      NAME: null,
      PATH: ['universalCommunication'],
    },
    {
      BACKEND_PATH: ['trade_contact'],
      MODEL: DELIVERY_NOTE_QUALITY_TRADE_CONTACT,
      NAME: 'Ansprechperson',
      PATH: ['tradeContact'],
    },
  ],
} as const;

export const DELIVERY_NOTE_QUALITY_ARTICLE = {
  IS_ARTICLE: true,
  ITEMS: [
    { NAME: 'Paket', PATH: ['logisticsPackage'] },
    { BACKEND_PATH: ['id'], NAME: 'Position', PATH: ['position'] },
    {
      BACKEND_PATH: ['seller_assigned_id'],
      NAME: 'Nummer',
      PATH: ['number'],
    },
    { BACKEND_PATH: ['name'], NAME: 'Name', PATH: ['type'] },
    { BACKEND_PATH: ['ean'], NAME: 'EAN', PATH: ['ean'] },
    {
      BACKEND_PATH: ['product', 'description'],
      NAME: 'Beschreibung',
      PATH: ['description'],
    },
    {
      BACKEND_PATH: ['product', 'comment'],
      NAME: 'Kommentar',
      PATH: ['comment'],
    },
    {
      BACKEND_PATH: ['product', 'construction_plan'],
      NAME: 'Plan',
      PATH: ['constructionPlan'],
    },
    {
      BACKEND_PATH: ['product', 'construction_component'],
      NAME: 'Bauteil',
      PATH: ['constructionComponent'],
    },
    {
      BACKEND_PATH: ['settlement', 'payable_account', 'name'],
      NAME: 'Kostenstelle',
      PATH: ['costCenter'],
    },
    {
      BACKEND_PATH: ['delivery', 'product_unit', 'quantity'],
      NAME: 'Menge',
      PATH: ['quantity', 'value'],
    },
    {
      BACKEND_PATH: ['delivery', 'product_unit', 'quantity_type'],
      FORMATTER: getAbbreviatedUnits,
      NAME: 'Menge-Einheit',
      PATH: ['quantity', 'unit'],
    },
    {
      BACKEND_PATH: ['delivery', 'net_weight', 'weight'],
      NAME: 'Gewicht',
      PATH: ['weight', 'value'],
    },
    {
      BACKEND_PATH: ['delivery', 'net_weight', 'measure'],
      FORMATTER: getAbbreviatedUnits,
      NAME: 'Gewicht-Einheit',
      PATH: ['weight', 'unit'],
    },
    {
      BACKEND_PATH: [
        'document_line',
        'note',
        'content',
        'gross_weight',
        'weight',
      ],
      NAME: 'Bruttogewicht',
      PATH: ['weighingInformation', 'gross', 'weight', 'value'],
    },
    {
      BACKEND_PATH: [
        'document_line',
        'note',
        'content',
        'gross_weight',
        'measure',
      ],
      FORMATTER: getAbbreviatedUnits,
      NAME: 'Bruttogewicht-Einheit',
      PATH: ['weighingInformation', 'gross', 'weight', 'unit'],
    },
    {
      BACKEND_PATH: ['document_line', 'note', 'content', 'gross_weight', 'id'],
      NAME: 'Bruttogewicht Waage',
      PATH: ['weighingInformation', 'gross', 'scaleId'],
    },
    {
      BACKEND_PATH: [
        'document_line',
        'note',
        'content',
        'tare_weight',
        'weight',
      ],
      NAME: 'Taragewicht',
      PATH: ['weighingInformation', 'tare', 'weight', 'value'],
    },
    {
      BACKEND_PATH: [
        'document_line',
        'note',
        'content',
        'tare_weight',
        'measure',
      ],
      FORMATTER: getAbbreviatedUnits,
      NAME: 'Taragewicht-Einheit',
      PATH: ['weighingInformation', 'tare', 'weight', 'unit'],
    },
    {
      BACKEND_PATH: ['document_line', 'note', 'content', 'tare_weight', 'id'],
      NAME: 'Taragewicht Waage',
      PATH: ['weighingInformation', 'tare', 'scaleId'],
    },
    {
      BACKEND_PATH: ['product', 'manufacturer'],
      MODEL: DELIVERY_NOTE_QUALITY_COMPANY,
      NAME: 'Hersteller',
      PATH: ['manufacturer'],
    },
  ],
} as const;

export const DELIVERY_NOTE_QUALITY_CATEGORY = {
  ARTICLE: {
    BACKEND_PATH: ['transaction', 'logistics_package', '*', 'line_item', '*'],
    MODEL: DELIVERY_NOTE_QUALITY_ARTICLE,
    MULTIPLE: true,
    NAME: 'Artikel',
    PATH: ['articles'],
  },
  BUYER: {
    BACKEND_PATH: ['transaction', 'agreement', 'buyer'],
    MODEL: DELIVERY_NOTE_QUALITY_COMPANY,
    NAME: 'Auftraggeber',
    PATH: ['buyer'],
  },
  CARRIER: {
    BACKEND_PATH: [
      'transaction',
      'delivery',
      'ship_from',
      'consignment',
      'carrier',
    ],
    MODEL: DELIVERY_NOTE_QUALITY_COMPANY,
    NAME: 'Spediteur',
    PATH: ['carrier'],
  },
  EXECUTION: {
    ITEMS: [
      // DISCLAIMER: This code is a workaround and should be refactored.
      // Being pragmatic, we only access the first license plate so that we don't have to map multiple validation results to one data quality pair.
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'ship_from',
          'consignment',
          'movement',
          'registration_id',
          '0',
          'id',
        ],
        NAME: 'Kfz-Kennzeichen',
        PATH: ['carrierLicensePlate'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'ship_from',
          'consignment',
          'movement',
          'registration_id',
          '0',
          'issuer_assigned_id',
        ],
        NAME: 'Fahrzeug-Nr.',
        PATH: ['carrierVehicleNumber'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'ship_from',
          'consignment',
          'movement',
          'driver',
        ],
        MODEL: DELIVERY_NOTE_QUALITY_TRADE_CONTACT,
        NAME: 'Fahrer',
        PATH: ['driver'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'execution',
          'begin_loading_planned',
        ],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Beginn Beladung (Geplant)',
        PATH: ['execution', 'beginLoadingPlanned'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'execution',
          'end_loading_planned',
        ],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Ende Beladung (Geplant)',
        PATH: ['execution', 'endLoadingPlanned'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'execution',
          'departure_planned',
        ],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Abfahrt (Geplant)',
        PATH: ['execution', 'departurePlanned'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'execution',
          'arrived_planned',
        ],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Ankunft (Geplant)',
        PATH: ['execution', 'arrivedPlanned'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'execution',
          'begin_unloading_planned',
        ],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Beginn Entladung (Geplant)',
        PATH: ['execution', 'beginUnloadingPlanned'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'execution',
          'end_unloading_planned',
        ],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Ende Entladung (Geplant)',
        PATH: ['execution', 'endUnloadingPlanned'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'execution',
          'begin_execution_planned',
        ],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Beginn Lieferung (Geplant)',
        PATH: ['execution', 'beginExecutionPlanned'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'execution',
          'end_execution_planned',
        ],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Ende Lieferung (Geplant)',
        PATH: ['execution', 'endExecutionPlanned'],
      },
      {
        BACKEND_PATH: ['transaction', 'delivery', 'execution', 'begin_loading'],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Beginn Beladung',
        PATH: ['execution', 'beginLoading'],
      },
      {
        BACKEND_PATH: ['transaction', 'delivery', 'execution', 'end_loading'],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Ende Beladung',
        PATH: ['execution', 'endLoading'],
      },
      {
        BACKEND_PATH: ['transaction', 'delivery', 'execution', 'departure'],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Abfahrt',
        PATH: ['execution', 'departure'],
      },
      {
        BACKEND_PATH: ['transaction', 'delivery', 'execution', 'arrived'],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Ankunft',
        PATH: ['execution', 'arrived'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'execution',
          'begin_unloading',
        ],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Beginn Entladung',
        PATH: ['execution', 'beginUnloading'],
      },
      {
        BACKEND_PATH: ['transaction', 'delivery', 'execution', 'end_unloading'],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Ende Entladung',
        PATH: ['execution', 'endUnloading'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'execution',
          'begin_execution',
        ],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Beginn Lieferung',
        PATH: ['execution', 'beginExecution'],
      },
      {
        BACKEND_PATH: ['transaction', 'delivery', 'execution', 'end_execution'],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'Zeitpunkt | Ende Lieferung',
        PATH: ['execution', 'endExecution'],
      },
    ],
    NAME: 'Auslieferung',
  },
  FREIGHT_FORWARDER: {
    BACKEND_PATH: [
      'transaction',
      'delivery',
      'ship_from',
      'consignment',
      'movement',
      'freight_forwarder',
    ],
    MODEL: DELIVERY_NOTE_QUALITY_COMPANY,
    NAME: 'Frachtführer',
    PATH: ['freightForwarder'],
  },
  FROM_SITE: {
    BACKEND_PATH: ['transaction', 'delivery', 'ship_from'],
    MODEL: DELIVERY_NOTE_QUALITY_SITE,
    NAME: 'Beladeort',
    PATH: ['fromSite'],
  },
  ISSUER: {
    BACKEND_PATH: ['header', 'issuer'],
    MODEL: DELIVERY_NOTE_QUALITY_COMPANY,
    NAME: 'Ausgeber',
    PATH: ['issuer'],
  },
  META_DATA: {
    ITEMS: [
      { BACKEND_PATH: ['header', 'id'], NAME: 'LFS-Nr.', PATH: ['number'] },
      {
        BACKEND_PATH: ['header', 'date'],
        FORMATTER: (date: Date | undefined) =>
          dateUtils.getFormattedDate_safe(
            date,
            dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
          ),
        NAME: 'LFS-Datum.',
        PATH: ['dlnDate'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'logistics_package',
          '0',
          'line_item',
          '0',
          'agreement',
          'seller_order',
        ],
        NAME: 'Bestellreferenz Auftragnehmer',
        PATH: ['sellerOrderReferences'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'logistics_package',
          '0',
          'line_item',
          '0',
          'agreement',
          'buyer_order',
        ],
        NAME: 'Bestellreferenz Auftraggeber',
        PATH: ['buyerOrderReferences'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'ship_to',
          'trade_address',
          'issuer_assigned_id',
        ],
        NAME: 'Projekt',
        PATH: ['project'],
      },
      {
        BACKEND_PATH: ['transaction', 'agreement', 'delivery_terms', 'code'],
        NAME: 'Lieferungsart',
        PATH: ['deliveryType'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'delivery',
          'ship_from',
          'consignment',
          'movement',
          'means',
        ],
        NAME: 'Zufuhrart',
        PATH: ['movementMeans'],
      },
      {
        BACKEND_PATH: [
          'transaction',
          'logistics_package',
          '0',
          'line_item',
          '0',
          'settlement',
          'payable_account',
          'name',
        ],
        NAME: 'Kostenstelle',
        PATH: ['costCenter'],
      },
      {
        BACKEND_PATH: ['transaction', 'settlement', 'sales_account', 'name'],
        NAME: 'Kunden-Nr.',
        PATH: ['buyerId'],
      },
    ],
    NAME: 'Metadaten',
  },
  RECIPIENT: {
    BACKEND_PATH: ['transaction', 'delivery', 'ship_to'],
    MODEL: DELIVERY_NOTE_QUALITY_COMPANY,
    NAME: 'Abnehmer',
    PATH: ['recipient'],
  },
  SELLER: {
    BACKEND_PATH: ['transaction', 'agreement', 'seller'],
    MODEL: DELIVERY_NOTE_QUALITY_COMPANY,
    NAME: 'Auftragnehmer',
    PATH: ['seller'],
  },
  SUPPLIER: {
    BACKEND_PATH: ['transaction', 'delivery', 'ship_from'],
    MODEL: DELIVERY_NOTE_QUALITY_COMPANY,
    NAME: 'Lieferant',
    PATH: ['supplier'],
  },
  TO_SITE_SUPPLIER: {
    BACKEND_PATH: ['transaction', 'delivery', 'ship_to'],
    MODEL: DELIVERY_NOTE_QUALITY_SITE,
    NAME: 'Lieferort',
    PATH: ['toSiteSupplier'],
  },
} as const;

class ES6ClassFactory {
  // Function is needed because redux tends to make plain objects out of ES6 class objects.
  // Thus, methods of the ES6 classes get lost.
  // With this class it is ensured that when retrieving ES6 classes from the store, that they have the right methods.

  // Cache prototypes to avoid repeatedly getting the same prototype
  #prototypeCache = new WeakMap();

  convertToES6Class(plainObjects, es6Object) {
    if (!plainObjects?.length || !es6Object) {
      return [];
    }

    // Get the prototype once instead of cloning the entire object for each item.
    // Use cached prototype if available for this es6Object constructor
    let prototype = this.#prototypeCache.get(es6Object.constructor);

    if (!prototype) {
      prototype = Object.getPrototypeOf(es6Object);
      // Cache the prototype for future use with same constructor
      this.#prototypeCache.set(es6Object.constructor, prototype);
    }

    // For large arrays, process in batches to avoid blocking the main thread
    if (plainObjects.length > 100) {
      return this.#convertLargeArray(plainObjects, prototype);
    }

    // For smaller arrays, process directly for better performance
    return plainObjects.map((object) =>
      this.#createObjectWithPrototype(object, prototype),
    );
  }

  // Handle large arrays with batched processing.
  #convertLargeArray(plainObjects, prototype) {
    const result = [];
    const batchSize = 100;

    // Pre-allocate the result array for better performance.
    result.length = plainObjects.length;

    // Process synchronously but in smaller batches
    for (let index = 0; index < plainObjects.length; index += batchSize) {
      const end = Math.min(index + batchSize, plainObjects.length);

      // Process the current batch.
      for (let index_ = index; index_ < end; index_++) {
        result[index_] = this.#createObjectWithPrototype(
          plainObjects[index_],
          prototype,
        );
      }
    }

    return result;
  }

  // Create a new object with the prototype and properties from plainObject
  #createObjectWithPrototype(plainObject, prototype) {
    // Create a new object with the ES6 class prototype.
    const newObject = Object.create(prototype);

    // Copy all properties from the plain object to the new object.
    // Using Object.assign is more efficient than manual property copying
    Object.assign(newObject, plainObject);

    // Return the new object, which now is an ES6 object with the corresponding methods.
    return newObject;
  }
}

export const es6ClassFactory = new ES6ClassFactory();

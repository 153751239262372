import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { ROUTE } from '~/constants/Route';

import { useGetUserUtils, useQueryUserData } from '~/data/user';

import LocalStorageService from '~/services/localStorage.service';
import ToastService from '~/services/toast.service';

import BrowserUtils from '~/utils/browserUtils';
import { Log } from '~/utils/logging';

import { type MainNavItemType } from './types';

type P = {
  isNavCollapsed: boolean;
  items: MainNavItemType[];
  setIsNavCollapsed: (isNavCollapsed: boolean) => void;
};

const defaultDisplayedNavItems = new Set([
  ROUTE.DASHBOARD.ROUTE,
  ROUTE.DELIVERIES.ROUTE,
]);

const clientPortalDisplayedNavItems = new Set([
  ROUTE.CONCRETE_DIARY.ROUTE,
  ROUTE.DASHBOARD.ROUTE,
  ROUTE.DELIVERIES.ROUTE,
  ROUTE.INCOMING_INVOICES.ROUTE,
  ROUTE.OUTGOING_INVOICES.ROUTE,
]);

const moduleInvoiceRestrictionDisplayedNavItems = new Set([
  ROUTE.INCOMING_INVOICES.ROUTE,
  ROUTE.OUTGOING_INVOICES.ROUTE,
]);

const packageBasicRestrictionDisplayedNavItems = new Set([
  ROUTE.CONCRETE_DIARY.ROUTE,
  ROUTE.DASHBOARD.ROUTE,
  ROUTE.DELIVERIES.ROUTE,
]);

const filterAvailableNavItems = (
  navItems: MainNavItemType[],
  userPermissions: string[],
  featureFlags: Record<string, boolean>,
  UserUtils: Record<string, any>,
) => {
  return navItems
    .map((navItem) => ({
      ...navItem,
      children:
        navItem?.children?.filter(({ route }) => {
          return (
            defaultDisplayedNavItems.has(route) ||
            (featureFlags?.clientPortal &&
              clientPortalDisplayedNavItems.has(route)) ||
            (featureFlags?.moduleInvoiceRestriction &&
              moduleInvoiceRestrictionDisplayedNavItems.has(route)) ||
            (featureFlags?.packageBasicRestriction &&
              packageBasicRestrictionDisplayedNavItems.has(route)) ||
            UserUtils.userIsAuthorizedForPage(
              route,
              userPermissions,
              featureFlags,
            )
          );
        }) ?? [],
    }))
    .filter(
      ({ children, route }) =>
        // Keep items that either have a route themselves or have valid children.
        route || children.length > 0,
    );
};

export const useMainNav = ({ isNavCollapsed, items, setIsNavCollapsed }: P) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { UserUtils } = useGetUserUtils();

  const {
    data: currentUser,
    isError,
    isLoading: isLoadingUserData,
  } = useQueryUserData(true);
  const userPermissions = useMemo(
    () => currentUser?.userPermissions ?? [],
    [currentUser],
  );
  const featureFlags = useMemo(
    () => currentUser?.featureFlags ?? {},
    [currentUser],
  );

  const allowedMainNavItems = filterAvailableNavItems(
    items,
    userPermissions,
    featureFlags,
    UserUtils,
  );

  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());

  const handleChildItemClick = useCallback(
    (route: string, callback?: () => void) => {
      if (!route) {
        console.warn('Attempted to navigate to an undefined route');
        return;
      }

      if (
        !UserUtils.userIsAuthorizedForPage(route, userPermissions, featureFlags)
      ) {
        if (isError) {
          ToastService.error([
            'Berechtigungen konnten nicht geladen werden.',
            ToastService.MESSAGE.CONTACT_SUPPORT,
          ]);

          Log.productAnalyticsEvent(
            'Failed to load user permissions',
            Log.FEATURE.MENU,
            Log.TYPE.ERROR,
          );

          return;
        }

        if (isLoadingUserData) {
          ToastService.info(['Berechtigungen werden geladen...']);

          return;
        }

        ToastService.warning([
          'Kein Zugriff: Fordere die fehlenden Berechtigungen bei deinem Admin an.',
        ]);

        Log.productAnalyticsEvent(
          'Missing access for this page',
          Log.FEATURE.MENU,
          Log.TYPE.ERROR,
        );

        return;
      }

      navigate(route);
      if (callback) {
        callback();
      }
    },
    [
      featureFlags,
      isError,
      isLoadingUserData,
      navigate,
      userPermissions,
      UserUtils,
    ],
  );

  const handleNavItemClick = useCallback(
    (name: string, route: string, callback?: () => void) => {
      if (route) {
        Log.productAnalyticsEvent(`Open ${name}`, Log.FEATURE.MENU);
        handleChildItemClick(route, callback);
      } else {
        console.warn(`Attempted to open menu item without route: ${name}`);
      }
    },
    [handleChildItemClick],
  );

  const handleChildItemsToggle = useCallback((name: string) => {
    setExpandedItems((previous) => {
      const newSet = new Set(previous);
      const isCurrentlyExpanded = newSet.has(name);

      if (isCurrentlyExpanded) {
        newSet.delete(name);
      } else {
        newSet.add(name);
      }

      Log.productAnalyticsEvent(
        isCurrentlyExpanded ? 'Hide menu item' : 'Expand menu item',
        Log.FEATURE.MENU,
      );

      return newSet;
    });
  }, []);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent, name: string, route: string) => {
      event.preventDefault();
      if (!route) {
        return;
      }

      Log.productAnalyticsEvent(
        `Open ${name} via context menu`,
        Log.FEATURE.MENU,
      );

      BrowserUtils.openMenuItemInNewTab(name, route);
    },
    [],
  );

  const handleMainNavToggle = useCallback(async () => {
    if (isNavCollapsed) {
      Log.productAnalyticsEvent('Open main nav', Log.FEATURE.MENU);
    } else {
      Log.productAnalyticsEvent('Close main nav', Log.FEATURE.MENU);
    }

    setIsNavCollapsed(!isNavCollapsed);
    LocalStorageService.setLocalStorage(
      LocalStorageService.HIDE_DRAWER,
      !isNavCollapsed,
    );
  }, [isNavCollapsed, setIsNavCollapsed]);

  useEffect(() => {
    const currentPath = location.pathname;
    const newExpandedItems = new Set<string>();

    for (const item of items) {
      if (
        item.children.length > 1 &&
        item.children.some(({ route }) => route === currentPath)
      ) {
        newExpandedItems.add(item.name);
      }
    }

    setExpandedItems(newExpandedItems);
  }, [location.pathname, items]);

  const isChildItemOpen = useCallback(
    (itemName: string) => expandedItems.has(itemName),
    [expandedItems],
  );

  return {
    allowedMainNavItems,
    expandedItems,
    handleChildItemClick,
    handleChildItemsToggle,
    handleContextMenu,
    handleMainNavToggle,
    handleNavItemClick,
    isChildItemOpen,
  };
};

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { unique } from '~/utils/array';
import { Log } from '~/utils/logging';

import { BILLED_ITEM_SETTLED_STATUS } from './constants';
import { type BilledItem } from './types';

/**
 * Represents a billed item with related details and utilities for status handling.
 */
export const BilledItemObject = {
  /**
   * Enum representing possible settlement statuses.
   */
  SETTLED_STATUS: BILLED_ITEM_SETTLED_STATUS,

  /**
   * Creates a billed item with the given data.
   * @param billedItem - The raw billed item data.
   * @returns An initialized BilledItem instance.
   */
  create(billedItem: Partial<BilledItem> = {}) {
    const billingDetails =
      billedItem?.billingDetails?.map((billingDetail: any) => ({
        amount: {
          unit: billingDetail?.billedUnit ?? null,
          value: billingDetail?.billedAmount ?? null,
        },
        invoiceId: billingDetail?.invoiceUuid ?? null,
      })) ?? [];

    const totalBilledQuotient = billedItem?.totalBilledQuot
      ? Number(billedItem?.totalBilledQuot)
      : 0;

    return {
      path: billedItem?.path ?? [],
      totalBilledQuotient,
      billingDetails,
      invoiceIds: unique(
        billingDetails.map(({ invoiceId }: any) => invoiceId).filter(Boolean),
      ),
      settledStatus: this.getSettledStatus(totalBilledQuotient),
    };
  },

  /**
   * Determines the settled status based on the total billed quotient.
   * @param totalBilledQuotient - The total billed quotient.
   * @returns The settlement status key.
   */
  getSettledStatus(totalBilledQuotient?: number) {
    if (!totalBilledQuotient) {
      return this.SETTLED_STATUS.NOT_SETTLED.KEY;
    }

    if (totalBilledQuotient === 1) {
      return this.SETTLED_STATUS.FULLY_SETTLED.KEY;
    }

    return this.SETTLED_STATUS.PARTLY_SETTLED.KEY;
  },

  /**
   * Calculates the combined settlement status for a list of statuses.
   * @param settledStatusList - A list of settlement status keys.
   * @returns The combined settlement status key.
   */
  calculateCombinedSettledStatus(settledStatusList: string[]) {
    if (
      settledStatusList.every(
        (status) => status === this.SETTLED_STATUS.NOT_SETTLED.KEY,
      )
    ) {
      return this.SETTLED_STATUS.NOT_SETTLED.KEY;
    }

    if (
      settledStatusList.every(
        (status) => status === this.SETTLED_STATUS.FULLY_SETTLED.KEY,
      )
    ) {
      return this.SETTLED_STATUS.FULLY_SETTLED.KEY;
    }

    return this.SETTLED_STATUS.PARTLY_SETTLED.KEY;
  },

  /**
   * Retrieves the description of a settlement status by its key.
   * @param key - The settlement status key.
   * @returns The settlement status description.
   */
  getSettledStatusDescription(key: string) {
    const status = Object.keys(this.SETTLED_STATUS).find(
      (x) =>
        this.SETTLED_STATUS[x as keyof typeof this.SETTLED_STATUS].KEY === key,
    );
    if (!status) {
      Log.error(
        null,
        new EnumValueNotFoundException(`Invalid settled status key: ${key}`),
      );
      return null;
    }

    return this.SETTLED_STATUS[status as keyof typeof this.SETTLED_STATUS]
      .DESCRIPTION;
  },

  /**
   * Provides a list of all settlement status descriptions.
   * @returns An array of settlement status descriptions.
   */
  getSettledStatusOptions() {
    return [
      this.SETTLED_STATUS.NOT_SETTLED.DESCRIPTION,
      this.SETTLED_STATUS.PARTLY_SETTLED.DESCRIPTION,
      this.SETTLED_STATUS.FULLY_SETTLED.DESCRIPTION,
    ];
  },
};

import { CSVValidatorObject } from '../CSVValidator';

export const USER_ASSET_CREATOR_USER_ID =
  '8814cf34-fd7a-4a3a-8abd-eea291dfea35';

export const USER_TYPE = {
  CARRIER: {
    KEY: 'carrier',
    NAME: 'Spediteur',
  },
  RECIPIENT: {
    KEY: 'buyer',
    NAME: 'Abnehmer',
  },
  SUPPLIER: {
    KEY: 'supplier',
    NAME: 'Lieferant',
  },
} as const;

export const USER_CSV_ALLOWED_COLUMNS = [
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'username',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'email',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'password',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'first_name',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'last_name',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'permitted_signature_types',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.UUID,
    NAME: 'company_id',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.BOOLEAN,
    NAME: 'is_active',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'position',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.TEXT,
    NAME: 'user_type',
  },
  {
    DATA_TYPE: CSVValidatorObject.DATA_TYPE.UUID_LIST,
    NAME: 'org_units',
  },
] as const;

export const DIFFERENCE_VALUES = {
  ACTIVE: 'Aktiv',
  COMPANY: 'Firma',
  EMAIL: 'E-Mail',
  FEATURE_FLAGS: 'Feature-Flags',
  FIRST_NAME: 'Vorname',
  ID: 'ID',
  LAST_NAME: 'Nachname',
  NAME: 'Name',
  ORG_GROUPS: 'Organisations-Gruppen',
  PASSWORD: 'Passwort',
  POSITION: 'Position',
  PUSH_NOTIFICATIONS_SETTINGS: 'Push-Benachrichtigungen',
  SIGNATURE_ROLES: 'Signaturrollen',
  USER_GROUPS: 'Benutzer-Gruppen',
} as const;

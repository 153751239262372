import { type Address } from '~/types/address';

import { ADDRESS_DEFAULT_COUNTRY_CODE } from './constants';

export const AddressObject = {
  COUNTRY_CODE: ADDRESS_DEFAULT_COUNTRY_CODE,

  /**
   * Creates an address object.
   * If no address data is provided, it returns the default address.
   * @param {Address} [address] - The input address object.
   * @returns {Address} - The created address object.
   */
  create(address: Partial<Address> = {}) {
    return {
      streetName: address?.streetName ?? '',
      buildingNumber: address?.buildingNumber ?? '',
      postCode: address?.postCode ?? '',
      city: address?.city ?? '',
      country: address?.country ?? '',
    };
  },

  /**
   * Joins array elements into a single string, using a specified delimiter.
   * Ignores null or undefined elements in the array.
   * @param {(string | null | undefined)[]} array - The array of elements to join.
   * @param {string} [joinSequence=' '] - The delimiter to use for joining the elements.
   * @returns {string} - The joined string.
   */
  joinElements: (
    array: Array<string | undefined>,
    joinSequence = ' ',
  ): string =>
    array
      .filter((element) => element !== null && element !== '')
      .join(joinSequence),

  /**
   * Converts an address object to its string representation.
   * Combines the address fields (street, building number, post code, city, country) into a formatted string.
   * @param {Partial<Address>} address - The address object to convert.
   * @returns {string} - The string representation of the address.
   */
  stringify(address: Partial<Address>) {
    if (!address) {
      return '';
    }

    const { buildingNumber, city, country, postCode, streetName } = address;

    return AddressObject.joinElements(
      [
        AddressObject.joinElements([streetName, buildingNumber]),
        AddressObject.joinElements([postCode, city]),
        AddressObject.joinElements([country]),
      ],
      ', ',
    );
  },

  /**
   * Compares two address objects and returns a list of differences.
   * The differences include street name, building number, postal code, city, and country.
   * @param {Partial<Address>} addressA - The first address object to compare.
   * @param {Partial<Address>} addressB - The second address object to compare.
   * @returns {string[]} - A list of differences (e.g., 'Straße', 'Hausnummer').
   */
  getDifferences(
    addressA: Partial<Address>,
    addressB: Partial<Address>,
  ): string[] {
    const differentValues: string[] = [];

    if (addressA?.streetName !== addressB?.streetName)
      differentValues.push('Straße');
    if (addressA?.buildingNumber !== addressB?.buildingNumber)
      differentValues.push('Hausnummer');
    if (addressA?.postCode !== addressB?.postCode) differentValues.push('PLZ');
    if (addressA?.city !== addressB?.city) differentValues.push('Ort');
    if (addressA?.country !== addressB?.country) differentValues.push('Land');

    return differentValues;
  },
};

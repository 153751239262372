import { camelize, decamelize, pascalize } from 'fast-case';
import slugifyRaw from 'slugify';
import truncateMiddle from 'truncate-middle';

const startsWithUuidRegex = /^[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}/i;

/**
 * Convert a string to camelCase.
 * Does not convert UUIDs, because that would break them.
 */
export const toCamelCase = (variableName: string) =>
  typeof variableName === 'string' && !startsWithUuidRegex.test(variableName)
    ? camelize(variableName)
    : variableName;

/**
 * Convert a string to PascalCase.
 * Does not convert UUIDs, because that would break them.
 */
export const toPascalCase = (variableName: string) =>
  typeof variableName === 'string' && !startsWithUuidRegex.test(variableName)
    ? pascalize(variableName)
    : variableName;

/**
 * Convert a string to snake_case.
 * Does not convert UUIDs, because that would break them.
 */
export const toSnakeCase = (variableName: string) =>
  typeof variableName === 'string' && !startsWithUuidRegex.test(variableName)
    ? decamelize(variableName)
    : variableName;

/**
 * Truncates a string to a specified maximum length and adds an ellipsis if truncated.
 *
 * @param {string} value - The string to be truncated.
 * @param {number} [maxLength=26] - The maximum length of the string.
 * @param {number} [charactersToPreserveAtEnd=0] - The number of characters to preserve at the end of the string. Will truncate the middle of the string if set.
 * @return {string} The truncated string.
 */
export const truncate = (
  value: string,
  maxLength = 26,
  charactersToPreserveAtEnd = 0,
) => {
  if (!value) {
    return '';
  }

  return truncateMiddle(
    value,
    maxLength - charactersToPreserveAtEnd,
    charactersToPreserveAtEnd,
    '…',
  );
};

/**
 * Convert a string to a slug by replacing spaces with underscores and making it lowercase.
 * Uses the `slugify` package under the hood.
 * @param text - The input string to be slugified.
 * @returns The slugified string.
 */
export const slugify = (text: string) =>
  slugifyRaw(text, {
    lower: true,
    replacement: '_',
    strict: true,
  });

import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import clsx from 'clsx';
import { memo, type ReactElement } from 'react';

type P = {
  isCollapsed: boolean;
  toggleState: () => void;
  withTooltip: (
    element: ReactElement,
    tooltip: string,
    placement?: 'top' | 'bottom' | 'left' | 'right',
  ) => ReactElement;
};

export const ExpandButton = memo(
  ({ isCollapsed, toggleState, withTooltip }: P) => {
    const button = (
      <div className="mt-auto h-12 w-full flex-shrink-0">
        <Button
          className="h-full w-full hover:bg-[#12306d]"
          color="inherit"
          size="large"
          startIcon={
            <KeyboardArrowLeftIcon
              className={clsx('transition-transform', {
                'rotate-180': isCollapsed,
              })}
            />
          }
          variant="text"
          onMouseDown={toggleState}
        >
          <span
            className={clsx({
              hidden: isCollapsed,
            })}
          >
            Einklappen
          </span>
        </Button>
      </div>
    );

    return withTooltip(button, isCollapsed ? 'Ausklappen' : '', 'right');
  },
);

ExpandButton.displayName = 'ExpandButton';

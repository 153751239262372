import * as FileSaver from 'file-saver';
import { createZip } from 'littlezipper';
import PDFMerger from 'pdf-merger-js/browser';
import { utils as utilsXLSX, write as writeXLSX } from 'xlsx';

import { apiUrl } from '~/constants/environment';

import { fetchInvoice } from '~/data/invoice';

import Invoice from '~/models/invoices/Invoice';

import axios from '~/utils/api-client';
import { sortByKey } from '~/utils/array';
import { dateUtils } from '~/utils/dateUtils';
import FunctionUtils from '~/utils/functionUtils';
import i18n from '~/utils/i18n';
import { Log } from '~/utils/logging';
import { promiseAllThrottled } from '~/utils/promise';
import { promiseHandler } from '~/utils/promiseHandler';
import { getAbbreviatedUnit } from '~/utils/unit';
import UnitUtils from '~/utils/unitUtils';

import { dateRangeOptions } from '~/ui/molecules/DateInput/constants';

import InvoicesService from './invoices.service';
import ToastService from './toast.service';

const API_URL = apiUrl + '/asset/';

class ExportService {
  constructor(props) {
    this.exportingDeliveryNotes = false;
    this.exportingInvoices = false;
    this.multiDeliveryNoteDownloadToast = null;
    this.multiInvoiceDownloadToast = null;

    this.DOWNLOAD_OPTION = {
      MERGE: 'merge',
      ZIP: 'zip',
    };
  }

  // get the PDF invoice for a particular invoice by its id / asset-id
  async getSpecificInvoiceAsPDF(invoiceId) {
    return axios.get(API_URL + 'invoice/' + invoiceId + '/pdf', {
      responseType: 'blob',
    });
  }

  async createSpecificDeliveryNoteAsPDF(deliveryNoteId) {
    return axios.get(API_URL + deliveryNoteId + '/request_pdf', {
      params: { type: 'docx' },
    });
  }

  async getSpecificDeliveryNoteAsPDF(deliveryNoteId) {
    return axios.get(API_URL + deliveryNoteId + '/get_pdf', {
      responseType: 'blob',
    });
  }

  // helper function for single delivery note
  async exportSpecificDeliveryNoteAsPDF(deliveryNoteId) {
    const [response1, error1] = await promiseHandler(
      this.createSpecificDeliveryNoteAsPDF(deliveryNoteId),
    );

    // In case of a 409 error, everything is ok and the PDF can be downloaded.
    if (error1 && error1.response.status !== 409) {
      Log.error(
        'Failed to request PDF creation of delivery note. id: ' +
          deliveryNoteId,
        error1,
      );
      Log.productAnalyticsEvent(
        'Failed to download delivery note PDF',
        Log.FEATURE.PDF_DOWNLOAD,
        Log.TYPE.ERROR,
      );
      throw error1;
    }

    let file = null;

    while (!file) {
      const [response2, error2] = await promiseHandler(
        this.getSpecificDeliveryNoteAsPDF(deliveryNoteId),
      );

      if (error2) {
        Log.error(
          'Failed to download PDF of delivery note. id: ' + deliveryNoteId,
          error2,
        );
        Log.productAnalyticsEvent(
          'Failed to download delivery note PDF',
          Log.FEATURE.PDF_DOWNLOAD,
          Log.TYPE.ERROR,
        );
        throw error2;
      }

      // If the server returns 202, it means that the PDF is not yet ready to be downloaded.
      // Therefore, wait for 1 second and then request PDF again.
      if (response2.status === 202) {
        await FunctionUtils.timer(1000);
        continue;
      }

      file = new Blob([response2.data], { type: 'application/pdf' });
    }

    return file;
  }

  // helper function for multiple delivery notes
  async exportMultipleDeliveryNotesAsPDF(deliveryNoteIds) {
    const blobArray = [];
    const failedPdfs = [];

    for (let index = 0; index < deliveryNoteIds.length; index++) {
      const deliveryNoteId = deliveryNoteIds[index];

      const [file, error] = await promiseHandler(
        this.exportSpecificDeliveryNoteAsPDF(deliveryNoteId),
      );

      if (error) {
        failedPdfs.push(deliveryNoteId);
        Log.error(
          'Failed to export PDF of delivery note. id: ' + deliveryNoteId,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to download delivery note PDF',
          Log.FEATURE.PDF_DOWNLOAD,
          Log.TYPE.ERROR,
        );
        continue;
      }

      blobArray.push({ file, id: deliveryNoteId });

      ToastService.promise(
        this.multiDeliveryNoteDownloadToast.promise,
        [
          this.multiDeliveryNoteDownloadToast.loadingLines[0] +
            ' (' +
            (index + 1) +
            '/' +
            deliveryNoteIds.length +
            ')',
        ],
        this.multiDeliveryNoteDownloadToast.successLines,
        this.multiDeliveryNoteDownloadToast.errorLines,
        this.multiDeliveryNoteDownloadToast.id,
      );
    }

    if (blobArray.length === 0) {
      throw 'Empty blobArray';
    }

    return [blobArray, failedPdfs];
  }

  async exportSpecificInvoiceAsPDF(invoiceId) {
    const [response, error] = await promiseHandler(
      this.getSpecificInvoiceAsPDF(invoiceId),
    );

    if (error) {
      Log.error('Failed to download PDF of invoice. id: ' + invoiceId, error);
      Log.productAnalyticsEvent(
        'Failed to download invoice PDF',
        Log.FEATURE.PDF_DOWNLOAD,
        Log.TYPE.ERROR,
      );
      throw error;
    }

    return new Blob([response.data], { type: 'application/pdf' });
  }

  async exportMultipleInvoicesAsPDF(invoiceIds) {
    const blobArray = [];
    const failedPdfs = [];

    for (let index = 0; index < invoiceIds.length; index++) {
      const invoiceId = invoiceIds[index];

      const [file, error] = await promiseHandler(
        this.exportSpecificInvoiceAsPDF(invoiceId),
      );

      if (error) {
        failedPdfs.push(invoiceId);
        Log.error('Failed to export PDF of invoice. id: ' + invoiceId, error);
        Log.productAnalyticsEvent(
          'Failed to download invoice PDF',
          Log.FEATURE.PDF_DOWNLOAD,
          Log.TYPE.ERROR,
        );
        continue;
      }

      blobArray.push({ file, id: invoiceId });

      ToastService.promise(
        this.multiInvoiceDownloadToast.promise,
        [
          this.multiInvoiceDownloadToast.loadingLines[0] +
            ' (' +
            (index + 1) +
            '/' +
            invoiceIds.length +
            ')',
        ],
        this.multiInvoiceDownloadToast.successLines,
        this.multiInvoiceDownloadToast.errorLines,
        this.multiInvoiceDownloadToast.id,
      );
    }

    if (blobArray.length === 0) {
      throw 'Empty blobArray';
    }

    return [blobArray, failedPdfs];
  }

  // this function downloads the selected PDF files of delivery notes and zips them
  exportDeliveryNotes = async (ids, downloadOption) => {
    Log.info(
      'Download delivery notes as PDF',
      { rowSelectionModel: ids },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );

    if (this.exportingDeliveryNotes) {
      ToastService.info(
        ['Bitte abwarten bis der aktuelle Download abgeschlossen ist.'],
        ToastService.ID.DLN_DOWNLOAD_WAIT,
      );
      return;
    }

    if (ids.length === 0) {
      ToastService.info(
        [ToastService.MESSAGE.DLN_DOWNLOAD_NONE_SELECTED],
        ToastService.ID.DLN_DOWNLOAD_NONE_SELECTED,
      );
      return;
    }

    // don't allow downloading more than 300 dlns because browser will run out of memory
    if (ids.length > 300) {
      ToastService.warning(
        [ToastService.MESSAGE.DLN_DOWNLOAD_TOO_MANY_SELECTED],
        ToastService.ID.DLN_DOWNLOAD_TOO_MANY_SELECTED,
      );
      Log.productAnalyticsEvent(
        'Too many delivery notes selected',
        Log.FEATURE.PDF_DOWNLOAD,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    // single delivery note that does not need to be zipped
    if (ids.length === 1) {
      Log.productAnalyticsEvent(
        'Download delivery note PDF',
        Log.FEATURE.PDF_DOWNLOAD,
      );

      this.exportingDeliveryNotes = true;

      const deliveryNoteId = ids[0];

      const promise = this.exportSpecificDeliveryNoteAsPDF(deliveryNoteId);

      ToastService.promise(
        promise,
        ['PDF-Lieferung wird geladen...'],
        ['PDF-Lieferung konnte geladen werden.'],
        [
          'PDF-Lieferung konnte nicht geladen werden.',
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ],
      );

      const [file, error] = await promiseHandler(promise);

      if (error) {
        Log.error(
          'Failed to export PDF of delivery note. id: ' + deliveryNoteId,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to download delivery note PDF',
          Log.FEATURE.PDF_DOWNLOAD,
          Log.TYPE.ERROR,
        );
        this.exportingDeliveryNotes = false;
        throw error;
      }

      this.downloadFileWithCustomName(
        file,
        this.getDeliveryNoteFileName(deliveryNoteId),
      );
    }

    if (ids.length > 1) {
      if (downloadOption === this.DOWNLOAD_OPTION.MERGE) {
        Log.productAnalyticsEvent(
          'Download multiple delivery note PDFs as one merged document',
          Log.FEATURE.PDF_DOWNLOAD,
        );
      }

      this.exportingDeliveryNotes = true;

      let loadingMessage = 'PDF-Lieferungen werden geladen...';
      if (ids.length >= 10) {
        loadingMessage =
          'PDF-Lieferungen werden geladen. Dies kann einige Sekunden dauern...';
      }

      if (ids.length >= 30) {
        loadingMessage =
          'PDF-Lieferungen werden geladen. Dies kann einige Minuten dauern...';
      }

      const promise = this.exportMultipleDeliveryNotesAsPDF(ids);

      // It is necessary to store the toast information in a global variable
      // because the toast needs to accessed from inside the promise from which it is created.
      // The current solution with the global this.multiDeliveryNoteDownloadToast variable is not a clean solution.
      this.multiDeliveryNoteDownloadToast = {
        errorLines: [
          'PDF-Lieferungen konnten nicht geladen werden.',
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ],
        id: 'multiDeliveryNoteDownloadToast-id',
        loadingLines: [loadingMessage],
        promise,
        successLines: ['PDF-Lieferungen konnten geladen werden.'],
      };

      ToastService.promise(
        this.multiDeliveryNoteDownloadToast.promise,
        [
          this.multiDeliveryNoteDownloadToast.loadingLines[0] +
            ' (0/' +
            ids.length +
            ')',
        ],
        this.multiDeliveryNoteDownloadToast.successLines,
        this.multiDeliveryNoteDownloadToast.errorLines,
        this.multiDeliveryNoteDownloadToast.id,
      );

      const [response, error] = await promiseHandler(promise);

      if (error) {
        Log.error('Failed to export PDF of delivery notes.', error);
        Log.productAnalyticsEvent(
          'Failed to download delivery note PDF',
          Log.FEATURE.PDF_DOWNLOAD,
          Log.TYPE.ERROR,
        );
        this.exportingDeliveryNotes = false;
        throw error;
      }

      const [blobArray, failedPdfs] = response;

      if (failedPdfs.length > 0) {
        const numbers = failedPdfs.map((dlnId) => dlnId);

        if (failedPdfs.length === 1) {
          ToastService.error([
            'Download fehlgeschlagen für die Lieferung ' +
              numbers.join(', ') +
              '.',
            ToastService.MESSAGE.CONTACT_SUPPORT,
          ]);
          Log.productAnalyticsEvent(
            'Failed to download delivery note PDF',
            Log.FEATURE.PDF_DOWNLOAD,
            Log.TYPE.ERROR,
          );
        } else {
          ToastService.error([
            'Download fehlgeschlagen für die Lieferungen ' +
              numbers.join(', ') +
              '.',
            ToastService.MESSAGE.CONTACT_SUPPORT,
          ]);
          Log.productAnalyticsEvent(
            'Failed to download multiple delivery note PDFs',
            Log.FEATURE.PDF_DOWNLOAD,
            Log.TYPE.ERROR,
          );
        }
      }

      if (
        blobArray.length > 0 &&
        downloadOption === this.DOWNLOAD_OPTION.MERGE
      ) {
        this.mergeFiles(
          blobArray,
          this.ENTITIES.DELIVERY_NOTES,
          this.FILE_FORMATS.PDF,
        );
      }
    }

    this.exportingDeliveryNotes = false;
  };
  // get the selected PDF invoices from the table, zip them and download them
  exportInvoices = async (ids, downloadOption) => {
    Log.info(
      'Download invoices as PDF',
      {
        rowSelectionModel: ids,
      },
      Log.BREADCRUMB.USER_ACTION.KEY,
    );

    if (this.exportingInvoices) {
      ToastService.info(
        ['Bitte abwarten, bis der aktuelle Download abgeschlossen ist.'],
        ToastService.ID.INV_DOWNLOAD_WAIT,
      );
      return;
    }

    if (ids.length === 0) {
      ToastService.info(
        [ToastService.MESSAGE.INV_DOWNLOAD_NONE_SELECTED],
        ToastService.ID.INV_DOWNLOAD_NONE_SELECTED,
      );
      return;
    }

    // don't allow downloading more than 100 invoices because browser will run out of memory
    if (ids.length > 100) {
      ToastService.warning(
        [ToastService.MESSAGE.INV_DOWNLOAD_TOO_MANY_SELECTED],
        ToastService.ID.INV_DOWNLOAD_TOO_MANY_SELECTED,
      );
      Log.productAnalyticsEvent(
        'Too many invoices selected',
        Log.FEATURE.PDF_DOWNLOAD,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    // if only one PDF is selected, we do not need the zip file
    if (ids.length === 1) {
      Log.productAnalyticsEvent(
        'Download invoice PDF',
        Log.FEATURE.PDF_DOWNLOAD,
      );

      this.exportingInvoices = true;

      const invoiceId = ids[0];

      const promise = this.exportSpecificInvoiceAsPDF(invoiceId);

      ToastService.promise(
        promise,
        ['PDF-Rechnung wird geladen...'],
        ['PDF-Rechnungen konnte geladen werden.'],
        [
          'PDF-Rechnungen konnte nicht geladen werden.',
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ],
      );

      const [file, error] = await promiseHandler(promise);

      if (error) {
        Log.error('Failed to export PDF of invoice. id: ' + ids[0], error);
        Log.productAnalyticsEvent(
          'Failed to download invoice PDF',
          Log.FEATURE.PDF_DOWNLOAD,
          Log.TYPE.ERROR,
        );
        this.exportingInvoices = false;
        throw error;
      }

      this.downloadFileWithCustomName(
        file,
        this.getInvoiceFileName(invoiceId, null, 'pdf'),
      );
    }

    if (ids.length > 1) {
      Log.productAnalyticsEvent(
        'Download multiple invoice PDFs',
        Log.FEATURE.PDF_DOWNLOAD,
      );

      this.exportingInvoices = true;

      let loadingMessage = 'PDF-Rechnungen werden geladen...';
      if (ids.length >= 10) {
        loadingMessage =
          'PDF-Rechnungen werden geladen. Dies kann einige Sekunden dauern...';
      }

      if (ids.length >= 30) {
        loadingMessage =
          'PDF-Rechnungen werden geladen. Dies kann einige Minuten dauern...';
      }

      const promise = this.exportMultipleInvoicesAsPDF(ids);

      this.multiInvoiceDownloadToast = {
        errorLines: [
          'PDF-Rechnungen konnten nicht geladen werden.',
          ToastService.MESSAGE.CONTACT_SUPPORT,
        ],
        id: 'multiInvoiceDownloadToast-id',
        loadingLines: [loadingMessage],
        promise,
        successLines: ['PDF-Rechnungen konnten geladen werden.'],
      };

      ToastService.promise(
        this.multiInvoiceDownloadToast.promise,
        [
          this.multiInvoiceDownloadToast.loadingLines[0] +
            ' (0/' +
            ids.length +
            ')',
        ],
        this.multiInvoiceDownloadToast.successLines,
        this.multiInvoiceDownloadToast.errorLines,
        this.multiInvoiceDownloadToast.id,
      );

      const [response, error] = await promiseHandler(promise);

      if (error) {
        Log.error('Failed to export PDF of invoices.', error);
        Log.productAnalyticsEvent(
          'Failed to download invoice PDFs',
          Log.FEATURE.PDF_DOWNLOAD,
          Log.TYPE.ERROR,
        );
        this.exportingInvoices = false;
        throw error;
      }

      const [blobArray, failedPdfs] = response;

      if (failedPdfs.length > 0) {
        const numbers = failedPdfs.map(
          (invoiceId) => Invoice.getNumberById(invoiceId) ?? invoiceId,
        );

        if (failedPdfs.length === 1) {
          ToastService.error([
            'Download fehlgeschlagen für die Rechnung ' +
              numbers.join(', ') +
              '.',
            ToastService.MESSAGE.CONTACT_SUPPORT,
          ]);
          Log.productAnalyticsEvent(
            'Failed to download invoice PDF',
            Log.FEATURE.PDF_DOWNLOAD,
            Log.TYPE.ERROR,
          );
        } else {
          ToastService.error([
            'Download fehlgeschlagen für die Rechnungen ' +
              numbers.join(', ') +
              '.',
            ToastService.MESSAGE.CONTACT_SUPPORT,
          ]);
          Log.productAnalyticsEvent(
            'Failed to download multiple invoice PDFs',
            Log.FEATURE.PDF_DOWNLOAD,
            Log.TYPE.ERROR,
          );
        }
      }

      if (blobArray.length > 0) {
        if (downloadOption === this.DOWNLOAD_OPTION.ZIP) {
          this.zipInvoices(blobArray);
        }

        if (downloadOption === this.DOWNLOAD_OPTION.MERGE) {
          this.mergeFiles(
            blobArray,
            this.ENTITIES.INVOICES,
            this.FILE_FORMATS.PDF,
          );
        }
      }
    }

    this.exportingInvoices = false;
  };

  async fileToArrayBuffer(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result));
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  // create a zip file based on the stored blob files
  async zipExcelInvoices(blobArray) {
    const files = await promiseAllThrottled(
      blobArray.map(async (element, index) => {
        const fileData = await this.fileToArrayBuffer(element.file);

        return {
          data: new Uint8Array(fileData),
          path: this.getInvoiceFileName(element.id, index, 'xlsx'),
        };
      }),
    );

    const zipBlob = new Blob([await createZip(files)], {
      type: 'application/zip',
    });
    const filename = this.generateFileName({
      entityType: this.ENTITIES.INVOICES,
      format: this.FILE_FORMATS.XLSX,
      isArchive: true,
    });

    FileSaver.saveAs(zipBlob, filename);
  }

  // create a zip file based on the stored blob files
  async zipInvoices(blobArray) {
    const files = await promiseAllThrottled(
      blobArray.map(async (element, index) => {
        const fileData = await this.fileToArrayBuffer(element.file);

        return {
          data: new Uint8Array(fileData),
          path: this.getInvoiceFileName(element.id, index, 'pdf'),
        };
      }),
    );

    const zipBlob = new Blob([await createZip(files)], {
      type: 'application/zip',
    });
    const filename = this.generateFileName({
      entityType: this.ENTITIES.INVOICES,
      format: this.FILE_FORMATS.PDF,
      isArchive: true,
    });

    FileSaver.saveAs(zipBlob, filename);
  }

  // merge the pdfs into one file
  async mergeFiles(blobArray, entityType, format) {
    const merger = new PDFMerger();

    for (const element of blobArray) {
      await merger.add(element.file);
    }

    const mergedPdf = await merger.saveAsBlob();

    this.downloadFileWithCustomName(
      mergedPdf,
      this.generateFileName({
        entityType,
        format,
      }),
    );
  }

  /**
   * Export the data as an Excel file.
   *
   * @param {Array} data - The data to export.
   * @param {Object} options - The options for the exported file.
   * @param {string | undefined} options.predefinedRange - The predefined date range string to use for the exported file.
   * @param {Date[] | undefined} options.customRange - The custom date range to use for the exported file.
   * @param {string | undefined} options.entityType - The entity type to use for the exported file.
   */
  exportExcel = (data, options) => {
    // Don't log product analytics event here because it is already logged in the functions calling exportExcel
    // Log.productAnalyticsEvent('Download Excel', Log.FEATURE.EXCEL_DOWNLOAD);

    // Create a new Work Sheet using the data stored in an Array of Arrays.
    const workSheet = utilsXLSX.aoa_to_sheet(data);
    // Generate a Work Book containing the above sheet.
    const workBook = {
      SheetNames: ['data'],
      Sheets: { cols: [], data: workSheet },
    };

    // Exporting the file with the desired name and extension.
    const excelBuffer = writeXLSX(workBook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const fileData = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    const fileNamingOptions = {
      entityType: options.entityType ?? this.ENTITIES.INVOICES,
      format: this.FILE_FORMATS.XLSX,
    };

    if (options?.predefinedRange && options?.predefinedRange !== 'individual') {
      fileNamingOptions.predefinedRange = options.predefinedRange;
    } else if (options?.customRange) {
      fileNamingOptions.date = this.generateDateStringFromDateRange(
        options.customRange,
      );
    }

    FileSaver.saveAs(fileData, this.generateFileName(fileNamingOptions));
  };
  /**
   * Export the data as an Excel file from a blob.
   *
   * @param {Blob} fileData - The blob to export.
   * @param {Object} options - The options for the export file.
   * @param {string} options.entityType - The entity type to use for the exported file.
   * @param {string} options.predefinedRange - The predefined date range string to use for the exported file.
   * @param {Date[]} options.customRange - The custom date range to use for the exported file.
   */
  exportExcelFromBlob = (fileData, options) => {
    const fileNamingOptions = {
      entityType: options.entityType ?? this.ENTITIES.DELIVERIES,
      format: this.FILE_FORMATS.XLSX,
    };

    if (options?.predefinedRange && options?.predefinedRange !== 'individual') {
      fileNamingOptions.predefinedRange = options.predefinedRange;
    } else if (options?.customRange) {
      fileNamingOptions.date = this.generateDateStringFromDateRange(
        options.customRange,
      );
    }

    FileSaver.saveAs(fileData, this.generateFileName(fileNamingOptions));
  };
  /**
   * Export the data as a CSV file from a blob.
   *
   * @param {Blob} fileData - The blob to export.
   * @param {Object} options - The options for the export file.
   * @param {string} options.entityType - The entity type to use for the exported file.
   * @param {string} options.predefinedRange - The predefined date range string to use for the exported file.
   * @param {Date[]} options.customRange - The custom date range to use for the exported file.
   */
  exportCSVFromBlob = (fileData, options) => {
    const fileNamingOptions = {
      entityType: options.entityType ?? this.ENTITIES.DELIVERIES,
      format: this.FILE_FORMATS.CSV,
    };

    if (options?.predefinedRange && options?.predefinedRange !== 'individual') {
      fileNamingOptions.predefinedRange = options.predefinedRange;
    } else if (options?.customRange) {
      fileNamingOptions.date = this.generateDateStringFromDateRange(
        options.customRange,
      );
    }

    FileSaver.saveAs(fileData, this.generateFileName(fileNamingOptions));
  };

  getInvoiceAsExcel(invoice) {
    const data = [];

    data.push([invoice.number], [invoice.seller.name]);
    data.push(
      [
        dateUtils.getFormattedDate_safe(
          invoice.date,
          dateUtils.DATE_FORMAT.DD_MM_YYYY,
          dateUtils.DATE_FORMAT.YYYY_MM_DD__HH_mm_ss_SSSSSS,
        ),
      ],
      [],
      ['----------------------------'],
      [
        'Position',
        'Lieferung',
        'Nummer',
        'Name',
        'Preis',
        'pro Einheit',
        'Menge',
        'Einheit',
        'Gesamt (netto)',
        'MwSt',
        'Gesamt (brutto)',
      ],
    );
    for (const position of sortByKey(invoice.positions, 'positionNumber')) {
      data.push([
        position.positionNumber,
        position.deliveryNotes
          .map((deliveryNote) => deliveryNote.number)
          .join(', '),
        position.number,
        position.name,
        position.itemPriceNet,
        position.netPricePerQuantity,
        position.amount.value,
        getAbbreviatedUnit(position.amount.unit),
        null,
        position.taxPercent,
        null,
      ]);
    }

    data.push(
      ['----------------------------'],
      [],
      [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        'Gesamt (netto)',
        null,
        'Gesamt (brutto)',
      ],
      [null, null, null, null, null, null, null, null, null, null, null],
      [],
      [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        'Rabatte/Zuschläge/Versandkosten',
        null,
        null,
      ],
      [null, null, null, null, null, null, null, null, null, null, null],
      [],
      [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        'Rechnungssumme (netto)',
        'Steuerbetrag',
        'Rechnungssumme (brutto)',
      ],
      [null, null, null, null, null, null, null, null, null, null, null],
    );

    // Create a new Work Sheet using the data stored in an Array of Arrays.
    const workSheet = utilsXLSX.aoa_to_sheet(data);

    const currency = getAbbreviatedUnit(invoice.currency);

    for (const [index, position] of invoice.positions.entries()) {
      const row = index + 7;
      workSheet[`E${row}`] = {
        t: 'n',
        v: position.itemPriceNet,
        z: `#,##0.00 ${currency}`,
      };
      workSheet[`I${row}`] = {
        f: `E${row} / F${row} * G${row}`,
        t: 'n',
        z: `#,##0.00 ${currency}`,
      };
      workSheet[`J${row}`] = {
        t: 'n',
        v: position.taxPercent,
        z: '0 %',
      };
      workSheet[`K${row}`] = {
        f: `E${row} / F${row} * G${row} * (1 + J${row})`,
        t: 'n',
        z: `#,##0.00 ${currency}`,
      };
    }

    workSheet[`I${invoice.positions.length + 10}`] = {
      f: invoice.positions
        .map((position, index) => 'I' + (index + 7))
        .join(' + '),
      t: 'n',
      z: `#,##0.00 ${currency}`,
    };
    workSheet[`K${invoice.positions.length + 10}`] = {
      f: invoice.positions
        .map((position, index) => 'K' + (index + 7))
        .join(' + '),
      t: 'n',
      z: `#,##0.00 ${currency}`,
    };
    workSheet[`I${invoice.positions.length + 13}`] = {
      t: 'n',
      v: invoice.logisticFees + invoice.generalAllowance,
      z: `#,##0.00 ${currency}`,
    };
    workSheet[`I${invoice.positions.length + 16}`] = {
      t: 'n',
      v: invoice.totalPriceNet,
      z: `#,##0.00 ${currency}`,
    };
    workSheet[`J${invoice.positions.length + 16}`] = {
      t: 'n',
      v: invoice.totalTax,
      z: `#,##0.00 ${currency}`,
    };
    workSheet[`K${invoice.positions.length + 16}`] = {
      t: 'n',
      v: invoice.totalPriceGross,
      z: `#,##0.00 ${currency}`,
    };

    // Generate a Work Book containing the above sheet.
    const workBook = {
      SheetNames: ['data'],
      Sheets: {
        cols: [],
        data: workSheet,
      },
    };

    // Exporting the file with the desired name and extension.
    const excelBuffer = writeXLSX(workBook, {
      bookType: 'xlsx',
      type: 'array',
    });
    return new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
  }

  exportInvoiceAsExcel = (invoice) => {
    FileSaver.saveAs(
      this.getInvoiceAsExcel(invoice),
      this.generateFileName({
        entityType: this.ENTITIES.INVOICE,
        filterGroupName: invoice.number,
        format: this.FORMATS.XLSX,
      }),
    );
  };

  async exportInvoicesAsExcelZip(ids, direction) {
    const blobArray = [];

    for (const id of ids) {
      const [invoice, error] = await promiseHandler(fetchInvoice(id));

      if (error) {
        Log.error('Failed to get invoice by id. id: ' + id, error);
        Log.productAnalyticsEvent(
          'Failed to download invoices as Excel',
          Log.FEATURE.EXCEL_DOWNLOAD,
        );
        continue;
      }

      const initializedInvoice = new Invoice(invoice, direction);

      blobArray.push({ file: this.getInvoiceAsExcel(invoice), id });
    }

    this.zipExcelInvoices(blobArray);
  }

  downloadFileWithCustomName(file, fileName) {
    const fileURL = globalThis.URL.createObjectURL(file);

    this.downloadUrlWithCustomName(fileURL, fileName);
  }

  downloadUrlWithCustomName(fileURL, fileName) {
    const anchor = document.createElement('a');
    anchor.href = fileURL;
    anchor.download = fileName; // Set the desired file name here

    // Programmatically trigger a click event on the anchor element
    const clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: globalThis,
    });
    anchor.dispatchEvent(clickEvent);

    // Clean up after the download
    globalThis.URL.revokeObjectURL(fileURL);
  }

  getDeliveryNoteFileName({
    deliveryNoteDate,
    deliveryNoteId,
    deliveryNoteNumber,
    fileFormat = 'pdf',
    index,
  }) {
    const number = deliveryNoteNumber ?? index ?? '';
    const date = dateUtils.getFormattedDate(
      deliveryNoteDate,
      dateUtils.DATE_FORMAT.YYYYMMDD,
    );

    return this.generateFileName({
      entityType: this.ENTITIES.DELIVERY,
      date,
      filterGroupName: number,
      format: fileFormat,
    });
  }

  getInvoiceFileName(invoiceId, index, fileFormat) {
    const invoice = Invoice.getById(invoiceId);

    if (invoice.originalFilename) {
      return invoice.originalFilename;
    }

    const number = invoice?.number ?? index ?? '';
    const date =
      invoice?.date &&
      dateUtils.getFormattedDate(invoice.date, dateUtils.DATE_FORMAT.YYYYMMDD);

    return this.generateFileName({
      entityType: this.ENTITIES.INVOICE,
      date,
      filterGroupName: number,
      format: fileFormat,
    });
  }

  ENTITIES = {
    CONTACT_POINTS: i18n.t('export.entity.contactPoints'),
    DATA_SUBSCRIPTIONS: i18n.t('export.entity.dataSubscriptions'),
    DELIVERIES: i18n.t('export.entity.deliveries'),
    DELIVERY_NOTE: i18n.t('export.entity.deliveryNote'),
    DELIVERY_NOTES: i18n.t('export.entity.deliveryNotes'),
    DELIVERY: i18n.t('export.entity.delivery'),
    INCOMING_INVOICES: i18n.t('export.entity.incomingInvoices'),
    INVOICE: i18n.t('export.entity.invoice'),
    INVOICES: i18n.t('export.entity.invoices'),
    NOTIFICATION_POLICIES: i18n.t('export.entity.notificationPolicies'),
    OUTGOING_INVOICES: i18n.t('export.entity.outgoingInvoices'),
    PRODUCT_ANALYTICS: i18n.t('export.entity.productAnalytics'),
    REPORT: i18n.t('export.entity.report'),

    // Settings entity translations
    article: i18n.t('export.entity.article'),
    category: i18n.t('export.entity.category'),
    company: i18n.t('export.entity.company'),
    costCenter: i18n.t('export.entity.costCenter'),
    customField: i18n.t('export.entity.customField'),
    invoiceCheckIgnoredArticle: i18n.t(
      'export.entity.invoiceCheckIgnoredArticle',
    ),
    organisationalGroup: i18n.t('export.entity.organisationalGroup'),
    signatureField: i18n.t('export.entity.signatureField'),
    site: i18n.t('export.entity.site'),
    user: i18n.t('export.entity.user'),
    userGroup: i18n.t('export.entity.userGroup'),
    vehicle: i18n.t('export.entity.vehicle'),
  };
  static PREFIX = 'Vestigas';
  FILE_FORMATS = {
    CSV: 'csv',
    PDF: 'pdf',
    XLSX: 'xlsx',
  };

  /**
   * Generates a file name based on the entity type, date, predefined range, filter group name, format and if it is an archive
   *
   * @param {Object} options
   * @param {string} options.entityType - The entity type
   * @param {string} [options.date] - The date
   * @param {string} [options.predefinedRange] - The predefined range
   * @param {string} [options.filterGroupName] - The filter group name
   * @param {string} options.format - The format
   * @param {boolean} [options.isArchive] - If it is an archive
   * @returns {string}
   */
  generateFileName({
    entityType,
    date = undefined,
    predefinedRange = undefined,
    filterGroupName = undefined,
    format,
    isArchive = false,
  }) {
    const parts = [ExportService.PREFIX];

    parts.push(entityType);

    if (date) {
      parts.push(date);
    } else if (predefinedRange) {
      parts.push(dateRangeOptions[predefinedRange]);
    }

    if (filterGroupName) {
      parts.push(filterGroupName);
    }

    if (!date) {
      parts.push(
        `${dateUtils.getFormattedDate(new Date(), dateUtils.DATE_FORMAT.YYYY_MM_DD)}`,
      );
    }

    let fileName = parts.join('_');

    if (format) {
      fileName += '.';
      fileName += format;
    }

    if (isArchive) {
      fileName += '.zip';
    }

    return fileName;
  }

  /**
   * Generates a string from a date range array
   *
   * @param {Date[]} dateRangeArray - Should include 2 dates, from and to in that order
   * @returns {string}
   */
  generateDateStringFromDateRange(dateRangeArray) {
    const fromDate = dateUtils.getFormattedDate(
      dateRangeArray[0],
      dateUtils.DATE_FORMAT.YYYY_MM_DD,
    );
    const toDate = dateUtils.getFormattedDate(
      dateRangeArray[1],
      dateUtils.DATE_FORMAT.YYYY_MM_DD,
    );

    return `Von-${fromDate}-bis-${toDate}`;
  }
}

const exportServiceInstance = new ExportService();

export default exportServiceInstance;

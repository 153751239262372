import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';

import { queryKeysCostCenter } from './queryKeys';
import { type CostCenter } from './useQueryCostCenter';

type BulkCostCentersResponse = {
  items: CostCenter[];
};

/**
 * Fetches multiple cost centers in bulk from the API.
 * @param {UUID[]} costCenterIds - Array of cost center IDs to fetch.
 * @returns {Promise<CostCenter[]>} Array of cost center data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Accounting-Reference/operation/query_acc_ref_in_bulk_accounting_reference_query_bulk_post
 */
export const fetchCostCentersBulk = async (costCenterIds: UUID[]) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.COST_CENTER.GET_BULK(), {
        json: { ids: costCenterIds },
      })
      .json<BulkCostCentersResponse>();

    return response?.items ?? [];
  } catch (error) {
    Log.error('Error fetching cost centers in bulk', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting multiple cost centers in bulk.
 * @param {UUID[]} costCenterIds - Array of cost center IDs to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<CostCenter[]>} The query result containing array of cost centers.
 */
export const useQueryCostCentersBulk = (
  costCenterIds: UUID[],
  options?: Omit<UseQueryOptions<CostCenter[]>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    enabled: costCenterIds.length > 0,
    queryFn: async () => fetchCostCentersBulk(costCenterIds),
    queryKey: queryKeysCostCenter.getBulk(costCenterIds),
    ...options,
  });

import { unique } from '~/utils/array';
import { isPlainObject } from '~/utils/object';

import { type ValueCreatedObject, type ValueGroupData } from './types';
import { ValueObject } from './valueUtils';

export const ValueGroupObject = {
  create() {
    return {
      current: undefined as ValueCreatedObject | undefined,
      initial: undefined as ValueCreatedObject | undefined,
      history: [],
    };
  },

  /**
   * Compares the latest value with a plain value.
   * @param {any} plainValue - The value to compare with.
   * @returns {boolean} - Returns true if the latest value is equal to the plain value.
   */
  latestValueEquals(valueGroup: ValueGroupData, plainValue: any) {
    if (valueGroup.history.length > 0) {
      if (
        JSON.stringify(valueGroup.history.at(-1)?.value) ===
        JSON.stringify(plainValue)
      ) {
        return true;
      }
    } else if (
      valueGroup.initial &&
      JSON.stringify(valueGroup.initial.value) === JSON.stringify(plainValue)
    ) {
      return true;
    }

    return false;
  },

  /**
   * Checks if the given value is a ValueGroup.
   * @param {any} value - The value to check.
   * @returns {boolean} - True if the value is a ValueGroup.
   */
  isValueGroup(value?: any) {
    return (
      isPlainObject(value) &&
      Object.hasOwn(value, 'current') &&
      Object.hasOwn(value, 'initial') &&
      Object.hasOwn(value, 'history')
    );
  },

  /**
   * Gets the current value of the ValueGroup.
   * @param {any} value - The value or ValueGroup to check.
   * @returns {any} - The current value if it's a ValueGroup, otherwise the value itself.
   */
  getCurrentValue(value: any) {
    if (ValueGroupObject.isValueGroup(value)) {
      return value.current?.value;
    }

    return value;
  },

  /**
   * Applies a callback function on a collection of value groups.
   * @param {Record<string, any>} valueGroups - The value groups to apply the function on.
   * @param {(value: Record<string, any>) => any} callback - The callback function to apply.
   * @returns {ValueGroupData} - The resulting ValueGroupData.
   */
  applyFunction(
    valueGroups: Record<string, any>,
    callback: (value: Record<string, any>) => any,
  ) {
    if (!valueGroups) {
      return ValueGroupObject.create();
    }

    const returnValueGroup = ValueGroupObject.create();

    const current: Record<string, any> = {};
    for (const key of Object.keys(valueGroups)) {
      current[key] = ValueGroupObject.getCurrentValue(valueGroups[key]);
    }

    returnValueGroup.current = ValueObject.create(callback(current), undefined);

    const initial: Record<string, any> = {};
    let datetime: string | undefined;
    let company: Record<string, any>;
    for (const key of Object.keys(valueGroups)) {
      initial[key] = valueGroups[key]?.initial?.value ?? current[key];
      datetime = valueGroups[key]?.initial?.datetime;
      company = valueGroups[key]?.initial?.company;
    }

    returnValueGroup.initial = ValueObject.create(callback(initial), datetime);

    let historyDatetimes: string[] = [];
    for (const key of Object.keys(valueGroups)) {
      if (valueGroups[key]?.history?.length) {
        for (const value of valueGroups[key].history) {
          historyDatetimes.push(value.datetime);
        }
      }
    }

    historyDatetimes = unique(historyDatetimes);
    historyDatetimes.sort();

    for (const historyDatetime of historyDatetimes) {
      let company: any;

      for (const key of Object.keys(valueGroups)) {
        const updatedValue = valueGroups[key]?.history?.find(
          ({ datetime }) => datetime === historyDatetime,
        );
        if (updatedValue) {
          initial[key] = updatedValue.value;
          company = updatedValue.company;
        }
      }

      returnValueGroup.history.push(
        ValueObject.create(callback(initial), historyDatetime, company),
      );
    }

    return returnValueGroup;
  },

  /**
   * Checks if the object has been edited by comparing paths.
   * @param {any} object - The object to check.
   * @param {any[]} excludedPaths - The paths to exclude from the check.
   * @param {any[]} [currentPath=undefined] - The current path being checked.
   * @returns {boolean} - True if the object has been edited.
   */
  hasBeenEdited(
    object: Record<string, any>,
    excludedPaths: any[],
    currentPath: any[] | undefined = undefined,
  ) {
    if (currentPath === null) {
      currentPath = [];
    }

    for (const excludedPath of excludedPaths) {
      if (JSON.stringify(excludedPath) === JSON.stringify(currentPath)) {
        return false;
      }
    }

    let hasBeenEdited = false;

    if (!isPlainObject(object)) {
      return false;
    }

    if (ValueGroupObject.isValueGroup(object)) {
      return object.history?.length > 0;
    }

    for (const key of Object.keys(object)) {
      if (
        ValueGroupObject.hasBeenEdited(object[key], excludedPaths, [
          ...currentPath,
          key,
        ])
      ) {
        hasBeenEdited = true;
      }
    }

    return hasBeenEdited;
  },

  /**
   * Removes ValueGroup objects from the input.
   * @param {unknown} input - The input to process.
   * @returns {unknown} - The processed input with ValueGroups removed.
   */
  removeValueGroups(input: unknown) {
    if (ValueGroupObject.isValueGroup(input)) {
      return ValueGroupObject.getCurrentValue(input);
    }

    if (isPlainObject(input)) {
      for (const key in input) {
        if (Object.hasOwn(input, key)) {
          input[key] = ValueGroupObject.removeValueGroups(input[key]);
        }
      }

      return input;
    }

    if (Array.isArray(input)) {
      return input.map((item) => ValueGroupObject.removeValueGroups(item));
    }

    return input;
  },
};

export const SIGNATURE_ROLE = {
  SUPPLIER: {
    KEY: 'supplier',
    STRING: 'Lieferant',
  },
  CARRIER: {
    KEY: 'carrier',
    STRING: 'Spediteur',
  },
  RECIPIENT: {
    KEY: 'recipient',
    LEGACY_KEY: 'buyer',
    STRING: 'Abnehmer',
  },
  ON_BEHALF_SUPPLIER: {
    KEY: 'onBehalfSupplier',
    STRING: 'Lieferant',
  },
  ON_BEHALF_CARRIER: {
    KEY: 'onBehalfCarrier',
    STRING: 'Spediteur',
  },
  ON_BEHALF_RECIPIENT: {
    KEY: 'onBehalfRecipient',
    STRING: 'Abnehmer',
  },
} as const;

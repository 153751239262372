import { type DeliveryNoteListItem } from '~/data/deliveryNote/types';

import { ArticleObject } from '~/models/articles';

export const getArticles = (assetMain: DeliveryNoteListItem) => {
  if (!assetMain) {
    return [];
  }

  const articles = [];
  const logisticsPackages =
    assetMain?.assetState?.body?.transaction?.logisticsPackage ?? [];

  for (const [
    logisticsPackageIndex,
    logisticsPackage,
  ] of logisticsPackages.entries()) {
    const lineItems = logisticsPackage?.lineItem ?? [];

    for (const [lineItemIndex, lineItem] of lineItems.entries()) {
      if (!lineItem) {
        continue;
      }

      const article = ArticleObject.create({
        acceptItems: assetMain.acceptStates?.acceptItems,
        billedItems: assetMain.billedItems,
        documentLineItemPosition: lineItemIndex,
        documentLogisticsPackagePosition: logisticsPackageIndex,
        lineItem: logisticsPackage?.lineItem?.[lineItemIndex],
        logisticsPackage: logisticsPackage.id,
      });

      articles.push(article);
    }
  }

  return articles;
};

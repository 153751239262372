import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';

import {
  isDevelopmentEnvironment,
  isProductionEnvironment,
} from './environment';
import GlobalStorage from './GlobalStorage';

export const Log = {
  info(message, data, breadcrumb) {
    if (!isProductionEnvironment) {
      console.info(message, data);
    }

    const breadcrumbConst = Object.keys(this.BREADCRUMB).find((x) => {
      return breadcrumb === this.BREADCRUMB[x].KEY;
    });

    Sentry.addBreadcrumb({
      category: this.BREADCRUMB[breadcrumbConst]?.CATEGORY ?? 'info',
      data,
      level: 'info',
      message:
        message ?? this.BREADCRUMB[breadcrumbConst]?.DEFAULT_MESSAGE ?? '',
      type: this.BREADCRUMB[breadcrumbConst]?.TYPE ?? 'info',
    });
  },

  warn(message, data, breadcrumb) {
    if (!isProductionEnvironment) {
      console.warn(message, data);
    }

    const breadcrumbConst = Object.keys(this.BREADCRUMB).find((x) => {
      return breadcrumb === this.BREADCRUMB[x].KEY;
    });

    Sentry.addBreadcrumb({
      category: this.BREADCRUMB[breadcrumbConst]?.CATEGORY ?? 'info',
      data,
      level: 'warning',
      message:
        message ?? this.BREADCRUMB[breadcrumbConst]?.DEFAULT_MESSAGE ?? '',
      type: this.BREADCRUMB[breadcrumbConst]?.TYPE ?? 'info',
    });
  },

  error(message, error) {
    if (message) {
      console.error(message);
    }

    if (error) {
      console.error(error);
    }

    if (isDevelopmentEnvironment) {
      return;
    }

    // To check whether a variable is a JS Error.
    // Checking for stack and message isn't 100% correct and may produce false positives.
    // However, using value instanceof Error won't work if the error was thrown in a different window/frame/iframe.
    // See: https://stackoverflow.com/questions/30469261/checking-for-typeof-error-in-js
    const isError = (value) => value?.stack && value.message;

    // It seems that somehow, an err was provided that was not instance of JS Error.
    // To investigate further, this case is caught and the variable is sent to Sentry as an exception.
    // https://sentry.io/organizations/vestigas/issues/3805048262/?environment=production&project=6410247&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=90d
    // https://github.com/getsentry/sentry-javascript/issues/2292
    if (error && !isError(error)) {
      Sentry.captureException(
        new Error('Provided variable is not instance of JS Error.'),
        {
          extra: {
            err: error,
            message,
          },
        },
      );
    }

    Sentry.captureException(error ?? new Error(message), {
      extra: {
        message,
      },
    });
  },

  productAnalyticsEvent(event, feature, type, eventProperties) {
    if (!GlobalStorage.productAnalyticsToolsInitialized) {
      return;
    }

    if (type) {
      amplitude.track(`${feature} - (${type}) ${event}`, eventProperties);
      return;
    }

    amplitude.track(`${feature} - ${event}`, eventProperties);
  },

  // https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
  // types: navigation, user, info
  // categories: form, modal, click, state change, props change, filter, selection
  // level: fatal, critical, error, warning, log, info, debug
  BREADCRUMB: {
    FILTER_CHANGE: {
      CATEGORY: 'filter',
      DEFAULT_MESSAGE: 'Change filter value',
      KEY: 'filter-change',
      TYPE: 'user',
    },
    FORM_CHANGE: {
      CATEGORY: 'form',
      DEFAULT_MESSAGE: 'Change form value',
      KEY: 'form-change',
      TYPE: 'user',
    },
    FORM_CLOSE: {
      CATEGORY: 'form',
      DEFAULT_MESSAGE: 'Close form',
      KEY: 'form-close',
      TYPE: 'navigation',
    },
    FORM_OPEN: {
      CATEGORY: 'form',
      DEFAULT_MESSAGE: 'Open form',
      KEY: 'form-open',
      TYPE: 'navigation',
    },
    FORM_SUBMIT: {
      CATEGORY: 'form',
      DEFAULT_MESSAGE: 'Submit form',
      KEY: 'form-submit',
      TYPE: 'user',
    },
    HTTP_NOT_200: {
      CATEGORY: 'xhr',
      DEFAULT_MESSAGE: 'Response status is not 200',
      KEY: 'http-not-200',
      TYPE: 'http',
    },
    INIT_MODEL: {
      CATEGORY: 'model',
      DEFAULT_MESSAGE: 'Initialize model',
      KEY: 'init-model',
      TYPE: 'info',
    },
    MODAL_OPEN: {
      CATEGORY: 'modal',
      DEFAULT_MESSAGE: 'Open modal',
      KEY: 'modal-open',
      TYPE: 'navigation',
    },
    REDUX_CHANGE: {
      CATEGORY: 'redux',
      DEFAULT_MESSAGE: 'Change redux state',
      KEY: 'redux-change',
      TYPE: 'info',
    },
    SELECTION_CHANGE: {
      CATEGORY: 'selection',
      DEFAULT_MESSAGE: 'Change selection value',
      KEY: 'selection-change',
      TYPE: 'user',
    },
    USER_ACTION: {
      CATEGORY: 'action',
      DEFAULT_MESSAGE: null,
      KEY: 'user-action',
      TYPE: 'user',
    },
  },

  FEATURE: {
    ACCORDION: 'Accordion',
    ADD_ITEM_MODAL: 'Add item modal',
    ARCHIVE_MODE: 'Archive mode',
    ARTICLE_MASTER: 'Article master',
    AUTHENTICATION: 'Authentication',
    BASIC_TABLE: 'Basic table',
    BAYWA_SCREEN: 'BayWa screen',
    COMPANY: 'Company',
    COMPLEX_PAGINATED_MULTI_PICKER: 'Complex paginated multi picker',
    CONCRETE_DIARY: 'Concrete diary',
    COST_CENTER: 'Cost center',
    CREATE_DELIVERY_NOTE: 'Create delivery note',
    CURRENT_SITE_AND_COST_CENTER: 'Current site and cost center',
    CUSTOM_FIELD: 'Custom field',
    DASHBOARD: 'Dashboard',
    DATA_PROTECTION: 'Data protection',
    DATA_SUBSCRIPTION: 'Data subscription',
    DELIVERY_CHANGES: 'Delivery changes',
    DELIVERY_LIST: 'Delivery list',
    DELIVERY_NOTE: 'Delivery note',
    DELIVERY_OVERVIEW: 'Delivery overview',
    EXCEL_DOWNLOAD: 'Excel download',
    FILTER_GROUPS: 'Filter groups',
    FORM: 'Form',
    HOME: 'Home',
    IDS: 'IDS',
    IMAGE_UPLOAD: 'Image upload',
    INVOICE_CHECK: 'Invoice check',
    INVOICE_LIST: 'Invoice list',
    INVOICE: 'Invoice',
    KANBAN_SUPPLIER_OVERVIEW: 'Kanban Supplier Overview',
    MENU: 'Menu',
    NAVIGATION: 'Navigation',
    NOTIFICATIONS: 'Notifications',
    ORGANISATIONAL_GROUP: 'Organisational group',
    OTHER_FEATURE: 'Other feature',
    PDF_DOWNLOAD: 'PDF download',
    PDF_SETTINGS: 'PDF settings',
    PDF_TEMPLATE: 'PDF template',
    PDF_VIEWER: 'PDF viewer',
    PERMISSION_GRANT_DIALOG: 'Permission grant dialog',
    PERMISSIONS: 'Permissions',
    PROFILE: 'Profile',
    REPORT: 'Report',
    SETTINGS_TABLE: 'Settings table',
    SHARE_DELIVERY_NOTE: 'Share delivery note',
    SIGNATURE_FIELD: 'Signature field',
    SITE_MAPPING: 'Site mapping',
    SITE: 'Site',
    SUBSCRIPTION: 'Subscription',
    SUPPLIER_CONTACT_PAGE: 'Supplier contact page',
    SUPPLIER_OVERVIEW: 'Supplier overview',
    USER_GROUP: 'User group',
    USER: 'User',
    VEHICLE: 'Vehicle',
    WIZARD: 'Wizard',
  },
  TYPE: {
    ERROR: 'Error',
    FAILED_VALIDATION: 'Failed Validation',
  },
};

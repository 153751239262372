import { debounce } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import {
  useMutationUpdateCurrentUserSettings,
  useQueryUserData,
} from '~/data/user';

import LocalStorageService from '~/services/localStorage.service';

import { type UserSettingsKeyValues } from './userSettingsKeys';

type P = {
  debounceTime?: number;
  settingsKey: UserSettingsKeyValues;
  useLocalStorage?: boolean;
};

const DEFAULT_DEBOUNCE_TIME = 1000;

/**
 * Custom hook to manage and persist user settings either on the server or in localStorage.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {number} [params.debounceTime=DEFAULT_DEBOUNCE_TIME] - Time in milliseconds to debounce the update function.
 * @param {string} params.settingsKey - Key to identify the settings in storage.
 * @param {boolean} [params.useLocalStorage=false] - Flag to determine if localStorage should be used.
 *
 * @returns {Object} - An object containing methods and state for managing user settings.
 * @returns {Function} getUserSettings - Function to retrieve user settings.
 * @returns {Function} persistUserSettings - Function to persist user settings.
 * @returns {boolean} isError - Flag indicating if there was an error.
 * @returns {boolean} isSuccess - Flag indicating if the query was successful.
 * @returns {Error | undefined} error - The error object if an error occurred.
 */
export const usePersistedUserSettings = ({
  debounceTime = DEFAULT_DEBOUNCE_TIME,
  settingsKey,
  useLocalStorage = false,
}: P) => {
  const [localStorageError, setLocalStorageError] = useState<Error | undefined>(
    null,
  );
  const [updateError, setUpdateError] = useState<Error | undefined>(null);

  const {
    data: userSettings,
    isError: isErrorUserData,
    isSuccess,
    error: queryError,
  } = useQueryUserData(true, {
    enabled: !useLocalStorage && Boolean(settingsKey),
    select: (data) => data?.settings?.[settingsKey] ?? {},
  });

  const { mutateAsync: updateUserSettings, error: mutationError } =
    useMutationUpdateCurrentUserSettings();

  /**
   * Get the settings from the user's settings regardless of their storage location (server or localStorage).
   */
  const getUserSettings = useCallback(() => {
    if (useLocalStorage) {
      try {
        return LocalStorageService.getObjectFromLocalStorage(settingsKey) ?? {};
      } catch (error) {
        setLocalStorageError(
          error instanceof Error ? error : new Error(String(error)),
        );

        return {};
      }
    }

    return userSettings ?? {};
  }, [settingsKey, useLocalStorage]);

  const debouncedUpdateFunction = useMemo(
    () =>
      debounce(async (settings) => {
        try {
          await updateUserSettings({
            [settingsKey]: settings,
          });

          setUpdateError(null);
        } catch (error) {
          setUpdateError(
            error instanceof Error ? error : new Error(String(error)),
          );
        }
      }, debounceTime),
    [updateUserSettings, settingsKey, debounceTime],
  );

  /**
   * Store the settings in user's settings on the server and optionally in localStorage.
   */
  const persistUserSettings = useCallback(
    ({ columnVisibilityModel, columnOrderModel, columnWidthModel }) => {
      const settings = {
        columnOrderModel,
        columnVisibilityModel,
        columnWidthModel,
      };

      if (useLocalStorage) {
        try {
          LocalStorageService.setObjectAsLocalStorage(settingsKey, settings);
          setLocalStorageError(null);
        } catch (error) {
          setLocalStorageError(
            error instanceof Error ? error : new Error(String(error)),
          );
        }
      }

      void debouncedUpdateFunction(settings);
    },
    [debouncedUpdateFunction, settingsKey, useLocalStorage],
  );

  const isError =
    isErrorUserData || Boolean(localStorageError) || Boolean(updateError);
  const error = queryError ?? mutationError ?? localStorageError ?? updateError;

  return {
    getUserSettings,
    persistUserSettings,
    isError,
    isSuccess,
    error,
  };
};

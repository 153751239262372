import { AddressObject } from '../Address';

import { type SiteIdentifier } from './types';

export const SiteIdentifierObject = {
  /**
   * Creates a site identifier object with default values or based on the provided data.
   * @param {Object} siteIdentifier - The input site identifier data.
   * @param {string} assignedSiteId - The assigned site ID.
   * @returns {Object} - The created site identifier object.
   */
  create(siteIdentifier: SiteIdentifier, assignedSiteId = '') {
    // Line breaks cause the MUI datagrid to crash so they need to be "cleaned".
    const cleanedIdentifier =
      siteIdentifier.identifier?.replaceAll('\n', ' ') || '';

    return {
      address: siteIdentifier?.address ?? null,
      addressString: AddressObject.stringify(siteIdentifier?.address ?? {}),
      assignedSiteId,
      assignedSiteName: null,
      blacklisted: siteIdentifier?.blacklisted ?? false,
      id: `${siteIdentifier?.supplierId}-${cleanedIdentifier}`,
      identifier: cleanedIdentifier,
      identifierId: siteIdentifier?.identifierUuid ?? null,
      ignoreAddressInfo: siteIdentifier?.ignoreAddressInfo ?? true,
      issuerId: siteIdentifier?.supplierId ?? null,
      issuerName: siteIdentifier?.supplierName ?? '',
      proposalId: siteIdentifier?.proposalUuid ?? null,
    };
  },
};

import { Typography } from '@mui/material';
import {
  // gridPageCountSelector,
  gridRowSelectionStateSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import { memo } from 'react';

import { Pagination } from './Pagination';

type P = {
  readonly isFetching: boolean;
  readonly paginationText?: string;
  readonly selectedRowCount?: number;
};

export const Footer = memo(
  ({ isFetching, paginationText, selectedRowCount }: P) => {
    const apiRef = useGridApiContext();
    const rowSelectionModel = useGridSelector(
      apiRef,
      gridRowSelectionStateSelector,
    );
    // const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const count = selectedRowCount ?? rowSelectionModel.length;
    const selectionText = `${count} Element${count === 1 ? '' : 'e'} ausgewählt`;

    // const isHidden = !pageCount && !count;

    return (
      <div
        className={clsx(
          'border-divider flex items-center justify-between border-t border-gray-200 pr-2 pl-4 transition-opacity duration-150',
          // isHidden ? 'h-0 p-0 opacity-0' : 'py-1',
        )}
      >
        <div>
          {rowSelectionModel.length > 0 && (
            <Typography variant="body2">{selectionText}</Typography>
          )}
        </div>
        <Pagination isFetching={isFetching} paginationText={paginationText} />
      </div>
    );
  },
);

Footer.displayName = 'Footer';

import { type UUID } from '~/types/common';

import { ATTACHMENT_TYPE } from './constants';
import { type AttachmentBlobType, type AttachmentTypeType } from './types';

export const AttachmentObject = {
  /**
   * Creates an attachment object with the given parameters.
   * @param blob - The blob object containing attachment details.
   * @param [chainId] - The chain ID associated with the attachment.
   * @param [type] - The type of the attachment.
   * @returns The created attachment object.
   */
  create({
    blob,
    chainId,
    type,
  }: {
    blob: AttachmentBlobType;
    chainId: UUID | undefined;
    type: AttachmentTypeType | undefined;
  }) {
    return {
      chainId: chainId ?? '',
      contentType: blob?.contentType ?? '',
      digest: blob?.digest ?? '',
      type,
    };
  },

  /**
   * Checks if the given attachment is a delivery picture.
   * @param {object} attachment - The attachment object to check.
   * @returns {boolean} - true if the attachment is of type DELIVERY_PICTURE, otherwise false.
   */
  isDeliveryPicture(type: AttachmentTypeType) {
    return type === ATTACHMENT_TYPE.DELIVERY_PICTURE;
  },
};

import { ValueGroupObject } from '../../valueGroup';

/**
 * Recursively traverses an object following a path and collects changes.
 * @param changes - Accumulated array of changes
 * @param formatter - Optional formatter function for values
 * @param ignoreInitialValue - Whether to ignore single-change values
 * @param name - Display name for the change
 * @param object - Object to traverse
 * @param path - Array of path segments to follow
 * @returns Array of changes found along the path
 */
export const setChangesForPath = ({
  changes = [],
  formatter,
  ignoreInitialValue,
  name,
  object,
  path,
}: {
  changes: any[];
  formatter: any;
  ignoreInitialValue: boolean;
  name: string;
  object: any;
  path: string[];
}): any[] => {
  if (!object) {
    return changes;
  }

  const clonedPath = [...path];
  const currentPath = clonedPath.shift();

  if (currentPath === '*') {
    // current element is wildcard, so all keys must be iterated through
    return Object.keys(object).reduce((accumulator, key) => {
      return setChangesForPath({
        changes: accumulator,
        object: object[key],
        path: clonedPath,
        name,
        formatter,
        ignoreInitialValue,
      });
    }, changes);
  }

  if (currentPath) {
    // there is still a key in path, so we need to go a lever deeper into the object
    return setChangesForPath({
      changes,
      object: object[currentPath],
      path: clonedPath,
      name,
      formatter,
      ignoreInitialValue,
    });
  }

  // path is empty, so we can now check if the value contains a change
  const valueGroup = object;

  if (!ValueGroupObject.isValueGroup(valueGroup)) {
    return changes;
  }

  if (valueGroup.history.length === 0) {
    return changes;
  }

  // costCenters and toSiteRecipient are expected to be set during dln accepting.
  // Hence, they are excluded from the "real" changes, when they have been changed only once.
  if (ignoreInitialValue && valueGroup.history.length === 1) {
    return changes;
  }

  return [
    ...changes,
    {
      formatter,
      history: valueGroup.history,
      initial: valueGroup.initial,
      name,
    },
  ];
};

import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import ms from 'ms';

import { ENDPOINT } from '~/constants/endpoints';

import { type Address } from '~/types/address';
import { type UUID } from '~/types/common';
import { type EntityType } from '~/types/entity';
import { type Permission } from '~/types/permission';

import { vestigasApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';

import { queryKeysUser } from './queryKeys';

export type UserData = {
  company: {
    address: Address;
    companyAccountId: UUID;
    id: UUID;
    name: string;
    orgUnitPaths: UUID[][];
    orgUnits: UUID[];
    permissionsFrom: Array<{
      id: number;
      permissions: Permission[];
      subjectId: UUID;
      subjectType:
        | 'accounting_reference'
        | 'company'
        | 'org_unit'
        | 'site'
        | 'user_group'
        | 'user'
        | 'vehicle';
    }>;
    syncKey: string;
  };
  companyAccount: {
    baseOuId: UUID;
    companies: Array<{
      address: Address;
      companyAccountId: UUID;
      id: UUID;
      name: string;
      orgUnitPaths: UUID[][];
      orgUnits: UUID[];
      permissionsFrom: Array<{
        id: number;
        permissions: Permission[];
        subjectId: UUID;
        subjectType:
          | 'accounting_reference'
          | 'company'
          | 'org_unit'
          | 'site'
          | 'user_group'
          | 'user'
          | 'vehicle';
      }>;
      syncKey: string;
    }>;
    data: Record<string, unknown>;
    id: UUID;
    name: string;
  };
  firstName: string;
  isVestigasSupport: boolean;
  lastName: string;
  name: string;
  position: string;
  roles: Array<{
    entityId: UUID;
    entityType: EntityType;
    permission: Permission[];
    role: number[];
    userId: UUID;
  }>;
  settings: Record<string, unknown>;
  totalPermissions: Permission[];
  userPermissions: Permission[];
};

/**
 * Fetches user data from the API.
 * @param {boolean} includeCompanyInfo - Whether to include company info in the response.
 * @returns {Promise<UserData|undefined>} The user data if successful, undefined otherwise.
 * @see https://app.dev.vestigas.com/redoc#tag/User/operation/get_all_user_data_user_data_get
 */
export const fetchUserData = async (includeCompanyInfo = true) => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.USER.GET_DATA(includeCompanyInfo))
      .json<UserData>();

    return response;
  } catch (error) {
    Log.error('Error fetching user data', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Generates the query options for the user data query.
 *
 * @param {Object} params - Parameters for the query
 * @param {boolean} params.includeCompanyInfo - Whether to include company info
 * @param {Object} params.options - Additional options for the query
 * @returns {Object} The query options including queryKey, queryFn, and other settings.
 */
export const getUserDataQueryOptions = ({
  includeCompanyInfo = true,
  options,
}: {
  includeCompanyInfo?: boolean;
  options?: Parameters<typeof useQuery>[0];
}) => {
  return {
    queryFn: async () => fetchUserData(includeCompanyInfo),
    queryKey: queryKeysUser.getData(includeCompanyInfo),
    select: (data: UserData) => ({
      companyAccountInfo: data.companyAccount ?? {},
      companyInfo: data.company ?? {},
      featureFlags: {
        ...data.companyAccount?.data?.featureFlags, // company-level feature flags
        ...data.settings?.featureFlags, // user-level feature flags
      },
      isVestigasSupport: Boolean(data.isVestigasSupport),
      name: [data.firstName, data.lastName].filter(Boolean).join(' '),
      position: data.position ?? '',
      userPermissions: data.totalPermissions ?? [],
      userSettings: data.settings ?? {},
    }),
    staleTime: ms('10m'),
    ...options,
  };
};

/**
 * React Query based custom hook for getting the data for the current user.
 * @param {boolean} includeCompanyInfo - Whether to include company info in the response
 * @param {Object} options - Additional options for the useQuery hook
 * @returns {UseQueryResult<UserData | undefined>} The result of the useQuery hook
 */
export const useQueryUserData = (
  includeCompanyInfo = true,
  options?: Parameters<typeof useQuery>[0],
): UseQueryResult<UserData | undefined> =>
  useQuery(
    getUserDataQueryOptions({
      includeCompanyInfo,
      options,
    }),
  );

import { intersection } from '~/utils/array';

import { type DeliveryNoteCreatedValues } from '../deliveries/DeliveryNote';

import Invoice from './Invoice';
import { InvoiceCheckCategoryObject } from './invoiceCheckCategoryUtils';
import { InvoiceCheckResultObject } from './invoiceCheckResultUtils';
import {
  type CheckResult,
  type InvoiceCheckCategoryChartCategoriesValues,
  type InvoicePosition,
} from './types';

export const InvoicePositionObject = {
  /**
   * Creates an invoice position object from the given item and check results.
   * @param {InvoicePosition} item - The invoice position data.
   * @param {CheckResult[]} checkResults - Array of check results for validation.
   * @param {string} version - The version of the invoice format (V1 or others).
   * @returns {object} - The formatted invoice position with calculated values.
   */
  create(item: InvoicePosition, checkResults: CheckResult[], version: string) {
    const isVersionV1 = version === Invoice.VERSION.V1;
    const positionNumber = isVersionV1 ? 0 : item.positionNr;
    const deliveryNotes = isVersionV1
      ? item.deliveryNoteId
        ? [
            {
              id: item.deliveryNoteAssetIds ?? null,
              number: item.deliveryNoteId ?? null,
            },
          ]
        : []
      : (item?.deliveryNoteNrs?.map(
          (deliveryNoteNr: string, index: number) => ({
            id: item?.deliveryNoteAssetIds?.[index] ?? null,
            number: deliveryNoteNr ?? null,
          }),
        ) ?? []);

    const taxPercent = Number.parseFloat(item.taxPercent) / 100;

    const invoicePosition = {
      positionNumber,
      name: item.name,
      number: item.sellerAssignedId,
      amount: {
        unit: item.unitType,
        value: Number.parseFloat(item.quantity),
      },
      taxPercent,
      currency: item.currency,
      netPricePerQuantity: Number.parseFloat(item.netPricePerQuantity),
      itemPriceNet: Number.parseFloat(item.netPrice),
      itemPriceGross: Number.parseFloat(item.netPrice) * (1 + taxPercent),
      totalPriceNet: Number.parseFloat(item.priceTotal),
      totalPriceGross: Number.parseFloat(item.priceTotal) * (1 + taxPercent),
      deliveryNotes,
      errorChecks: this.assignErrorChecks(
        checkResults,
        deliveryNotes,
        item.name,
      ),
      chartCategory: '',
    };

    invoicePosition.chartCategory = this.assignChartCategory(
      invoicePosition.errorChecks,
    );
    return invoicePosition;
  },

  /**
   * Assigns error checks to the invoice position based on provided check results and delivery notes.
   * @param {CheckResult[]} checkResults - Array of check results to validate the invoice position.
   * @param {DeliveryNoteCreatedValues[]} deliveryNotes - The delivery notes associated with the invoice.
   * @param {string} itemName - The name of the invoice item being checked.
   * @returns {string[]} - Array of error check names for the invoice position.
   */
  assignErrorChecks(
    checkResults: CheckResult[],
    deliveryNotes: DeliveryNoteCreatedValues[],
    itemName: string,
  ) {
    // Pre-compute values used multiple times
    const deliveryNoteNumbers = new Set(
      deliveryNotes.map(({ number }) => number ?? ''),
    );
    const hasNoDeliveryNote = !deliveryNotes[0]?.number;

    return checkResults
      .filter((checkResult) => {
        // Early return for non-error status
        if (checkResult.status !== InvoiceCheckResultObject.STATUS.ERROR) {
          return false;
        }

        const isDeliveryNoteExists = checkResult.name === 'DeliveryNoteExists';

        // Special case for DeliveryNoteExists with no delivery notes
        if (
          isDeliveryNoteExists &&
          !checkResult.deliveryNotes[0]?.number &&
          hasNoDeliveryNote
        ) {
          return true;
        }

        // Check if there's an intersection between delivery note numbers
        const checkResultDeliveryNumbers =
          InvoiceCheckResultObject.getDeliveryNoteNumbers(checkResult);
        if (
          checkResultDeliveryNumbers.length > 0 &&
          !checkResultDeliveryNumbers.some((number_) =>
            deliveryNoteNumbers.has(number_),
          )
        ) {
          return false;
        }

        // Return true for DeliveryNoteExists checks
        if (isDeliveryNoteExists) {
          return true;
        }

        // Check article name match
        if (checkResult.articleName && checkResult.articleName !== itemName) {
          return false;
        }

        return true;
      })
      .map(({ name }) => name);
  },

  /**
   * Assigns a chart category based on the provided error checks.
   * @param {string[]} errorChecks - Array of error check names for the invoice position.
   * @returns {string} - The chart category name based on the error checks.
   */
  assignChartCategory(errorChecks: string[]) {
    if (errorChecks.length === 0) {
      return InvoiceCheckCategoryObject.CHART_CATEGORIES.APPROVED.NAME;
    }

    const chartCategory = Object.keys(
      InvoiceCheckCategoryObject.CHART_CATEGORIES,
    ).find((key) => {
      return (
        intersection(
          InvoiceCheckCategoryObject.CHART_CATEGORIES[
            key as InvoiceCheckCategoryChartCategoriesValues
          ].CHECKS,
          errorChecks,
        ).length > 0
      );
    });

    if (!chartCategory) {
      return InvoiceCheckCategoryObject.CHART_CATEGORIES.APPROVED.NAME;
    }

    return InvoiceCheckCategoryObject.CHART_CATEGORIES[
      chartCategory as InvoiceCheckCategoryChartCategoriesValues
    ].NAME;
  },
};

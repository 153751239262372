import { useQueryClient } from '@tanstack/react-query';
import ms from 'ms';
import { unique } from 'remeda';

import { fetchCostCentersBulk, queryKeysCostCenter } from '~/data/costCenter';
import {
  fetchCustomFieldsBulk,
  queryKeysCustomField,
} from '~/data/customField';
import { type DeliveryNoteListItem } from '~/data/deliveryNote/types';
import { fetchSitesBulk, queryKeysSite } from '~/data/site';
import {
  fetchUsersBulk,
  queryKeysUser,
  useQueryUserActions,
} from '~/data/user';

import { Log } from '~/utils/logging';
import { promiseAllThrottled } from '~/utils/promise';
import { promiseHandler } from '~/utils/promiseHandler';

import { DeliveryNoteObject } from '../../deliveryNoteUtils';
import { type DeliveryNoteCreatedValues } from '../../types';
import { getCustomFieldIds } from '../getCustomFieldIds';

/**
 * Check if a delivery note is already initialized by checking if the number is set.
 * In the raw asset, this information is located in the assetState.body.header.id field.
 */
const isInitialized = (asset: Record<string, unknown>) => asset.number?.length;

export const useInitDlns = () => {
  const queryClient = useQueryClient();

  const { data: userActionsData } = useQueryUserActions([], true);
  const userActions = userActionsData?.all ?? {};

  return async (assetMains: DeliveryNoteListItem[]) => {
    const deliveryNotes: DeliveryNoteCreatedValues[] = [];

    for (const assetMain of assetMains) {
      try {
        // Hack to not initialize already initialized DLNs.
        if (isInitialized(assetMain)) {
          deliveryNotes.push(assetMain);
        } else {
          deliveryNotes.push(DeliveryNoteObject.create(assetMain));
        }
      } catch (error) {
        Log.error(
          `Failed to initialize delivery note. id: ${assetMain?.id}`,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to initialize delivery note',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
      }
    }

    let siteIds = [];
    let costCenterIds = [];
    let userIds = [];
    let customFieldIds = [];

    for (const deliveryNote of deliveryNotes) {
      siteIds.push(
        deliveryNote.toSiteRecipient.id,
        ...deliveryNote.permittedToSites.map(({ id }) => id),
      );
      costCenterIds.push(
        ...deliveryNote.permittedCostCenters.map(({ id }) => id),
      );
      userIds.push(
        ...Object.entries(deliveryNote.processStateChangeUser).map(
          ([_, value]) => value?.id,
        ),
      );
      customFieldIds.push(
        ...getCustomFieldIds(deliveryNote.articles, deliveryNote.customData),
      );
    }

    // Need to filter out 10003 manually because in dev - polier-dev there is a corrupt dln with this site id.
    siteIds = unique(siteIds).filter((siteId) => siteId && siteId !== '10003');
    costCenterIds = unique(costCenterIds).filter(Boolean);
    userIds = unique(userIds).filter(Boolean);
    customFieldIds = unique(customFieldIds).filter(Boolean);

    const promises = [
      siteIds.length > 0
        ? queryClient.fetchQuery({
            queryKey: queryKeysSite.getBulk(siteIds),
            queryFn: async () => fetchSitesBulk(siteIds),
            staleTime: ms('60s'),
          })
        : Promise.resolve([]),

      costCenterIds.length > 0
        ? queryClient.fetchQuery({
            queryKey: queryKeysCostCenter.getBulk(costCenterIds),
            queryFn: async () => fetchCostCentersBulk(costCenterIds),
            staleTime: ms('60s'),
          })
        : Promise.resolve([]),

      userIds.length > 0
        ? queryClient.fetchQuery({
            queryKey: queryKeysUser.getBulk(userIds),
            queryFn: async () => fetchUsersBulk(userIds),
            staleTime: ms('60s'),
          })
        : Promise.resolve([]),

      customFieldIds.length > 0
        ? queryClient.fetchQuery({
            queryKey: queryKeysCustomField.getBulk(customFieldIds),
            queryFn: async () => fetchCustomFieldsBulk(customFieldIds),
            staleTime: ms('60s'),
          })
        : Promise.resolve([]),
    ];

    const [sites, costCenters, users, customFields] =
      await promiseAllThrottled(promises);

    for (const [index, deliveryNote] of deliveryNotes.entries()) {
      try {
        const [, error_] = await promiseHandler(
          deliveryNote.asyncInitWithBulkEntities(
            sites,
            costCenters,
            users,
            customFields,
          ),
        );

        if (error_) {
          Log.error(
            'Failed to initialize async data of delivery note. id: ' +
              assetMains[index]?._id,
            error_,
          );
          Log.productAnalyticsEvent(
            'Failed to initialize enhanced delivery note data',
            Log.FEATURE.DELIVERY_NOTE,
            Log.TYPE.ERROR,
          );
        }

        deliveryNote.initUserActions(userActions);
      } catch (error) {
        Log.error(
          `Failed to initialize delivery note. id: ${assetMains[index]?._id}`,
          error,
        );
        Log.productAnalyticsEvent(
          'Failed to initialize delivery note',
          Log.FEATURE.DELIVERY_NOTE,
          Log.TYPE.ERROR,
        );
      }
    }

    return deliveryNotes;
  };
};

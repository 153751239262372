import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ROUTE } from '~/constants/Route';
import { TAB } from '~/constants/Tab';
import { NavigationTestIds } from '~/constants/test-ids';

import { useQueryUserData } from '~/data/user';

import {
  setDashboard_tab as setDashboardTab,
  setDelivery_tab as setDeliveryTab,
} from '~/redux/filtersSlice';

import { Log } from '~/utils/logging';
import RouteUtils from '~/utils/routeUtils';

import {
  CreateDeliveryIcon,
  CreateDeliveryOutlinedIcon,
  DeliveryIcon,
  DeliveryOutlinedIcon,
  HomeIcon,
  HomeOutlinedIcon,
  IncomingInvoiceIcon,
  IncomingInvoiceOutlinedIcon,
  InvoiceIcon,
  InvoiceOutlinedIcon,
  OutgoingInvoiceIcon,
  OutgoingInvoiceOutlinedIcon,
  PieChartIcon,
  PieChartOutlinedIcon,
  ReportIcon,
  ReportOutlinedIcon,
  SettingsIcon,
  SettingsOutlinedIcon,
  SupplierIcon,
} from '~/assets/icons';

const getMainNavItems = ({
  currentPath,
  dataExchanges,
  dispatch,
  featureFlags,
  filters,
  setDashboardTab,
  setDeliveryTab,
}) => {
  return [
    {
      buttonTestId: NavigationTestIds.HOME.BUTTON,
      children: [
        {
          filledIcon: <HomeIcon className="size-5" />,
          isSelected:
            currentPath === ROUTE.HOME.ROUTE || currentPath === '/home',
          name: 'Home',
          outlinedIcon: <HomeOutlinedIcon className="size-5" />,
          route: ROUTE.HOME.ROUTE,
        },
      ],
      filledIcon: <HomeIcon className="size-6" />,
      name: 'Home',
      outlinedIcon: <HomeOutlinedIcon className="size-6" />,
    },
    {
      buttonTestId: NavigationTestIds.STATISTIC.BUTTON,
      children: [
        {
          buttonTestId: NavigationTestIds.STATISTIC.CHILDREN.REPORT.BUTTON,
          callback: () => dispatch(setDashboardTab(TAB.DASHBOARD.CHARTS.INDEX)),
          filledIcon: <PieChartIcon className="size-5" />,
          isSelected:
            currentPath === ROUTE.DASHBOARD.ROUTE &&
            filters.dashboard_tab === TAB.DASHBOARD.CHARTS.INDEX,
          name: TAB.DASHBOARD.CHARTS.NAME,
          outlinedIcon: <PieChartOutlinedIcon className="size-5" />,
          route: ROUTE.DASHBOARD.ROUTE,
        },
        {
          buttonTestId: NavigationTestIds.STATISTIC.CHILDREN.CHARTS.BUTTON,
          callback() {
            dispatch(setDashboardTab(TAB.DASHBOARD.REPORT.INDEX));
            Log.productAnalyticsEvent(
              'Open dashboard reports tab',
              Log.FEATURE.MENU,
            );
          },
          disabledByClientPortal: Boolean(featureFlags?.clientPortal),
          disabledByPackageBasicRestriction: Boolean(
            featureFlags?.packageBasicRestriction,
          ),
          filledIcon: <ReportIcon className="size-5" />,
          isSelected:
            currentPath === ROUTE.DASHBOARD.ROUTE &&
            filters.dashboard_tab === TAB.DASHBOARD.REPORT.INDEX,
          name: TAB.DASHBOARD.REPORT.NAME,
          outlinedIcon: <ReportOutlinedIcon className="size-5" />,
          route: ROUTE.DASHBOARD.ROUTE,
        },
      ],
      filledIcon: <PieChartIcon className="size-6" />,
      name: 'Statistiken',
      outlinedIcon: <PieChartOutlinedIcon className="size-6" />,
    },
    {
      buttonTestId: NavigationTestIds.DELIVERY.BUTTON,
      children: [
        {
          buttonTestId: NavigationTestIds.DELIVERY.CHILDREN.LIST.BUTTON,
          callback: () => dispatch(setDeliveryTab(TAB.DELIVERY.LIST.INDEX)),
          filledIcon: <DeliveryIcon className="size-5" />,
          isSelected:
            currentPath === ROUTE.DELIVERIES.ROUTE ||
            RouteUtils.getMainRoute(currentPath) === ROUTE.DELIVERIES.ROUTE,
          name: TAB.DELIVERY.LIST.NAME,
          outlinedIcon: <DeliveryOutlinedIcon className="size-5" />,
          route: ROUTE.DELIVERIES.ROUTE,
        },
        {
          buttonTestId:
            NavigationTestIds.DELIVERY.CHILDREN.CREATE_DELIVERY_NOTE.BUTTON,
          filledIcon: <CreateDeliveryIcon className="size-5" />,
          isSelected: currentPath === ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
          name: 'Lieferung anlegen',
          outlinedIcon: <CreateDeliveryOutlinedIcon className="size-5" />,
          route: ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
        },
        {
          buttonTestId:
            NavigationTestIds.DELIVERY.CHILDREN.CREATE_SERVICE_NOTE.BUTTON,
          filledIcon: <CreateDeliveryIcon className="size-5" />,
          isSelected: currentPath === ROUTE.CREATE_SERVICE_NOTE.ROUTE,
          name: 'Leistungsnachweis anlegen',
          outlinedIcon: <CreateDeliveryOutlinedIcon className="size-5" />,
          route: ROUTE.CREATE_SERVICE_NOTE.ROUTE,
        },
      ],
      filledIcon: <DeliveryIcon className="size-6" />,
      name: 'Lieferungen',
      outlinedIcon: <DeliveryOutlinedIcon className="size-6" />,
    },
    {
      buttonTestId: NavigationTestIds.INVOICE.BUTTON,
      children: [
        {
          buttonTestId:
            NavigationTestIds.INVOICE.CHILDREN.INCOMING_INVOICES.BUTTON,
          disabledByClientPortal: Boolean(featureFlags?.clientPortal),
          disabledByModuleInvoiceRestriction: Boolean(
            featureFlags?.moduleInvoiceRestriction,
          ),
          filledIcon: <IncomingInvoiceIcon className="size-5" />,
          isSelected:
            currentPath === ROUTE.INCOMING_INVOICES.ROUTE ||
            RouteUtils.getMainRoute(currentPath) ===
              ROUTE.INCOMING_INVOICES.ROUTE,
          name: 'Eingangsrechnungen',
          outlinedIcon: <IncomingInvoiceOutlinedIcon className="size-5" />,
          route: ROUTE.INCOMING_INVOICES.ROUTE,
        },
        {
          buttonTestId:
            NavigationTestIds.INVOICE.CHILDREN.OUTGOING_INVOICES.BUTTON,
          disabledByClientPortal: Boolean(featureFlags?.clientPortal),
          disabledByModuleInvoiceRestriction: Boolean(
            featureFlags?.moduleInvoiceRestriction,
          ),
          filledIcon: <OutgoingInvoiceIcon className="size-5" />,
          isSelected:
            currentPath === ROUTE.OUTGOING_INVOICES.ROUTE ||
            RouteUtils.getMainRoute(currentPath) ===
              ROUTE.OUTGOING_INVOICES.ROUTE,
          name: 'Ausgangsrechnungen',
          outlinedIcon: <OutgoingInvoiceOutlinedIcon className="size-5" />,
          route: ROUTE.OUTGOING_INVOICES.ROUTE,
        },
      ],
      disabledByClientPortal: Boolean(featureFlags?.clientPortal),
      disabledByModuleInvoiceRestriction: Boolean(
        featureFlags?.moduleInvoiceRestriction,
      ),
      filledIcon: <InvoiceIcon className="size-6" />,
      name: 'Rechnungen',
      outlinedIcon: <InvoiceOutlinedIcon className="size-6" />,
    },
    {
      buttonTestId: NavigationTestIds.SUPPLIER.BUTTON,
      children: [
        {
          filledIcon: <SupplierIcon className="size-5" />,
          isSelected: currentPath === ROUTE.DATA_EXCHANGE.ROUTE,
          name: 'Lieferanten',
          outlinedIcon: <SupplierIcon className="size-5" />,
          route: ROUTE.DATA_EXCHANGE.ROUTE,
        },
      ],
      error: dataExchanges.currentIssues,
      filledIcon: <SupplierIcon className="size-6" />,
      name: 'Lieferanten',
      outlinedIcon: <SupplierIcon className="size-6" />,
    },
    {
      buttonTestId: NavigationTestIds.SETTINGS.BUTTON,
      children: [
        {
          filledIcon: <SettingsIcon className="size-5" />,
          isSelected:
            currentPath === ROUTE.SETTINGS_MASTERDATA.ROUTE ||
            RouteUtils.getMainRoute(currentPath) === ROUTE.SETTINGS.ROUTE,
          name: 'Einstellungen',
          outlinedIcon: <SettingsOutlinedIcon className="size-5" />,
          route: ROUTE.SETTINGS_MASTERDATA.ROUTE,
        },
      ],
      filledIcon: <SettingsIcon className="size-6" />,
      name: 'Einstellungen',
      outlinedIcon: <SettingsOutlinedIcon className="size-6" />,
    },
  ];
};

const selectFilters = (state) => state.filters;
const selectDataExchanges = (state) => state.dataExchanges;

/**
 * Custom hook to get the main navigation items.
 *
 * This hook combines various pieces of state and dispatch functions to generate
 * the main navigation items for the application. It uses memoization to optimize
 * performance by only recalculating the navigation items when the dependencies change.
 *
 * @returns {Array} An array of navigation item objects, each containing information
 * about a main navigation item and its children.
 */
export const useMainNavItems = () => {
  const location = useLocation();

  const { data: currentUser } = useQueryUserData(true);
  const featureFlags = currentUser?.featureFlags ?? {};

  const dispatch = useDispatch();

  const filters = useSelector(selectFilters);
  const dataExchanges = useSelector(selectDataExchanges);

  const currentPath = location.pathname;

  return useMemo(
    () =>
      getMainNavItems({
        currentPath,
        dataExchanges,
        dispatch,
        featureFlags,
        filters,
        setDashboardTab,
        setDeliveryTab,
      }),
    [
      currentPath,
      dataExchanges,
      dispatch,
      featureFlags?.clientPortal,
      featureFlags?.packageBasicRestriction,
      featureFlags?.moduleInvoiceRestriction,
      filters,
    ],
  );
};

import { type GridApiRef, useGridApiRef } from '@mui/x-data-grid';
import { keepPreviousData } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQueryCustomFields } from '~/data/customField';
import {
  fetchExportedDeliveryNotePdf,
  fetchExportedReport,
  getReportConfiguration,
  getReportOrder,
  useMutationRequestDeliveryNoteExcel,
  useMutationRequestDeliveryNotePdfExport,
  useQueryDeliveryNoteReport,
} from '~/data/deliveryNote';

import {
  setDashboard_selectableColumns,
  setDashboard_selectedColumns,
} from '~/redux/filtersSlice';

import ExportService from '~/services/export.service';
import FeatureService from '~/services/feature.service';
import ToastService from '~/services/toast.service';

import { moveItem, uniqueBy } from '~/utils/array';
import { Log } from '~/utils/logging';
import { clone } from '~/utils/object';

import { ClientPortalMessage } from '~/components/salesPackages/clientPortal/ClientPortalMessage';
import { PackageBasicRestrictionMessage } from '~/components/salesPackages/packageBasicRestriction/PackageBasicRestrictionMessage';
import { withErrorBoundary } from '~/ui/atoms';
import { DatagridServerDriven } from '~/ui/molecules/Datagrid';
import { type DateRangeOption } from '~/ui/molecules/DateInput';

import { DashboardReportChips } from '../DashboardReportChips';

import { SELECTABLE_COLUMNS } from './constants';
import { selectDashboardReportData } from './selectDashboardReportData';
import { useDashboardPagination } from './useDashboardPagination';
import { useReportColumns } from './useReportColumns';
import { useReportSortingModel } from './useReportSorting';

const FILTER_AND_COLUMN_CHANGE_DEBOUNCE_TIME = 800;

const selectSelectableColumns = (state) =>
  state.filters.dashboard_selectableColumns;
const selectSelectedColumns = (state) =>
  state.filters.dashboard_selectedColumns;
const selectSelectedUnit = (state) => state.filters.dashboard_selectedUnit;

type P = {
  searchBody: any;
  predefinedDateRange: string;
  dateRange: Date[];
};

export const DashboardReport = memo(
  withErrorBoundary(({ searchBody, predefinedDateRange, dateRange }: P) => {
    const apiRef = useGridApiRef() as GridApiRef;

    const dispatch = useDispatch();
    const selectedUnit = useSelector(selectSelectedUnit);
    const selectableColumns = useSelector(selectSelectableColumns);
    const selectedColumns = useSelector(selectSelectedColumns);

    const { mutateAsync: requestExport } =
      useMutationRequestDeliveryNoteExcel();
    const { mutateAsync: requestPdfExport } =
      useMutationRequestDeliveryNotePdfExport();

    const { data: customFieldsData, isLoading: isLoadingCustomFields } =
      useQueryCustomFields();
    const customFields = customFieldsData ?? [];

    const { paginationModel, handlePaginationModelChange } =
      useDashboardPagination(searchBody);

    const { sortingModel, handleSortingModelChange } = useReportSortingModel();

    const reportConfig = useMemo(
      () => getReportConfiguration(selectedColumns, customFields),
      [selectedColumns, customFields],
    );
    const reportOrder = useMemo(
      () => getReportOrder(selectedColumns, customFields),
      [selectedColumns, customFields],
    );
    const searchParamsObject = useMemo(
      () => ({
        ...searchBody,
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        orderBy: sortingModel.orderBy,
        reportConfig,
        reportOrder,
        sortOrder: sortingModel.sortOrder,
        unitType: selectedUnit || null,
      }),
      [
        paginationModel.page,
        paginationModel.pageSize,
        reportConfig,
        reportOrder,
        searchBody,
        selectedUnit,
        sortingModel.orderBy,
        sortingModel.sortOrder,
      ],
    );

    const searchParams = useDebounce(
      searchParamsObject,
      FILTER_AND_COLUMN_CHANGE_DEBOUNCE_TIME,
    );
    const {
      data: reportData,
      isFetching: isFetchingReportData,
      isLoading: isLoadingReportData,
    } = useQueryDeliveryNoteReport(searchParams, {
      enabled: !isLoadingCustomFields && Boolean(reportConfig?.length > 0),
      placeholderData: keepPreviousData,
      select: selectDashboardReportData,
    });

    const { getColumns } = useReportColumns({
      isLoading: isFetchingReportData,
      reportData,
      selectableColumns,
      selectedColumns,
    });

    useEffect(() => {
      if (!customFields) {
        return;
      }

      const columns =
        selectableColumns.length > 0
          ? clone(selectableColumns)
          : clone(SELECTABLE_COLUMNS);

      for (const customField of customFields) {
        columns.push({
          disableReorder: true,
          field: customField.key,
          headerName: customField.displayName,
          resizable: true,
          sortable: true,
        });
      }

      dispatch(
        setDashboard_selectableColumns(uniqueBy(columns, ({ field }) => field)),
      );

      // Only reorder selectedColumns on initial load if they exist in localStorage
      if (selectedColumns.length === 0) {
        const fields = columns.map(({ field }) => field);
        const reorderedSelectedColumns = selectedColumns
          .filter((field) => fields.includes(field))
          .sort((a, b) => fields.indexOf(a) - fields.indexOf(b));

        // Detect changes in content and order
        const hasChanges =
          JSON.stringify(reorderedSelectedColumns) !==
          JSON.stringify(selectedColumns);

        if (hasChanges) {
          dispatch(setDashboard_selectedColumns(reorderedSelectedColumns));
        }
      }
    }, [JSON.stringify(customFields)]);

    const handleExportExcelFromBackend = async () => {
      const searchParams = {
        ...searchBody,
        reportConfig,
        reportOrder,
        unitType: selectedUnit || null,
      };

      const response = await requestExport(searchParams);

      const data = await fetchExportedReport(response.requestId);

      ExportService.exportExcelFromBlob(data, {
        customRange: dateRange,
        entityType: ExportService.ENTITIES.REPORT,
        predefinedRange: predefinedDateRange,
      });
    };

    const handleExportPdfFromBackend = async (
      format = ExportService.FILE_FORMATS.PDF,
    ) => {
      const exportPromise = (async () => {
        try {
          const exportRequestResponse = await requestPdfExport({
            ...searchBody,
            format,
          });

          const data = await fetchExportedDeliveryNotePdf(
            exportRequestResponse.requestId,
          );

          ExportService.downloadFileWithCustomName(
            data,
            ExportService.generateFileName({
              entityType: ExportService.ENTITIES.DELIVERY_NOTES,
              format,
              predefinedRange: predefinedDateRange as DateRangeOption,
              date:
                predefinedDateRange === 'individual'
                  ? ExportService.generateDateStringFromDateRange(dateRange)
                  : undefined,
            }),
          );

          Log.productAnalyticsEvent(
            'User downloaded delivery note PDF bundle',
            Log.FEATURE.PDF_DOWNLOAD,
            'user',
            {
              format,
            },
          );
        } catch (error) {
          Log.error('Failed to export PDF of delivery notes.', error);

          Log.productAnalyticsEvent(
            'Failed to download delivery note PDF',
            Log.FEATURE.PDF_DOWNLOAD,
            Log.TYPE.ERROR,
          );

          throw error; // Re-throw to trigger toast error state
        }
      })();

      ToastService.promise(
        exportPromise,
        ['PDF-Export wird erstellt…'],
        ['PDF-Export abgeschlossen'],
        ['PDF-Export fehlgeschlagen. Bitte kontaktiere den Support.'],
      );

      await exportPromise;
    };

    const handleToggleColumnIncludedState = useCallback(
      (clickedField) => {
        Log.productAnalyticsEvent('(De)select column', Log.FEATURE.REPORT);

        let newSelectedFields = [...selectedColumns];

        if (selectedColumns.includes(clickedField)) {
          newSelectedFields = newSelectedFields.filter(
            (field) => field !== clickedField,
          );
        } else {
          // Insert the clickedField at same relative position as in selectableColumns
          const orderedFields = selectableColumns.map(({ field }) => field);

          const insertIndex = newSelectedFields.findIndex(
            (field) =>
              orderedFields.indexOf(field) >
              orderedFields.indexOf(clickedField),
          );

          if (insertIndex === -1) {
            newSelectedFields.push(clickedField);
          } else {
            newSelectedFields.splice(insertIndex, 0, clickedField);
          }
        }

        dispatch(setDashboard_selectedColumns(newSelectedFields));
      },
      [selectedColumns, selectableColumns, dispatch],
    );

    const handleReorderColumns = useCallback(
      (sourceIndex, destinationIndex) => {
        Log.productAnalyticsEvent('Reorder columns', Log.FEATURE.REPORT);

        let newSelectableColumns = clone(selectableColumns);
        newSelectableColumns = moveItem(
          newSelectableColumns,
          sourceIndex,
          destinationIndex,
        );
        dispatch(setDashboard_selectableColumns(newSelectableColumns));

        const sortedColumns = newSelectableColumns.map(({ field }) => field);
        dispatch(
          setDashboard_selectedColumns(
            sortedColumns.filter((column) => selectedColumns.includes(column)),
          ),
        );
      },
      [selectableColumns, selectedColumns, dispatch],
    );

    const toolbarItems = [
      {
        id: 'export-xlsx-server-side',
        icon: 'excel',
        title: 'Daten exportieren (Excel)',
        actions: [
          {
            name: 'Daten exportieren (Excel)',
            onClick() {
              handleExportExcelFromBackend().catch((error) => {
                console.error('Failed to export Excel:', error);
              });
            },
          },
        ],
      },
      {
        id: 'export-pdf-server-side',
        icon: 'pdf',
        title: 'PDFs exportieren',
        actions: [
          {
            name: 'Als ein zusammengefasstes PDF',
            onClick() {
              handleExportPdfFromBackend('pdf').catch((error) => {
                console.error('Failed to export composite PDF file:', error);
              });
            },
          },
          {
            name: 'Als zip-File mit einzelnen PDFs',
            onClick() {
              handleExportPdfFromBackend('zip').catch((error) => {
                console.error('Failed to export zip file of PDF files:', error);
              });
            },
          },
        ],
      },
    ];

    const { page, pageSize } = paginationModel;

    const paginationText = `${page * pageSize + 1}-${Math.min(
      (page + 1) * pageSize,
      page * pageSize + ((reportData?.paginatedCount as number) ?? 0),
    )}`;

    if (FeatureService.clientPortal()) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <ClientPortalMessage />
        </div>
      );
    }

    if (FeatureService.packageBasicRestriction()) {
      return (
        <div className="flex flex-1 items-center justify-center">
          <PackageBasicRestrictionMessage />
        </div>
      );
    }

    return (
      <div className="flex flex-1 flex-col">
        <div className="py-2">
          <DashboardReportChips
            selectableColumns={selectableColumns}
            selectedColumns={selectedColumns}
            onClick={handleToggleColumnIncludedState}
            onReorderColumns={handleReorderColumns}
          />
        </div>
        {selectedColumns.length === 0 ? (
          <div className="pb-2">
            * Bitte wähle mindestens eine Spalte (Status, LFS-Datum, ...) aus,
            um die Berichte-Funktion zu verwenden.
          </div>
        ) : null}
        <div className="h-[600px] overflow-x-auto">
          <DatagridServerDriven
            apiRef={apiRef}
            checkboxSelection={false}
            columns={getColumns()}
            isLoading={isLoadingReportData || isFetchingReportData}
            paginationMeta={{ totalRowCount: reportData?.totalCount ?? 0 }}
            paginationModel={paginationModel}
            paginationText={paginationText}
            rows={reportData?.data ?? []}
            sortingMode="server"
            toolbarItems={toolbarItems}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortingModelChange}
          />
        </div>
      </div>
    );
  }, 'Berichts-Daten konnten nicht geladen werden.'),
);

DashboardReport.displayName = 'DashboardReport';

import { type DeliveryNoteListItem } from '~/data/deliveryNote/types';

export const getConstructionPlans = (
  assetMain: Partial<DeliveryNoteListItem> = {},
) => {
  const constructionPlans = [];

  if (assetMain?.assetState?.body?.transaction?.logisticsPackage)
    for (const logisticsPackage of assetMain?.assetState?.body?.transaction
      ?.logisticsPackage) {
      if (logisticsPackage?.lineItem)
        for (const lineItem of logisticsPackage?.lineItem) {
          if (
            lineItem?.product?.constructionPlan &&
            !constructionPlans.includes(lineItem.product?.constructionPlan)
          ) {
            constructionPlans.push(lineItem.product.constructionPlan);
          }
        }
    }

  return constructionPlans.join(', ');
};

import { getNestedProperty, isPlainObject } from '~/utils/object';
import { getAbbreviatedUnit, getBiggestUnit } from '~/utils/unit';
import UnitUtils from '~/utils/unitUtils';

/**
 * Calculates the total weight of a list of articles.
 * Aggregates the values and returns the total in the biggest unit from the data.
 *
 * @param {Array<Object>} articles - An array of articles with weight information.
 * @return {string|undefined} The total weight formatted as a string with the unit abbreviation, or null if no weight information is available.
 */

export const calculateTotalWeight = (
  articles: any[],
  path = 'weight',
  isBrokenDown = false,
) => {
  if (!articles || articles.length === 0) {
    return undefined;
  }

  const articleWeights = articles
    .map((article) => {
      const nestedValue = getNestedProperty(article, path);

      return nestedValue && isPlainObject(nestedValue)
        ? {
            unit: nestedValue.unit,
            value: nestedValue.value,
          }
        : undefined;
    })
    .filter((weight) => weight?.value && weight.unit)
    .map(({ unit, value }) => ({
      unit,
      value,
    }));

  if (articleWeights.length === 0) {
    return undefined;
  }

  const targetUnit = getBiggestUnit(articleWeights.map(({ unit }) => unit));

  if (!targetUnit) {
    return undefined;
  }

  const totalWeightValue = articleWeights.reduce(
    (total, { value, unit }) =>
      total +
      Number(UnitUtils.calculateWeightInTargetUnit(value, unit, targetUnit)),
    0,
  );

  if (totalWeightValue === 0) {
    return undefined;
  }

  const formattedTotalWeight =
    UnitUtils.roundAndFormatDe_safe(totalWeightValue);

  if (formattedTotalWeight === null) {
    return undefined;
  }

  if (isBrokenDown) {
    return {
      amount: formattedTotalWeight,
      unit: getAbbreviatedUnit(targetUnit),
    };
  }

  return [formattedTotalWeight, getAbbreviatedUnit(targetUnit)].join(' ');
};

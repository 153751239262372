import { Button, Popover } from '@mui/material';

import { MissingPermissionsTooltip } from '~/utils/componentUtils';

import { Spinner } from '~/ui/atoms/Spinner';

export const BasicPopover = ({
  anchorEl,
  children,
  customDeleteButton,
  disableSubmitButton,
  formSuccess,
  missingPermissionsToSubmit,
  onClose,
  open,
  submitButtonTitle = 'Speichern',
  submittingForm,
}) => {
  // Renders the submit button with loading state and permission checks
  const getSubmitButton = () => {
    const button = (
      <Button
        className={
          missingPermissionsToSubmit || disableSubmitButton
            ? 'disabled-primary-button'
            : 'primary-button'
        }
        color="primary"
        disabled={
          submittingForm || disableSubmitButton || missingPermissionsToSubmit
        }
        type="submit"
        variant="contained"
      >
        {submittingForm ? (
          <Spinner title={`${submitButtonTitle}...`} white />
        ) : (
          submitButtonTitle
        )}
      </Button>
    );

    // Wrap button with permissions tooltip if user lacks permissions
    if (missingPermissionsToSubmit) {
      return <MissingPermissionsTooltip>{button}</MissingPermissionsTooltip>;
    }

    return button;
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      open={open}
      transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      onClose={onClose}
    >
      <form onSubmit={formSuccess}>
        <div className="min-w-80 px-4 pt-4 pb-20">
          {' '}
          {/* Min width to prevent that footer buttons don't fit the whole row */}
          {children}
        </div>
        <div className="sticky-dialog-footer flex w-full items-center justify-between px-4">
          <div>{customDeleteButton}</div>
          <div className="flex items-center gap-2">
            <Button variant="text" onClick={onClose}>
              Abbrechen
            </Button>
            {getSubmitButton()}
          </div>
        </div>
      </form>
    </Popover>
  );
};

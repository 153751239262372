import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';

import { queryKeysCustomField } from './queryKeys';

type CustomFieldOption = {
  id: UUID;
  value: string;
};

type CustomField = {
  id: UUID;
  name: string;
  options?: CustomFieldOption[];
  type: string;
};

type BulkCustomFieldsResponse = {
  items: Array<{
    customField: CustomField;
    customFieldOptions: CustomFieldOption[];
  }>;
};

/**
 * Fetches multiple custom fields in bulk from the API.
 * @param {UUID[]} customFieldIds - Array of custom field IDs to fetch.
 * @returns {Promise<CustomField[]>} Array of custom field data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Custom-Field/operation/query_custom_fields_in_bulk_custom_field_query_bulk_post
 */
export const fetchCustomFieldsBulk = async (customFieldIds: UUID[]) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.CUSTOM_FIELD.GET_BULK(), {
        json: {
          ids: customFieldIds,
          include_options: true,
        },
      })
      .json<BulkCustomFieldsResponse>();

    // Transform the response to match the expected format
    return (
      response?.items.map((item) => ({
        ...item.customField,
        options: item.customFieldOptions,
      })) ?? []
    );
  } catch (error) {
    Log.error('Error fetching custom fields in bulk', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting multiple custom fields in bulk.
 * @param {UUID[]} customFieldIds - Array of custom field IDs to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<CustomField[]>} The query result containing array of custom fields.
 */
export const useQueryCustomFieldsBulk = (
  customFieldIds: UUID[],
  options?: Omit<UseQueryOptions<CustomField[]>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    enabled: customFieldIds.length > 0,
    queryFn: async () => fetchCustomFieldsBulk(customFieldIds),
    queryKey: queryKeysCustomField.getBulk(customFieldIds),
    ...options,
  });
